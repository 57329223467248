import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useEffect } from "react";
import { useState } from "react";
import { StyledTableCell, StyledTableRow } from "../../Styles";
import { useDispatch, useSelector } from "react-redux";
import NoRecords from "../Dashboard/Other-components/NoRecords";
import AddIcon from "@material-ui/icons/Add";
import { setIsSubmitted} from "../../Reducers/loginSlice"
import RemoveIcon from "@material-ui/icons/Remove";
import moment from "moment";
import { getCookie } from "../../Utils/commonUtils";
import { HashLink as Link } from "react-router-hash-link";
import { CustomerHistoryApi } from "../../Services/CustomerHistoryApi";
import { DRRApi } from "../../Services/DRRApi";
import { NavLink, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import SearchIcon from '@material-ui/icons/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {
  CircularProgress,
  InputAdornment,
  TablePagination,
  TextField,
  Button
} from '@material-ui/core'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from 'reactstrap'


const useStyles = makeStyles({
  mainContainer: {
    padding: "80px 30px 30px 30px",
  },
  table: {
    minWidth: 700,
  },

  button: {
    backgroundColor: "#3A1C67",
    color: "#fff",
    marginLeft: "10px",
    borderRadius: "5px",
    fontSize: "12px",
    padding: "5px 20px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#7916BD",
    },
  },
  tableContainer: {
    margin: "20px 0px",
    maxHeight: 450,
    width: "100%",
    "&::-webkit-scrollbar": {
      width: "0.5em",
      display: "block",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "darkgrey",
      outline: "1px solid slategrey",
      borderRadius: "5px",
    },
  },
  addIcon: {
    transition: "opacity 0.3s ease-in-out"
  },
  
  minusIcon: {
    transition:' opacity 0.3s ease-in-out'
  },
  filterHolder: {
    display: 'flex',
    gap: '20px',
    justifyContent: 'space-between'
  },
});
export default function DRRTeleCaller(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [action, setAction] = useState("Add");
  const { userInfo } = useSelector((state) => state.login);
  // const { filteredUsers ,loading,totalCount} = useSelector((state) => state.users)
  const [offset, setOffset] = useState(1);
  const [updated, setUpdated] = useState(true);
  const [confirm, setConfirm] = useState(false);
  const [deletedUser, setDeletedUser] = useState("");
  const [deleteLoader, setDeleteLoader] = useState(false);
  const loginInfo = getCookie("callpal-user");
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingdetail, setLoadingDetail] = useState(false);

  
  // -------------------------------------------------
  const [editedName, setEditedName] = useState('');
  const [editingIndex, setEditingIndex] = useState(-1);
  const [targetList, setTargetList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [currentValue, setCurrentValue] = useState('');
  const params = useParams()
  const teamlead_id = atob(params.temlead_id);
  const navigate = useNavigate()
  const [dayWiseTargetList, setDayWiseTargetList] = useState([]);
  const [totalTarget, setTotalTarget] = useState();
  const [totalDisbursement, setTotalDisbursement] = useState();
  const [remainingTarget, setRemainingTarget] = useState();
  const [dailyAvgTar, setDailyAvgTar] = useState();
  const [targetRemainingDay, setTargetRemainingDay] = useState();
  const [currentYear, setCurrentYear] = useState(moment().year());
  const [currentMonth, setCurrentMonth] = useState(moment().format('MM'));
  const [monthArr, setMonthArr] = useState([{ id: 1, name: 'January' },
  { id: 2, name: 'February' },
  { id: 3, name: 'March' },
  { id: 4, name: 'April' },
  { id: 5, name: 'May' },
  { id: 6, name: 'June' },
  { id: 7, name: 'July' },
  { id: 8, name: 'August' },
  { id: 9, name: 'September' },
  { id: 10, name: 'October' },
  { id: 11, name: 'November' },
  { id: 12, name: 'December' }]);
  const [monthSearch, setMonthSearch] = useState('');
  const [monthLoader, setMonthLoader] = useState(false);
  const [monthDetails, setMonthDetails] = useState({
    id: '',
    name: ''
  });


  // -------------------------------------------------

  const [expandedRow, setExpandedRow] = useState(null); // Initialize state

  const handlePlusClick =  async (index,id) => {
    setExpandedRow((prev) => (prev === index ? null : index)); // Toggle expansion
    const isExpanded = expandedRow === index;
    if (!isExpanded) {
      try {
        setLoadingDetail(true);
        const data = JSON.stringify({
          user_id : id,
         
        });
        const res = await DRRApi.getDRRPerDayTaleCallerTargetList(data);
        if (res.status) {
          setDayWiseTargetList(res.data);
          setTotalTarget(res.totalTarget);
          setTotalDisbursement(res.totalDisbursement);
          setRemainingTarget(res.remainingTarget);
          setTargetRemainingDay(res.remainingDay);
          setDailyAvgTar(res.dailyAvgTar);
          
        }
      } catch (err) {
        setLoadingDetail(false);
        console.error(err);
      } finally {
        setLoadingDetail(false);
      } 
    }

  };

  const handleChangePage = (event, newPage) => {
    setUpdated(true);
    setPage(newPage);
    let num = 0;
    if (newPage > page) {
      num = offset + 1;
    } else {
      num = offset - 1;
    }
    setOffset(num);
  };

  const handleChangeRowsPerPage = (event) => {
    setUpdated(true);
    setRowsPerPage(event.target.value);
    setPage(0);
    setOffset(0);
  };

  const headers = [
    "Employee Id",
    "User Name",
    "Role",
    "Target",
    "Disbursement Amount",
    "Action",
  ];

  useEffect(() => {
    if (loginInfo?.userInfo?.mobile_number && updated) {
        // getCustomerTeamLeadDetails();
        setUpdated(false);
    }
    TaleCallerTargetList(currentMonth);
    // handleUpdateInfo();
  }, [userInfo, page, rowsPerPage, updated]);


  // ------------------------------------------------------
 
  const handleFieldClick = (index) => {
    setEditingIndex(index);
  };

   const handleBlur = (e, id, index) => {
    const targetAmount = e.target.value;
    const user_id = id;
    if (e.target.value.toLowerCase().includes('e')) {
      dispatch(setIsSubmitted({ open: true, severity: "error", msg: "Only numbers are allowed." }));
      e.preventDefault();
    }
    if (currentValue && rows) { // Check if currentValue and rows are defined
      const updatedRows = [...rows];
      updatedRows[index].target = currentValue; // Update the target value in the corresponding row
      setRows(updatedRows); // Update the state with the new rows
      setEditingIndex(null); // Disable editing mode
      updateTaleCallerTargetAmount(targetAmount,user_id)
    }
    setEditingIndex(-1);
  };

  const handleKeyDown = (e,id, index) => {
    const targetAmount = e.target.value;
    const user_id = id;
    if (e.key === 'Enter') {
      if (e.key === 'e' || e.key === 'E') {
        dispatch(setIsSubmitted({ open: true, severity: "error", msg: "Only numbers are allowed." }));
        e.preventDefault();
    }
      if (currentValue && rows) { // Check if currentValue and rows are defined
        const updatedRows = [...rows];
        updatedRows[index].target = currentValue; // Update the target value in the corresponding row
        setRows(updatedRows); // Update the state with the new rows
        setEditingIndex(null); // Disable editing mode
        updateTaleCallerTargetAmount(targetAmount,user_id)
      }
    }
  };

   const updateTaleCallerTargetAmount =async (targetAmount, id, ) => {
    try {
      
      setLoading(true);
      const data = JSON.stringify({
        target_amount:targetAmount,
        team_lead_id: teamlead_id,
        user_id: id,
        month: currentMonth,
        year:currentYear, 
      })
      const res = await DRRApi.updateTaleCallerTarget(data);
      if(res.status) {
        dispatch(
          setIsSubmitted({ open: true, severity: "success", msg: res.message }),
        );
      }else{
        dispatch(
          setIsSubmitted({ open: true, severity: "error", msg: res.message }),
        );
      }
    } catch (err) {
      setLoading(false);
    } finally {
      setLoading(false);
   }

  };



  const TaleCallerTargetList = async (monthNumber) => {
    try {
      setLoading(true);
      const currentMonth = moment().format('MM');
      const currentYear = moment().year();
      const data = JSON.stringify({
        user_id: teamlead_id,
        per_page_row: rowsPerPage,
        page: offset,
        month: parseInt(monthNumber),
        year:currentYear, 
      });
      const res = await DRRApi.getDRRTaleCallerTargetList(data);
      if (res.status) {
        setRows([...res.data]);
        setIsEdit(res.is_edit)
        setCount(res.total_count)

      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    } finally {
      setLoading(false);
    } 
  }


  const handleDropdownItemClick = (el) => {
    // Action for onClick: Set current month
    setCurrentMonth(el.id);
    TaleCallerTargetList(el.id);
    // Action for onChange: Update month details
    setMonthDetails({
      ...monthDetails,
      id: el.id,
      name: el.name,
    });
  };
  



  return (
    <div className={classes.mainContainer}>
  <div className={classes.filterHolder}>
        <div
          style={{
            color: '#F86E20',
            textAlign: 'center',
            fontFamily: 'Open-sans',
            width: 'fit-content',
          }}
        >
          <div style={{ fontWeight: 600, fontSize: '20px', color: '#F86E20' }}>
            DRR Tale Caller 
          </div>
        
        </div>
        <div style={{ display: 'flex' }}>
          
          <div className={classes.filterHolderComp}>
            <UncontrolledButtonDropdown>
              <DropdownToggle
                style={{
                  display: 'flex',
                  gap: '10px',
                  background: 'white',
                  color: '#3A1C67',
                  border: 'none',
                  borderRadius: '5px',
                  width:200,
                  justifyContent: 'space-between'
                }}
              >
              {monthDetails.name.length==0?'Select Months':monthDetails.name}
              <ArrowDropDownIcon></ArrowDropDownIcon>
              </DropdownToggle>
              <DropdownMenu>
              {monthArr.length > 0 && !monthLoader && (
                <>
          <DropdownItem header>
            <TextField
              placeholder="Search Months" 
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              size='small' variant='outlined' onChange={(e) => setMonthSearch(e.target.value)} />
                </DropdownItem>
                <div style={{ maxHeight: 150, overflowY: 'scroll' }}>
                  {
                    monthArr.length > 0 && monthArr.filter((e) => (e.name.toString().toLowerCase().includes(monthSearch.toLowerCase()))
                    ).map((el, i) => (
                      <DropdownItem key={el.id} 
                      onClick={() => {
                        handleDropdownItemClick(el);

                        // setCurrentMonth(el.id); // Semicolon to separate statements
                        // setMonthDetails({ ...monthDetails, id: el.id, name: el.name });
                      }}
                      >{el.name}</DropdownItem>
                    ))
                  }
                </div>
                </>
                )} 
                {monthLoader && (
                        <div style={{ textAlign: "center" }}>
                    <CircularProgress size={20} />
                  </div>
                )}
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>

          <Button className={classes.button} onClick={() => navigate(-1)}> 
               Back
          </Button>
        </div>
        </div>
      
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table
          stickyHeader
          className={classes.table}
          aria-label="customized table"
          size="small"
        >
          <TableHead>
            <TableRow>
              {headers.map((el, i) => {
                return (
                  <StyledTableCell key={el} align="center">
                    {el}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>  
          <TableBody>
        {rows.map((row, index) => (
          <React.Fragment key={index}>
            <StyledTableRow>
            <StyledTableCell align="center">{row.employee_id}</StyledTableCell>
              <StyledTableCell align="center">{row.name}</StyledTableCell>
              <StyledTableCell align="center">{row.role}</StyledTableCell>
              {row.is_edit ? ( 
                  <StyledTableCell align="center" onClick={() => handleFieldClick(index)}>
                    {editingIndex === index ? 
                      <input
                          key={index}
                          type="number"
                          defaultValue={row.target}
                          onChange={(e) => setCurrentValue(e.target.value)} // Update current value on change
                          onBlur={(e) => handleBlur(e, row.id, index)} // Trigger blur handler to save changes
                          onKeyDown={(e) => handleKeyDown(e, row.id, index)} // Pass index to handleKeyDown
                          // readOnly={true} 
                      />
                     : row.target ? row.target : "-"
                     }
                  </StyledTableCell>
              ) : (
                  <StyledTableCell align="center">{row.target ? row.target : "-"}</StyledTableCell>
              )}

              <StyledTableCell align="center">{row.disbursementAmount ? row.disbursementAmount  : "-"}</StyledTableCell>
              <StyledTableCell align="center">
                <span onClick={() => handlePlusClick(index, row.id)}> {expandedRow === index ? (<RemoveIcon className={classes.minusIcon} />) : (<AddIcon className={classes.addIcon} />)} </span>
              </StyledTableCell>
            </StyledTableRow>
             {expandedRow === index && (
                <>
                <StyledTableRow style={{backgroundColor:"rgb(255 248 248)"}}>
                <StyledTableCell  colSpan={6}>
               <div className="row" style={{padding:"11px"}}>

               {dayWiseTargetList && dayWiseTargetList.length > 0 ? dayWiseTargetList.map((el, i) => (
                    <div key={i} className="col-md-2" style={{borderRight: "1px solid #CCC", height:"30px"}}>
                   <strong>{moment(el.date).format('DD MMMM')}</strong> : {el.title === "off" ?  <span className="text-danger"> {el.title} </span> : <span> ₹ {el.disbursement} </span>}
                  
                    </div>
                    
                ))
              : <NoRecords rows={dayWiseTargetList} loading={loadingdetail} />
              }

               </div>
               <br></br>
               <StyledTableRow style={{backgroundColor:"rgb(249 236 236)"}}>
                <StyledTableCell  colSpan={6} style={{borderBottom:"none",padding:"0px"}}>
                <StyledTableCell align="center"><strong>Total Target</strong> :  {totalTarget ? "₹" + totalTarget : "-" } </StyledTableCell>
                <StyledTableCell align="center"><strong>Total Disbursement</strong> :{totalDisbursement ? "₹" + totalDisbursement : "-" }</StyledTableCell>
                <StyledTableCell align="center"><strong>Remeaning Target</strong> :{remainingTarget ? "₹" + remainingTarget : "-" } </StyledTableCell>
                <StyledTableCell align="center"><strong>Remeaning No of Days</strong> : {targetRemainingDay && targetRemainingDay > 0 ? targetRemainingDay : "-" }</StyledTableCell>
                <StyledTableCell align="center"><strong>Daily Avg Target</strong> : {dailyAvgTar ? "₹" + dailyAvgTar : "0" }</StyledTableCell>
                </StyledTableCell>
                </StyledTableRow>

                </StyledTableCell>
                </StyledTableRow>


              
                </>

               
            )}
          </React.Fragment>
        ))}
      </TableBody>
        </Table>
        <NoRecords rows={rows} loading={loading} />

      </TableContainer>
     

      <div>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          backIconButtonProps={{
            "aria-label": "Previous Page",
            style: {
              color: "#fff",
              backgroundColor: "#3A1C67",
              padding: 3,
              borderRadius: "5px",
              marginRight: "5px",
            },
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
            style: {
              color: "#fff",
              backgroundColor: "#3A1C67",
              padding: "3px",
              borderRadius: "5px",
            },
          }}
        />
      </div>
    </div>
  );
}
