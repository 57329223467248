
import {  makeStyles } from '@material-ui/core/styles'
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {   Button, TablePagination, TextField } from '@material-ui/core'
import { Sort } from '@material-ui/icons';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor:"#F1F1F1",
      color: '#3A1C67',
      fontWeight:700,
      fontSize:16

    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  




const useStyles = makeStyles({
    mainContainer: {
      padding: '80px 30px 30px 30px',
    },
    headerContaniner: {
      display:'flex',
      flexDirection:'row',
      justifyContent:'space-between',
      marginBottom:'20px'
    },
    
    headerLeft:{
    display:'flex',
      flexDirection:'column',
    },
    title:{
        fontSize:'18px',
        fontWeight:'700',
        color: '#3A1C67',
        textAlign:'left'
    },
    
    button: {
        // backgroundColor: '#FFFF',
        color: '##3A1C67',
        marginLeft: '10px',
        borderRadius: '5px',
        fontSize: '14px',
        border:'1px solid #3A1C67',
        textTransform: 'none',
        padding: '5px 20px',
        '&:hover': {
        //   backgroundColor: '#FFFF',
        },
      },
      viewAll:{
        display:'flex',
        flexDirection:'row-reverse',
        marginTop:'20px',
        fontSize:'12px',
        fontWeight:700,
        color:'#3A1C67'

      }
  })

const Enquiries=()=>{
    const classes = useStyles();
    const headers = ['Id', 'Name', 'Email', 'Mobile']
    const rows=[
        {Id:'',Name:'',Email:'',Mobile:''},
        {Id:'',Name:'',Email:'',Mobile:''},
        {Id:'',Name:'',Email:'',Mobile:''},
        {Id:'',Name:'',Email:'',Mobile:''},
        {Id:'',Name:'',Email:'',Mobile:''},
        {Id:'',Name:'',Email:'',Mobile:''},
        {Id:'',Name:'',Email:'',Mobile:''},
        {Id:'',Name:'',Email:'',Mobile:''},
        {Id:'',Name:'',Email:'',Mobile:''},
        {Id:'',Name:'',Email:'',Mobile:''}
    ]
   
   
    return(
        <div className={classes.mainContainer}>
            <div className={classes.headerContaniner}>
                <div className={classes.headerLeft}>
                    <span className={classes.title}> Live Demo Enquiry </span>
                  
                  <div style={{height:'20px',marginTop:'10px'}}>  
                  <TextField
                
                        InputProps={{
                            style:{height:'30px'},
                        startAdornment: (
                            <InputAdornment position="start">
                            <SearchIcon />
                            </InputAdornment>
                        ),
                        }}
                    variant="outlined"
                    style={{height:'30px'}}
                    
      />
                </div>
                </div>
                <div>
        
            <Button className={classes.button}  endIcon={<Sort />}>
        Sort
        </Button>
             
                </div>

            </div>

        <TableContainer component={Paper} >
            <Table
             sx={{ minWidth: 700 }} aria-label="customized table"
            >
            <TableHead>
                <TableRow>
                {headers.map((el, i) => {
                    return (
                    <StyledTableCell key={el} align='center'>
                        {el}
                    </StyledTableCell>
                    )
                })}
                </TableRow>
            </TableHead>

            <TableBody>
                {
                rows.length > 0  && rows.map((row) => (
                    <StyledTableRow key={row.Id}>
                    <StyledTableCell align="center">{row.Id}</StyledTableCell>
                    <StyledTableCell align="center">{row.Name}</StyledTableCell>
                    <StyledTableCell align="center">{row.Email}</StyledTableCell>
                    <StyledTableCell align="center">{row.Mobile}</StyledTableCell>
                    </StyledTableRow>
                ))}
                {/* <NoRecords rows={rows} loading={loading} />2 */}
            </TableBody>
            </Table>
      </TableContainer>

      <div className={classes.viewAll}>
    View All
      </div>   
        </div>
    )
}
export default Enquiries