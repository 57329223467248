import React from "react";

import { Button, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useEffect, useState } from "react";
import CreateTemplatePopper from "../Other-components/CreateTemplatePopper";
import EditTemplate from "./EditTemplate";
import { Skeleton } from "@mui/material";
import { getCookie } from "../../../Utils/commonUtils";

import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { TablePagination } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import { StyledTableCell } from "../../../Styles";
import EditIcon from "@material-ui/icons/Edit";
import trashIcon from "../../../Images/trash.svg";
import NoRecords from "../Other-components/NoRecords";
import TableBody from "@material-ui/core/TableBody";
import { StyledTableRow } from "../../../Styles";
import { setIsSubmitted } from "../../../Reducers/loginSlice";
import ConfirmationPopper from "../Other-components/ConfirmationPopper";

import { useDispatch, useSelector } from "react-redux";
import { SmsTemplateApi } from "../../../Services/SmsTemplateApi";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "80px 30px 30px 30px",
  },
  heading: {
    color: "#3A1C67",
    fontSize: "18px",
    fontWeight: 700,
    textAlign: "left",
    marginLeft: 20,
  },
  button: {
    backgroundColor: "#3A1C67",
    color: "#fff",
    textTransform: "none",
    height: "32px",
    borderRadius: "10px",
    fontSize: "14px",
  },
  templateContainer: {
    width: "50%",
    overflowY: "scroll",
    height: 480,
    "&::-webkit-scrollbar": {
      width: "0.4em",
      display: "block",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "darkgrey",
      outline: "1px solid slategrey",
      borderRadius: "5px",
    },
  },
  templateBox: {
    backgroundColor: "#fff",
    padding: 15,
    borderRadius: "10px",
    margin: 8,
    textAlign: "left",
    cursor: "pointer",
  },
  editContainer: {
    width: "50%",
    height: 480,
    backgroundColor: "#fff",
    border: "14px",
    margin: "0px 10px",
    padding: "20px",
  },
  editContent: {
    width: "auto",
    height: "250px",
    overflowY: "scroll",
    textAlign: "left",
    fontSize: "12px",
    margin: "20px 10px",
    padding: 20,
    color: "#3A1C67",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;",
    "&::-webkit-scrollbar": {
      width: "0.4em",
      display: "block",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "darkgrey",
      outline: "1px solid slategrey",
      borderRadius: "5px",
    },
  },
  cancelButton: {
    backgroundColor: "#fff",
    color: "#3A1C67",
    textTransform: "none",
    height: "32px",
    borderRadius: "10px",
    border: "1px solid #3A1C67",
    fontSize: "14px",
  },
  tempMsg: {
    padding: "5px 0px",
    fontSize: 12,
    whiteSpace: "nowrap",
    maxWidth: 500,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const SmsTemplate = () => {
  const [selected, setSelected] = useState({});
  const [cancelled, setCancelled] = useState(true);
  const [hoverIndex, setHoverIndex] = useState(-1);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { userInfo } = useSelector((state) => state.login);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState(true);
  const loginInfo = getCookie("callpal-user");

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [offset, setOffset] = useState(1);
  const [count, setCount] = useState(0);
  const [selectedUser, setSelectedUser] = useState({});
  const [deletedUser, setDeletedUser] = useState("");
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [action, setAction] = useState("Add");
  const [confirm, setConfirm] = useState(false);
  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    setUpdated(true);
    setPage(newPage);
    let num = 0;
    if (newPage > page) {
      num = offset + 1;
    } else {
      num = offset - 1;
    }

    setOffset(num);
  };

  const handleChangeRowsPerPage = (event) => {
    setUpdated(true);
    setRowsPerPage(event.target.value);
    setPage(0);
    setOffset(0);
  };

  const headers = ["Template Name", " Template Message", "Created By", "Action"];

  useEffect(() => {
    if (
      (userInfo.hasOwnProperty("mobile_number") ||
        userInfo?.userInfo?.mobile_number) &&
      updated
    ) {
      getSMSTemplateData();
      setUpdated(false);
    }
  }, [userInfo, updated]);

  const getSMSTemplateData = async () => {
    try {
      setLoading(true);
      const data = JSON.stringify({
            user_id: loginInfo.userInfo.id,
            per_page_row: rowsPerPage,
            page: offset,
      });
      const res = await SmsTemplateApi.getSmsTemplateList(data);
      if (res.status) {
        setRows([...res.data]);
        setCount(res.total_count);
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };


  const deleteSmsTemplate = async () => {
    try {
      setUpdated(false);
      setDeleteLoader(true);
      const res = await SmsTemplateApi.deleteSmsTemplate(deletedUser);
      setDeleteLoader(false);
      setConfirm(false);
      if (res.status) {
        dispatch(
          setIsSubmitted({ open: true, severity: "success", msg: res.message }),
        );
        setUpdated(true);
      } else {
        dispatch(
          setIsSubmitted({ open: true, severity: "error", msg: res.message }),
        );
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };


  return (
    <div className={classes.mainContainer}>
      <CreateTemplatePopper
        open={open}
        setOpen={(f) => setOpen(f)}
        template={"sms"}
        setUpdated={(f) => setUpdated(f)}
        action={action}
        selectedUser={selectedUser} 
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 20,
        }}
      >
        <div className={classes.heading}>SMS Template</div>
        <div>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            startIcon={<AddIcon />}
            onClick={() => setOpen(true)}
          >
            Create Template
          </Button>
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table
            stickyHeader
            className={classes.table}
            aria-label="customized table"
            size="small"
          >
            <TableHead>
              <TableRow>
                {headers.map((el, i) => {
                  return (
                    <StyledTableCell key={el} align="center">
                      {el}
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {rows.length > 0 &&
                !loading &&
                rows.map((row) => (
                  <StyledTableRow>

                    <StyledTableCell align="center">
                      {row.template_name}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.message}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.created_by_name}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.is_edit_delete ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <EditIcon
                            alt=""
                            style={{ height: "18px" }}
                            onClick={() => {
                              setSelectedUser(row);
                              setAction("Edit");
                              setOpen(true);
                            }}
                          />
                          <img
                            src={trashIcon}
                            alt=""
                            width={18}
                            onClick={() => {
                              setConfirm(true);
                              setDeletedUser(row.id);
                            }}
                          />
                        </div>
                      ) : (
                        "-"
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              <NoRecords rows={rows} loading={loading} />
            </TableBody>
          </Table>
        </TableContainer>

        {!cancelled && (
          <EditTemplate
            selected={selected}
            setCancelled={(f) => {
              setCancelled(true);
              setSelected({});
            }}
            setUpdated={(f) => setUpdated(f)}
          />
        )}
      </div>

      <div>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          backIconButtonProps={{
            "aria-label": "Previous Page",
            style: {
              color: "#fff",
              backgroundColor: "#3A1C67",
              padding: 3,
              borderRadius: "5px",
              marginRight: "5px",
            },
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
            style: {
              color: "#fff",
              backgroundColor: "#3A1C67",
              padding: "3px",
              borderRadius: "5px",
            },
          }}
        />
      </div>
      <ConfirmationPopper
      
        loading={deleteLoader}
        open={confirm}
        setOpen={(f) => setConfirm(f)}
        deleteUser={() => {
          deleteSmsTemplate();
        }}
      />
    </div>
  );
};
export default SmsTemplate;
