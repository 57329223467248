import React, { useRef, useState } from "react";
import uploadIcon from "../../../Images/cloud.svg";
import { makeStyles } from "@material-ui/core";

function FileUpload({setSelectedFile}) {
  const [files, setFiles] = useState([]);
  const hiddenFileInput = useRef(null);
  const handleDrop = (event) => {
    event.preventDefault();
    const { files } = event.dataTransfer;
    
    if (files.length > 0) {
      setFiles([...files]);
      setSelectedFile(files[0])
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDragStart = (event) => {
    event.dataTransfer.setData("text/plain", event.target.id);
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files;
    
    setFiles([...fileUploaded]);
    setSelectedFile(fileUploaded[0])
  };

  return (
    <div
      style={{
        border: "2px solid #3A1C67",
        borderStyle: "dotted",
        margin: "20px 40px",
        padding: 20,
        cursor: "pointer",
      }}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onClick={() => handleClick()}
    >
      <div draggable="true" onDragStart={handleDragStart}>
        <div>
          <img src={uploadIcon} alt="" />

          <div>
            {files.map((file, index) => (
              <div style={{ color: "#3A1C67" }} key={index}>
                {file.name}
              </div>
            ))}
          </div>
        </div>
        <div style={{ color: "#3A1C67", fontSize: 18, fontWeight: 600 }}>
          Drag and drop or{" "}
          <span>
            click here to upload
            <input
              style={{ display: "none" }}
              ref={hiddenFileInput}
              onChange={handleChange}
              id="icon-button-file"
              type="file"
            />
          </span>
        </div>
      </div>
    </div>
  );
}

export default FileUpload;
