import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CircularProgress } from '@mui/material';

export default function NotInterested({loading,open,setOpen,updateStatus}, ) {

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Are you sure you want to update your status to "Not Interested"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
            <div className="btns_add_user">
          <Button onClick={()=>updateStatus()} style={{color:'#fff',backgroundColor:'#3A1C67'}}>
            {loading?<CircularProgress size={25} style={{color:'#fff'}}/>:'Yes'}
          </Button>
          </div>
          <div className="btns_add_user">
          <Button onClick={handleClose} style={{color:'#fff',backgroundColor:'#FA9510'}}>
            Cancel
          </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}