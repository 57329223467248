import { makeStyles,Button, TextField, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import HeadingPara from "../../Common/HeadingPara";

const useStyles = makeStyles({
    mainContainer: {
      padding: '80px 30px 30px 30px',
    },
    subContainer:{
        display:'flex',
        justifyContent:'space-between'
    },
    card:{
        height:"581px",
        width:'49%',
        height:'581px',
        background: '#FFFFFF'
},
    header:{
        display:'flex',
        justifyContent:'space-between',
        background: '#3A1C67',
        fontSize:'20px',
        fontWeight:700,
        height:'50px',
        alignItems:'center',
        color: '#FFFFFF',
        paddingLeft:'10px',
        paddingRight:'10px',
        borderRadius:'10px 10px 0px 0px'

    },
    button:{
        background: '#FA9510',
        color: '#FFFFFF',
        '&:hover': {
          backgroundColor: '#FA9510',
        },

    },
    root: {
        "& input[type=number]": {
          "-moz-appearance": "textfield",
        },
        "& input[type=number]::-webkit-outer-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
        "& input[type=number]::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
        "& label.Mui-focused": {
          color: "#F86E20",
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "#F86E20",
          },
          "&:hover fieldset": {
            borderColor: "#F86E20",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#F86E20",
          },
        },
        margin: "10px",
      },
    formContainer: {
        padding: "20px 10px",
      },
    multilineColor: {
        color: "#F86E20",
      },
      formRow: {
        display: "flex",
        justifyContent:'space-between'
      },
      mobileNumber:{
        display:'flex'
      },
      cardFlex:{
        display:"flex"
    },
    paraHeding1: {
        fontSize: "12px",
        color:'#252525',
        fontWeight: 700,
        textAlign:"start",
        marginBottom:'0px'
      },
      paraHeding: {
        fontSize: "18px",
        color:'#FA9510',
        fontWeight: 700,
        textAlign:"start",
        marginBottom:'0px'
      },
      subTitleClass:{
        fontSize: "13px",
        color:'#252525',
        fontWeight: 400,
        textAlign:'start'
      }
  })


const Settings=()=>{
    const classes = useStyles();
    return(
        <div className={classes.mainContainer}>
            <div className={classes.subContainer}>
                <div className={classes.card}>
                        <div className={classes.header}>
                            <span>Personal Information</span>
                            <Button className={classes.button} >
                                Save
                            </Button>
                        </div>
                        <form className={classes.formContainer}>
                            <div className={classes.formRow}> 
                                <TextField
                                size="small"
                                placeholder="First Name"
                                inputProps={{
                                    className: classes.multilineColor,
                                }}
                                className={classes.root}
                                name="first_name"
                                variant="outlined"
                                /> 
                                <TextField
                                size="small"
                                placeholder="Last Name"
                                className={classes.root}
                                inputProps={{
                                    className: classes.multilineColor,
                                }}
                                name="last_name"
                                variant="outlined"
                                />
                                
                            </div>
                            <div className={classes.formRow}> 
                            <div className={classes.mobileNumber}>
                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    defaultValue="91"
                                    style={{height:"42px",marginTop:'6px',marginLeft:'10px', color: '#FA9510', border:'1px solid #FA9510 ', borderRadius:'5px'
                                }}
                                >
                                    <MenuItem value={91}>91 +</MenuItem>
                                </Select>
                           </FormControl>
                                <TextField
                                size="small"
                                placeholder="Mobile Number"
                                inputProps={{
                                    className: classes.multilineColor,
                                }}
                                style={{width:'140px'}}
                                className={classes.root}
                                name="mobile"
                                variant="outlined"
                                />
                                </div> 
                                <TextField
                                size="small"
                                placeholder="Email Address"
                                className={classes.root}
                                inputProps={{
                                    className: classes.multilineColor,
                                }}
                                name="email"
                                variant="outlined"
                                />   
                            </div>

                            <div className={classes.formRow}> 
                                <TextField
                                size="small"
                                placeholder="Company Name"
                                className={classes.root}
                                style={{width:'200px'}}
                                inputProps={{
                                    className: classes.multilineColor,
                                }}
                                name="company"
                                variant="outlined"
                                />   
                                
                            </div>
                            

                        </form>

                </div>
                <div className={classes.card}>
                        <div className={classes.header}>
                            <span>Change Password / Delete Account</span>
                        </div>
                        <div className={classes.formContainer}>
                        <div className={classes.cardFlex}>
                            <HeadingPara color='#252525' subTitleClass={classes.subTitleClass} headingClass={classes.paraHeding} title="Change Password" subTitle="Your new password must be different from your previously used password." />
                         </div>
                         <div style={{textAlign:'start', marginLeft:"-10px"}}>                         <TextField
                                size="small"
                                placeholder="New Password"
                                type='password'
                                className={classes.root}
                                inputProps={{
                                    className: classes.multilineColor,
                                }}
                                name="newPassword"
                                variant="outlined"
                                /> 

                            <TextField
                                size="small"
                                placeholder="Confirm Password"
                                className={classes.root}
                                inputProps={{
                                    className: classes.multilineColor,
                                }}
                                name="confirmPassword"
                                variant="outlined"
                                /> 
                                </div>
                            
                            <HeadingPara color='#252525' headingClass={classes.paraHeding} title="Delete Account"  />
                            <div className={classes.cardFlex}>
                            <HeadingPara color='#252525' subTitleClass={classes.subTitleClass} headingClass={classes.paraHeding1} title="Would you like to delete your Callpal Account?" subTitle="Deleting your account will remove all of your content and data associated with it." />
                         </div>
                         <div style={{textAlign:'start',}}>
                        <label style={{ color: '#FA9510', fontSize: '12px' ,fontWeight:700, paddingBottom: '20px'}}>
                            <input type="checkbox" style={{  marginRight:'5px' }} />
                            I want to delete my account
                        </label>
                       </div>



                        </div>

                </div>
            </div>
        </div>
    )
}
export default Settings