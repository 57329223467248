import React from 'react';
import { Box, Button, Divider, makeStyles, MenuItem } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Doughnut, Line } from "react-chartjs-2";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import AppBar from '@material-ui/core/AppBar';

import TabPanel from '@material-ui/lab/TabPanel';
import {
  Chart,
  Tooltip,
  Title,
  ArcElement,
  Legend,
  scales,
  CategoryScale,
  registerables,
} from "chart.js";
import Calldash from "./table";
import TeamStatus from "./TeamStatus";
import DRR from "./DRR";

import Rating from "@material-ui/lab/Rating";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { DateRange, KeyboardArrowDown } from "@material-ui/icons";
import DatePicker from "react-date-picker";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  UncontrolledButtonDropdown,
} from "reactstrap";
import moment from "moment";
import { Skeleton } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { getCookie } from "../../Utils/commonUtils";
import { DashboardApi } from "../../Services/DashboardApi";
import { CustomerHistoryApi } from "../../Services/CustomerHistoryApi";
import { CircularProgress, InputAdornment, TextField } from "@material-ui/core";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchIcon from "@material-ui/icons/Search";
import { CommonApi } from "../../Services/CommonApi";
import { NavLink } from "react-router-dom";
import NoRecords from "./Other-components/NoRecords";

Chart.register(Tooltip, Title, ArcElement, Legend, ChartDataLabels);
Chart.register(...registerables);
Chart.register(CategoryScale);

const useStyles = makeStyles((theme, props) => ({
  mainContainer: {
    padding: "80px 30px 20px 30px",
  },
  container: {
    display: "flex",
    backgroundColor: "#848891",
    padding: "10px",
    justifyContent: "space-between",
    borderRadius: "12px",
  },
  button: {
    textTransform: "none",
    backgroundColor: "#F1F1F1",
    width: "130px",
    color: "#3A1C67",
    fontSize: "12px",
    fontFamily: "open-sans",
    borderRadius: "7px",
    margin: "0px 10px",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  table: {
    marginTop: "30px",
    borderCollapse: "collapse",
    width: "100%",
    color: "#3A1C67",
    borderRadius: "30px",
  },
  cell: {
    fontSize: "12px",
    border: "1px solid #dddddd",
    padding: "8px",
  },

  boxContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  box: {
    textAlign: "center",
    padding: "15px",
    height: "80px",
    margin: "20px 0px",
    borderRadius: "10px",
    color: "#3A1C67",
    fontWeight: 700,
    width: "230px",
    backgroundColor: "#fff",
  },
  chartContainer: {
    width: "100%",
    marginBottom: "2em",
  },
  dateInputComp: {
    display: "flex",
    background: "white",
    alignItems: "center",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "6px",
    paddingBottom: "6px",
    gap: "10px",
  },
  greyBox: {
    paddingTop: "10px",
    backgroundColor: "#fff",
    borderRadius: "10px",
    height: "auto",
    width: "480px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  chartBox: {
    //height:'260px',
    padding: " 0px 0px",
    padding: "20px",
    backgroundColor: "#fff",
    // marginRight: "20px",
    borderRadius: "10px",
    height: "100%",
  },
  ratingBox: {
    height: 190,
    width: 200,
    textAlign: "center",
    padding: "10px",
    backgroundColor: "#979CBA33",
    borderRadius: "10px",
    color: "#3A1C67",
    boxShadow: "0px 15px 10px -15px #111",
    margin: "10px 10px",
  },
  label: {
    fontSize: "14px",
    backgroundColor: "#fff",
    padding: 2,
    fontWeight: 700,
    margin: "10px 70px",
    border: "1px solid #3A1C67",
    borderRadius: 5,
  },
}));

const SalesDashboard = ({ filterData, filterDates }) => {
  const [selected, setSelected] = useState(true);
  const [viewMore, setViewMore] = useState(false);
  const classes = useStyles({ selected });
  const [open, setOpen] = useState(false);
  const { userInfo } = useSelector((state) => state.login);
  const loginInfo = getCookie("callpal-user");

  const [loading, setLoading] = useState(false);
  const [callLogData, setCallLogData] = useState();
  const [inboundDetailsCount, setInboundDetailsCount] = useState();
  const [outboundDetailsCount, setOutboundDetailsCount] = useState();
  const [dataAccuracyCount, setDataAccuracyCount] = useState();
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // --------------------------------------------
  const dates = filterDates
  // --------------------------------------------

  const [statusDetails, setStatusDetails] = useState({
    id: "",
    name: "",
  });
  const [statusArr, setStatusArr] = useState([]);
  const [productLoader, setProductLoader] = useState(false);
  const [statusSearch, setStatusSearch] = useState("");
  const [productId, setProductId] = useState();

  const [toYearOptions, setToYearOptions] = useState([]);
  const [top3, setTop3] = useState([]);
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  // const [dates, setDates] = useState(filterDates);
  const currentYear = new Date().getFullYear();
  const numberOfYears = 5; // Adjust this value as needed
  const years = Array.from(
    { length: numberOfYears },
    (_, index) => currentYear - index
  );


  // State to manage the selected value
  const [selectedFromYear, setSelectedFromYear] = useState(
    currentYear.toString()
  );
  const [selectedToYear, setSelectedToYear] = useState(currentYear.toString());

  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: [
      {
        label: "Monthly Sales",
        data: [],
        borderColor: "rgba(75,192,192,1)",
        fill: false,
        borderWidth: 2,
        lineTension: 0.4,
        pointRadius: 0,
      },
    ],
  });
  const [inboundGraph, setInboundGraph] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
      },
    ],
  });
  const [outboundGraph, setOutboundGraph] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
      },
    ],
  });

  const [dataAccuracy, setDataAccuracy] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
      },
    ],
  });

  const [filter, setFilter] = useState(filterData);
  let login = 0;
  let rejected = 0;
  let disburse = 0;
  let sanction = 0;
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState({
    datasets: [
      {
        data: [10, 20, 30, 40],
        backgroundColor: ["#3098FF", "#FFCA5F", "#B5EAD7", "#FF9AA2"],
        borderWidth: 5,
      },
    ],
    labels: ["Login", "Sanction", "Disbursement", "Rejected Files"],
  });

  const [boundData, setBoundData] = useState({
    datasets: [
      {
        data: [10, 20, 30],
        backgroundColor: ["#3098FF", "#B5EAD7", "#FF9AA2"],

        borderWidth: 5,
      },
    ],
    labels: ["Answered", "Incoming Personal", "Missed"],
  });

  const options = {
    plugins: {
      datalabels: {
        color: "#3A1C67",
        formatter: function (value, context) {
          return value + "%";
        },
      },

      //   tooltip: {
      //     titleFont: {
      //       size: 20
      //     },
      //     bodyFont: {
      //       size: 20
      //     },
      //  },
      legend: {
        display: true,
        responsive: true,
        maintainAspectRatio: true,
        // /position: "right",

        labels: {
          boxWidth: 5,
          //padding: 20,
          font: {
            size: 14,
          },
        },
        align: "center",
      },
    },
  };

  const boundOptions = {
    // maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
        color: "white",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.label || "";
            const percentage = "%";
            return ` ${label} : ${context.formattedValue} ${percentage}`;
          },
        },
      },
      legend: {
        display: true,
        responsive: true,
        maintainAspectRatio: true,
        //position: "right",
        labels: {
          position: "right",
          boxWidth: 15,
          font: {
            size: 14,
            fontColor: "black"
          },
        },
        // labels: {
        //   boxWidth: 15,
        //   paddingBottom: "20px",
        //   font: {
        //     size: 14,
        //   },
        // },
        // align: "center",
      },
    },
  };


  useEffect(() => {
    getProductData();
    getDashboardInboundOutbound();
    getDataAccuracy();
  }, [userInfo, dates, filterData]);


  const formatValue = (value) => {
    // Customize this function to format the value as needed
    if (value >= 1e7) {
      return (value / 1e7).toFixed(2) + " CR";
    } else if (value >= 1e5) {
      return (value / 1e5).toFixed(2) + " LPA";
    } else {
      return value.toFixed(2) + " N";
    }
  };

  // Example options with customization for the category scale
  const loptions = {
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          return formatValue(tooltipItem.value);
        },
      },
    },

    scales: {
      x: [
        {
          type: "category",
          labels: [
            "Jan",
            "Feb",
            "Mar",
            "April",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sept",
            "Oct",
            "Nov",
            "Dec",
          ],
          beginAtZero: true,
        },
      ],
      y: {
        type: "linear",
        display: true,
        position: "left",
        ticks: {
          callback: (value) => formatValue(value),
        },
      },
    },
    plugins: {
      datalabels: {
        display: false, // Hide data labels on the line
      },
    },
  };
  const handleChangeFromYear = (event) => {
    const fromYear = event.target.value;
    setSelectedFromYear(fromYear);
    // Call the API with the updated selected year
    const updatedToYearOptions = years.filter((year) => year >= fromYear);
    setToYearOptions(updatedToYearOptions);
    setSelectedToYear(fromYear);
    getGraphParameters(fromYear, fromYear);
  };

  const handleChangeToYear = (event) => {
    const toYear = event.target.value;
    setSelectedToYear(toYear);
    getGraphParameters(selectedFromYear, toYear);
  };

  const getGraphParameters = async (fromYear, toYear) => {
    try {
      setLoading(true);
      const data = JSON.stringify({
        fromyear: fromYear,
        toyear: toYear,
        // per_page_row: 10,
        // page: 1,
      });
      const res = await DashboardApi.getGraphData(data);
      if (res?.status) {
        const graphlabels = res.data.monthData;
        const datasets = res.data.graphsdata.map((graph) => {
          return {
            label: `${graph.title}`,
            data: graph.yearData.map((month) => month.amount),
            borderColor: graph.lineColor,
            fill: false,
            borderWidth: 2,
            lineTension: 0.4,
            pointRadius: 0,
          };
        });
        setGraphData({
          labels: graphlabels,
          datasets: datasets,
        });
      }
    } catch (err) {
      setLoading(false);
      // console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const getTopEmployees = async () => {
    try {
      const res = await DashboardApi.getTopThreeEmp();
      if (res.status) {
        setTop3(res.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getDashboardInboundOutbound = async () => {
    try {
      setLoading(true);
      const data = JSON.stringify({
        fromdate: dates.from,
        todate: dates.to,
        per_page_row: 10,
        page: 1,
        team_lead_id: loginInfo.userInfo.id,
      });
      const res = await DashboardApi.getDashboardInboundOutbound(data);
      if (res.status) {
        setInboundDetailsCount(res.data.inboundDetailsCount);
        setOutboundDetailsCount(res.data.outboundDetailsCount);
        setCallLogData(res.data);
        const Inboundlabels = res.data.inboundDetails.map(
          (detail) => detail.count + " " + detail.call_status_lable
        );
        const Inboundvalues = res.data.inboundDetails.map(
          (detail) => detail.percentage
        );
        const InboundbackgroundColors = res.data.inboundDetails.map(
          (detail) => detail.colorCode
        );
        const Inboundcount = res.data.inboundDetails.map(
          (detail) => detail.count
        );

        setInboundGraph({
          datasets: [
            {
              data: Inboundvalues,
              backgroundColor: InboundbackgroundColors,
              borderWidth: 5,
            },
          ],
          labels: Inboundlabels,
        });
        const outboundlabels = res.data.outboundDetails.map(
          (detail) => detail.count + " " + detail.call_status_lable
        );
        const outboundvalues = res.data.outboundDetails.map(
          (detail) => detail.percentage
        );
        const outboundbackgroundColors = res.data.outboundDetails.map(
          (detail) => detail.colorCode
        );
        const outboundcount = res.data.outboundDetails.map(
          (detail) => detail.count
        );

        setOutboundGraph({
          datasets: [
            {
              data: outboundvalues,
              backgroundColor: outboundbackgroundColors,
              borderWidth: 5,
            },
          ],
          labels: outboundlabels,
        });
      }

      const options = {
        legend: {
          display: true,
          position: "right",
          labels: {
            fontColor: "red", // Change the font color of the legend labels
          },
        },
      };
    } catch (err) {
      setLoading(false);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const getProductData = async () => {
    try {
      setProductLoader(true);
      const res = await CustomerHistoryApi.getProductMaster();
      if (res.status) {
        const statusArray = Object.entries(res.data).map(([id, status]) => ({
          id,
          status,
        }));
        setStatusArr(statusArray);
      }
      setProductLoader(false);
    } catch (err) {
      setProductLoader(false);
    } finally {
      setProductLoader(false);
    }
  };


  const getDataAccuracy = async () => {
    try {
      const data = JSON.stringify({
        fromdate: dates.from,
        todate: dates.to,
      });
      const res = await DashboardApi.getDataAccuracy(data);

      if (res.status) {
        setDataAccuracyCount(res.data.dataAccuracyNumber)

        const dataAccuracylabels = res.data.dataAccuracy.map(
          // (detail) => detail.count + " " + detail.call_status_lable + " " + detail.percentage
          (detail) => detail.percentage + "%" + " " + detail.call_status_lable
        );
        const dataAccuracyValues = res.data.dataAccuracy.map(
          (detail) => detail.percentage

        );

        const dataAccuracyColors = res.data.dataAccuracy.map(
          (detail) => detail.colorCode
        );


        setDataAccuracy({
          datasets: [
            {
              data: dataAccuracyValues,
              backgroundColor: dataAccuracyColors,
              borderWidth: 5,
            },
          ],
          labels: dataAccuracylabels,
        });

      }
    } catch (err) {
      console.log(err);
    }
  };










  return (

    <div>


      {!viewMore && (
        <>


          <div className={classes.chartContainer}>
            <NoRecords rows={0} loading={loading} />
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className={classes.chartBox}>
                  {/* <div className="d-flex">
                    <h2 className="Heading">Data Accuracy : {dataAccuracyCount} </h2>
                  </div> */}
                  <TabContext value={value}>
                      <div style={{padding:"0px"}} className="d-flex">
                        <div>
                          <TabList aria-label="simple tabs example">
                            <NavLink
                              to={
                                loginInfo.userInfo.fk_role_id === 2
                                  ? "/dashboard/data-accuracy-team-lead"
                                  : `/dashboard/data-accuracy-team-lead/data-accuracy-tele-caller/${btoa(loginInfo.userInfo.id)}`
                              }
                            >
                                <Tab
                                  className="Heading"
                                  label={`Data Accuracy: ${dataAccuracyCount > 0 ? dataAccuracyCount : ""}`}
                                />
                            </NavLink>
                             {/* <Tab
                                  className="Heading"
                                  label={`Data Accuracy: ${dataAccuracyCount > 0 ? dataAccuracyCount : ""}`}
                                /> */}
                          </TabList>
                        </div>
                      </div>
                    </TabContext>

                  <div style={{width:"400px"}}>
                    {/* Data Accuracy */}
                    {dataAccuracyCount && dataAccuracyCount > 0 ? 
                    <Doughnut
                      data={dataAccuracy}
                      options={boundOptions}
                    />
                      : 
                      <span style={{marginTop: "50px"}}> No Record Found. </span>}
                  </div>
                </div>
              </div>
              <div  className="col-md-6 col-sm-12">
                <div className={classes.chartBox}>
                  <div style={{padding:"0px"}} className="row">
                    <TabContext value={value}>
                      <div className="d-flex">
                        <div>
                          <TabList onChange={handleChange} aria-label="simple tabs example">
                            <Tab label={`Inbound : ${inboundDetailsCount}`} value="1" />
                            <Tab label={`Outbound : ${outboundDetailsCount}`} value="2" />
                          </TabList>
                        </div>
                        <NavLink to={"/dashboard/call-logs/"} className="see-con">
                          <p className="see-all-txt">See All </p>
                          <ArrowForwardIosRoundedIcon className="arrow-icon" />
                        </NavLink>
                      </div>
                      <div style={{marginTop:"-25px"}} className="row">
                        <TabPanel value="1">
                          <div style={{width:"350px"}}>
                              <Doughnut
                                data={inboundGraph}
                                options={boundOptions}
                              />
                            </div>
                        </TabPanel>
                        {/* Out Bound */}
                        <TabPanel value="2">
                            <div style={{width:"350px"}}>
                              <Doughnut
                                data={outboundGraph}
                                options={boundOptions}
                                />
                            </div>
                        </TabPanel>
                      </div>
                    </TabContext>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="page-container">
        <div className="row">
          <div className="col-md-8 col-sm-12">
            <Calldash />
          </div>
          <div className="col-md-4 col-sm-12">
            <TeamStatus />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <DRR />
          </div>
        </div>
      </div>


    </div>
  );
};
export default SalesDashboard;
