import { Route, Routes } from 'react-router-dom'

import Home from '../Components/Home/Home'

import Screen2 from '../Components/Post-CRM/PostCrm'


import ContactUs from '../Components/ContactUs/ContactUs';
import TnCPolicy from '../Components/Policy/TnCPolicy';
import Partners from '../Components/Partners/Partners';
import PrivacyPolicy from '../Components/Policy/PrivacyPolicy';
import PricingPage from '../Components/Pricing/PricingPage';
import FieldCrm from '../Components/Field-Crm/FieldCrm';
import PageNotFound from '../Components/PageNotFound';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import theme from '../theme';
import SignUp from '../Components/Login/SignUp';
import SignIn from '../Components/Login/SignIn';
import { routes } from '../constants/routes';
import Dashboard from '../Components/Dashboard/Dashboard';
import UsagePolicy from '../Components/Policy/UsagePolicy';
import ProtectedRoute from './ProtectedRoute';
import Protected from './Protected';
import ErrorBoundary from '../Components/ErrorBoundary';
import CustomCarousel from '../Components/CustomCarousel/CustomCarousel'
import CallManagement from '../Components/Call-Management/CallManagement';
import Blogs1 from '../Components/Blogs/Blogs1';
import Blogs2 from '../Components/Blogs/Blogs2';
import MainBlogPage from '../Components/Blogs/MainBlogPage';
import DRRTable from '../Components/Dashboard/NewDashboard/DRRTable';


const AppRoutes = () => {


  return (
    <Theme>
      <Routes>


        <Route exact path={routes.home} element={<Home />} errorElement={<ErrorBoundary />} />
        <Route exact path={routes.liveDemo} element={<ContactUs />} errorElement={<ErrorBoundary />} />
        <Route exact path={routes.freeTrial} element={<ContactUs />} errorElement={<ErrorBoundary />} />
        <Route path={routes.postCrm} element={<Screen2 />} errorElement={<ErrorBoundary />} />
        <Route path={routes.callManagement} element={<CallManagement />} errorElement={<ErrorBoundary />} />
        <Route path={routes.fieldCrm} element={<FieldCrm />} errorElement={<ErrorBoundary />} />
        <Route path={routes.usagePolicy} element={<UsagePolicy errorElement={<ErrorBoundary />} />} />
        <Route path={routes.contactUs} element={<ContactUs />} errorElement={<ErrorBoundary />} />
        <Route path={routes.terms} element={<TnCPolicy />} errorElement={<ErrorBoundary />} />
        <Route path={routes.partners} element={<Partners />} errorElement={<ErrorBoundary />} />
        <Route path={routes.blogs} element={<MainBlogPage />} errorElement={<ErrorBoundary />} />
        <Route path={routes.blogs1} element={<Blogs1 />} errorElement={<ErrorBoundary />} />
        <Route path={routes.blogs2} element={<Blogs2 />} errorElement={<ErrorBoundary />} />
        <Route path={routes.privacyPolicy} element={<PrivacyPolicy />} errorElement={<ErrorBoundary />} />
        <Route path={routes.pricing} element={<PricingPage />} errorElement={<ErrorBoundary />} />
        <Route path={routes.signUp} element={<SignUp />} errorElement={<ErrorBoundary />} />
        {/* <Route path={routes.ddrList} element={<DRRTable/>} errorElement={<ErrorBoundary />} /> */}
        <Route path={routes.carousel} element={<CustomCarousel />}></Route>
        {/* <Route path={routes.signIn} element={<SignIn />} /> */}
        {/* <Route path={routes.dashboard} element={<Dashboard />} /> */}
        <Route path={routes.signIn} errorElement={<ErrorBoundary />} element={
          <Protected>

            <SignIn />
          </Protected> 
        } />
        <Route path={routes.dashboard} element={
          <ProtectedRoute>

            <Dashboard />
          </ProtectedRoute>
        } />

        <Route exact path="*" element={<PageNotFound />} />

      </Routes>
    </Theme>
  )
}
export default AppRoutes

const Theme = ({ children }) => {
  return (
    <MuiThemeProvider theme={theme}>
      {' '}
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  )
}
