import React, { useState, useEffect } from "react";
import {
  SwipeableDrawer,
  TextField,
  Button,
  Typography,
  InputAdornment,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import UpdateIcon from "@material-ui/icons/Update";
import { makeStyles } from "@material-ui/core/styles";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import SearchIcon from "@material-ui/icons/Search";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { MasterDataApi } from "../../../Services/MasterDataApi";
import { useDispatch, useSelector } from "react-redux";
import { setIsSubmitted } from "../../../Reducers/loginSlice";
import { Box,   CircularProgress,  Container,  } from "@material-ui/core";

const AddProcessor = ({ open, onClose ,processAction, userEditId}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (processAction === "Edit") {
     editProcessorPopper(userEditId)
    }else{
      formik.setFieldValue("id",  "");
      formik.setFieldValue("process_name",  "");
    }
  }, [processAction]);



const editProcessorPopper  =async(userEditId)=> {
  setLoading(true)
  try {
    const res = await MasterDataApi.EditMasterDataProcessorApi(userEditId);
    if (res.status) {
      formik.setFieldValue("id", userEditId ? userEditId : "");
      formik.setFieldValue("process_name", res.data.process_name ? res.data.process_name : "");
    } else {
      formik.resetForm({
        values: {
          id:"",
          process_name: "",
        },
      });
     
      }
      setLoading(false);
  } catch (e) {
    setLoading(false);
    console.error(e);
  }
}


  

  // const handleAddDepartment = () => {
  //   onAddDepartment(department_name);
  //   setdepartment_name("");
  //   onClose();
  // };

  const useStyles = makeStyles((theme) => ({
    // Existing styles remain the same
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: "70px",
      color: "#fff",
      fontSize: "17px",
      backgroundColor: "#3A1C67",
      fontWeight: 600,
      padding: "0 20px",
      width: "40rem",
    },
    iconButton: {
      color: "#fff",
      border: "1px solid orange",
      padding: "10px 30px",
    },
    textField: {
      "& label.Mui-focused": {
        color: "#F86E20", // Orange color for the label when the input is focused
      },
      "& .MuiInputBase-input": {
        color: "#F86E20", // Orange color for the input text
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#F86E20", // Orange border
          borderWidth: "2px", // Thicker border
        },
        "&:hover fieldset": {
          borderColor: "#F86E20", // Orange border on hover
        },
        "&.Mui-focused fieldset": {
          borderColor: "#F86E20", // Orange border when focused
          borderWidth: "2px", // Keeping the border thick when focused
        },
      },
      margin: theme.spacing(1),
      marginTop: 20,
    },

    buttonContainer: {
      display: "flex",
      justifyContent: "space-between",
      gap: 20,
    },
    buttonContainerNew: {
      display: "flex",
      margin: "20px 90px",
      justifyContent: "space-between",
    },
    saveButton: {
      backgroundColor: "#FA9510",
      color: "#fff",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#F76A1A",
      },
      width: "180px",
    },
    cancelButton: {
      backgroundColor: "#fff",
      border: "1px solid #848891",
      color: "#848891",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#E5E3E1",
      },
      width: "180px",
    },
  }));

  const classes = useStyles();

  const validationSchema = Yup.object().shape({
    process_name: Yup.string()
      .required("Processor name is required")
  });

  const formik = useFormik({
    initialValues: {
      id: "",
      process_name: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      // setLoading(true);
      try {
        const data = await MasterDataApi.UpdateMasterDataProcessor(values);
        if (data.status) {
          dispatch(
            setIsSubmitted({
              open: true,
              severity: "success",
              msg: data.message,
            })
          );
          onClose(false);
          resetForm();
          setLoading(false);
        } else { 
          if (data.hasOwnProperty("errors")) {
            formik.setFieldError("process_name", data.errors.process_name[0]);
          } else {
            dispatch(
              setIsSubmitted({
                open: true,
                severity: "error",
                msg: data.message,
              })
            );
          }
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        console.error(e);
      }
    },
  });

  return (

    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={onClose}
      onOpen={() => {}}
    >
        
      <div className={classes.header}>
        <Typography variant="h6">  {processAction === "Edit" ? "Edit" : "Add" } Processor</Typography>
        <div className={classes.buttonContainer}>
        {/* <Button type="submit" className={classes.iconButton} onClick={formik.handleSubmit}>
            <UpdateIcon style={{ marginRight: "1.2rem" }} />   { processAction === "Edit" ?  "Update" : "Save" }
          </Button> */}
        </div>
      </div>

      <form onSubmit={formik.handleSubmit}>

      <TextField
        label="Enter Processor Name"
        variant="outlined"
        className={classes.textField}
        multiline
        minRows={1}
        name="process_name"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.process_name}
        style={{width:"97%"}}
      />
      {formik.touched.process_name &&
        formik.errors.process_name && (
          <p className="text-danger formik-masterdata-msg  text-small text-left formik-msg ">
            {formik.errors.process_name}
          </p>
        )}
     
     
      {loading && (
         <div style={{ textAlign: "center" }}>
         <CircularProgress size={50} />
       </div>
      )}

    <div className="btns_add_user">
              <div className={classes.buttonContainerNew}>
                <Button type="submit" className={classes.saveButton}>
                  {loading ? (
                    <CircularProgress size={20} />
                  ) : processAction == "Edit" ? (
                    "Update"
                  ) : (
                    "Save"
                  )}
                </Button>
                <Button
                  className={classes.cancelButton}
                  onClick={() => onClose(false)}
                >
                  Cancel
                </Button>
              </div>
            </div>
    </form>
    </SwipeableDrawer>
  );
};

export default AddProcessor;
