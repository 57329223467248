import { Button, InputAdornment, MenuItem, TextField, makeStyles } from '@material-ui/core'
import { KeyboardArrowDown } from '@material-ui/icons'
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { useState } from 'react'
import { DateRange} from '@material-ui/icons'
import DatePicker from 'react-date-picker'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import moment from "moment";
import { reportingMasterApi } from '../../../Utils/commonUtils'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@mui/icons-material/Add';
import { CommonApi } from "../../../Services/CommonApi";
import { getCookie } from '../../../Utils/commonUtils'


const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    gap: '10px',
    // backgroundColor: '#848891',
    padding: '10px',
    justifyContent: 'space-between',
    borderRadius: '12px',
  },
  dropdownBG: {
    backgroundColor: '#F1F1F1',
    border: 'none',
    color: '#3A1C67',
    borderRadius: '15px',
    padding:'7.5px 25px',
    fontSize:'12px',
    '&:hover': {
      backgroundColor: '#F1F1F1',
      color: '#3A1C67',
    },
  },
  button: {
    textTransform: 'none',
    backgroundColor: '#FFF',
    color: '#3A1C67',
    fontSize: '12px',
    border:'none',
    transition: 'none',
    '&:hover': {
      backgroundColor: '#FFF',
      color: '#3A1C67',
    },
  },
  dateInputComp: {
    display: 'flex',
    background: 'white',
    alignItems: 'center',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingTop: '6px',
    paddingBottom: '6px',
    gap: '10px',
  },
  createButton: {
    background: '#FA9510',
    color: '#fff',
    marginRight: '10px',
    borderRadius: '10px',
    fontSize: '12px',
    padding: '10px 12px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#FA9510',
    },
  },
}))
const FilterSection = ({isAllEmp,isCreateLeadButton,setDates,setEmpDetails,empDetails,setIsCreateLead,teamLeadId}) => {
  const classes = useStyles()
  const { userInfo } = useSelector((state) => state.login)
  const [startDate,setStartDate]=useState(moment().format('YYYY-MM-DD'))
  const [endDate,setEndDate]=useState(moment().format('YYYY-MM-DD'))
  const [open,setOpen]=useState(false)
  const [filter,setFilter]=useState({
    overall:true,
    today:false,
    range:false
  })
  const [isSelect,setIsSelect]=useState(false)
  const [reportArr,setReportArr]=useState([])
  const [reportLoader,setReportLoader]=useState(false)
  const [reportSearch, setReportSearch] = useState('')
  const loginInfo=getCookie('callpal-user');
  
 

  const setDateFilter=(val)=>{
    if(val=='range'){
      setFilter({...filter,today:false,overall:false,range:true})
      setDates({from:startDate,to:endDate});
    }
    else if(val=='today'){
      setFilter({...filter,today:true,overall:false,range:false})
      setDates({from: moment().format('YYYY-MM-DD'), to: moment().format('YYYY-MM-DD') })
    }
    else{
      setFilter({...filter,today:false,overall:true,range:false})
      setDates({from: '', to: ''});
      setEmpDetails({id:'',name:''});
    }
  }

  const getTeamLeadTeleCallerList = async () => {
    try {
      setReportLoader(true);
      const res = await CommonApi.getTeamLeadTeleCaller(teamLeadId);
      if (res.status) {
        const reportingArray = Object.entries(res.data).map(
          ([id, reporting_to]) => ({ id, reporting_to }),
        );
        setReportArr(reportingArray);
      }
      setReportLoader(false);
    } catch (err) {
      setReportLoader(false);
    } finally {
      setReportLoader(false);
    }
  };

  useEffect(()=>{
    if (userInfo.hasOwnProperty('token') || userInfo?.userInfo?.token) {
      getTeamLeadTeleCallerList();
    }

  },[userInfo])

  return (

    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
     {isCreateLeadButton&& 
        <Button className={classes.createButton} startIcon={<AddIcon />} onClick={()=>{setIsCreateLead(true)}}>
        Create Lead
        </Button>
      }
      <div className={classes.container}>
        
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {isAllEmp &&
        <UncontrolledDropdown
         toggle={() => {
          setIsSelect(!isSelect)
        }}
        // onClick={() => {
        //   setIsSelect(!isSelect)
        // }}
        isOpen={isSelect}
        >
          <DropdownToggle
            className={classes.button}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '10px',
              alignItems:'center',
              width:165,
              background: 'white',
              color: '#3A1C67',
              border: 'none',
              borderRadius: '5px',
            }}
            caret
          >
            <div style={{ marginTop: '2px' }}>{empDetails?.name?.length==0?'All Employees':empDetails?.name}</div>
            {/* <KeyboardArrowDown></KeyboardArrowDown> */}
          </DropdownToggle>
          <DropdownMenu>
          <DropdownItem header>
          <TextField
                          placeholder="Search Employees"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                          size='small' variant='outlined' onChange={(e) => setReportSearch(e.target.value)} />
          </DropdownItem>
          <div style={{maxHeight: 150, overflowY: 'scroll'}}>
                        {
                          reportArr.length > 0 && reportArr.filter((e) => (e.reporting_to.toString().toLowerCase().includes(reportSearch.toLowerCase()))
                          ).map((el, i) => (
                            <DropdownItem key={el.id} onClick={() => {setEmpDetails({...empDetails,id:el.id,name:el.reporting_to})}}>{el.reporting_to}</DropdownItem>

                          ))
                        }
                      </div>
          </DropdownMenu>
        </UncontrolledDropdown>
}
      </div>
          <Button className={classes.button} style={{paddingLeft:30,paddingRight:30,backgroundColor:filter.overall?'#fff':'#F1F1F1'}}
          onClick={()=>setDateFilter('')}
          >Over All</Button>
        
        <div>
          <Button className={classes.button} style={{paddingLeft:30,paddingRight:30,backgroundColor:filter.today?'#fff':'#F1F1F1'}}
           onClick={()=>setDateFilter('today')}
          >Today's</Button>
        </div>

        <UncontrolledDropdown className=''
           toggle={() => {
            setOpen(!open)
          }}
          onClick={() => {
            setOpen(true)
          }}
          isOpen={open}
        >
        <div className="filters">

          <DropdownToggle
            className={classes.dropdownBG}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '10px',
              alignItems:'center',
              backgroundColor:filter.range&&'#fff'
            }}
          >
            Select Range
            <KeyboardArrowDown style={{fontSize:17}}></KeyboardArrowDown>
          </DropdownToggle>
          </div>
          <DropdownMenu>
            <DropdownItem>
                  <div className={classes.dateInputComp}>
                    {/* <input type='date' style={{ borderRight: 'none' }}></input>
          <input type='date' style={{ borderLeft: 'none' }}></input> */}
                    <div>
                      <DateRange
                        className='dateIcon'
                        color='#3A1C67'
                      ></DateRange>
                    </div>
                    <div style={{ display: 'flex', gap: '5px' }}>
                      {' '}
                      <DatePicker
                        style={{
                          display: 'none',
                          color: '#3A1C67',
                          cursor: 'pointer',
                        }}
                        value={startDate}
                        onChange={(val)=>{
                          setOpen(true)
                          const d= moment(val).format('YYYY-MM-DD')
                          setStartDate(d)
                          
                        }}
                        calendarIcon={null}
                        clearIcon={null}
                        className='datePickerInput'
                       
                      ></DatePicker>
                      -
                      <DatePicker
                        calendarIcon={null}
                        clearIcon={null}
                        style={{ display: 'none', color: '#3A1C67' }}
                        value={endDate}
                        onChange={(val) => {
                          const d= moment(val).format('YYYY-MM-DD')
                          setEndDate(d)
                          setOpen(true)
                        }}
                        className='datePickerInput'
                        minDate={new Date(startDate)}
                      ></DatePicker>
                    </div>
                  </div>
                  <div style={{textAlign:'center'}}>
                  <div className="btns_add_user">
                    <Button style={{backgroundColor:'#848891',color:'#fff',textTransform:'none'}} onClick={()=>{setDateFilter('range');setOpen(false);}}>Apply</Button>
                  </div>
                  </div>
                </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>

        {/* <div>
        <Button className={classes.button} >Over All</Button>
      </div> */}
      </div>
    </div>
  )
}

export default FilterSection
