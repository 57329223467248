import axios from "axios"
import { getCookie } from '../Utils/commonUtils'


export const CustomerCreditCardApi = {
  
  UpdateCreditCardRecord: async (data) => {
    try {
       
        const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/add-credit-card`, data, {
          headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getCookie('callpal-user').token}`
          }
        });
        return response; // Assuming your data is in response.data
      } catch (error) {
        console.error('Error in getTeamList:', error);
        return false
        //throw error; // Re-throw the error to handle it at the calling site if needed
      }
  },


  getCreditCardList: async (data) => {
    try {
         
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/credit-card-type-list`, {
          headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getCookie('callpal-user').token}`
          }
        });
        return response; // Assuming your data is in response.data
      } catch (error) {
        console.error('Error in getCreditCardList:', error);
        return false
        //throw error; // Re-throw the error to handle it at the calling site if needed
      }
},
getCreditCardRecord: async (id) => {
  try {
       
      const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/get-credit-card-record/${id}`, {
        headers: {
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getCookie('callpal-user').token}`
        }
      });
      return response; // Assuming your data is in response.data
    } catch (error) {
      console.error('Error in getCreditCardList:', error);
      return false
      //throw error; // Re-throw the error to handle it at the calling site if needed
    }
},



}