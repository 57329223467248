import { Container } from "@material-ui/core"
import Footer from "./Footer"
import Header from "./Header"

const PageNotFound=()=>{
    return(
        <div>
            <Header/>
            <Container maxWidth={'md'} style={{padding:'110px 0px',textAlign:'left',color:'#3A1C67'}}>
                <div style={{fontSize:'120px',fontWeight:600}}>404</div>
                <div style={{fontSize:'40px',fontWeight:700}}>Whoooops!</div>
                <div style={{fontSize:'40px',paddingBottom:'50px'}}>Something’s missing.</div>
                <div style={{fontSize:'25px'}}>We couldn’t help you here.</div>
                <div style={{fontSize:'25px'}}>Guess you searched for something <span style={{fontWeight:700}}>out of this world!</span></div>
            </Container>
            <Footer/>
        </div>
    )
}
export default PageNotFound