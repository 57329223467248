import { Box, Container, makeStyles, Button } from "@material-ui/core";
import Header from "../Header";
import Footer from "../Footer";
import * as React from "react";
import { useNavigate } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: "30px",
    color: "#3A1C67",
    fontWeight: 700,
  },
  subHeading: {
    fontSize: "18px",
    color: "#848891",
    fontWeight: 400,
  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
  },
  mainContainer: {
    marginTop: "90px",
  },
  box: {
    marginTop: "20px",
    textAlign: "left",
  },

  cardHeading: {
    fontSize: "18px",
    color: "#3A1C67",
    fontWeight: 600,
  },
  cardSubHeading: {
    fontSize: "14px",
    color: "#848891",
    fontWeight: 400,
    textAlign: "start",
    margin: "10px 0px",
  },
  card: {
    width: "28%",
    maxHeight: "340px",
    height: "340px",
    background: "#94DAD5",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: "10px",
    marginBottom: "50px",
    padding: "20px 13px",
  },
  button: {
    backgroundColor: "#FA9510 !important",
    color: "#fff !important",
    // margin: "15px !important",
    width: "100px",
    transition: ".4s all ease-in-out",
  },
}));
const MainBlogPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const handleClick = (path) => {
    if (path === "blogs1") {
      navigate("/blogs1");
    } else {
      navigate("/blogs2");
    }
  };
  return (
    <div>
      <Header />

      <Container maxWidth="lg" className={classes.mainContainer}>
        {/* <img loading="eager" src={img} alt="" className={classes.img}/> */}
        <div className={classes.headerContainer}>
          <span className={classes.heading}>Blog</span>
        </div>
        <div
          style={{
            padding: "30px 10px 0px 10px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
            flexDirection: "row",
          }}
        >
          <div className={classes.card}>
            <div className={classes.box}>
              <span className={classes.cardHeading}>
                The Impact of Local CRM on Small Businesses
              </span>
              <p className={classes.cardSubHeading}>
                CRM software helps businesses and customers build successful
                connections, offer tailored experiences, and stretch the sales
                cycle from lead generation to a devoted customer base.
              </p>
            </div>
            <Button
              onClick={() => handleClick("blogs2")}
              className={classes.button}
            >
              Read More
            </Button>
          </div>
          <div className={classes.card} style={{ background: "#F0DBD8" }}>
            <div className={classes.box}>
              <span className={classes.cardHeading}>
                Transforming Your Business Process with Callpal's Unique Field
                CRM
              </span>
              <p className={classes.cardSubHeading}>
                As someone who runs several businesses, you probably want to
                keep all your data in one place. It can be frustrating to switch
                between different CRM tools. We understand this problem, and
                that's why we created a solution that can help you manage all
                your businesses in one spot.
              </p>
            </div>
            <Button
              onClick={() => handleClick("blogs1")}
              className={classes.button}
            >
              Read More
            </Button>
          </div>
          
        </div>
      </Container>
      <Footer/>
    </div>
  );
};
export default MainBlogPage;
