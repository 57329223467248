import { Box, Container, makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: "30px",
    color: "#3A1C67",
    fontWeight: 700,
  },
  subHeading: {
    fontSize: "18px",
    color: "#848891",
    padding: "0px 200px",
    paddingTop: "10px",
    paddingBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 20px",
    },
  },
  boxCotainer: {
    height: "356px",
    margin: "10px 30px",
    width: "350px",
    backgroundColor: "#00D6BC1A",
    borderRadius: "12px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      margin: "90px 20px",
    },
  },
}));
const CardWithoutImg = ({title,background,textColor}) => {
  const classes = useStyles();
  return (
    <div>
      <Container maxWidth="lg" style={{ padding: "0px 0px" }}>
      <Box
            className={classes.boxCotainer}
            style={{ backgroundColor: background ,width:"346px" ,height:"200px"}}
          >
            <Box
              style={{
                paddingTop: "70px",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <Box
                style={{ color:textColor, fontSize: "20px", fontWeight: 600 }}
              >
                {title}
              </Box>
            </Box>
          </Box>
      </Container>
    </div>
  );
};
export default CardWithoutImg;
