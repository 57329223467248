import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Box, Tab, Tabs, TextField, Button, Table, TableContainer, TableHead, TableRow, TableBody } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { StyledTableCell } from '../../Styles';
import { StyledTableRow } from "../../Styles";
import EditIcon from "@material-ui/icons/Edit";
import trashIcon from "../../Images/trash.svg";
import { MasterDataApi } from '../../Services/MasterDataApi';
import MasterDepartmentsList from './Department/MasterDepartmentsList';
import MasterProcessorList from './Processor/MasterProcessorList';
import MasterOfficeTimings from './OfficeTiming/MasterOfficeTimings';
import MasterDataAnnouncement from './Announcement/MasterDataAnnouncement';
import UpdateIcon from '@material-ui/icons/Autorenew';
import AddDepartmentDrawer from './Department/AddDepartmentPopper';
import AddOfficeTimings from './OfficeTiming/AddOfficeTimings';
import AddAnnouncementDrawer from './Announcement/AddAnnouncementDrawer';
import { Announcement } from '@material-ui/icons';
import AddProcessor from './Processor/AddProcessorPopper';
import AddOfficeTimingsDrawer from './OfficeTiming/AddOfficeTimings';
import MasterOverview from './Overview/MasterOverview';


const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "80px 30px 30px 30px",
    textAlign: "left",
  },
  heading: {
    color: "#3A1C67",
    fontSize: "18px",
    fontWeight: 700,
    textAlign: "left",
    marginBottom: 10,
    padding: "12px",
  },
  root: {
    minWidth: 275,
  },
  tab: {
    backgroundColor: 'transparent',
    color: '#3f51b5',
    borderColor: '#3f51b5',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 10,
    textTransform: 'none',
    marginRight: '1.2rem',
    padding: '6px 12px',
    '&:hover': {
      backgroundColor: 'rgba(63, 81, 181, 0.1)',
    },
    '&.Mui-selected': {
      backgroundColor: '#3A1C67',
      color: '#fff',
    },
    '& .Mui-root': {
      padding: '0px',
    },
  },
  tabButton: {
    padding: '4px 8px', // Adjust the padding values as desired
  },
  tabContainer: {
    width: '100%',
    display: "flex",
    justifyContent: "space-between",
    padding: 0,
    '& .MuiTabs-indicator': { // Add this line
      display: 'none', // Add this line
    }, // Add this line
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginTop: 20,
  },
  imageUpload: {
    border: '2px solid orange',
    marginRight: 20,
    width: "300px",
    height: "300px",
    borderRadius: 10
  },
  inputFields: {
    flex: 1,
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: 30,
    // '& .MuiTextField-root': {
    //   marginBottom: 20,
    //   '& .MuiOutlinedInput-root': {
    //     '& fieldset': {
    //       borderColor: 'orange',
    //     },
    //     '&:hover fieldset': {
    //       borderColor: 'orange',
    //     },
    //     '&.Mui-focused fieldset': {
    //       borderColor: 'orange',
    //     },
    //   },
    // },
  },

  inputField: {
    color: 'orange',
    '& .MuiOutlinedInput-root': {
      borderRadius: 8, // Adjust the value to increase or decrease the roundness
      '& fieldset': {
        borderColor: 'orange',
      },
      '&:hover fieldset': {
        borderColor: 'orange',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'orange',
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: "11.5px 14px"
    }
  },
  uploadButton: {
    backgroundColor: 'transparent',
    border: '2px solid orange',
    borderRadius: 10,
    color: 'orange',
    textAlign: 'center',
    padding: "10px 40px",
    boxShadow: 'none'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(2),
    marginTop: theme.spacing(4), // Add some top margin
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 7,
    background: '#3A1C67',
    color: 'white',
    padding: '2px 15px'
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  // buttonsContainer :{
  //   float:'right',
  // },
  headingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const timingHeaders = ["Shift", "Start Time", "End Time", "Status", "Actions"];


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const MasterData = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

 
  const [action, setAction] = useState("Add");
  const [shiftAction, setShiftAction] = useState("Add");
  const [announcementAction, setAnnouncementAction] = useState("Add");
  const [processAction, setProcessAction] = useState("Add");

  const [userEditId, setuserEditId] = useState();
  const [userShiftEditId, setuserShiftEditId] = useState();

  const [officeTimingDrawerOpen, setOfficeTimingDrawerOpen] = useState(false);
  const [announcement, setAnnouncement] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [officeTimingPopup, setOfficeTimingPopup] = useState(false);

  const [processor, setProcessor] = useState(false);
  
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
    setAction("Add");
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setAction("Add");
  };

  const handleOfficeTimingDrawerOpen = () => {
    setOfficeTimingDrawerOpen(true);
    setOfficeTimingPopup(true);

  };

  const handleOfficeTimingDrawerClose = () => {
    setOfficeTimingDrawerOpen(false);
    setOfficeTimingPopup(false);
    setShiftAction("Add");
  };

  const handleAnnouncementOpen = () => {
    setAnnouncement(true);
    setAnnouncementAction("Add")

  };

  const handleAnnouncementClose = () => {
    setAnnouncement(false);
    setAnnouncementAction("Add")
  };

  const handleProcessorOpen = () => {
    setProcessor(true);
    setProcessAction("Add");
  };

  const handleProcessorClose = () => {
    setProcessor(false);
    setProcessAction("Add");
  };


  


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const editDepartment = (id) => {
    setuserEditId(id)
    setDrawerOpen(true);
    setAction("Edit")
  }


  const editShift = (id) => {
    setuserShiftEditId(id)
    setOfficeTimingDrawerOpen(true);
    setOfficeTimingPopup(true);
    setShiftAction("Edit")
  }

  const editProcessor = (id) => {
    setuserEditId(id)
    setProcessor(true);
    setProcessAction("Edit")
  }

  const editAnnouncement = (id) => {
    setuserEditId(id)
    setAnnouncement(true);
    setAnnouncementAction("Edit")
  }



  


  return (

    <div className={classes.mainContainer}>
      <div className={classes.headingContainer}>
        <div className={classes.heading}>Company Profile Dashboard >> Company Profile</div>
        {(value === 1 || value === 2 || value === 3 || value === 4) && (
          <div className={classes.buttonsContainer}>
            {value === 1 ? (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<span className={classes.icon}>+</span>}
                onClick={() => {
                  handleDrawerOpen();

                }}
              >
                Add Department
              </Button>

            ) : value === 2 ? (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<span className={classes.icon}>+</span>}
                onClick={handleOfficeTimingDrawerOpen}  // Update this line
              >
                Add Office Timings
              </Button>
            ) : value === 3 ? (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<span className={classes.icon}>+</span>}
                onClick={handleAnnouncementOpen}  // Update this line
              >
                Add Announcement
              </Button>
            ) : value === 4 ? (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<span className={classes.icon}>+</span>}
                onClick={handleProcessorOpen}
              >
                Create Processor
              </Button>
            ) : null}
          </div>
        )}
        <AddDepartmentDrawer
          open={drawerOpen}
          onClose={handleDrawerClose}
          action={action}
          userEditId={userEditId}
        />

        <AddOfficeTimingsDrawer
          open={officeTimingDrawerOpen}
          onClose={handleOfficeTimingDrawerClose}
          shiftAction={shiftAction}
          userShiftEditId={userShiftEditId}
        />

        <AddAnnouncementDrawer
          open={announcement}
          onClose={handleAnnouncementClose}
          announcementAction={announcementAction}
          userEditId={userEditId}
        />

        <AddProcessor
          open={processor}
          onClose={handleProcessorClose}
          processAction={processAction}
          userEditId={userEditId}
        />
      </div>
      <Card className={classes.root}>
        <CardContent>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            className={classes.tabContainer}
          >
            <Tab className={classes.tab} label="Overview" {...a11yProps(0)} />
            <Tab className={classes.tab} label="Department" {...a11yProps(1)} />
            <Tab className={classes.tab} label="Office Timings" {...a11yProps(2)} />
            <Tab className={classes.tab} label="Announcements" {...a11yProps(3)} />
            <Tab className={classes.tab} label="Processor" {...a11yProps(4)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <MasterOverview/>
          </TabPanel>

          <TabPanel value={value} index={1}>
            <MasterDepartmentsList drawerOpen={drawerOpen} editDepartment={editDepartment} />
          </TabPanel>

          <TabPanel value={value} index={2}>
            <MasterOfficeTimings   officeTimingPopup={officeTimingPopup}  editShift={editShift} />
           
          </TabPanel>


          <TabPanel value={value} index={3}>
            <MasterDataAnnouncement announcement={announcement} editAnnouncement={editAnnouncement}/>
          </TabPanel>

          <TabPanel value={value} index={4}>
          <MasterProcessorList processor={processor} editProcessor={editProcessor} />
          </TabPanel>
        </CardContent>
      </Card>


    </div>
  );
};

export default MasterData;