import {
    Box,
    TextField,
    Button,
    Checkbox,
    FormControlLabel,
    IconButton,
    InputBase,
    makeStyles,
    withStyles,
  } from "@material-ui/core";
  import { OrangeCheckbox, OrangeSmallCheckbox } from "../../../../Styles";
  import updateIcon from "../../../../Images/history 2.svg";
  import { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { setIsSubmitted } from "../../../../../src/Reducers/loginSlice";
  import { getCookie } from "../../../../../src/Utils/commonUtils";
  import {
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    DropdownItem,
  } from "reactstrap";
  import SearchIcon from "@material-ui/icons/Search";
  import { CircularProgress, InputAdornment, colors } from "@material-ui/core";
  import { useParams } from "react-router-dom";
  import { useFormik } from "formik";
  import * as Yup from "yup";
  import { CommonApi } from "../../../../Services/CommonApi";
  import { BankApi } from "../../../../Services/BankApi";

  const useStyles = makeStyles((theme) => ({
    mainContainer: {
      padding: "80px 30px 20px 30px",
    },
    heading: {
      color: "#3A1C67",
      fontSize: "18px",
      fontWeight: 700,
      textAlign: "left",
      marginLeft: 20,
    },
    sectionContainer: {
      display: "flex",
    },
    section1: {
      // container height
      // height: 500,
      height: 275,
      backgroundColor: "#fff",
      margin: 10,
      width: "100%",
      borderRadius: 10,
    },
    sectionHeading: {
      color: "#fff",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      fontSize: 16,
      backgroundColor: "#3A1C67",
      height: 50,
      padding: "0px 30px",
      textAlign: "left",
      fontWeight: 700,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    historyContainer: {
      padding: 15,
      height: 450,
      overflowY: "scroll",
      "&::-webkit-scrollbar": {
        width: "100%",
        display: "block",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "darkgrey",
        outline: "1px solid slategrey",
        borderRadius: "5px",
      },
    },
    card: {
      width: "100%",
      paddingBottom: 10,
      backgroundColor: "#F1F1F1",
      textAlign: "left",
      fontSize: 12,
      color: "#FA9510",
      boxShadow:
        "rgba(0, 0, 0, 0.22) 0px 1px 5px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
    },
    dot: {
      height: 10,
      width: " 100%",
      border: "1px solid #FA9510",
      borderRadius: "50%",
    },
    indicator: {
      borderLeft: "2px solid #848891",
      height: 60,
      marginLeft: 4,
    },
    saveButton: {
      backgroundColor: "#FA9510",
      color: "#fff",
      textTransform: "none",
      width: "100%",
      height: "30px",
      borderRadius: "10px",
      fontSize: "14px",
      marginLeft: 10,
      "&:hover": {
        backgroundColor: "#FA7A21",
      },
    },
    formContainer: {
      padding: 15,
      width: "100%",
      // height: 450,
      overflowY: "scroll",
      "&::-webkit-scrollbar": {
        width: "100%",
        display: "block",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "darkgrey",
        outline: "1px solid slategrey",
        borderRadius: "5px",
      },
    },
    statusButton: {
      backgroundColor: "#fff",
      color: "#3A1C67",
      textTransform: "none",
      height: "35px",
      borderRadius: "5px",
      border: "1px solid #3A1C67",
      fontSize: "14px",
      marginBottom: 5,
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
      "&:hover": {
        backgroundColor: "#fff",
      },
    },
    root: {
      "& label.Mui-focused": {
        color: "#F86E20",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#F86E20",
        },
        "&:hover fieldset": {
          borderColor: "#F86E20",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#F86E20",
        },
      },
      margin: "5px 5px",
      width: "100%",
      // [theme.breakpoints.down('xs')]: {
      //     width:'110'
      //   },
    },
    multilineColor: {
      color: "#F86E20",
      fontSize: 14,
    },
  }));
  const SanctionBank = ({bankList, statusSectionArr, secondBankSection}) => {
    
    const classes = useStyles();
    const loginInfo = getCookie("callpal-user");
    const [loading, setLoading] = useState(false);
    const [bankSearch, setBankSearch] = useState("");
    const [statusSearch, setStatusSearch] = useState("");
    const [secondSection, setSecondSection] = useState(secondBankSection);
    const params = useParams();
    const dispatch = useDispatch();
    useEffect(() => {
    }, []);
  

    const handleBankChange = (index, value, bankName) => {
        const updatedRows = [...secondSection];
        updatedRows[index].fk_bank_id = value;
        updatedRows[index].fk_bank_name = bankName;
        setSecondSection(updatedRows);
    };
  
    const handleStatusChange = (index, value, statusName) => {
        const updatedRows = [...secondSection];
        updatedRows[index].fk_status_id = value;
        updatedRows[index].fk_status_name = statusName;
        if(statusName == 'Disbursement') {
          updatedRows[index].is_disbursement = true;
        } else {
          updatedRows[index].is_disbursement = false;
        }
        setSecondSection(updatedRows);
    };
  
    const handleSanctionAmountChange = (index, value) => {
        const updatedRows = [...secondSection];
        updatedRows[index].sanction_amount = value;
        setSecondSection(updatedRows);
    };

    const handleDisbursementAmountChange = (index, value) => {
      const updatedRows = [...secondSection];
      updatedRows[index].disbursement_amount = value;
      setSecondSection(updatedRows);
    };


    const calculateContainerHeight = () => {
      // Adjust the multiplier as needed based on your design
      return 250 + secondSection.length * 50; // Assuming each row adds 40 pixels to the height
    };
    const SanctionSubmit = async () => {
     
      try {
        const data = await BankApi.addBankSanction(JSON.stringify({"lead_sanction_bank" : secondSection}));
        if (data.status) {
          dispatch(
            setIsSubmitted({
              open: true,
              severity: "success",
              msg: data.message,
            })
          );
          window.location.reload();
          setLoading(false);
        } else {
          dispatch(
            setIsSubmitted({
              open: true,
              severity: "error",
              msg: data.message,
            })
          );
    
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        console.error("Error in LoginSubmit:", e.message || e.toString());
      }
    };
  
  
    return (
          <div className={classes.sectionContainer}>
          <div className={classes.section1} 
          style={{
            height: "auto",
            // overflowY: "auto",
          }}>
            <div className={classes.sectionHeading}>
              {/* <FormControlLabel
                control={
                  <OrangeCheckbox
                    // checked={state.checkedB}
                    // onChange={handleChange}
                    // name="checkedB"
                    style={{ color: "#fff" }}
                  />
                }
                label={
                  <div>
                    {loading ? <CircularProgress size={20} /> : "Sanction"}
                  </div>
                }
              /> */}
              <div>
                    {loading ? <CircularProgress size={20} /> : "Sanction"}
                  </div>

            <div style={{ display: "flex" }}>
                <div className="btns_add_user"></div>
                <div className="btns_add_user">
                  {secondSection.length > 0 ?  <Button
                    type="submit"
                    className={classes.saveButton}
                    startIcon={<img src={updateIcon} width="100%" alt="" />}
                    onClick={SanctionSubmit}
                  >
                    Save
                  </Button>:<> </>  }
                
                </div>
              </div>
            </div>

            <div style={{ padding: "1rem 1rem" }}>
              {/* Clone row */}
              {secondSection.length > 0 ? (
                secondSection.map((row, index) => (
                  <div className="row" key={index}>
                  {/* Bank Dropdown */}
                  <div className="col-sm-3">
                    <div className={classes.formRow}>
                      <UncontrolledDropdown
                        style={{ margin: "0px 5px", width: "100%" }}
                        disabled={true}
                      >
                        <DropdownToggle
                          caret
                          style={{
                            width: "95%",
                            display: "flex",
                            justifyContent: "space-between",
                            paddingTop: "10px",
                            paddingBottom: "6px",
                            background: "white",
                            color: "#F86E20",
                            border: "1px solid #F86E20",
                            alignItems: "center",
                            fontSize: "14px",
                          }}
                        >
                          {row.fk_bank_name ? row.fk_bank_name : "Select a Bank"}
                        </DropdownToggle>
                        <DropdownMenu style={{ width: "100%" }}>
                          <DropdownItem header>
                            <TextField
                              placeholder="Search Bank"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon />
                                  </InputAdornment>
                                ),
                              }}
                              size="small"
                              variant="outlined"
                              onChange={(e) => setBankSearch(e.target.value)}
                            />
                          </DropdownItem>
                              
                          <div className={classes.dropdownItem}>
                            {bankList && bankList.length > 0 ? (
                              bankList
                                .filter((e) => 
                                  e.bank
                                    .toString()
                                    .toLowerCase()
                                    .includes(bankSearch.toLowerCase())
                                )
                                .map((el, i) => (
                                  <DropdownItem
                                    key={el.id}
                                    onClick={() =>
                                      handleBankChange(index, el.id, el.bank)
                                    }
                                  >
                                    {el.bank}
                                  </DropdownItem>
                                ))
                            ) : (
                              <DropdownItem align="center">
                                No Bank available
                              </DropdownItem>
                            )}
                          </div>
                        </DropdownMenu>
                        <div
                          style={{
                            color: "red",
                            fontSize: 12,
                            textAlign: "left",
                          }}
                        ></div>
                      </UncontrolledDropdown>
                    </div>
                  </div>

                  {/* Status Dropdown */}
                  <div className="col-sm-3">
                    <div className={classes.formRow}>
                      <UncontrolledDropdown
                        style={{ margin: "0px 5px 0px -3px", width: "100%" }}
                      >
                        <DropdownToggle
                          caret
                          style={{
                            width: "95%",
                            display: "flex",
                            justifyContent: "space-between",
                            paddingTop: "10px",
                            paddingBottom: "6px",
                            background: "white",
                            color: "#F86E20",
                            border: "1px solid #F86E20",
                            alignItems: "center",
                            fontSize: "14px",
                          }}
                        >
                          {row.fk_status_name ? row.fk_status_name : "Select Status"}
                        </DropdownToggle>
                        <DropdownMenu style={{ width: "100%" }}>
                          <DropdownItem header>
                            <TextField
                              placeholder="Search Status"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon />
                                  </InputAdornment>
                                ),
                              }}
                              size="small"
                              variant="outlined"
                              onChange={(e) => setStatusSearch(e.target.value)}
                            />
                          </DropdownItem>

                          <div className={classes.dropdownItem}>
                            {Object.entries(row?.status).length > 0 ? (
                              Object.entries(row.status).map(([id, status]) => ({ id, status }))
                                .filter((e) =>
                                  e.status
                                    .toString()
                                    .toLowerCase()
                                    .includes(statusSearch.toLowerCase())
                                )
                                .map((el, i) => (
                                  <DropdownItem
                                    key={el.id}
                                    onClick={() =>
                                      handleStatusChange(index, el.id, el.status)
                                    }
                                  >
                                    {el.status}
                                  </DropdownItem>
                                ))
                            ) : (
                              <DropdownItem align="center">
                                No Status available
                              </DropdownItem>
                            )}
                          </div>
                        </DropdownMenu>
                        <div
                          style={{
                            color: "red",
                            fontSize: 12,
                            textAlign: "left",
                          }}
                        ></div>
                      </UncontrolledDropdown>
                    </div>
                  </div>

                  {/* Amount Field */}

                  <div className="col-sm-2">
                    <TextField
                      size="small"
                      placeholder="Login Amount"
                      inputProps={{
                        className: classes.multilineColor,
                        readOnly:true,
                        style: {
                          height: "13px",
                        },
                      }}
                      className={classes.root}
                      id="login_amount"
                      variant="outlined"
                      name="login_amount"
                      value={row.login_amount}
                    />
                  </div>


                  <div className="col-sm-2">
                    <TextField
                      size="small"
                      placeholder="Sanction Amount"
                      inputProps={{
                        className: classes.multilineColor,
                        style: {
                          height: "13px",
                        },
                        readOnly:true
                      }}
                      className={classes.root}
                      id="sanction_amount"
                      variant="outlined"
                      name="sanction_amount"
                      onChange={(e) =>
                        handleSanctionAmountChange(index, e.target.value)
                      }
                      value={row.sanction_amount}
                    />
                  </div>

                  {row.is_disbursement &&
                    <div className="col-sm-2">
                      <TextField
                        size="small"
                        placeholder="Disbursement Amount"
                        inputProps={{
                          className: classes.multilineColor,
                          style: {
                            height: "13px",
                          },
                        }}
                        className={classes.root}
                        id="disbursement_amount"
                        variant="outlined"
                        name="disbursement_amount"
                        onChange={(e) =>
                          handleDisbursementAmountChange(index, e.target.value)
                        }
                        value={row.disbursement_amount}
                      />
                    </div>
                    }

                  {/* Delete Button */}
                  <div className="col-sm-2">
                    
                  </div>
                </div>
                ))
              ) : (
                <div className="noRecordFound">No records found</div>
              )}
                          
            </div>
          </div>
          </div>
    );
  };
  export default SanctionBank;
  