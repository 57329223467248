import React from 'react'
import {  makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import { Box, Button, Divider,  MenuItem } from "@material-ui/core";


import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../../CustomCSS/CallLogs.css'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import 'react-date-picker/dist/DatePicker.css'
import 'react-calendar/dist/Calendar.css';
import { useParams } from "react-router-dom";
import ViewCallLogHistory from './ViewCallLogHistory'
import moment from "moment";
import {  useNavigate } from 'react-router-dom';

import {
  CircularProgress,
  InputAdornment,
  TablePagination,
  TextField,
} from '@material-ui/core'
import { useEffect } from 'react'
import { useState } from 'react'
import incoming from '../../../Images/Incoming.svg'
import outgoing from '../../../Images/Out going.svg'
import { StyledTableCell, StyledTableRow } from '../../../Styles'
import DatePicker from 'react-date-picker'
import SearchIcon from '@material-ui/icons/Search';
import {
  DateRange

} from '@material-ui/icons'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from 'reactstrap'
import { useSelector } from 'react-redux'
import NoRecords from '../Other-components/NoRecords'
import UseGetUserData from '../../../Hooks/UseGetUserData'
import EditIcon from '@material-ui/icons/Edit';
import { getCookie } from '../../../Utils/commonUtils'
import viewIcon from "../../../Images/eye.svg";
import { CallLogApi } from "../../../Services/CallLogApi";
import { CommonApi } from "../../../Services/CommonApi";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
const useStyles = makeStyles({
  mainContainer: {
    padding: '80px 30px 30px 30px',
  },
  table: {
    minWidth: 700,
  },
  button: {
    backgroundColor: '#3A1C67',
    color: '#fff',
    marginLeft: '10px',
    borderRadius: '5px',
    fontSize: '12px',
    padding: '5px 20px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#7916BD',
    },
  },
  tableContainer: {
    margin: '20px 0px',
    maxHeight: 450,
    width: '100%',
    '&::-webkit-scrollbar': {
      width: '0.5em',
      display: 'block',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'darkgrey',
      outline: '1px solid slategrey',
      borderRadius: '5px',
    },
  },
  filterHolder: {
    display: 'flex',
    gap: '20px',
    justifyContent: 'space-between'
  },
  dateInputComp: {
    display: 'flex',
    background: 'white',
    alignItems: 'center',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingTop: '6px',
    paddingBottom: '6px',
    gap: '10px',
  },
  filterHolderComp: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 10
  },
  filterDropDown: {
    outline: 'none !important',
    width: '200px',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingTop: '7px',
    paddingBottom: '7px',
    border: 'none',
    color: '#3A1C67',
    fontSize: 'medium',
  },
  eyeicon:{
    cursor:"pointer"
  },
  dropDownButton: {
    textTransform: "none",
    backgroundColor: "#F1F1F1",
    width: "130px",
    color: "#3A1C67",
    fontSize: "12px",
    fontFamily: "open-sans",
    borderRadius: "7px",
    margin: "0px 10px",
    "&:hover": {
      backgroundColor: "#fff",
      
    },
  },
  button: {
    backgroundColor: '#3A1C67',
    color: '#fff',
    marginLeft: '10px',
    borderRadius: '5px',
    fontSize: '12px',
    padding: '5px 20px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#7916BD',
    },
  },

})

export default function CallLogs() {
  const classes = useStyles()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const { userInfo } = useSelector((state) => state.login)
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(false);
  const [loadingHistory, setLoadingHistory] = useState(false);

  
  const [open, setOpen] = useState(false);
  const [offset, setOffset] = useState(1);
  const [reportSearch, setReportSearch] = useState('');
  const [statusSearch, setStatusSearch] = useState('');
  const [empDetails, setEmpDetails] = useState({
    id: '',
    name: ''
  });
  const [statusDetails, setStatusDetails] = useState({
    id: '',
    name: ''
  });
  const [startDate,setStartDate]=useState('');
  const [endDate,setEndDate]=useState('');
  const loginInfo=getCookie('callpal-user');
  const [action, setAction] = useState('Add');
  const [lead_id, setLeadId] = useState();
  const [historyData, setHistoryData] = useState([]);
  const [statusArr, setStatusArr] = useState([]);
  const [statusLoader, setStatusLoader] = useState(false);
  const [reportLoader,setReportLoader]=useState(false)
  const [reportArr,setReportArr]=useState([])
  const navigate = useNavigate()
  const [exportExcelLoading, setExportExcelLoading] = useState(false);

  const [filter, setFilter] = useState({
    today: true,
    last_7: false,
    last_30: false,
    range: false,
  });


  const [count, setCount] = useState(0)
  const params = useParams();
  const id = params.id != '' && params.id != undefined ?  atob(params.id) : loginInfo.userInfo.id;
  const handleChangePage = (event, newPage) => {
    
    setPage(newPage);
    let num = 0
    if (newPage > page) {
      num = offset + 1
    }
    else {
      num = offset - 1
    }

    setOffset(num)
  };


  

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setOffset(0)
  };

  const heading = [
      'Customer/Leads Name',
      'Number',
      'Status',
      // 'Customer',
      'Tele Caller',
      'Duration',
      'Action'
  ]

  useEffect(() => {
    
    if (((userInfo.hasOwnProperty('mobile_number') || userInfo?.userInfo?.mobile_number) && moment(endDate).diff(moment(startDate), 'days')>=0)|| (startDate=='' && endDate=='')) {
      getCallLogData();
      getStatusData();
      getTeamLeadTeleCallerList();
    }
 
    if(moment(endDate).diff(moment(startDate), 'days')<0){
      setEndDate(startDate)
    }
  }, [userInfo, page, rowsPerPage,empDetails,statusDetails,startDate,endDate])

  const getCallLogData = async () => {
    try {
      setLoading(true); 
      const data = JSON.stringify({
        per_page_row: rowsPerPage,
          page: offset,
          // fromdate: dates? dates.from : startDate,
          // todate:dates? dates.to : endDate ,
          fromdate: startDate,
          todate: endDate ,
          user_id: empDetails.id,
          status_id: statusDetails.id,
          team_lead_id: parseInt(id),
      });
      const res = await CallLogApi.getCallLogList(data);
      if (res.status) {
        setRows([...res.data]);
        setCount(res.total_count);
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const getStatusData = async () => {
    try {
      setStatusLoader(true);
      const res = await CommonApi.getStatusData();
      if (res.status) {
        const statusArray = Object.entries(res.data).map(
          ([id, status]) => ({ id, status }),
        );
        setStatusArr(statusArray);
      }
      setStatusLoader(false);
    } catch (err) {
      setStatusLoader(false);
    } finally {
      setStatusLoader(false);
    }
  };


  const getCallHistory = async (lead_id) => {
    try {
      setLoadingHistory(true)
      const obj = JSON.stringify({
        lead_id: lead_id
      });
      const res = await CallLogApi.getCallHistory(obj);
      setLoadingHistory(false)
      if (res.status) {
        setHistoryData(res.data)
      }
    }
    catch (e) {
     setLoadingHistory(false)
    }
  }


  const getTeamLeadTeleCallerList = async () => {
    try {
      setReportLoader(true);
      const teamLeadId = id;
      const res = await CommonApi.getTeamLeadTeleCaller(teamLeadId);
      if (res.status) {
        const reportingArray = Object.entries(res.data).map(
          ([id, reporting_to]) => ({ id, reporting_to }),
        );
        setReportArr(reportingArray);
      }
      setReportLoader(false);
    } catch (err) {
      setReportLoader(false);
    } finally {
      setReportLoader(false);
    }
  };


  const setDateFilter = async (flag) => {
    if (flag == "1") {
      setStartDate(moment().format("YYYY-MM-DD"));
      setEndDate(moment().format("YYYY-MM-DD"));
    } else if (flag == "7") {
      setStartDate(moment().subtract(7, "days").format("YYYY-MM-DD"));
      setEndDate(moment().format("YYYY-MM-DD"));
    } else if (flag == "30") {
      setStartDate(moment().subtract(30, "days").format("YYYY-MM-DD"));
      setEndDate(moment().format("YYYY-MM-DD"));
    } else {
      setStartDate(startDate);
      setEndDate(endDate);
    }
  };


  const handleExportExcel = async() => {
    try {
      setExportExcelLoading(true);
      const data = JSON.stringify({
        per_page_row: count,
        page: offset,
        fromdate: startDate,
        todate: endDate ,
        user_id: empDetails.id,
        status_id: statusDetails.id,
        team_lead_id: parseInt(id),
      });
      const res = await CallLogApi.getCallLogList(data);;
      if (res.status) {
        const formattedData = res.data.map(row => ({
          'Customer/Leads Name':row.name,
          'Number': row.mobile_number,
          'Status':row.status,
          'Tele Caller':row.telecaller,
          'Duration':row.calltime,
        }));
  
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(formattedData, { header: heading});
  
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
        saveAs(dataBlob, `Tele_Caller_Call_logs.xlsx`);
      }
    } catch (err) {
      setExportExcelLoading(false);
      console.error(err);
    } finally {
      setExportExcelLoading(false);
    }
  }


  return (
    <div className={classes.mainContainer}>
      <div className={classes.filterHolder}>
        <div
          style={{
            color: '#F86E20',
            textAlign: 'center',
            fontFamily: 'Open-sans',
            width: 'fit-content',
          }}
          className={classes.filterHolderComp}
        >
          <h4 style={{ fontWeight: '600' }}> {count !== 0 && count} Call Logs</h4>
        </div>
        <div style={{ display: 'flex' }}>

          
          <div className={classes.filterHolderComp}>
            {/* <UncontrolledButtonDropdown>
              <DropdownToggle
                style={{
                  display: 'flex',
                  gap: '10px',
                  background: 'white',
                  border: 'none',
                  color: '#3A1C67',
                  borderRadius: '5px',
                }}
              >
                Status: Select Filters
                <ArrowDropDownIcon></ArrowDropDownIcon>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>One</DropdownItem>
                <DropdownItem>Two</DropdownItem>
                <DropdownItem>Three</DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown> */}

            <UncontrolledButtonDropdown>
              <DropdownToggle
                style={{
                  display: 'flex',
                  gap: '10px',
                  background: 'white',
                  color: '#3A1C67',
                  border: 'none',
                  borderRadius: '5px',
                  width: 160,
                  justifyContent: 'space-between'
                }}
              >
               {statusDetails.name.length==0?'Select Status':statusDetails.name}
              <ArrowDropDownIcon></ArrowDropDownIcon>
              </DropdownToggle>
              <DropdownMenu>
              {statusArr.length > 0 && !statusLoader && (
                 <>
                <DropdownItem header>
                  <TextField
                    placeholder="Search Status" 
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    size='small' variant='outlined' onChange={(e) => setStatusSearch(e.target.value)} />
                </DropdownItem>
                <div style={{ maxHeight: 150, overflowY: 'scroll' }}>
                  {
                    statusArr.length > 0 && statusArr.filter((e) => (e.status.toString().toLowerCase().includes(statusSearch.toLowerCase()))
                    ).map((el, i) => (
                      <DropdownItem key={el.id} onClick={() => { setStatusDetails({ ...statusDetails, id: el.id, name: el.status }) }}>{el.status}</DropdownItem>
                    ))
                  }
                </div>
                </>
                 )} 
                {statusLoader && (
                        <div style={{ textAlign: "center" }}>
                    <CircularProgress size={20} />
                  </div>
                )}
              </DropdownMenu>
            </UncontrolledButtonDropdown>


          </div>
          <div className={classes.filterHolderComp}>
            <UncontrolledButtonDropdown>
              <DropdownToggle
                style={{
                  display: 'flex',
                  gap: '10px',
                  background: 'white',
                  color: '#3A1C67',
                  border: 'none',
                  borderRadius: '5px',
                  width: 164,
                  justifyContent: 'space-between'
                }}
              >
               {empDetails.name.length==0?'All Employees':empDetails.name}
              <ArrowDropDownIcon></ArrowDropDownIcon>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem header>
                  <TextField
                    placeholder="Search Employees"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    size='small' variant='outlined' onChange={(e) => setReportSearch(e.target.value)} />
                </DropdownItem>
                <div style={{ maxHeight: 150, overflowY: 'scroll' }}>
                  {
                    reportArr.length > 0 && reportArr.filter((e) => (e.reporting_to.toString().toLowerCase().includes(reportSearch.toLowerCase()))
                    ).map((el, i) => (
                      <DropdownItem key={el.id} onClick={() => { setEmpDetails({ ...empDetails, id: el.id, name: el.reporting_to }) }}>{el.reporting_to}</DropdownItem>

                    ))
                  }
                </div>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
          <div className={classes.filterHolderComp} >
            <UncontrolledButtonDropdown>
              <DropdownToggle
                 style={{
                  display: 'flex',
                  gap: '10px',
                  background: 'white',
                  color: '#3A1C67',
                  border: 'none',
                  borderRadius: '5px',
                  width: 164,
                  justifyContent: 'space-between'
                }}
              >
                Select Days
                <ArrowDropDownIcon></ArrowDropDownIcon>
              </DropdownToggle>
              <DropdownMenu>
                <>
                  <DropdownItem header>
                    {/* <TextField
                      placeholder="Search Product"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      size="small"
                      variant="outlined"
                      onChange={(e) => setStatusSearch(e.target.value)}
                    /> */}
                  </DropdownItem>
                  <div style={{ maxHeight: 150, overflowY: "scroll" }}>
                    <DropdownItem>
                      <div>
                        <Button
                          style={{ backgroundColor: filter.today && "#fff" }}
                          className={classes.dropDownButton}
                          onClick={() => setDateFilter("1")}
                        >
                          Today's
                        </Button>
                      </div>
                    </DropdownItem>

                    <DropdownItem>
                      <div>
                        <Button
                          style={{ backgroundColor: filter.last_7 && "#fff" }}
                          className={classes.dropDownButton}
                          onClick={() => setDateFilter("7")}
                        >
                          Last 7 days
                        </Button>
                      </div>
                    </DropdownItem>

                    <DropdownItem>
                      <div>
                        <Button
                          style={{ backgroundColor: filter.last_30 && "#fff" }}
                          className={classes.dropDownButton}
                          onClick={() => setDateFilter("30")}
                        >
                          Last 30 days
                        </Button>
                      </div>
                    </DropdownItem>
                  </div>
                </>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>


          <div className={classes.dateInputComp}>
            {/* <input type='date' style={{ borderRight: 'none' }}></input>
          <input type='date' style={{ borderLeft: 'none' }}></input> */}

            <div>
              <DateRange className='dateIcon' color='#3A1C67'></DateRange>
            </div>
            <div style={{ display: 'flex', gap: '5px' }}>
              {' '}
              <DatePicker
                style={{ display: 'none', color: '#3A1C67', cursor: 'pointer' }}
                calendarIcon={null}
                clearIcon={null}
                className='datePickerInput'
                value={startDate}
                onChange={(val)=>{
                  const d= moment(val).format('YYYY-MM-DD');
                  setStartDate(d);
                }}
              //   onClick={() => setDateFilter("range")
              
              // const d= moment(val).format('YYYY-MM-DD');
              //    setStartDate(d);
              //   }

              // onClick={(val) => {
              //   const d = moment(val).format('YYYY-MM-DD');
              //   setStartDate(d);
              //   setDateFilter("range");
              // }}

              ></DatePicker>
              -
              <DatePicker
                calendarIcon={null}
                clearIcon={null}
                style={{ display: 'none', color: '#3A1C67' }}
                value={endDate}
                onChange={(val)=>{
                  const d= moment(val).format('YYYY-MM-DD')
                  setEndDate(d)
                }}
                // onClick={(val)=>{
                //   const d= moment(val).format('YYYY-MM-DD');
                //   setEndDate(d);
                //   setDateFilter("range");
                // }}
                minDate={new Date(startDate)}
                className='datePickerInput'
              ></DatePicker>


          
            </div>
            
          </div>
          {/* {loginInfo.userInfo.fk_role_id == 2 && (
          <Button className={classes.button} onClick={() => navigate(-1)}> 
               Back
          </Button>
          )} */}


          <div >
              <Button className={classes.button} onClick={() => handleExportExcel()}  > 
                  {exportExcelLoading ? "Please wait...." : "Export" } 
              </Button>
          </div>

        </div>
      </div>

      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table
          stickyHeader
          className={classes.table}
          aria-label='customized table'
          size='small'
        >
          <TableHead>
            <TableRow>
              {heading.map((el, i) => (
                <StyledTableCell key={i} align='center'>
                  {el}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.length > 0 && !loading && rows.map((row) => (
              <StyledTableRow>
                <StyledTableCell align='center'>
                  {/* {
                    <img
                      src={row.status === 'Interested' ? incoming : outgoing}
                      height={22}
                      alt=''
                    />
                  } */}


                  {row.name}
                  </StyledTableCell>
                  <StyledTableCell
                    align='center'
                  >

                  {row.mobile_number}
                  {/* {!row.status?'NA':row.status} */}
                </StyledTableCell>
                <StyledTableCell align='center'>
                  {row.status}
                </StyledTableCell>
                <StyledTableCell align='center'>{row.telecaller}</StyledTableCell>
                <StyledTableCell align='center'>{row.calltime}</StyledTableCell>
                <StyledTableCell align='center'>
                {/* <EditIcon/>  */}
               
                {<img className={classes.eyeicon} src={viewIcon} onClick={() => { setAction('Add'); getCallHistory(row.id); setOpen(true) }} alt="" />}

                </StyledTableCell>
                {/* <StyledTableCell align='center'>{row.time}</StyledTableCell>
                <StyledTableCell align='center'>
                  {row.duration}
                </StyledTableCell> */}
                
                {/* <StyledTableCell align='center'>
                 <EditIcon/>
                </StyledTableCell> */}
              </StyledTableRow>
            ))}
            <NoRecords rows={rows} loading={loading} />
          </TableBody>
        </Table> 
      </TableContainer>

      <ViewCallLogHistory open={open} setOpen={(f) => setOpen(f)} param={'team'} action={action} loading={loadingHistory} historyData={historyData}/>

      <div>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              backIconButtonProps={{
                "aria-label": "Previous Page",
                style: {
                  color: "#fff",
                  backgroundColor: "#3A1C67",
                  padding: 3,
                  borderRadius: "5px",
                  marginRight: "5px",
                },
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page",
                style: {
                  color: "#fff",
                  backgroundColor: "#3A1C67",
                  padding: "3px",
                  borderRadius: "5px",
                },
              }}
            />
          </div>
    </div>
  )
}
