
import hyundai from "../../Images/hyundai.png";
import mercedesbenz from "../../Images/mercedesbenz.png";
import prograd from "../../Images/prograd.png";
import indira from "../../Images/indira.png";
import kama from "../../Images/kama.png";
import upstox from "../../Images/upstox.png";



import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../Components/CustomCarousel/CustomCarousel.css";

import { EffectCoverflow, Pagination, Navigation, Autoplay } from "swiper";

const Page3_2 = () => {
  return (
    <>
      
      <div className="trusted_main_slide" style={{ padding: "12px 0" , marginBottom: "30px"}}>
        <div className="">
          <h2 className="" style={{ color: "#3A1C67" }}>
            Trusted By
          </h2>
           
        </div>
        {/* <div className="trusted_slides" style={{ display: "flex", justifyContent: 'center' }}>
          <div>
            <img loading="eager" src={hyundai} alt="" />
          </div>
          <div>
            <img loading="eager" src={mercedesbenz} alt="" />
          </div>
          <div>
            <img loading="eager" src={prograd} alt="" style={{width: "255px" , marginTop:"10px"}} />
          </div>
          <div>
            <img loading="eager" src={indira} alt="" style={{width: "255px" , marginTop:"10px"}}/>
          </div>
          <div>
            <img loading="eager" src={kama} alt="" style={{width: "255px" , marginTop:"10px"}}/>
          </div>
          <div>
            <img loading="eager" src={upstox} alt="" />
          </div>
        </div> */}

        <Swiper
          // effect={'coverflow'}
          spaceBetween={40}
          slidesPerView={4}
          grabCursor={true}
          centeredSlides={true}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          coverflowEffect={
            {
              // rotate: 0,
              // stretch: 0,
              // depth: 100,
              // modifier: 2.5,
            }
          }
          modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
          className="swiper_container trusted_by_slides"
        >
          <SwiperSlide>
            <div className="sliderContain">
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  marginBottom: "40px",
                }}
              >
                <img
                  src={mercedesbenz}
                  style={{
                    width: "auto",
                    height: "150px",
                  }}
                ></img>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="sliderContain">
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  marginBottom: "40px",
                }}
              >
                <img
                  src={prograd}
                  style={{
                    width: "auto",
                    height: "150px",
                  }}
                ></img>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="sliderContain">
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  marginBottom: "40px",
                }}
              >
                <img
                  src={indira}
                  style={{
                    width: "auto",
                    height: "150px",
                  }}
                ></img>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="sliderContain">
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  marginBottom: "40px",
                }}
              >
                <img
                  src={kama}
                  style={{
                    width: "auto",
                    height: "150px",
                  }}
                ></img>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="sliderContain">
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  marginBottom: "40px",
                }}
              >
                <img
                  src={prograd}
                  style={{
                    width: "auto",
                    height: "150px",
                  }}
                ></img>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="sliderContain">
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  marginBottom: "40px",
                }}
              >
                <img
                  src={kama}
                  style={{
                    width: "auto",
                    height: "150px",
                  }}
                ></img>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="sliderContain">
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  marginBottom: "40px",
                }}
              >
                <img
                  src={prograd}
                  style={{
                    width: "auto",
                    height: "150px",
                  }}
                ></img>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="sliderContain">
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  marginBottom: "40px",
                }}
              >
                <img
                  src={kama}
                  style={{
                    width: "auto",
                    height: "150px",
                  }}
                ></img>
              </div>
            </div>
          </SwiperSlide>

          {/* <SwiperSlide>
          <img src={slide_image_2} alt='slide_image' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_3} alt='slide_image' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_4} alt='slide_image' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_5} alt='slide_image' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_6} alt='slide_image' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_7} alt='slide_image' />
        </SwiperSlide> */}
        </Swiper>
      </div>
    </>
  );
};

export default Page3_2;
