import { Box, Button,  CircularProgress,  Container, TextField, makeStyles } from "@material-ui/core";
import LeftVectorCRM from '../../Images/LeftVectorCRM.png'
import { useFormik } from "formik";
import * as Yup from "yup";
import { addContactUSDetail } from "../../Redux/features/home/homeSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { routes } from "../../constants/routes";
import { setIsSubmitted } from "../../Reducers/loginSlice";
const useStyles = makeStyles((theme) => ({
  mainContainer:{
   padding:'100px 70px 40px',
   [theme.breakpoints.down('sm')]: {
    padding:'100px 20px',
   
   
  },
  },
   container:{
    display:'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection:'column',
    },
   },
   form:{
    marginTop:'20px',
    width:'40%',
    backgroundColor:'#fff',
   padding:'30px 60px 30px 30px',
   textAlign:'left',
   borderRadius:'12px',
    maxHeight:'400px',
   marginLeft:'20px',
   [theme.breakpoints.down('sm')]: {
    marginLeft:'0px',
    width:'100%',
   marginRight:'20px',
   
  },
   },
   image:{
    height:'auto',
    width:'60%',
    [theme.breakpoints.down('sm')]: {
      width:'100%'
    },
   },
   root: {
  
    '& label.Mui-focused': {
      color: '#FA9510',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#FA9510',
       
      },
      '&:hover fieldset': {
        borderColor: '#FA9510',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FA9510',
      },
    },
   marginTop:'20px',
   width:'100%'
},
multilineColor:{
    color:'#FA9510'
},
button:{
    backgroundColor:'#FA9510',
    color:'#fff',
    textTransform:'none',marginTop:'20px',
    width:'200px',
    transition:'.4s all ease-in-out',
    '&:hover':{
      backgroundColor:'#FA9510',
      boxShadow:'-2px 5px 8px #575f6b80'
    }
},


    }))
const ProjectSubscription=({pageTitle,pageDetail,pageDetailTheme})=>{
    const classes=useStyles()
    const dispatch = useDispatch();
      const navigate = useNavigate()
      const { loading } = useSelector((state) => state.home)
    
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
      phone_number: Yup.string()
      .required("Phone number is required")
      .matches(/^\d{10}$/, "Phone number must be 10 digits"),
  });
    const formik = useFormik({
      initialValues: {
          name: "",
          email: "",
          phone_number: "",
      },
      validationSchema,
      // validateOnChange: false,
      // validateOnBlur: false,
      onSubmit: (data) => {
          data['contact_type_id']=3
         
          dispatch(addContactUSDetail(data)).then((res)=>{
            if(res.type==='home/addContactUSDetail/fulfilled' && res?.payload?.status){
            dispatch(setIsSubmitted({ open: true, severity: 'success', msg: res?.payload?.message }))
             navigate(routes.signUp) 
            } 
            if(res.type==='home/addContactUSDetail/rejected'){
                dispatch(setIsSubmitted({ open: true, severity: 'error', msg: 'Something went wrong!' }))
                } 
        }).catch((error)=>{
            dispatch(setIsSubmitted({open:true,severity:'error',msg:'Something went wrong !'}))
          })
      },
  });
    
  
    return(
        <Container maxWidth="lg" className={classes.mainContainer}>
            <Box className={classes.container}>
            <img loading="eager" src={LeftVectorCRM} className={classes.image}/>
            
            <Box className={classes.form}>
                <Box style={{color:'#3A1C67',fontWeight:600}}>
                Get 10 Days Free Trial
                </Box>
                <form onSubmit={formik.handleSubmit}>
            <TextField 
            size="small"
            placeholder="Name*"
            name='name'
            inputProps={{
                className:classes.multilineColor
              }}
            className={classes.root} id="outlined-basic"  variant="outlined" 
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            />
 {formik.touched.name && formik.errors.name ? (
                  <span className="text-danger formik-addUser-msg text-small text-left ">
                    {formik.errors.name}
                  </span>
                ) : null}
              <TextField 
              size="small"
            placeholder="Email Address*"
            name='email'
            inputProps={{
                className:classes.multilineColor
              }}
            className={classes.root} id="outlined-basic"  variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email} />
            {formik.touched.email && formik.errors.email ? (
                  <span className="text-danger formik-addUser-msg text-small text-left ">
                    {formik.errors.email}
                  </span>
                ) : null}
              <TextField 
              name='phone_number'
              size="small"
            placeholder="Phone Number*"
            inputProps={{
                className:classes.multilineColor
              }}
            className={classes.root} id="outlined-basic"  variant="outlined" 
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phone_number} />
            {formik.touched.phone_number && formik.errors.phone_number ? (
                  <span className="text-danger formik-addUser-msg text-small text-left ">
                    {formik.errors.phone_number}
                  </span>
                ) : null}
            <div>
            <label style={{color:'#848891',fontSize:'14px',paddingBottom:'20px'}}>
            <input type="checkbox" style={{marginRight:"5px", marginTop:"10px"}}/>
            By clicking here I agree to the privacy policy
            </label>
            </div>
            <Button className={classes.button} type="submit">
            {loading ? <CircularProgress size={20} /> : 'Try Free Trial'}  
            </Button>
            </form>
            </Box>
            </Box>
            <Box style={{textAlign:'left'}}>
                <div style={{color:'#3A1C67',fontSize:'35px',fontWeight:800,padding:'15px 0px'}}>{pageTitle}</div>
                <div className={pageDetailTheme}>
      {pageDetail}               
 </div>
            </Box>
        </Container>
    )
}
export default ProjectSubscription;
