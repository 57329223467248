import axios from "axios"
import { getCookie } from '../Utils/commonUtils'

export const TeamApi = {
    addTeam: async (data) => {
      try {
         
          // const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/add-update-team`, data, {
          //   headers: {
          //     'Accept': 'application/json',
          //     //'Access-Control-Allow-Origin': '*',
          //     'Content-Type': 'application/json',
          //     'Authorization': `Bearer ${getCookie('callpal-user').token}`
          //   }
          // });
          const response = await fetch(
            `${process.env.REACT_APP_API_ENDPOINT}/add-update-team`,
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                'Authorization': `Bearer ${getCookie('callpal-user').token}`
              },
              body: data,
            }
          );
          return  await response.json(); // Assuming your data is in response.data
        } catch (error) {
          console.error('Error in getTeamList:', error);
          return false
          //throw error; // Re-throw the error to handle it at the calling site if needed
        }
    },
    getTeamList: async (data) => {
        try {
           
            const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/my-team-member`, data, {
              headers: {
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getCookie('callpal-user').token}`
              }
            });
            return response; // Assuming your data is in response.data
          } catch (error) {
            console.error('Error in getTeamList:', error);
            return false
            //throw error; // Re-throw the error to handle it at the calling site if needed
          }
    },
    deleteTeam: async (id) => {
        try {
           
            const response = await axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/user-delete/${id}`, {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getCookie('callpal-user').token}`
              }
            });
            return response;
          } catch (error) {
            console.error('Error in deleteTeam:', error);
            return false
            //throw error; // Re-throw the error to handle it at the calling site if needed
          }
    },
    getTeamViewList: async (data) => {
      try {
         
          const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/my-team-leads`, data, {
            headers: {
              'Accept': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getCookie('callpal-user').token}`
            }
          });
          return response; // Assuming your data is in response.data
        } catch (error) {
          console.error('Error in getTeamList:', error);
          return false
          //throw error; // Re-throw the error to handle it at the calling site if needed
        }
  },
  getTeamViewDetailList: async (data) => {
    try {
       
        const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/team-lead-telecaller-lead`, data, {
          headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getCookie('callpal-user').token}`
          }
        });
        return response; // Assuming your data is in response.data
      } catch (error) {
        console.error('Error in getTeamList:', error);
        return false
        //throw error; // Re-throw the error to handle it at the calling site if needed
      }
},
}