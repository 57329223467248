import {
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  props
  
} from '@material-ui/core'
import filterIcon from '../../Images/Filter 1.svg'
import { useEffect } from 'react'
import { getCookie } from '../../Utils/commonUtils'
import { useDispatch, useSelector } from 'react-redux'
import NoRecords from '../Dashboard/Other-components/NoRecords'
import { useState } from 'react'
import { StyledTableCell, StyledTableRow } from '../../Styles'
import React from 'react'
import {
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@material-ui/icons'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap'
import FollowUpCount from './FollowUpCount'
import { FollowUpApi } from "../../Services/FollowUpApi";
import { useParams } from "react-router-dom";

const useStyles = makeStyles({
  mainContainer: {
    padding: '80px 30px 30px 30px',
  },
  table: {
    minWidth: 700,
  },

  button: {
    backgroundColor: '#3A1C67',
    color: '#fff',
    marginLeft: '10px',
    borderRadius: '5px',
    fontSize: '12px',
    padding: '5px 20px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#7916BD',
    },
  },
  tableContainer: {
    margin: '20px 0px',
    maxHeight: 450,
    width: '100%',
    '&::-webkit-scrollbar': {
      width: '0.5em',
      display: 'block',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'darkgrey',
      outline: '1px solid slategrey',
      borderRadius: '5px',
    },
  },
})
const FollowUp = ({ type, setShowHistory, todayCount, }) => {
  const classes = useStyles({ type: type })
  const [status, setStatus] = useState('pending')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const loginInfo=getCookie('callpal-user'); 
 const [updated, setUpdated] = useState(true)
 const [count,setCount]=useState(0);
 const { userInfo } = useSelector((state) => state.login);
 const [offset, setOffset] = useState(1);
 const [rows, setRows] = useState([]);
 const [ pending, setPending] = useState();
 const [ upcoming, setUpcoming] = useState();
 const [ today, setToday] = useState();

 const [ pendingTitle, setPendingTitle] = useState("");
 const [ upcomingTitle, setUpcomingTitle] = useState("");
 const [ todayTitle, setTodayTitle] = useState("");
 const [currentStatus, setCurrentStatus] = useState("");
 const params = useParams();
 const id = params.id != '' && params.id != undefined ?  atob(params.id) : loginInfo.userInfo.id;


 const handleChangePage = (event, newPage) => {
  setPage(newPage);
  let num=0
  if(newPage>page){
      num=offset+1
  }
  else{
      num=offset-1
  }
 
  setOffset(num)
  };

  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
      setOffset(0)
  };

  const heading = [
    'User Name',
    'Phone no.',
    'Tele Caller',
    'Status',
    'Created On',
    'Followup On',
  ]

  useEffect(() => {
  
    if (loginInfo?.userInfo?.mobile_number && updated) {
      getFollowUpData(currentStatus);
    }
    
  }, [loginInfo?.userInfo?.mobile_number, page, rowsPerPage, updated])

  const searchFilter = (currentStatusValue) => {
    getFollowUpData(currentStatusValue);
  }
  


  const getFollowUpData = async (currentStatusValue) => {
    try {
      setLoading(true);
      const data = JSON.stringify({
        team_lead_id: parseInt(id),
        per_page_row: rowsPerPage,
        page: offset,
        status:currentStatusValue,
      });
      const res = await FollowUpApi.getFollowUpList(data);
      if (res.status) {
        setRows([...res.data])
        setCount(res.total_count);
        setPending(res.pending);
        setUpcoming(res.upcoming);
        setToday(res.today);
        setPendingTitle(res.pending_title);
        setUpcomingTitle(res.upcoming_title);
        setTodayTitle(res.today_title);
      }
    } catch (err) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }


 

  
 
  return (
    <div className={classes.mainContainer}>
      <FollowUpCount  searchFilter={searchFilter} todayCount ={today}  pendingCount={pending} upcomingCount={upcoming} pendingTitle={pendingTitle} upcomingTitle={upcomingTitle} todayTitle={todayTitle} />
      <TableContainer component={Paper}  className={classes.tableContainer}>
        <Table
          stickyHeader
          className={classes.table}
          aria-label='customized table'
          size='small'
        >
          <TableHead>
            <TableRow>
              {heading.map((el, i) => {
                return (
                  <StyledTableCell key={i} align='center'>
                    {el}
                  </StyledTableCell>
                )
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            { rows.length>0 && !loading && rows.map((row) => (
                <StyledTableRow
                  style={{ cursor: 'pointer',  backgroundColor : (row.bgcolor),  color : (row.tcolor)}}
                  onClick={() => type == 'customer' && setShowHistory(true)}
                >
                  <StyledTableCell align='center'  style={{color : (row.tcolor)}}>
                    {row.name}
                  </StyledTableCell>
                  <StyledTableCell align='center'  style={{color : (row.tcolor)}}>{row.mobile_number}</StyledTableCell>
                  <StyledTableCell align='center'  style={{color : (row.tcolor)}}>
                    {row.telecaller}
                  </StyledTableCell>
                  <StyledTableCell align='center'   style={{color : (row.tcolor)}}>{row.status}</StyledTableCell>
                  <StyledTableCell align='center'  style={{color : (row.tcolor)}}>
                    {row.created_on}
                  </StyledTableCell>
                  <StyledTableCell align='center'  style={{color : (row.tcolor)}}>
                    {row.update_date} {row.update_time}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              <NoRecords rows={rows} loading={loading}/>
          </TableBody>
        </Table>
      </TableContainer>

      <div>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              backIconButtonProps={{
                "aria-label": "Previous Page",
                style: {
                  color: "#fff",
                  backgroundColor: "#3A1C67",
                  padding: 3,
                  borderRadius: "5px",
                  marginRight: "5px",
                },
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page",
                style: {
                  color: "#fff",
                  backgroundColor: "#3A1C67",
                  padding: "3px",
                  borderRadius: "5px",
                },
              }}
            />
          </div>
    </div>
  )
}
export default FollowUp
