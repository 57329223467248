import {
    Box,
    Button,
    CircularProgress,
    TextField,
    makeStyles,
  } from "@material-ui/core";
  import {  useState, useEffect } from "react";
  import logImg from "../../Images/login1.png";
  import logo from "../../Images/Logo.png";
  import { useLocation, useNavigate } from "react-router-dom";
  import { HashLink as Link } from "react-router-hash-link";
  import { routes } from "../../constants/routes";
  import { setIsSubmitted } from "../../Reducers/loginSlice";
  import { useDispatch } from "react-redux";
  import { useFormik } from "formik";
  import * as Yup from "yup";
  import viewIcon from "../../Images/eye.svg";
  import eyeCross from "../../Images/eye-cross.svg";
import { LoginApi } from "../../Services/LoginApi";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import {
  InputAdornment,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      height: "auto",
      textAlign: "left",
    },
    section1: {
      paddingTop: "20px",
      width: "70%",
      padding: "0px 120px",
      justifyContent: "center",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        padding: "30px",
      },
    },
    section2: {
      width: "30%",
      backgroundColor: "#3A1C6733",
      position: "relative",
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    textSize: {
      width: "300px",
    },
    root: {
      "& input[type=number]": {
        "-moz-appearance": "textfield",
      },
      "& input[type=number]::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "& input[type=number]::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "& label.Mui-focused": {
        color: "#3A1C67",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#3A1C67",
        },
        "&:hover fieldset": {
          borderColor: "#3A1C67",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#3A1C67",
        },
      },
      margin: "10px 0px",
      width: "370px",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    multilineColor: {
      color: "#3A1C67",
    },
    button: {
      backgroundColor: "#FA9510",
      color: "#fff",
      textTransform: "none",
      marginTop: "20px",
      width: "370px",
      height: "45px",
      borderRadius: "10px",
      fontSize: "16px",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
      transition: ".4s all ease-in-out",
      "&:hover": {
        backgroundColor: "#FA9510",
        boxShadow: "-2px 7px 8px #575f6b80",
      },
    },
    form: {
      marginTop: "20px",
      display: "flex",
      flexDirection: "column",
  
      padding: "30px 60px 30px 0px",
      textAlign: "left",
      // borderRadius:'12px',
      //height:'370px',
      paddingRight: "60px",
      [theme.breakpoints.down("sm")]: {
        padding: "20px 0px",
      },
    },
    image: {
      position: "absolute",
      right: "20%",
  
      width: "500px",
      height: "auto",
    },
  }));
  const SignUp = () => {
    const classes = useStyles();
    const [login, setLogin] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();
    const [showPassword, setShowPassword] = useState(true);


    const [designArr, setDesignArr] = useState([]);
    const [designLoader, setDesignLoader] = useState(false);
    const [industTypeSearch, setIndustTypeSearch] = useState("");
    const [fieldValue, setFieldValue] = useState()
    const [industTypeArr, setIndustTypeArr] = useState([]);

    const [industSubTypeArr, setIndustSubTypeArr] = useState([]);
    const [industSubTypeSearch, setIndustSubTypeSearch] = useState("");
    const [industSubTypeTitle, setIndustSubTypeTitle] = useState();

    
    useEffect(() => {
      getIndustryTypeList();
    }, []);
  
    const getIndustryTypeList = async () => {
      try {
     
        // setProductLoader(true);
        const res = await LoginApi.getIndustryTypeList(); // assuming getLeadTransferList() returns an object with status and data properties
        if (res.status) {
          const IndustArray = Object.entries(res.data).map(([id, type]) => ({
            id,
            type,
          }));
        setIndustTypeArr(IndustArray)
         }
        // setProductLoader(false);
      } catch (err) {
        console.error("Error fetching lead transfer list:", err);
        // setProductLoader(false);
  
      } finally {
        // setProductLoader(false);
  
      }
    };


    const getIndustrySubTypeList = async (id) => {
      try {
     
        // setProductLoader(true);
        const res = await LoginApi.getIndustrySubTypeList(id); // assuming getLeadTransferList() returns an object with status and data properties
        if (res.status) {
          const subIndustArray = Object.entries(res.data).map(([id, type]) => ({
            id,
            type,
          }));
          setIndustSubTypeArr(subIndustArray)
          formik.setFieldValue("fk_industry_sub_type", "" );
          setIndustSubTypeTitle("")
         }
        // setProductLoader(false);
      } catch (err) {
        console.error("Error fetching lead transfer list:", err);
        // setProductLoader(false);
  
      } finally {
        // setProductLoader(false);
  
      }
    };
   
    // Function to check if a string has consecutive digits more than `maxCount` times
    const hasConsecutiveDigits = (value, maxCount) => {
      const regex = /(.)\1{5,}/; // Matches any character repeated 6 or more times consecutively
      return regex.test(value);
    };

    // Password validation regex: at least one uppercase letter, one lowercase letter, one number, one special character, and minimum 8 characters long
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  
    // Custom email validation to check if the email contains a complete domain part
      // Email validation function
    const isEmailValid = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };


    const validationSchema = Yup.object().shape({
      first_name: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, 'Only letters are allowed')
    .required('First Name is required')
    .max(20, "First Name should less than 20 characters"),
    last_name: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, 'Only letters are allowed')
    .max(20, "Last Name should less than 20 characters")
    .required('Last Name is required')
    .test('last_name', 'Last Name should not be same like First Name', (value) => {
      return Boolean(formik?.values?.first_name !== value);
   }),
      email: Yup.string().email("Invalid email") .test('is-valid-email', 'Invalid email address', (value) => isEmailValid(value)),
      // .required("Email is required"),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .max(20, "Password should less than 20 characters")
        .required("Password is required"),
      company_name: Yup.string().matches(/^[a-zA-Z\s]+$/, 'Only letters are allowed').required("Company Name is required"),
      team_size: Yup.number()
      .test('team_size', 'Team Size is required', (value) => {
        return Yup.number().integer().positive().test(
          (value) => {
            return value===undefined  ? true : Boolean(Number(value));
          }
        ).isValidSync(value);
     })
      .test('team_size', 'Team Size must be digits', (value) => {
        return Boolean(Number(value));
     }),
      mobile_number: Yup.string()
        // .matches(/^[0-9]{10}$/, "Mobile Number must be 10 digits")
        .test('mobile_number', 'Mobile Number is required', (value) => {
          return Yup.number().integer().positive().test(
            (value) => {
              return value===undefined  ? true : Boolean(Number(value));
            }
          ).isValidSync(value);
       })
        .test('mobile_number', 'Mobile Number must be  digits', (value) => {
          return Boolean(Number(value));
       })
        .test('mobile_number', 'Mobile Number must be 10 digits', (value) => {
          return Yup.number().integer().positive().test(
            (value) => {
              return (value && value.toString().length ===10) ? true : false;
            }
          ).isValidSync(value);
       
       })
       .test('no-consecutive-digits', 'Please enter a valid mobile number', (value) => {
        return !hasConsecutiveDigits(value, 6);
    })
        ,
      sales_code: Yup.string().max(20,'Sales Code should less than 20 characters'),
      // fk_industry_type: Yup.string().required("Industry Type is required"),
      fk_industry_type: Yup.number().required("Industry Type is required"),
      fk_industry_sub_type:   industSubTypeArr.length > 0 ?   Yup.number().required("Industry Subtype is required")
                                : Yup.string().nullable(),
      
    });
  
    const formik = useFormik({
      initialValues: {
        first_name: state?.first_name || "",
        last_name: "",
        email: state?.email || "",
        password: "",
        company_name: "",
        team_size: "",
        mobile_number: state?.mobile_number || "",
        sales_code: "",
        fk_industry_type: "",
        fk_industry_sub_type: "",

      },
      validationSchema: validationSchema,
      onSubmit: async (values) => {
       
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_ENDPOINT}/register`,
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(values),
            }
          );
          const data = await response.json();
          if (data.status) {
            dispatch(
              setIsSubmitted({
                open: true,
                severity: "success",
                msg: "User created successfully, please login!",
              })
            );
          
              setTimeout(()=>{
                navigate(routes.signIn);
              },[6000])
            
        
           
          } else {
            
            if (data.hasOwnProperty("errors")) {
              formik.setFieldError("email", data.errors?.email && data.errors?.email[0]);
              formik.setFieldError("first_name",data.errors?.first_name && data.errors?.first_name[0]);
              formik.setFieldError("last_name", data.errors?.last_name &&data.errors?.last_name[0]);
              formik.setFieldError("password", data.errors?.password && data.errors?.password[0]);
              formik.setFieldError("company_name", data.errors?.company_name && data.errors?.company_name[0]);
              formik.setFieldError("team_size", data.errors?.team_size && data.errors?.team_size[0]);
              formik.setFieldError("mobile_number", data.errors?.mobile_number && data.errors?.mobile_number[0]);

            }
          }
        } catch (e) {
          console.error(e);
        }
      },
    });


    const toggleShowPassword = () => {
      setShowPassword(!showPassword);
    };  
    

    const handleChange = (name, value) => {
      setFieldValue(name);
    };

    
    return (
      <div className={classes.container}>
        <div className={classes.section1}>
          <img src={logo} width={"50px"} loading="eager" />
          <div style={{ color: "#3A1C67", fontSize: "20px", paddingTop: "20px" }}>
            <span
              onClick={() => navigate(routes.signIn)}
              style={{ color: "#848891", cursor: "pointer", marginRight: "20px" }}
            >
              Sign In
            </span>{" "}
            <span
              onClick={() => setLogin(false)}
              style={{
                cursor: "pointer",
                paddingBottom: "10px",
                borderBottom: !login ? "2px solid black" : "",
              }}
            >
              {" "}
              SignUp
            </span>
          </div>
          <Box className={classes.form}>
            <form
              id="registrationForm"
              // initialValues={initialValues}
              // validationSchema={validationSchema}
              onSubmit={formik.handleSubmit}
            >
              <Box
                style={{
                  color: "#3A1C67",
                  marginBottom: "20px",
                  fontWeight: 700,
                  fontSize: "30px",
                }}
              >
                {login ? "Welcome Back!" : "Sign Up With Callpal!"}
              </Box>
              <Box
                style={{
                  color: "#848891",
                  marginBottom: "10px",
                  fontWeight: 400,
                  fontSize: "17px",
                }}
              >
                {login
                  ? "Please enter your mobile number and password"
                  : "Create your account with callpal to experience the best."}
              </Box>
              <TextField
                size="small"
                placeholder="First Name*"
                inputProps={{
                  className: classes.multilineColor,
                  autocomplete: 'off',
                }}
                className={classes.root}
                id="first-name"
                variant="outlined"
                name="first_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.first_name}
                
              />
              {formik.touched.first_name && formik.errors.first_name ? (
                <p className="text-danger text-small text-left formik-msg ">
                  {formik.errors.first_name}
                </p>
              ) : null}
              <TextField
                size="small"
                placeholder="Last Name*"
                inputProps={{
                  className: classes.multilineColor,
                  autocomplete: 'off',
                }}
                className={classes.root}
                id="last-name"
                variant="outlined"
                name="last_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.last_name}
                autocomplete="off"
              />
              {formik.touched.last_name && formik.errors.last_name ? (
                <p className="text-danger text-small text-left formik-msg">
                  {formik.errors.last_name}
                </p>
              ) : null}
              <TextField
                size="small"
                placeholder="Mobile Number*"
                inputProps={{
                  maxLength: 10,
                  className: classes.multilineColor,
                  autocomplete: 'off',
                }}
                className={classes.root}
                id="mobile-number"
                variant="outlined"
                name="mobile_number"
                type="number"
                // helperText={error.mobile}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.mobile_number}
              />
              {formik.touched.mobile_number && formik.errors.mobile_number ? (
                <p className="text-danger text-small text-left formik-msg">
                  {formik.errors.mobile_number}
                </p>
              ) : null}
  
              <TextField
                size="small"
                placeholder="Email (Optional)"
                inputProps={{
                  className: classes.multilineColor,
                  autocomplete: 'off',
                  
                }}
                className={classes.root}
                id="email"
                variant="outlined"
                name="email"
                // helperText={emailErr}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              
              {formik.touched.email && formik.errors.email ? (
                <p className="text-danger text-small text-left formik-msg">
                  {formik.errors.email}
                </p>
              ) : null}
  
              <TextField
                size="small"
                placeholder="Password*"
                type={showPassword ? 'password' : 'text'}
                inputProps={{
                  className: classes.multilineColor,
                  autocomplete: 'new-password',
                        form: {
                          autocomplete: 'off',
                        },
                }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                className={classes.root}
                id="password"
                variant="outlined"
                name="password"
                // helperText={(formInput.password.length < 8 && formInput.password.length > 0) && 'Password should be atleast 8 characters long'}
              />
              
              <div>
                    <img
                        className="passwordEyeIcon"
                        src={showPassword ? eyeCross : viewIcon}
                        alt="Toggle Password Visibility"
                        onClick={toggleShowPassword}
                    />
              </div>
              

              
              {formik.touched.password && formik.errors.password ? (
                <p className="text-danger text-small text-left formik-msg">
                  {formik.errors.password}
                </p>
              ) : null}
  
              <TextField
                size="small"
                placeholder="Company*"
                inputProps={{
                  className: classes.multilineColor,
                  autocomplete: 'off',
                }}
                className={classes.root}
                id="comapany"
                variant="outlined"
                name="company_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.company_name}
              />
              {formik.touched.company_name && formik.errors.company_name ? (
                <p className="text-danger text-small text-left formik-msg">
                  {formik.errors.company_name}
                </p>
              ) : null}
  
              <TextField
                size="small"
                placeholder="Team Size*"
                inputProps={{
                  className: classes.multilineColor,
                  autocomplete: 'off',
                }}
                className={classes.root}
                id="team-size"
                variant="outlined"
                name="team_size"
                type="number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.team_size}
              />
  
              {formik.touched.team_size && formik.errors.team_size ? (
                <p className="text-danger text-small text-left formik-msg">
                  {formik.errors.team_size}
                </p>
              ) : null}
  
              <TextField
                size="small"
                placeholder="Sales Code(Optional)"
                inputProps={{
                  className: classes.multilineColor,
                  autocomplete: 'off',
                }}
                className={classes.root}
                id="sales"
                variant="outlined"
                name="sales_code"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.sales_code}
              />
             {formik.touched.sales_code && formik.errors.sales_code ? (
                <p className="text-danger text-small text-left formik-msg ">
                  {formik.errors.sales_code}
                </p>
              ) : null}

              <UncontrolledDropdown className={classes.root}>
                    <DropdownToggle
                      caret
                      style={{
                        width: "370px",
                        display: "flex",
                        justifyContent: "space-between",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        background: "#f9f4f000",
                        color: "#ae97d2",
                        border: "1px solid rgba(0, 0, 0, 0.87)",
                        alignItems: "center",
                      }}
                    >
                      {fieldValue? fieldValue: "Industry Type"}
                    </DropdownToggle>
                    <DropdownMenu style={{ width: "285px" }}>
                      {industTypeArr.length > 0 && (
                        <>
                          <DropdownItem header>
                            <TextField
                              placeholder="Search Industry Type"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon />
                                  </InputAdornment>
                                ),
                              }}
                              size="small"
                              variant="outlined"
                              onChange={(e) => setIndustTypeSearch(e.target.value)}
                            />
                          </DropdownItem>
                          <div className={classes.dropdownItem}>
                            {industTypeArr.length > 0 &&
                              industTypeArr
                                .filter((e) => e.type && e.type.toLowerCase().includes(industTypeSearch.toLowerCase()))
                                .map((el) => (
                                  <DropdownItem
                                    key={el.id}
                                    onClick={() => {
                                      setFieldValue(el.type);
                                      formik.setFieldValue("fk_industry_type",parseInt(el.id, 10) );
                                      getIndustrySubTypeList(el.id)
                                    }}
                                  >
                                    {el.type}
                                  </DropdownItem>
                                ))}
                          </div>
                        </>
                      )}
                    </DropdownMenu>
              </UncontrolledDropdown>

                  {formik.touched.fk_industry_type &&
                  formik.errors.fk_industry_type ? (
                    <p className="text-danger text-small text-left formik-msg ">
                    {formik.errors.fk_industry_type}
                    </p>
                  ) : null}
  
              {industSubTypeArr.length > 0 && (

                <UncontrolledDropdown className={classes.root}>
                    <DropdownToggle
                      caret
                      style={{
                        width: "370px",
                        display: "flex",
                        justifyContent: "space-between",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        background: "#f9f4f000",
                        color: "#ae97d2",
                        border: "1px solid rgba(0, 0, 0, 0.87)",
                        alignItems: "center",
                      }}
                    >
                      {industSubTypeTitle? industSubTypeTitle: "Industry Type"}
                    </DropdownToggle>
                    <DropdownMenu style={{ width: "285px" }}>
                     
                        <>
                          <DropdownItem header>
                            <TextField
                              placeholder="Search Industry Type"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon />
                                  </InputAdornment>
                                ),
                              }}
                              size="small"
                              variant="outlined"
                              onChange={(e) => setIndustSubTypeSearch(e.target.value)}
                            />
                          </DropdownItem>
                          <div className={classes.dropdownItem}>
                            {industSubTypeArr.length > 0 &&
                              industSubTypeArr
                                .filter((e) => e.type && e.type.toLowerCase().includes(industSubTypeSearch.toLowerCase()))
                                .map((el) => (
                                  <DropdownItem
                                    key={el.id}
                                    onClick={() => {
                                      setIndustSubTypeTitle(el.type);
                                      // formik.setFieldValue("fk_industry_sub_type",el.id );
                                      formik.setFieldValue("fk_industry_sub_type", parseInt(el.id, 10)); // Parse id to integer
                                    }}
                                  >
                                    {el.type}
                                  </DropdownItem>
                                ))}
                          </div>
                        </>
                    
                    </DropdownMenu>
               </UncontrolledDropdown>
              )}
                  {formik.touched.fk_industry_sub_type && industSubTypeArr.length > 0 &&
                  formik.errors.fk_industry_sub_type ? (
                    <p className="text-danger text-small text-left formik-msg ">
                    {formik.errors.fk_industry_sub_type}
                    </p>
                  ) : null}
  
              <div>
                <Box
                  style={{ color: "#3A1C67", fontSize: "12px", fontWeight: 600 }}
                >
                  Forgot Password?
                </Box>
              </div>
              <div className="btns_add_user">
                <Button className={classes.button} type="submit">
                  {isLoading ? <CircularProgress size={30} /> : "Sign Up"}
                </Button>
              </div>
              <Box
                style={{
                  fontSize: "12px",
                  color: "#848891",
                  padding: "10px 0px",
                }}
              >
                Already have an account?{" "}
                <span
                  onClick={() => navigate(routes.signIn)}
                  style={{ color: "#3A1C67", cursor: "pointer" }}
                >
                  Sign In
                </span>
              </Box>
              <Box style={{ fontSize: "12px", color: "#848891" }}>
                By clicking on Sign Up, I accept The{" "}
                <Link
                  style={{ textDecoration: "none", color: "#3A1C67" }}
                  to={routes.terms}
                  target="_blank"
                >
                  Terms & Conditions
                </Link>{" "}
                &{" "}
                <Link
                  style={{ textDecoration: "none", color: "#3A1C67" }}
                  to={routes.privacyPolicy}
                  target="_blank"
                >
                  Privacy Policy
                </Link>{" "}
              </Box>
            </form>
          </Box>
        </div>
  
        <div className={classes.section2}>
          <img src={logImg} loading="eager" className={classes.image} />
        </div>
      </div>
    );
  };
  export default SignUp;
  