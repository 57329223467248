import {
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    makeStyles,
    Button
    
  } from '@material-ui/core'
  import React, { useState } from 'react'
  import { StyledTableCell, StyledTableRow } from '../../../Styles'
  import FilterSection from '../Other-components/FilterSection'
import NoRecords from '../Other-components/NoRecords'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getCookie } from '../../../Utils/commonUtils'
import { routes } from '../../../constants/routes'
import {
  useNavigate,useLocation
} from 'react-router-dom';
import bankIcon from "../../../Images/bankIcon.png"; 
import { CustomerHistoryApi } from "../../../Services/CustomerHistoryApi";
import viewIcon from "../../../Images/eye.svg";
import ViewBankStatusHistory from '../Other-components/ViewBankStatusHistory'
import { useParams } from "react-router-dom";
import NotInterested from './NotInterested'
import { useDispatch, } from "react-redux";
import { setIsSubmitted } from '../../../Reducers/loginSlice'
import constantConfig from '../../../constants/constantConfig'
import statusUpdate from '../../../Images/Team Live status 1.svg'
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

  const useStyles = makeStyles((theme, props) => ({
    mainContainer: {
      padding: '80px 30px 20px 30px',
    },
    bankIcon : {
      width : "18px"

    },

    container: {
      display: 'flex',
      backgroundColor: '#848891',
      padding: '10px',
      justifyContent: 'space-between',
      borderRadius: '12px',
    },
    updateIcon: {
      cursor: 'pointer',       // Changes cursor to pointer on hover
      position: 'absolute',    // Allows for absolute positioning
      top: 0,                  // Aligns the element to the top
      right: 0,                // Aligns the element to the right
      marginTop: '5px',        // Adjust as needed for spacing
      width: '24px',           // Adjust the width as needed
      height: '24px',          // Adjust the height as needed
      zIndex: 10,              // Ensures the icon is on top of other elements
    },
    button: {
      backgroundColor: '#3A1C67',
      color: '#fff',
      marginLeft: '10px',
      borderRadius: '5px',
      fontSize: '12px',
      padding: '5px 20px',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: '#7916BD',
      },
    },
    tableContainer: {
      margin: '20px 0px',
      maxHeight: 430,
      width: '100%',
      '&::-webkit-scrollbar': {
        width: '0.5em',
        display: 'block',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'darkgrey',
        outline: '1px solid slategrey',
        borderRadius: '5px',
      },
    },
  }))
  const Customer = ( ) => {
    const classes = useStyles()
    const dispatch = useDispatch();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const { userInfo } = useSelector((state) => state.login)
    const ind_type = userInfo?.userInfo?.ind_type;
    const industryType = constantConfig.INDUSTRY_TYPE.OTHER;
    const [rows, setRows] = useState([])
    const [loading, setLoading] = useState(false)
    const [offset, setOffset] = useState(1)
    const [count, setCount] = useState(0)
    const [isCreateLead, setIsCreateLead] = useState(false);
    const [showHistory,setShowHistory]=useState(false)
    const [action, setAction] = useState('Add')
    const navigate = useNavigate()
    const loginInfo=getCookie('callpal-user');
    const [open, setOpen] = useState(false);
    const [loadingHistory, setLoadingHistory] = useState(false);
    const [historyData, setHistoryData] = useState([]);
    const params = useParams();
    const id = params.id != '' && params.id != undefined ?  atob(params.id) : loginInfo.userInfo.id;
    const [confirm, setConfirm] = useState(false);
    const[customerID, setCustomerID] = useState([]);
    const [exportExcelLoading, setExportExcelLoading] = useState(false);
    const[statusUpdateId, setStatusUpdateId] = useState();
    const [dates,setDates]=useState({
      from:'',
      to:''
    }) 
    const [empDetails,setEmpDetails]=useState({
      id:'',
      name:''
    });
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        let num=0
        if(newPage>page){
            num=offset+1
        }
        else{
            num=offset-1
        }
        setOffset(num)
    };
   
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
        setOffset(0)
    };

    

    const heading = [
        'Customer/Leads Name',
        'Phone no.',
        'Tele Caller',
        'Status',
        'Created On',
        'Created At',
        "Action "
      ]
    useEffect(() => {
        if (userInfo.hasOwnProperty('mobile_number')|| userInfo?.userInfo?.mobile_number) {
            getUserData();
        }
    }, [userInfo, offset, rowsPerPage,dates,empDetails, ]);



    const getUserData = async () => {
      try {
        setLoading(true);
        const data = JSON.stringify({
                  per_page_row: rowsPerPage,
                  page: offset,
                  fromdate:dates.from,
                  todate:dates.to,
                  user_id:empDetails.id,
                  team_lead_id: parseInt(id),
        });
        const res = await CustomerHistoryApi.getCustomerList(data);
        if (res.status) {
          setRows([...res.data]);
          setCount(res.total_count);
        }
      } catch (err) {
        setLoading(false);
        console.error(err);
      } finally {
        setLoading(false);
      }
  }



  const getCustomerHistory = async (lead_id) => {
    try {
      setLoadingHistory(true)
      // const obj = JSON.stringify({
      //   lead_id: lead_id
      // });
      const res = await CustomerHistoryApi.getLeadBankHistory(lead_id);
      setLoadingHistory(false)
      if (res.status) {
        setHistoryData(res.data)
      }
    }
    catch (e) {
     setLoadingHistory(false)
    }
  }


  const handelUpdateStatus =(id) => {
    setStatusUpdateId(id)
    setConfirm(true)

  }


  const updateStatus = async()=>{
    try {
    
      const res = await CustomerHistoryApi.UpdateCustomerStatus(statusUpdateId);
      
      setConfirm(false);
      if (res.status) {
       
        dispatch(
          setIsSubmitted({ open: true, severity: "success", msg: res.message })
        );
        getUserData();
      
      } else {
        dispatch(
          setIsSubmitted({ open: true, severity: "error", msg: res.message })
        );
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    } finally {
      setLoading(false);
    }
  }



  const handleExportExcel = async() => {
    try {
      setExportExcelLoading(true);
      const data = JSON.stringify({
        per_page_row: count,
        page: offset,
        fromdate:dates.from,
        todate:dates.to,
        user_id:empDetails.id,
        team_lead_id: parseInt(id),
      });
      const res = await CustomerHistoryApi.getCustomerList(data);
      if (res.status) {
        const formattedData = res.data.map(row => ({
          'Customer/Leads Name':row.name ,
          'Phone no.':row.mobile_number,
          'Tele Caller':row.telecaller ,
          'Status':row.status,
          'Created On':row.created_on ,
          'Created At':row.created_time ,
        }));
  
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(formattedData, { header: heading});
  
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
        saveAs(dataBlob, `customer_tele_caller.xlsx`);
      }
    } catch (err) {
      setExportExcelLoading(false);
      console.error(err);
    } finally {
      setExportExcelLoading(false);
    }
  }
  
    return (
      <div>
      <div className={classes.mainContainer}>
      {
      !showHistory &&
        <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ fontWeight: 600, fontSize: '20px', color: '#F86E20' }}>
          {count > 0  ? count : "" } Customers
          </div>
         
          <FilterSection isCreateLeadButton={false} isAllEmp={true} setIsCreateLead={setIsCreateLead} setDates={(d)=>setDates(d)} setEmpDetails={(d)=>setEmpDetails(d)} empDetails={empDetails} teamLeadId={id} />
          

          <Button className={classes.button} onClick={() => handleExportExcel()}  > 
              {exportExcelLoading ? "Please wait...." : "Export" } 
          </Button>

          {loginInfo.userInfo.fk_role_id == 2 && (
          <Button className={classes.button} onClick={() => navigate(-1)}> 
               Back
          </Button>
          )}

        </div>
  
        <div>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table
              stickyHeader
              className={classes.table}
              aria-label='customized table'
              size='small'
            >
              <TableHead>
                <TableRow>
                  {heading.map((el, i) => {
                    return (
                      <StyledTableCell key={i} align='center' >
                        {el}
                      </StyledTableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
  
              <TableBody>
              { rows.length>0 && !loading && rows.map((row) => (
                  <StyledTableRow
                    style={{ cursor: 'pointer'}}
                    onClick={() => {
                      // setShowHistory(true);
                      setCustomerID(row.id);
                      setAction('Edit');
                      navigate(`/dashboard${routes.customerHistory}/${row.id}`);
                    }}
                  >
                    <StyledTableCell align='center'  style={{color : (row.tcolor)}}> {row.name} </StyledTableCell>
                    <StyledTableCell align='center'  style={{color : (row.tcolor)}}>{row.mobile_number}</StyledTableCell>
                    <StyledTableCell align='center'  style={{color : (row.tcolor)}}> {row.telecaller} </StyledTableCell>
                    <StyledTableCell align='center'   style={{color : (row.tcolor)}}>{row.status} </StyledTableCell>
                    <StyledTableCell align='center'  style={{color : (row.tcolor)}}> {row.created_on} </StyledTableCell>
                    <StyledTableCell align='center'  style={{color : (row.tcolor)}}> {row.created_time}  </StyledTableCell>
                    
                    <StyledTableCell align='center'>
                      <div style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}>
                        <img  style={{ fill: 'red' }}  
                          title="Click to update status" // Simple tooltip text
                          onClick={(e)=> {e.stopPropagation(); handelUpdateStatus(row.id); }} 
                          className={classes.bankIcon} src={statusUpdate}  
                        />

                     {ind_type !== industryType &&  
                        <img   
                        title="Click to update Bank status" // Simple tooltip text
                        onClick={(e) => {
                        e.stopPropagation(); 
                        navigate(`/dashboard${routes.bank}/${btoa(row.id)}`);
                      }} className={classes.bankIcon} src={bankIcon} alt="" />
                      }
                      <img  
                        title="Click to View Bank Status History" // Simple tooltip text
                         onClick={(e) => { e.stopPropagation();  setAction('Add');
                                         getCustomerHistory(row.id); setOpen(true) }} 
                        className={classes.eyeicon} src={viewIcon} alt="" 
                      />

                     </div>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
                <NoRecords rows={rows} loading={loading}/>
            </TableBody>
            </Table>
          </TableContainer>
          <NotInterested   
            // open={true}
            open={confirm}
          setOpen={(f) => setConfirm(f)}
        updateStatus={() => {
          updateStatus();
        }}
        />



          <ViewBankStatusHistory open={open} setOpen={(f) => setOpen(f)} param={'team'} action={action} loading={loadingHistory} historyData={historyData}/>
  
          <div>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              backIconButtonProps={{
                "aria-label": "Previous Page",
                style: {
                  color: "#fff",
                  backgroundColor: "#3A1C67",
                  padding: 3,
                  borderRadius: "5px",
                  marginRight: "5px",
                },
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page",
                style: {
                  color: "#fff",
                  backgroundColor: "#3A1C67",
                  padding: "3px",
                  borderRadius: "5px",
                },
              }}
            />
          </div>
        </div>
        </>

        }
        {/* {
            showHistory && <CustomerHistory   customerID={customerID}   action={action}  />
        } */}
      </div>




      </div>
       )
  }
  export default Customer
  