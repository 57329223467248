import { Box, Button, CircularProgress, Container, TextField, makeStyles } from "@material-ui/core"
import Footer from "../Footer"
import Header from "../Header"
import { useState } from "react"
import { isValidEmail } from "../../Utils/commonUtils"
import { useLocation, useNavigate } from "react-router-dom"
import { addContactUSDetail } from "../../Redux/features/home/homeSlice"
import { useDispatch, useSelector } from "react-redux"
import { setIsSubmitted } from "../../Reducers/loginSlice"
import { routes } from "../../constants/routes"
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        padding: '120px 70px 66px 70px', 
        textAlign: 'left' ,
        [theme.breakpoints.down('sm')]: {
           flexDirection:'column',
           padding:'90px 20px'
          },
      
    },
    heading: {
        color: '#3A1C67',
        fontSize: '50px',
        fontWeight: 800
    },
    subHeading: {
        color: '#3A1C67',
        fontSize: '25px',
        fontWeight: 700
    },
    pinkSection: {
        fontSize: '18px',
        fontWeight: 600,
        color: '#B32B88',
        margin: '20px 0px 10px 0px'
    },
    otherHeading: {
        fontsize: '22px',
        color: '#3A1C67',
        fontWeight: 600,
        paddingTop: '30px',
        paddingBottom: '20px'
    },
    form:{
        marginTop:'20px',
        width:'40%',
        backgroundColor:'#fff',
       padding:'30px 60px 30px 30px',
       textAlign:'left',
       borderRadius:'12px',
       height:'370px',
       marginLeft:'20px',
       [theme.breakpoints.down('sm')]: {
        marginLeft:'0px',
        width:'100%',
       marginRight:'20px'
      },
    },
    root: {

        '& label.Mui-focused': {
            color: '#FA9510',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#FA9510',

            },
            '&:hover fieldset': {
                borderColor: '#FA9510',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#FA9510',
            },
        },
        marginBottom: '20px',
        width: '100%'
    },
    multilineColor: {
        color: '#FA9510'
    },
    button: {
        backgroundColor: '#FA9510',
        color: '#fff',
        textTransform: 'none', marginTop: '20px',
        width: '200px',
        transition:'.4s all ease-in-out',
        '&:hover':{
            backgroundColor:'#FA9510',
            boxShadow:'-2px 5px 8px #575f6b80'
          }
    }
}))



const ContactUs = () => {
    const classes = useStyles()
    const [err, setErr] = useState({
        name: '',
        email: '',
        phone_number: '',
      })
      
      const [userInput, setUserInput] = useState({
        name: '',
        email: '',
        phone_number: '',
      })
      const { state } = useLocation();
      const dispatch = useDispatch();
      const navigate = useNavigate()
      const { loading } = useSelector((state) => state.home)
    const handleSubmit = async (e) => {
        e.preventDefault()
        if (userInput.name.length == 0) {
            setErr({ ...err, name: 'Name is required' })
            return false
          }
          if (userInput.email.length == 0) {
            setErr({ ...err, email: 'Email is required' })
            return false
          }
          else {
            if (!isValidEmail(userInput.email)) {
              setErr({ ...err, email: 'Invalid email address' })
              return false
            }
          }
          if (userInput.phone_number.length == 0) {
            setErr({ ...err, phone_number: 'Phone number is required' })
            return false
          }
          else {
            if (userInput.phone_number.length && userInput.phone_number.length != 10) {
              setErr({ ...err, phone_number: 'Phone number must be 10 digits' })
              return false
            }
           }
          const payload={
            contact_type_id : state?.id || 1,
            name:userInput.name,
            email:userInput.email,
            phone_number:userInput.phone_number
        }
          dispatch(addContactUSDetail(payload)).then((res)=>{
            if(res.type==='home/addContactUSDetail/fulfilled' && res?.payload?.status){
            dispatch(setIsSubmitted({ open: true, severity: 'success', msg: res?.payload?.message }))
             navigate(routes.signUp,{ state: userInput}) 
            setErr({
                name: '',
                email: '',
                phone_number: '',
              })
              setUserInput({
                name: '',
                email: '',
                phone_number: '',
              })
            } 
            if(res.type==='home/addContactUSDetail/rejected'){
                dispatch(setIsSubmitted({ open: true, severity: 'error', msg: 'Something went wrong!' }))
                } 
        }).catch((error)=>{
            dispatch(setIsSubmitted({open:true,severity:'error',msg:'Something went wrong !'}))
          })
    }
    
    const handleChange = (name, val) => {
        setErr({ ...err, [name]: '' })
        setUserInput({ ...userInput, [name]: val })
      }
    
    return (
        <div>
            <Header />
            <Container className={classes.container}>
                <Box>
                    <Box>
                        <Box className={classes.heading}>We’d always love to</Box>
                        <Box className={classes.subHeading}>Hear From, <span style={{ color: '#F86E20' }}>You</span> </Box>
                        <Box className={classes.pinkSection}>
                            Have any questions about <span style={{ fontWeight: 700, background: '-webkit-linear-gradient(#E41C61,#9433A0 )', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>Callpal</span>, product demo or support?
                            Shoot them away here.
                        </Box>
                        <Box className={classes.otherHeading}>Address</Box>
                        <div style={{ color: '#848891' }}>
                            <div style={{ lineHeight: '25.6px',fontWeight:800,fontSize:'16px' }}>Callpal Tech Private Limited.</div>
                            <div style={{ lineHeight: '25.6px' }}>4th Floor, Shreenidhi Chembers,</div>
                            <div style={{ lineHeight: '25.6px' }}>Near Symboisis College, </div>
                            <div style={{ lineHeight: '25.6px' }}>Senapati Bapad Road,</div>
                            <div style={{ lineHeight: '25.6px' }}>Shivaji Nagar, Pune-411016.</div>
                        </div>
                    </Box>
                    <Box className={classes.otherHeading}>Phone Number</Box>
                    <div style={{ color: '#848891' }}>+91 7709504893</div>
                </Box>
                <Box className={classes.form}>
                    <Box style={{ color: '#3A1C67', marginBottom: '20px', fontWeight: 600 }}>
                        Get 10 Days Free Trial
                    </Box>
                    <form onSubmit={(e) => handleSubmit(e)}>
                    <TextField
                        size="small"
                        placeholder="Name*"
                        inputProps={{
                            className: classes.multilineColor
                        }}
                        className={classes.root} id="outlined-basic"
                        name='name'
                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                        value={userInput.name}
                        variant="outlined"
                        helperText={err.name}
                         />
                    <TextField
                        size="small"
                        placeholder="Email Address*"
                        inputProps={{
                            className: classes.multilineColor
                        }}
                        name='email'
                  type="email"
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  value={userInput.email}
                  helperText={err.email}
                        className={classes.root} id="outlined-basic" variant="outlined" />
                    <TextField
                        size="small"
                        placeholder="Phone Number*"
                        inputProps={{
                            className: classes.multilineColor
                        }}
                        className={classes.root} id="outlined-basic" variant="outlined"
                  name='phone_number'
                  type="number"
                  onChange={(e) => 
                    {
                      if(e.target.value.length<11){
                    handleChange(e.target.name, e.target.value)}
                      }
                  }
                  value={userInput.phone_number}
                  helperText={err.phone_number}
                   />
                    <div>
                        <label style={{ color: '#848891', fontSize: '14px', paddingBottom: '20px', marginLeft:'5px' }}>
                            <input type="checkbox" style={{  marginRight:'5px' }} />
                            By clicking here I agree to the privacy policy
                        </label>
                    </div>
                    <Button type="submit" className={classes.button}>
                    {loading ? <CircularProgress size={20} /> : 'Try Free Trial'}  
                    </Button>
                    </form>
                </Box>
            </Container>
            <Footer />
        </div>
    )
}
export default ContactUs