import { Button, MenuItem, makeStyles } from '@material-ui/core'
import { DateRange, KeyboardArrowDown } from '@material-ui/icons'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { useState, useEffect,  } from 'react';
import DatePicker from "react-date-picker";
import { TeamLiveStatusApi } from '../../../Services/TeamLiveStatusApi';
import { useSelector } from "react-redux";
import moment from "moment";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
const useStyles = makeStyles((theme, props) => ({
  container: {
    display: 'flex',
    gap: '10px',
    backgroundColor: '#848891',
    padding: '10px',
    justifyContent: 'space-between',
    borderRadius: '12px',
  },
  dateInputComp: {
    display: "flex",
    background: "white",
    alignItems: "center",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "6px",
    paddingBottom: "6px",
    gap: "10px",
  },
  dropdownBG: {
    backgroundColor: 'white',
    border: 'none',
    color: '#3A1C67',
    borderRadius: '15px',
    paddingLeft: '25px',
    paddingRight: '25px',
    fontSize:'14px',
    '&:hover': {
      backgroundColor: 'white',
      borderRadius: '15px',
      border: 'none',
      color: '#3A1C67',
      boxShadow: 'box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;',
    },
  },
  button: {
    textTransform: 'none',
    backgroundColor: '#F1F1F1',
    color: '#3A1C67',
    fontSize: '12px',
    margin: '0px 7px',

    transition: 'none',
    '&:hover': {
      textTransform: 'none',
      backgroundColor: '#F1F1F1',
      color: '#3A1C67',
      fontSize: '12px',
      margin: '0px 7px',
    },
  },
}))
const ReportsFilter = ({filterStartDate,filterEndDate,sendFilterDateRange}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const { userInfo } = useSelector((state) => state.login)

  const [toYearOptions, setToYearOptions] = useState([]);
  const [top3, setTop3] = useState([]);
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [dates, setDates] = useState({
    from: moment().format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD"),
  });
  const currentYear = new Date().getFullYear();
  const numberOfYears = 5; // Adjust this value as needed

  const years = Array.from(
    { length: numberOfYears },
    (_, index) => currentYear - index
  );

  const [filter, setFilter] = useState({
    today: true,
    last_7: false,
    last_30: false,
    range: false,
  });


  // const setDateFilter = async (flag) => {
  //   if (flag == "1") {
  //     setFilter({
  //       ...filter,
  //       today: true,
  //       last_7: false,
  //       last_30: false,
  //       range: false,
  //     });
  //     setDates({
  //       ...dates,
  //       from: moment().format("YYYY-MM-DD"),
  //       to: moment().format("YYYY-MM-DD"),
  //     });
  //   } else if (flag == "7") {
  //     setFilter({
  //       ...filter,
  //       last_7: true,
  //       today: false,
  //       last_30: false,
  //       range: false,
  //     });
  //     setDates({
  //       ...dates,
  //       from: moment().subtract(7, "days").format("YYYY-MM-DD"),
  //       to: moment().format("YYYY-MM-DD"),
  //     });
  //   } else if (flag == "30") {
  //     setFilter({
  //       ...filter,
  //       last_30: true,
  //       today: false,
  //       last_7: false,
  //       range: false,
  //     });
  //     setDates({
  //       ...dates,
  //       from: moment().subtract(30, "days").format("YYYY-MM-DD"),
  //       to: moment().format("YYYY-MM-DD"),
  //     });
  //   } else {
  //     setFilter({
  //       ...filter,
  //       last_30: false,
  //       today: false,
  //       last_7: false,
  //       range: true,
  //     });
  //     setDates({ ...dates, from: startDate, to: endDate });
  //   }
  // };






 
   

// useEffect(()=>{
//     if (
//         userInfo.hasOwnProperty("mobile_number") ||
//         userInfo?.userInfo?.mobile_number
//       ) {
//         getDashboardInfo();
//       }

// },[userInfo, dates])



    // const gerTeamLiveStatus = async () => {
    
    //     try {
    //       setLoading(true);
    //       const data = JSON.stringify({
    //         fromdate: startDate.from,
    //         todate: endDate.to,
    //       });
    //       alert(data)
    //       console.log()
    //       const res = await TeamLiveStatusApi.getTeamLiveStatusInfo(data);
    //       if (res?.status) {
    //         // setTopCount(res.topCount);
    //         // setBankData(res.bankData);
    //         // setCompanyName(res?.company?.company_name);
    //       }
    //     } catch (err) {
    //       setLoading(false);
    //       // console.error(err);
    //     } finally {
    //       setLoading(false);
    //     }
    //   };

const applyrange =()=>{
  // alert(startDate)
  // filterStartDate(startDate)
  // filterEndDate(endDate)


   sendFilterDateRange(startDate, endDate);
  
}
 
    
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      
      <div className={classes.container}>
        {/* <UncontrolledDropdown className=''>
          <div className="filters">
          <DropdownToggle className={classes.dropdownBG}>
            Overall
          </DropdownToggle>
          </div>
          
          <DropdownMenu>
            <MenuItem>Option 1</MenuItem>
            <MenuItem>Option 2</MenuItem>
            <MenuItem>Option 3</MenuItem>
          </DropdownMenu>
        </UncontrolledDropdown> */}

        {/* <UncontrolledDropdown className=''>
        <div className="filters">

          <DropdownToggle className={classes.dropdownBG}>
            Today's
          </DropdownToggle>
          </div>
          <DropdownMenu>
            <MenuItem>Option 1</MenuItem>
            <MenuItem>Option 2</MenuItem>
            <MenuItem>Option 3</MenuItem>
          </DropdownMenu>
        </UncontrolledDropdown> */}

        {/* <UncontrolledDropdown className=''>
        <div className="filters">

          <DropdownToggle
            className={classes.dropdownBG}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '10px',
              alignItems:'center'
            }}
          >
            Select Range
            <KeyboardArrowDown style={{fontSize:17}}></KeyboardArrowDown>
          </DropdownToggle>
          </div>
          <DropdownMenu>
            <MenuItem>Option 1</MenuItem>
            <MenuItem>Option 2</MenuItem>
            <MenuItem>Option 3</MenuItem>
          </DropdownMenu>
        </UncontrolledDropdown> */}
        <div>
            <Dropdown
              toggle={() => {
                setOpen(!open);
              }}
              onClick={() => {
                setOpen(true);
              }}
              isOpen={open}
            >
              <DropdownToggle
                style={{
                  background: filter.range ? "#fff" : "#F1F1F1",
                  color: "#3A1C67",
                  fontSize: "12px",
                  padding: "3.8px 16px",
                  border: "none",
                }}
              >
                Select Range
                <KeyboardArrowDown></KeyboardArrowDown>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <div className={classes.dateInputComp}>
                    <div>
                      <DateRange
                        className="dateIcon"
                        color="#3A1C67"
                      ></DateRange>
                    </div>
                    <div style={{ display: "flex", gap: "5px" }}>
                      {" "}
                      <DatePicker
                        style={{
                          display: "none",
                          color: "#3A1C67",
                          cursor: "pointer",
                        }}
                        value={startDate}
                        onChange={(val) => {
                          setOpen(true);
                          const d = moment(val).format("YYYY-MM-DD");
                          setStartDate(d);
                        }}
                        calendarIcon={null}
                        clearIcon={null}
                        className="datePickerInput"
                      ></DatePicker>
                      -
                      <DatePicker
                        calendarIcon={null}
                        clearIcon={null}
                        style={{ display: "none", color: "#3A1C67" }}
                        value={endDate}
                        onChange={(val) => {
                          const d = moment(val).format("YYYY-MM-DD");
                          setEndDate(d);
                          setOpen(true);
                        }}
                        className="datePickerInput"
                        minDate={new Date(startDate)}
                      ></DatePicker>
                    </div>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <Button
                      style={{
                        backgroundColor: "#848891",
                        color: "#fff",
                        textTransform: "none",
                      }}
                      onClick={applyrange}
                    >
                      Apply
                    </Button>
                  </div>
                </DropdownItem>
                <DropdownItem></DropdownItem>
              </DropdownMenu>
            </Dropdown>
            {/* <Button className={classes.button} endIcon={<ArrowDropDownIcon />}>
              Select Range
            </Button> */}
          </div>

        {/* <div>
        <Button className={classes.button} >Over All</Button>
      </div> */}
      </div>
    </div>
  )
}

export default ReportsFilter
