import React from "react";
import Header from "../Header";
import mobile from '../../Images/mobile1.png'
import { Button, makeStyles } from "@material-ui/core";
import Footer from "../Footer";
import Home2 from "./Home2";
import Home3 from "./Home3";
import Home4 from "./Home4";
import Home8 from "./Home8";
import Home6 from "./Home6";
import { useNavigate } from "react-router-dom";
import { routes } from "../../constants/routes";

const useStyles = makeStyles((theme) => ({
    mobileImg: {
       width:'60%',
       [theme.breakpoints.down('sm')]: {
        width:'100%',
      },
    },
   content:{
    textAlign: 'left', paddingLeft: '90px' ,
    [theme.breakpoints.down('sm')]: {
        position:'inherit',
        padding:'20px'
      },
   },
   container:{
    width: '100%', display: 'flex', position: 'relative', zIndex: 0, paddingTop: '80px' ,
    [theme.breakpoints.down('sm')]: {
        flexDirection:'column',
      },
   },
   button:{
    backgroundColor: '#FA9510',
     textTransform: 'none',
      color: '#fff', 
      marginRight: '20px' ,
      transition:'.4s all ease-in-out;',
      '&:hover':{
        backgroundColor: '#FA9510',
      boxShadow:'0px 10px 10px 0px grey'
      }
   },
   button2:{
    color: '#FA9510', textTransform: 'none', borderColor: '#FA9510' ,
    '&:hover':{
    
      boxShadow:'0px 10px 10px 0px grey'
      }
   }
}))
const Home = () => {
    const classes=useStyles()
    const navigate = useNavigate()
    return (
       
        <div style={{ backgroundColor: '#F9F4F0'}}>
            <Header />
            <div className={classes.container}>
                <div className={classes.content}>
                    <div style={{ color: '#3A1C67', fontWeight: 800, fontSize: '50px' }}>Say Yes To</div>
                    <div style={{ color: '#3A1C67', fontSize: '20px', fontWeight: 700 }}>Less Work, <span style={{ color: '#F86E20' }}>More Sales</span></div>
                    <div style={{ color: '#B32B88', padding: '20px 0px', fontSize: '16px', paddingTop: '20px' }}><span style={{ fontWeight: 500 }}>SIM based</span> Call Management <span style={{ fontWeight: 500 }}>CRM</span> for small, medium, and large enterprises</div>
                    <div>
                        
                        <Button className={classes.button} onClick={() => { navigate(routes.liveDemo,{ state: { id: 2 }}) }}>
                            Request a Live Demo
                        </Button>
                        <Button variant="outlined" className={classes.button2} onClick={() => { navigate(routes.freeTrial,{ state: { id: 3 }}) }}>
                            Get Free Trial
                        </Button>
                    </div>


                    <div style={{ color: '#3A1C67',fontSize:'18px', fontWeight: 700, lineHeight: '37.24px' }}>
                        <ul className="unordered_list">
                            <li>Perfect for Outbound, Sales, and Telecalling Operations.</li>
                            <li>Achieve a Remarkable Contact Ratio</li>
                            <li>Benefit from an an Auto Dialer Integrated with CRM</li>
                           
                        </ul>
                    </div>
                </div>

                
                <img src={mobile} height={'auto'} className={classes.mobileImg} loading="eager"/>


            </div>
            <Home2/>
            <Home3/>
            <Home4/>
            <Home6/>
            <Home8/>
           
            <Footer/>
        </div>
    )
}

export default Home;