import axios from "axios"
import { getCookie } from '../Utils/commonUtils'

export const CommonApi = {
    getDesignationData: async (data) => {
        try {
           
            const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/designation-master`, {
              headers: {
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getCookie('callpal-user').token}`
              }
            });
            return response; // Assuming your data is in response.data
          } catch (error) {
            console.error('Error in getDesignationList:', error);
            return false
            //throw error; // Re-throw the error to handle it at the calling site if needed
          }
    },
    getReportingData: async (data) => {
        try {
           
            const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/reporting-master`, data,{
              headers: {
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getCookie('callpal-user').token}`
              }
            });
            return response; // Assuming your data is in response.data
          } catch (error) {
            console.error('Error in getDesignationList:', error);
            return false
            //throw error; // Re-throw the error to handle it at the calling site if needed
          }
    },
    getTeamLeadTeleCaller: async (teamLeadId) => {
      try {
          const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/team-lead-telecaller/${teamLeadId}`,{
          headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getCookie('callpal-user').token}`
          }
          });
          return response; // Assuming your data is in response.data
        } catch (error) {
          console.error('Error in getDesignationList:', error);
          return false
          //throw error; // Re-throw the error to handle it at the calling site if needed
        }
  },
  getRoleData: async (data) => {
        try {
           
            const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/role-master`,{
              headers: {
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getCookie('callpal-user').token}`
              }
            });
            return response; // Assuming your data is in response.data
          } catch (error) {
            console.error('Error in getDesignationList:', error);
            return false
            //throw error; // Re-throw the error to handle it at the calling site if needed
          } 
    },
    getStatusData: async (data) => {
      try {
          const obj={
            industry_sub_type: getCookie('callpal-user')?.userInfo?.industry_sub_type
          };
          const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/status-master`,obj,{
            headers: {
              'Accept': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getCookie('callpal-user').token}`
            }
          });
          return response; // Assuming your data is in response.data
        } catch (error) {
          console.error('Error in getDesignationList:', error);
          return false
          //throw error; // Re-throw the error to handle it at the calling site if needed
        }
  },
  getBankStatusData: async (data) => {
    try {
       
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/bank-status`,{
          headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getCookie('callpal-user').token}`
          }
        });
        return response; // Assuming your data is in response.data
      } catch (error) {
        console.error('Error in getDesignationList:', error);
        return false
        //throw error; // Re-throw the error to handle it at the calling site if needed
      }
  },
  getCompanyTeamLead: async (data) => {
    try {
       
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/get-company-team-lead`,{
          headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getCookie('callpal-user').token}`
          }
        });
        return response; // Assuming your data is in response.data
      } catch (error) {
        console.error('Error in getDesignationList:', error);
        return false
        //throw error; // Re-throw the error to handle it at the calling site if needed
      }
  },
  
}