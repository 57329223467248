import { Box, Container, makeStyles } from "@material-ui/core";
import img1 from "../../Images/img1.png";
import img2 from "../../Images/img2.png";
import img3 from "../../Images/img3.png";
import img4 from "../../Images/img4.png";
import img5 from "../../Images/img5.png";
import img6 from "../../Images/img6.png";
const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: "30px",
    color: "#3A1C67",
    fontWeight: 700,
  },
  subHeading: {
    fontSize: "18px",
    color: "#848891",
    padding: "0px 200px",
    paddingTop: "10px",
    paddingBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 20px",
    },
  },
  boxCotainer: {
    height: "356px",
    margin: "90px 25px",
    width: "350px",
    backgroundColor: "#00D6BC1A",
    borderRadius: "12px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      margin: "90px 20px",
    },
  },
}));
const Page2_2 = () => {
  const classes = useStyles();
  return (
    <div>
      <Container maxWidth="lg" style={{ padding: "20px 0px" }}>
        <div>
          <Box className={classes.heading}>
            What makes our <span style={{ color: "#F86E20" }}>POS CRM</span>{" "}
            special?
          </Box>
          <Box className={classes.subHeading}>
            Dealing with transactions is not a headache anymore. POS CRM has
            many built in features that truly makes it stand out among most
            other mobile based CRMs out there.
          </Box>
        </div>
        <div
          style={{
            padding: "60px 20px 0px 20px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <Box
            className={classes.boxCotainer}
            style={{ backgroundColor: "#00D6BC1A" }}
          >
            <Box style={{ position: "absolute", left: "5%", bottom: "250px" }}>
              <img loading="eager" src={img2} alt="" />
            </Box>
            <Box
              style={{
                paddingTop: "130px",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <Box
                style={{ color: "#3A1C67", fontSize: "20px", fontWeight: 600 }}
              >
                Effortless App-based Billing
              </Box>
              <Box
                style={{
                  color: "#848891",
                  fontSize: "14px",
                  padding: "20px 0px",
                }}
              >
                Going beyond a customer-information system, integration with POS
                allows you to track who is buying what and how much. For your
                customers, making payments is not merely a transaction, it’s an
                experience here.
              </Box>
            </Box>
          </Box>
          <Box
            className={classes.boxCotainer}
            style={{ backgroundColor: "#4C608E1A" }}
          >
            <Box style={{ position: "absolute", left: "15%", bottom: "250px" }}>
              <img loading="eager" src={img1} alt="" />
            </Box>
            <Box
              style={{
                paddingTop: "130px",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <Box
                style={{ color: "#3A1C67", fontSize: "20px", fontWeight: 600 }}
              >
                Invoicing Options
              </Box>
              <Box
                style={{
                  color: "#848891",
                  fontSize: "14px",
                  padding: "20px 0px",
                }}
              >
                Having direct access to information about products and services
                availed by customers, you can send to them invoices through
                what’sapp or E-mail or you can produce a simple print generated
                from phone.
              </Box>
            </Box>
          </Box>
          <Box
            className={classes.boxCotainer}
            style={{ backgroundColor: "#DD8F791A" }}
          >
            <Box style={{ position: "absolute", left: "10%", bottom: "250px" }}>
              <img loading="eager" src={img3} alt="" />
            </Box>
            <Box
              style={{
                paddingTop: "130px",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <Box
                style={{ color: "#3A1C67", fontSize: "20px", fontWeight: 600 }}
              >
                Appointment Management
              </Box>
              <Box
                style={{
                  color: "#848891",
                  fontSize: "14px",
                  padding: "20px 0px",
                }}
              >
                Create, modify and cancel appointments for your customers and
                also, alert them with updates on the go, our appointment
                management system empowers you with the real mobile control.
              </Box>
            </Box>
          </Box>
          <Box
            className={classes.boxCotainer}
            style={{ backgroundColor: "#FE677A1A" }}
          >
            <Box style={{ position: "absolute", left: "15%", bottom: "250px" }}>
              <img loading="eager" src={img6} alt="" />
            </Box>
            <Box
              style={{
                paddingTop: "130px",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <Box
                style={{ color: "#3A1C67", fontSize: "20px", fontWeight: 600 }}
              >
                Inventory Management
              </Box>
              <Box
                style={{
                  color: "#848891",
                  fontSize: "14px",
                  padding: "20px 0px",
                }}
              >
                We allow you to manage your inventory on the move. Automatic
                updates on products and their quantities as per the real-time
                delivery empower you to easily monitor and start to re-fill your
                top-selling items at different locations, with all the
                information at one integrated platform.
              </Box>
            </Box>
          </Box>
          <Box
            className={classes.boxCotainer}
            style={{ backgroundColor: "#FFBD661A" }}
          >
            <Box style={{ position: "absolute", left: "15%", bottom: "250px" }}>
              <img loading="eager" src={img5} alt="" />
            </Box>
            <Box
              style={{
                paddingTop: "130px",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <Box
                style={{ color: "#3A1C67", fontSize: "20px", fontWeight: 600 }}
              >
                Customised Package
              </Box>
              <Box
                style={{
                  color: "#848891",
                  fontSize: "14px",
                  padding: "20px 0px",
                }}
              >
                All of your customer-retention plans, i.e. membership, wallet
                system, bundled offers etc. are integrated in our POS, freeing
                you up from hassles of maintaining separate records.
              </Box>
            </Box>
          </Box>
          <Box
            className={classes.boxCotainer}
            style={{ backgroundColor: "#3A2F2E1A" }}
          >
            <Box style={{ position: "absolute", left: "15%", bottom: "250px" }}>
              <img loading="eager" src={img4} alt="" />
            </Box>
            <Box
              style={{
                paddingTop: "130px",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <Box
                style={{ color: "#3A1C67", fontSize: "20px", fontWeight: 600 }}
              >
                Database Management
              </Box>
              <Box
                style={{
                  color: "#848891",
                  fontSize: "14px",
                  padding: "20px 0px",
                }}
              >
                We are a repository of records. All your contacts, customers’
                purchase-history, service-history, everything stays in one
                database which is backed up on secure Cloud.
              </Box>
            </Box>
          </Box>
        </div>
      </Container>
    </div>
  );
};
export default Page2_2;
