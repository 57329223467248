import axios from "axios"
import { getCookie } from '../Utils/commonUtils'

export const FollowUpApi = {
    getFollowUpList: async (data) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/follow-ups`, data, {
              headers: {
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getCookie('callpal-user').token}`
              }
            });
            return response; // Assuming your data is in response.data
          } catch (error) {
            console.error('Error in getTeamList:', error);
            return false
            //throw error; // Re-throw the error to handle it at the calling site if needed
          }
    },
    getFollowUpTeamLead: async (data) => {
      try {
          const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/s-follow-ups`, data, {
            headers: {
              'Accept': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getCookie('callpal-user').token}`
            }
          });
          return response; // Assuming your data is in response.data
        } catch (error) {
          console.error('Error in getTeamList:', error);
          return false
          //throw error; // Re-throw the error to handle it at the calling site if needed
        }
    }
}