import axios from "axios"
import { getCookie } from '../Utils/commonUtils'

export const LeadTransferApi = {
    getLeadTransferList: async (data) => {
        try {
             
            const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/lead-transfer-users`, {
              headers: {
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getCookie('callpal-user').token}`
              }
            });
            return response; // Assuming your data is in response.data
          } catch (error) {
            console.error('Error in getLeadTransferList:', error);
            return false
            //throw error; // Re-throw the error to handle it at the calling site if needed
          }
    },
       





}