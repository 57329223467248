import { routes } from "../constants/routes";

import dashboardImg from '../Images/dashboard 1.svg'
import teamImg from '../Images/Team (1).svg'
import teamViewImg from '../Images/Team View.svg'

import bulkDataUpload from '../Images/Bulk Data.svg'
import dataAllocation from '../Images/bulk data upload 2.svg'
import sms from '../Images/sms template 1.svg'
import whatsApp from '../Images/whatsapp 1.svg'
import callLogs from '../Images/call logs 1.svg'
import followUps from '../Images/Follow up.svg'
import autoDial from '../Images/autoDial.svg'
import reassign from '../Images/reassign.svg'
import calendar from '../Images/calendar 1.svg'
import liveStatus from '../Images/Team Live status 1.svg'
import SubscriptionImg from '../Images/Subscribe.svg'
import Settings from '../Images/Settings.svg'
import leadTransfer from '../Images/Lead Transfer 1.svg'
import user from '../Images/sa_team.png'
import masterData  from '../Images/reports 1.svg'


export  const dashboardList = [
    { 
      path: 'Dashboard',
      icon: dashboardImg,
      route: routes.dashboard1
    },
    {
      path: 'Team',
      icon: teamImg,
      route: routes.team
    },
    {
      path: 'Team View',
      icon: teamViewImg,
      route: routes.teamView
    },
    {
      path: 'Bulk Data upload',
      icon: bulkDataUpload,
      route: routes.bulkDataUpload
    },
    {
      path: 'Data Allocation',
      icon: dataAllocation,
      route: routes.dataAllocation
    },
    {
      path: 'Lead Transfer',
      icon: leadTransfer,
      route: routes.leadTransfer
    },
    {
      path: 'SMS Template',
      icon: sms,
      route: routes.smsTemplate
    },
    // {
    //   path: 'Enquiries',
    //   icon: Enquiries,
    //   route: routes.enquiries,
    //   isArrow:true
    // },
    {
      path: 'Whatsapp Template',
      icon: whatsApp,
      route: routes.whatsappTemplate
    },
    
    {
      path: 'Call Logs',
      icon: callLogs,
      route: routes.callLogs
    },
    {
      path: 'Calendar',
      icon: calendar,
      route: routes.calendar
    },
    {
      path: 'Follow Ups',
      icon: followUps,
      route: routes.followUps
    },
    // {
    //   path: 'Reports',
    //   icon: reports,
    //   route: routes.reports
    // },
    // {
    //   path: 'Team Live Status',
    //   icon: liveStatus,
    //   route: routes.teamLiveStatus
    // },
    {
      path: 'Reassign Data',
      icon: reassign,
      route: routes.reassignData
    },
    
    {
      path: 'Auto-Dial Configuration',
      icon: autoDial,
      route: routes.autoDial
    },
    {
      path: 'Customer',
      icon: user,
      route: routes.customer
    },
    {
      path: 'Master Data',
      icon: masterData,
      route: routes.masterData
    }
    // {
    //   path: 'Help',
    //   icon: helpImg,
    //   route: routes.help
    // },
    
  ]

  export const superAdminList=[
    {
      path:'Dashboard',
      icon:dashboardImg,
      route:routes.dashboard1
    },
    {
        path: 'Team',
        icon: teamImg,
        route: routes.team
      },
      {
        path: 'Team View',
        icon: teamViewImg,
        route: routes.teamView
      },
      {
        path: 'Bulk Data upload',
        icon: bulkDataUpload,
        route: routes.bulkDataUpload
      },
      {
        path: 'Data Allocation',
        icon: dataAllocation,
        route: routes.dataAllocationTeamLead
      },
      {
        path: 'Lead Transfer',
        icon: leadTransfer,
        route: routes.leadTransfer
      },
      {
        path: 'SMS Template',
        icon: sms,
        route: routes.smsTemplate
      },
      {
        path: 'Whatsapp Template',
        icon: whatsApp,
        route: routes.whatsappTemplate
      },
      {
        path: 'Call Logs',
        icon: callLogs,
        route: routes.callLogsTeamLead
      },
      {
        path: 'Follow Ups',
        icon: followUps,
        route: routes.followUpsTeamLead
      },
      {
        path: 'Team Live Status',
        icon: liveStatus,
        route: routes.teamLiveStatus
      },
      {
        path: 'Customer',
        icon: user,
        route: routes.customerTeamLead
      },
      {
        path: 'Reassign Data',
        icon: reassign,
        route: routes.reassignData
      },
      {
        path: 'Auto-Dial Configuration',
        icon: autoDial,
        route: routes.autoDial
      },
      {
        path: 'Master Data',
        icon: masterData,
        route: routes.masterData
      }
  ]

  export const bankList=[
    'HDFC','AXIS','ICICI','TATA CAPITAL',"MAHINDRA",'AWAS','ADITY BIRLA','MULTIPLE',
    'BOB','BAJAJ','IDFC','INDUS','SBI'

  ]