import React from "react";
import { useRef, useEffect,useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import "./table.css";
import FilterListRoundedIcon from "@material-ui/icons/FilterListRounded";
import filter from "../../Images/Filter.svg";
import NoRecords from "./Other-components/NoRecords";
import { NavLink } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import { CircularProgress, InputAdornment, TextField } from "@material-ui/core";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  UncontrolledButtonDropdown,
} from "reactstrap";
import { CommonApi } from "../../Services/CommonApi";
import { getCookie } from "../../Utils/commonUtils";
import { DashboardApi } from "../../Services/DashboardApi";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

const useStyles = makeStyles({
  table: {
    // padding: "20px",
  },
  tableRow: {
    backgroundColor: "white",
    borderRadius: "30px",
    padding: "10px 0",
  },
  tableCellTh: {
    border: "none",
    textAlign: "center",
    fontWeight: "600",
    fontSize: "15px",
  },
  tableCell: {
    border: "none",
    textAlign: "center",
    borderBottom: "1px solid #d5d5d5",
    borderTop: "1px solid #d5d5d5",
    borderColor: "#d5d5d5",
    padding: "20px !important",
  },
  tableCellRight: {
    border: "none",
    textAlign: "center",
    borderBottom: "1px solid #d5d5d5",
    borderRight: "1px solid #d5d5d5",
    borderTop: "1px solid #d5d5d5",
    borderBottomRightRadius: "20px",
    borderTopRightRadius: "20px",
    borderColor: "#d5d5d5",
    padding: "20px !important",
  },
  tableCellLeft: {
    border: "none",
    textAlign: "center",
    borderBottom: "1px solid #d5d5d5",
    borderLeft: "1px solid #d5d5d5",
    borderTop: "1px solid #d5d5d5",
    borderBottomLeftRadius: "20px",
    borderTopLeftRadius: "20px",
    borderColor: "#d5d5d5",
    padding: "15px !important",
    fontWeight: 600,
  },
  lastRow: {},
  customPaper: {
    borderRadius: "20px",
  },
  customPaper2: {
    borderRadius: "20px",
    height: "100%",
    padding: "0 20px 20px",
  },
  customPaper3: {
    borderSpacing: "0px 10px",
    borderCollapse: "separate",
  },
  tableContainer: {
    height: 400,
    overflowY: "auto",
  },

});

function createData(number, item, qty, price) {
  return { number, item, qty, price };
}

export default function Calldash() {
  const classes = useStyles();
  const { userInfo } = useSelector((state) => state.login);
  const loginInfo = getCookie("callpal-user");
  //  const teamLeadListArray = Object.entries(companyTeamLead || {});
  const [searchQuery, setSearchQuery] = useState("");
  // const filteredTeamLeads = companyTeamLead ? Object.entries(companyTeamLead).filter(([key, name]) => name.toLowerCase().includes(searchQuery.toLowerCase())) : [];
  const [selectedName, setSelectedName] = useState("");
  const [teamLeadIdDetails, setTeamLeadIdDetails] = useState({
    id: "",
    name: "",
  });

  const [callingTimmingList, setCallingTimmingList] = useState([]);
  const [companyTeamLead, setCompanyTeamLead] = useState();
  const [statusSearch, setStatusSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true); // Initially set to false
  const [loading, setLoading] = useState(false);


  const team_lead_id = loginInfo.userInfo.fk_role_id !== 2 ? loginInfo.userInfo.id : ""; 
  const [teamLead, setTeamLead] = useState(team_lead_id); 

  const tableContainerRef = useRef(null);

  useEffect(() => {
    getTeamLeadList();
    const container = tableContainerRef.current;
    let previousTimestamp = null;
  
    const scrollVertically = (timestamp) => {
      if (previousTimestamp === null) {
        previousTimestamp = timestamp;
      }
      const timeDiff = timestamp - previousTimestamp;
      const scrollHeight = container.scrollHeight - container.clientHeight;
      const scrollSpeed = 0.05; // Adjust this value for desired scrolling speed
      const scrollIncrement = timeDiff * scrollSpeed;
      container.scrollTop += scrollIncrement;
  
      if (container.scrollTop >= scrollHeight) {
        container.scrollTop = 0; // Reset to top when reaching the bottom
      }
  
      previousTimestamp = timestamp;
      requestAnimationFrame(scrollVertically);
    };
  
    requestAnimationFrame(scrollVertically);
    getDashboardCallingTimming(teamLead);
    return () => cancelAnimationFrame(scrollVertically);
  }, []);
  


  useEffect(() => {
    // getDashboardCallingTimming(teamLead);
    // getTeamLeadList();
  }, []);

  const getDashboardCallingTimming = async (team_lead_id, pageActive) => {
    try {
      setLoading(true);
      const data = JSON.stringify({
        team_lead_id: loginInfo.userInfo.fk_role_id === 2 ? team_lead_id : teamLead,
        //team_lead_id: teamLead,
        page: pageActive ? pageActive : currentPage,
        per_page_row: 5,
      });
      const res = await DashboardApi.getDashboardCallingTimming(data);
      if (res.status) {
        setCallingTimmingList((prevList) => [...prevList, ...res.data]);
        setCurrentPage((prevPage) => prevPage + 1);
        if (res.data.length == 0) {
          setHasMore(false); // If fetched less than 5 rows, stop loading more data
        }
      } else {
        setCallingTimmingList([]);
        
      }
      setLoading(false)
    } catch (err) {
      console.log(err);
      setLoading(false)
    }
  };


  const getTeamLeadList = async () => {
    setLoading(true);
    try {
      const res = await CommonApi.getCompanyTeamLead(loginInfo, true);
      if (res.status) {
        const statusArray = Object.entries(res.data).map(([id, status]) => ({
          id,
          status,
        }));
        setCompanyTeamLead(statusArray);
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err);
    }
  };
  // useEffect(() => {
  //   // Load initial data
  //   getDashboardCallingTimming(1);
  // }, []); 
 
  const handleSelectTeamLead = (e) => {
    setCallingTimmingList([]);
    setStatusSearch(e.status);
    setTeamLead(e.id);
    setCurrentPage(1);
    setHasMore(true);
    getDashboardCallingTimming(e.id,1)
    
  };

  
  return (
    <TableContainer
    component={Paper}
      className="conatiner"
      classes={{ root: classes.customPaper2, container: classes.tableContainer }}
      ref={tableContainerRef}
    >
      <div className="d-flex">
        <h2 className="Heading">Calling Timing</h2>
        <div className="see-con">
          <div className="imgBox">
            <div className={classes.filterHolderComp}>
            {loginInfo.userInfo.fk_role_id == 2 && (
                <UncontrolledButtonDropdown>
                <DropdownToggle
                  style={{
                    display: "flex",
                    gap: "10px",
                    background: "white",
                    color: "#3A1C67",
                    border: "none",
                    borderRadius: "5px",
                    width: 200,
                    justifyContent: "space-between",
                  }}
                >
                  {statusSearch === "" ? "All Team Lead" : statusSearch}
                  <ArrowDropDownIcon />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem header>
                    <TextField
                      placeholder="Search Employees"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      size="small"
                      variant="outlined"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </DropdownItem>
                  <DropdownItem onClick={() => handleSelectTeamLead({id: "", status: "All Team Lead"})}>
                      All Team Lead
                    </DropdownItem>
                  <div style={{ maxHeight: 150, overflowY: "scroll" }}>
                    {companyTeamLead &&
                      companyTeamLead
                        .filter((e) =>
                          e.status
                            .toString()
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase())
                        )
                        .map((el) => (
                          <DropdownItem
                            key={el.id}
                            onClick={() => {
                              handleSelectTeamLead(el); 
                            }}
                          >
                            {el.status}
                          </DropdownItem>
                        ))}
                   
                  </div>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            )}
          
            </div>
          </div>
          <NavLink to={"/dashboard/team-live-status"} className="see-all-txt">
            See All{" "}
          </NavLink>
          <ArrowForwardIosRoundedIcon className="arrow-icon" />
        </div>
      </div>
      
      <Table
        aria-label="simple table"
        className={classes.table}
        ref={tableContainerRef}
        classes={{ root: classes.customPaper3 }}
      >
        <div className={classes.tableContainer} id="scrollableDiv" >
          <InfiniteScroll
            dataLength={callingTimmingList.length}
            next={() => getDashboardCallingTimming(teamLead)} // Call getDashboardCallingTimming with the current page number
            hasMore={hasMore}
            loader={<p>Loading...</p>}
            scrollableTarget="scrollableDiv"
          >
            <TableHead >
              <TableRow>
                <TableCell className={classes.tableCellTh}>Employee Name</TableCell>
                <TableCell className={classes.tableCellTh}>
                  Average Talk Time
                </TableCell>
                <TableCell className={classes.tableCellTh}>
                  Total Talk Time
                </TableCell>
                <TableCell className={classes.tableCellTh}>Break Time</TableCell>
                <TableCell className={classes.tableCellTh}>Idle Time</TableCell>
                <TableCell className={classes.tableCellTh}>Call log</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              
                {callingTimmingList.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.telecaller_name}</TableCell>
                    <TableCell>{row.average_talk_time}</TableCell>
                    <TableCell>{row.total_talk_time}</TableCell>
                    <TableCell>{row.break_talktime}</TableCell>
                    <TableCell>{row.idle_time}</TableCell>
                    <TableCell>{row.calllog_count}</TableCell>
                  </TableRow>
                ))}
              <NoRecords rows={callingTimmingList} loading={loading} />
              
            </TableBody>
          </InfiniteScroll>
        </div>
      </Table>
    </TableContainer>
  );
}
