import { Box, Container, makeStyles } from "@material-ui/core"
import Footer from "../Footer"
import Header from "../Header"
const useStyles = makeStyles((theme) => ({
    container:{
    padding: '80px 80px', 
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
       
        padding: '80px 40px',
       },
    },
    header: {
        fontSize: '25px',
        color: '#3A1C67',
        fontWeight: 700,
        padding: '20px 0px'
    },
    pinkSection: {
        color: '#B32B88',
        fontSize: '15px',
        fontWeight: 600
    },
    greySection: {
        color: '#848891'
    }
}))
const TnCPolicy = () => {
    const classes = useStyles()
    return (
        <div>
            <Header />
            <Container maxWidth="lg" className={classes.container}>
                <Box className={classes.header}>Terms and Conditions</Box>
                <Box className={classes.pinkSection}>
                    The terms “We” / “Us” / “Our”/”Company” individually and collectively refer to the Seller .and
                    the terms “Visitor” ”User” refers to the Buyer of the product/services.


                    <div>This page states the Terms and Conditions under which you (the Buyer) may use the App.
                        Please read this page carefully. If you do not accept the Terms and Conditions stated here,
                        we would request you not to procure the App. The business, any of its business divisions and/or
                        its subsidiaries, associate companies or subsidiaries to subsidiaries or such other investment
                        companies (in India or abroad) reserve their respective rights to revise these Terms
                        and Conditions at any time by updating this posting in our Website.
                        You should visit this page periodically to re-appraise yourself of the Terms and Conditions
                        because they are binding on all users of this App.
                    </div>
                </Box>

                <Box className={classes.header}>
                    USE OF CONTENT
                </Box>
                <Box className={classes.greySection}>
                    <ul>
                        <li style={{ paddingBottom: '20px' }}>
                            All logos, brands, marks, headings, labels, names, signatures, numerals, shapes or any combinations thereof, appearing in this site, except as otherwise noted, are properties either owned, or used under licence, by the business of the Seller and / or its associate entities who feature on this Website or the App. The use of these properties or any other content of this App, except as provided in these terms and conditions or in the App content, is strictly prohibited.
                        </li>

                        <li style={{ paddingBottom: '20px' }}>

                            You may not sell or modify the content of this App or reproduce, display, publicly perform,
                            distribute, or otherwise use the materials in any way for any public or commercial purpose
                            without the Sellers’ written permission.
                        </li>


                    </ul>
                </Box>

                <Box className={classes.header}>
                    ACCEPTABLE WEBSITE USE
                </Box>
                <Box>

                </Box>
                <Box className={classes.greySection}>
                    <p style={{ color: '#848891', fontWeight: 600 }}>(A) Security Rules</p>
                    Visitors are prohibited from violating or attempting to violate security of the App, including, without limitation:
                    <div>1.accessing data not intended for such user or logging into a server or account which the user is
                        not authorised to access.</div>
                    <div>2.attempting to probe, scan or test the vulnerability of a system or network or to breach security or
                        authentication measures without proper authorisation.</div>
                    <div>attempting to interfere with service to any user, host or network, including, without limitation,
                        via means of submitting a virus or “Trojan horse” to the Website, overloading, “flooding”, “mailbombing”
                        or “crashing”, or sending unsolicited electronic mail, including promotions and/or advertising of products or services.
                        Violations of system or network security may result in civil or criminal liability. The Seller and/or its
                        associate entities will have the right to investigate occurrences that they suspect as involving such
                        violations and will have the right to involve and cooperate with law enforcement authorities in prosecuting
                        users who are involved in such violations.</div>

                    <p style={{ color: '#848891', fontWeight: 600 }}>(B) General Rules</p>
                    Buyer may not use the App in order to transmit, distribute, store or destroy material
                    <div>1.that could constitute or encourage conduct that would be considered a criminal offence or
                        violate any applicable law or regulation.</div>
                    <div>2.In a manner that will infringe the copyright, trademark, trade secret or other intellectual
                        property rights of the Seller or violate the privacy or publicity or other personal rights of the Seller.</div>
                    <div>3.That is libelous, defamatory, pornographic, profane, obscene, threatening, abusive or hateful.</div>
                </Box>
                <Box className={classes.header}>
                    INDEMNITY
                </Box>
                <Box className={classes.greySection}>
                    The Buyer unilaterally agree to indemnify and hold harmless, without objection, the Seller, its executives, officers, directors, employees and agents from and against any claims, actions and/or demands and/or liabilities and/or losses and/or damages whatsoever arising from or resulting from their use of the App i.e. Callpal or their breach of the terms .
                </Box>

                <Box className={classes.header}>
                    LIABILITY
                </Box>
                <Box className={classes.greySection}>
                    <ul>
                        <li style={{ paddingBottom: '20px' }}>
                            The Buyer agrees that neither the Seller nor its group companies, directors, officers or employees shall be liable for any direct and / or indirect and / or incidental and / or special and / or consequential and / or exemplary damages, resulting from the use and / or the inability to use the service and / or for cost of procurement of substitute goods and / or services or resulting from any goods
                            and / or data and / or information and / or services purchased and / or obtained and / or messages received and /
                            or transactions entered into through and / or from the service and / or resulting from unauthorized access to and /
                            or alteration of user’s transmissions and / or data and / or arising from any other matter relating to the service, including
                            but not limited to, damages for loss of profits and / or use and / or data or other intangible, even if the Seller has been advised
                            of the possibility of such damages.
                        </li>

                        <li style={{ paddingBottom: '20px' }}>
                            The Buyer further agrees that the Seller shall not be liable for any damages arising from interruption, suspension or
                            termination of service, including but not limited to direct and / or indirect and / or incidental and / or special
                            consequential and / or exemplary damages, whether such interruption and/or suspension and/or termination was justified or
                            not, negligent or intentional, inadvertent or advertent.
                        </li>
                        <li>
                            The Buyer agrees that the Seller shall not be responsible or liable to the Buyer, or anyone, for the statements or conduct of any third party
                            of the service. In sum, in no event shall the Seller’s total liability to the Buyer for all damages and/or losses and/or causes of action exceed
                            the amount paid by the Buyer to the Seller, if any, that is related to the cause of action.
                        </li>


                    </ul>
                </Box>
                <Box className={classes.header}>
                DISCLAIMER OF CONSEQUENTIAL DAMAGES
                </Box>
                <Box className={classes.greySection}>
                In no event shall the Seller or any parties, organizations or entities associated with the corporate brand name Callpal or otherwise, be liable for any damages whatsoever (including, without limitations, incidental and consequential damages, lost profits, or damage to computer hardware or loss of data information or business interruption) resulting from the use or inability to use the App and the Website material, whether based on warranty, contract, tort, or any other legal theory, and whether or not, the Seller or its entities were advised of the possibility of such damages.
                </Box>
                <Box className={classes.greySection} style={{ fontWeight: 700,paddingTop:'40px' }}>
                    2022 © Callpal. All Rights Reserved -2024
                </Box>

            </Container>
            <Footer />
        </div>
    )
}
export default TnCPolicy