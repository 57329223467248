import {
  makeStyles,
  useTheme,
  Box,
  Card,
  CardContent,
  Typography,
  CardActions,
  Avatar,
} from '@material-ui/core'
import { useEffect, useState } from 'react'
import MobileStepper from '@material-ui/core/MobileStepper'
import Button from '@material-ui/core/Button'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'
import CustomCarousel from '../CustomCarousel/CustomCarousel'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
  carouselContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '60px',
    width: '100%',
    overflowX: 'hidden',
  },

  carouselItems: {
    display: 'flex',
    alignItems: 'center',
  },

  carouselItem: {
    margin: '0 5px',
    transition: 'transform 0.3s',
    flex: '0 0 20%',
  },

  image: {
    maxWidth: '150px',
    maxHeight: '150px',
    cursor: 'pointer',
    borderRadius: '50%',
  },

  centers: {
    transform: 'scale(1.5)',
    margin: '3.2rem',
    opacity: 'none',
  },

  opacity: {
    opacity: '0.7',
  },

  end: {
    transform: 'scale(0.6)',
    margin: '-15px',
  },

  button: {
    fontSize: '24px',
    padding: '5px 10px',
    margin: '10px',
  },
  heading: {
    fontSize: '50px',
    fontWeight: 900,
    color: '#292830',
  },
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  card: {
    height: '200px',
    width: '300px',
    borderRadius: '8px',
    backgroundColor: '#fff',
    padding: '20px',
    color: '#848891',
    margin: '20px',
  },
}))

const initialImages = [
  {
    color: '#F86E20',
  },
  {
    color: '#3A1C67',
  },
  {
    color: '#E41C61',
  },
  {
    color: '#F86E20',
  },
  {
    color: '#3A1C67',
  },
  {
    color: '#E41C61',
  },
]

const Home6 = () => {
  const classes = useStyles()

  const [images, setImages] = useState(initialImages)
  const [currentIndex, setCurrentIndex] = useState(0)
  const theme = useTheme()

  useEffect(() => {
    const interval = setInterval(() => {
      moveItems()
    }, 3000) // Change the interval (in milliseconds) as desired

    return () => clearInterval(interval)
  }, [currentIndex])
  const handleBack = () => {
    setCurrentIndex((currentIndex) => currentIndex - 1)
  }
  const handleNext = () => {
    setCurrentIndex((currentIndex) => currentIndex + 1)
  }
  const moveItems = () => {
    // Get the next index
    const nextIndex = (currentIndex + 1) % images.length

    // Update the state with the new current index
    setCurrentIndex(nextIndex)
  }

  const handleItemClick = (index) => {
    setCurrentIndex(index)
  }

  // Function to create a circular index based on the current index and array length
  const getCircularIndex = (index, length) => {
    return (index + length) % length
  }

  return (
    <div style={{ paddingTop: '70px' }}>
      <div>
        <Box className={classes.heading}>We have millions of best wishers</Box>
      </div>
      <CustomCarousel></CustomCarousel>
     
    </div>
  )
}
export default Home6
