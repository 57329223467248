import React from "react";
import { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import "./DRR.css";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import InfiniteScroll from "react-infinite-scroll-component";
import { NavLink } from "react-router-dom";
import { DRRApi } from "../../Services/DRRApi";
import moment from "moment/moment";
import { ContactsOutlined } from "@material-ui/icons";

const useStyles = makeStyles({
  cardContainerOne: {
    margin: "20px 0",
    // background: "#ffefef",
    borderRadius: "30px !important",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardContainerTwo: {
    margin: "20px 0",
    // background: "#e7ffdf",
    borderRadius: "30px !important",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardContainerThree: {
    margin: "20px 0",
    // background: "#e0e8ff",
    borderRadius: "30px !important",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  disabledSlider: {
    pointerEvents: "none", // Disable pointer events
    // opacity: 0.5, // Reduce opacity to visually indicate disabled state
  },
  tableContainer: {
    height: 400,
    overflowY: "auto",
  },
});

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

export default function DRR() {
  const classes = useStyles();
  const [age, setAge] = React.useState("");

  // -------------------------------------------------------
  const [activeData, setActiveData] = useState({
    page: 1,
  });
  const [dates, setDates] = useState({from: moment().subtract(7, "days").format("YYYY-MM-DD"),
   to: moment().format("YYYY-MM-DD"), });
  const [selectedOption, setSelectedOption] = useState("week");
  const [loading, setLoading] = useState(false);
  const [drrList, setDRRList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredDrrList, setFilteredDrrList] = useState([]);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true); // Initially set to false

  
  // -------------------------------------------------------

  useEffect(() => {
    getDashboardDRRInfo();
  }, [dates]);


  const fetchMoreData = () => {
    const data = {
      page: activeData.page + 1,
    };
    getDashboardDRRInfo();
    setActiveData(data);
  };

  const getDashboardDRRInfo = async () => {
    try {
      setLoading(true);
      const currentMonth = moment().format('MM');
      const currentYear = moment().year();
      const data = JSON.stringify({
        fromdate: dates.from,
        todate: dates.to,
        per_page_row: 12,
        page: currentPage,
        month: currentMonth,
        year:currentYear, 
      });
      const res = await DRRApi.DRRDashboard(data);
      if (res.status) {
        setDRRList((prevList) => [...prevList, ...res.data]);
        setCurrentPage((prevPage) => prevPage + 1);
        if (res.data.length < 12) {
          setHasMore(false); // If fetched less than 5 rows, stop loading more data
        }
      } else {
        setDRRList([]);
      }
    } catch (err) {
      setLoading(false);
      // console.error(err);
    } finally {
      setLoading(false);
    }
  };



  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    // Filter the drrList based on the search query
    const filteredList = drrList.filter((data) =>
      data.name.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredDrrList(filteredList);
  };
  const renderDrrList = filteredDrrList.length > 0 ? filteredDrrList : drrList;

  const PrettoSlider = withStyles({
    root: {
      color: "#52af77",
      height: 8,
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: "#fff",
      border: "2px solid currentColor",
      marginTop: -8,
      marginLeft: -12,
      "&:focus, &:hover, &$active": {
        boxShadow: "inherit",
      },
    },
    active: {},
    valueLabel: {
      left: "calc(-50% + 4px)",
    },
    track: {
      height: 8,
      borderRadius: 4,
    },
    rail: {
      height: 8,
      borderRadius: 4,
    },
  })(Slider);
  return (
    <Card style={{ padding: "0 1.5em" }}>
      <div className="d-flex">
        <h2 className="Heading">DRR</h2>
        {/* <h4 className="text-center">Coming Soon</h4> */}
        <div className="see-con">
          <NavLink to={"/dashboard/drr"} className="see-all-txt">
            See All{" "}
          </NavLink>
          <ArrowForwardIosRoundedIcon className="arrow-icon" />
        </div>
      </div>
      <div className={classes.tableContainer} id="scrollableDRRDiv" >
        <InfiniteScroll
           //dataLength={1}
          dataLength={renderDrrList?.length}
          next={fetchMoreData}
          loader={<>Loading...</>}
          hasMore={hasMore}
          scrollableTarget="scrollableDRRDiv"
        >
          <div className="row">
          {renderDrrList &&
            renderDrrList.map((data, index) => (
              <div className="col-md-4 col-sm-6" key={index}>
                <Card
                  className={classes.cardContainerOne}
                  style={{ background: data.bgColor }}
                >
                  <div className={`status-tag-${data.statusColor}`}></div>
                  <div className="drr-content">
                    <div className="Drr-team-header-flex">
                      <p className="see-all-txt">{data.name}</p>
                      <p className="drr-card-heading-one">{data.role}</p>
                    </div>
                    <div className="mt-2 mb-2">
                      <PrettoSlider
                        valueLabelDisplay="auto"
                        aria-label="pretto slider"
                        defaultValue={data.percentage}
                        className={classes.disabledSlider} // Apply disabled styling
                      />
                      <div className="Drr-team-content-flex">
                        <p className="con-txt">{data.disbursementAmount}</p>
                        <p className="con-txt">{data.target}</p>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            ))}
          </div>
        </InfiniteScroll>
      </div>


      {/* <div className="row">
        <div className="col-md-4 col-sm-6">
          <Card className={classes.cardContainerOne}>
            <div className="status-tag-red"></div>
            <div className="drr-content">
              <div className="Drr-team-header-flex">
                <p className="see-all-txt">Vishal Joshi</p>
                <p className="drr-card-heading-one">Team Leader</p>
              </div>
              <div className="mt-2 mb-2">
                <PrettoSlider
                  valueLabelDisplay="auto"
                  aria-label="pretto slider"
                  defaultValue={20}
                />
                <div className="Drr-team-content-flex">
                  <p className="con-txt">3 Thousand</p>
                  <p className="con-txt">1 Lakhs</p>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className="col-md-4 col-sm-6">
          <Card className={classes.cardContainerTwo}>
            <div className="status-tag-green"></div>
            <div className="drr-content">
              <div className="Drr-team-header-flex">
                <p className="see-all-txt">Vishal Joshi</p>
                <p className="drr-card-heading-one">Team Leader</p>
              </div>
              <div className="mt-2 mb-2">
                <PrettoSlider
                  valueLabelDisplay="auto"
                  aria-label="pretto slider"
                  defaultValue={20}
                />
                <div className="Drr-team-content-flex">
                  <p className="con-txt">3 Thousand</p>
                  <p className="con-txt">1 Lakhs</p>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className="col-md-4 col-sm-6">
          <Card className={classes.cardContainerThree}>
            <div className="status-tag-blue"></div>
            <div className="drr-content">
              <div className="Drr-team-header-flex">
                <p className="see-all-txt">Vishal Joshi</p>
                <p className="drr-card-heading-one">Team Leader</p>
              </div>
              <div className="mt-2 mb-2">
                <PrettoSlider
                  valueLabelDisplay="auto"
                  aria-label="pretto slider"
                  defaultValue={20}
                />
                <div className="Drr-team-content-flex">
                  <p className="con-txt">3 Thousand</p>
                  <p className="con-txt">1 Lakhs</p>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div> */}
      {/* <div className="row">
        <div className="col-md-4 col-sm-6">
          <Card className={classes.cardContainerThree}>
            <div className="status-tag-blue"></div>
            <div className="drr-content">
              <div className="Drr-team-header-flex">
                <p className="see-all-txt">Vishal Joshi</p>
                <p className="drr-card-heading-one">Team Leader</p>
              </div>
              <div className="mt-2 mb-2">
                <PrettoSlider
                  valueLabelDisplay="auto"
                  aria-label="pretto slider"
                  defaultValue={20}
                />
                <div className="Drr-team-content-flex">
                  <p className="con-txt">3 Thousand</p>
                  <p className="con-txt">1 Lakhs</p>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className="col-md-4 col-sm-6">
          <Card className={classes.cardContainerOne}>
            <div className="status-tag-red"></div>
            <div className="drr-content">
              <div className="Drr-team-header-flex">
                <p className="see-all-txt">Vishal Joshi</p>
                <p className="drr-card-heading-one">Team Leader</p>
              </div>
              <div className="mt-2 mb-2">
                <PrettoSlider
                  valueLabelDisplay="auto"
                  aria-label="pretto slider"
                  defaultValue={20}
                />
                <div className="Drr-team-content-flex">
                  <p className="con-txt">3 Thousand</p>
                  <p className="con-txt">1 Lakhs</p>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className="col-md-4 col-sm-6">
          <Card className={classes.cardContainerTwo}>
            <div className="status-tag-green"></div>
            <div className="drr-content">
              <div className="Drr-team-header-flex">
                <p className="see-all-txt">Vishal Joshi</p>
                <p className="drr-card-heading-one">Team Leader</p>
              </div>
              <div className="mt-2 mb-2">
                <PrettoSlider
                  valueLabelDisplay="auto"
                  aria-label="pretto slider"
                  defaultValue={20}
                />
                <div className="Drr-team-content-flex">
                  <p className="con-txt">3 Thousand</p>
                  <p className="con-txt">1 Lakhs</p>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div> */}
    </Card>
  );
}
