import { makeStyles } from "@material-ui/core/styles";
import { Divider } from "@mui/material";
import CircleComponent from "../../Common/CircleComponent";
import * as React from "react";
import { useState, useRef, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import logout from "../../Images/logout.svg";

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputBase,
  withStyles,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import SearchIcon from "@material-ui/icons/Search";
import { TablePagination } from "@material-ui/core";
import moment from "moment";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F1F1F1",
    color: "#3A1C67",
    fontWeight: 700,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles({
  mainContainer: {
    padding: "80px 30px 30px 30px",
  },
  headerContaniner: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  headerRight: {
    display: "flex",
    justifyContent: "space-between",
  },
  headerLeft: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: "20px",
    fontWeight: "700",
    color: "#FA9510",
  },
  subTitle: {
    display: "flex",
    flexDirection: "row",
    textAlign: "start",
    fontSize: "12px",
    fontWeight: "400",
  },
  divider: {
    marginLeft: "5px !important",
    marginRight: "5px !important",
  },
  expired: {
    background: "#F32839",
    width: "16px",
    height: "16px",
    borderRadius: "50%",
    marginRight: "5px",
  },
  active: {
    background: "#138A00",
    width: "16px",
    height: "16px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    marginRight: "5px",
  },
  inner: {
    background: "white",
    width: "10px",
    height: "10px",
    borderRadius: "50%",
  },
  headerRightSub: {
    display: "flex",
    fontSize: "16px",
    fontWeight: "400",
    alignItems: "center",
    marginLeft: "20px",
    color: "#3A1C67",
  },
  button: {
    backgroundColor: "#3A1C67",
    color: "#fff",
    textTransform: "none",
    height: "32px",
    fontSize: "12px",
    width: 120,
    marginBottom: 20,
  },
});

const SubscriptionDetail = () => {
  const classes = useStyles();
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

  const topHeaders = [
    "No. Of Members",
    "",
    "Start Date",
    "End Date",
    "Summary",
    "Total",
  ];
  const headers = ["Employee Id", "  "];

  const rows = [
    { status: "Subtotal", amount: "Rs 324545" },
    { status: "GST", amount: " Rs 324545" },
  ];
  return (
    <div className={classes.mainContainer}>
      <div className={classes.headerContaniner}>
        <div className={classes.headerLeft}>
          <span className={classes.title}> 3 subscriptions </span>
          <div className={classes.subTitle}>
            <span style={{ color: "#1C9231" }}> 1 used</span>
            <Divider orientation="vertical" className={classes.divider} />
            <span style={{ color: "#F32839" }}> 1 Unused</span>
          </div>
        </div>
        <div>
          <div className={classes.headerRight}>
            <div className={classes.headerRightSub}>
              <CircleComponent outer={classes.active} inner={classes.inner} />
              Active
            </div>
            <div className={classes.headerRightSub}>
              <CircleComponent outer={classes.expired} />
              Expired
            </div>
          </div>
        </div>
      </div>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table
          stickyHeader
          className={classes.table}
          aria-label="customized table"
          size="small"
        >
          <TableHead>
            <TableRow>
              {topHeaders.map((el, i) => {
                return (
                  <StyledTableCell key={el} align="center">
                    {el}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            <StyledTableRow>
              <StyledTableCell align="center">45 </StyledTableCell>
              <StyledTableCell align="center">
                <div style={{ display: "flex" }}>
                  <IconButton
                    aria-label="delete"
                    className={classes.iconButton}
                    style={{
                      borderBottomLeftRadius: 10,
                      borderTopLeftRadius: 10,
                    }}
                    // onClick={() => formik.values.auto_dialer_timing > 0 && formik.setFieldValue('auto_dialer_timing', formik.values.auto_dialer_timing - 1)}
                  >
                    <RemoveIcon />
                  </IconButton>
                  <Box
                    style={{
                      // backgroundColor: "#3A1C67",
                      // marginTop: 20,
                      // padding: 5,
                      // height: 34,
                      // color: "#fff",
                      // fontSize: 16,
                      marginTop: "11px",
                    }}
                  >
                    45
                    {/* {sec} */}
                    {/* {formik.values.auto_dialer_timing} */}
                  </Box>
                  <IconButton
                    aria-label="delete"
                    className={classes.iconButton}
                    style={{
                      borderTopRightRadius: 10,
                      borderBottomRightRadius: 10,
                    }}
                    // onClick={() =>formik.setFieldValue('auto_dialer_timing', formik.values.auto_dialer_timing + 1)}
                  >
                    <AddIcon />
                  </IconButton>
                </div>
              </StyledTableCell>
              <StyledTableCell align="center">
                {" "}
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    // disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    //   label="Start Date"
                    autoOk // Automatically close the date picker after selection
                    // value={startDate}
                    // onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    InputProps={{
                      style: {
                        fontSize: 14,
                        height: 34,
                      },
                    }}
                    style={{ width: "70%" }}
                  />
                </MuiPickersUtilsProvider>
              </StyledTableCell>
              <StyledTableCell align="center">
                {" "}
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    // disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    //   label="Start Date"
                    autoOk // Automatically close the date picker after selection
                    // value={startDate}
                    // onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    InputProps={{
                      style: {
                        fontSize: 14,
                        height: 34,
                      },
                    }}
                    style={{ width: "70%" }}
                  />
                </MuiPickersUtilsProvider>
              </StyledTableCell>
              <StyledTableCell align="center">Rs. 499/- * 45</StyledTableCell>
              <StyledTableCell align="center">Rs. 34587/-</StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {headers.map((el, i) => {
                return (
                  <StyledTableCell key={el} align="left">
                    {el}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.length > 0 &&
              rows.map((row) => (
                <StyledTableRow>
                  <StyledTableCell align="left">{row.status}</StyledTableCell>
                  <StyledTableCell align="right">{row.amount}</StyledTableCell>
                </StyledTableRow>
              ))}
            {/* <NoRecords rows={rows} loading={loading} />2 */}
          </TableBody>
        </Table>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">PROMO CODE</StyledTableCell>
              <StyledTableCell align="center">
                <input
                  style={{ border: "none" }}
                  placeholder="Enter promo code"
                />
              </StyledTableCell>
              <StyledTableCell align="right">Rs 4445454</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <StyledTableRow>
              <StyledTableCell align="left">Total</StyledTableCell>
              <StyledTableCell align="center"></StyledTableCell>
              <StyledTableCell align="right">Rs 4445454</StyledTableCell>
            </StyledTableRow>

            {/* <NoRecords rows={rows} loading={loading} />2 */}
          </TableBody>
        </Table>
      </TableContainer>

      {/* <div >
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={3}
          rowsPerPage={10}
          page={1}
          onPageChange={()=>{}}
          onRowsPerPageChange={()=>{}}
          backIconButtonProps={{
            "aria-label": "Previous Page",
            style: { color: '#fff', backgroundColor: '#3A1C67', padding: 3, borderRadius: '5px', marginRight: '5px' },
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
            style: { color: '#fff', backgroundColor: '#3A1C67', padding: '3px', borderRadius: '5px' },
          }}
        />
      </div>    */}
      <div className="row">
        <div className="col-6">
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            // startIcon={<img src={logout} alt="" />}
            // onClick={() => handleLogout()}
            style={{
              float: "left",
            }}
          >
            Go Back
          </Button>
        </div>
        <div className="col-6">
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            // startIcon={<img src={logout} alt="" />}
            // onClick={() => handleLogout()}
            style={{
              float: "right",
            }}
          >
            Make Payment
          </Button>
        </div>
      </div>
    </div>
  );
};
export default SubscriptionDetail;
