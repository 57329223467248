import { Box, Button, Divider, makeStyles, MenuItem } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Doughnut, Line } from "react-chartjs-2";
import {
  Chart,
  Tooltip,
  Title,
  ArcElement,
  Legend,
  scales,
  CategoryScale,
  registerables,
} from "chart.js";
import FileTable from "./FileTable";
import Rating from "@material-ui/lab/Rating";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { DateRange, KeyboardArrowDown } from "@material-ui/icons";
import DatePicker from "react-date-picker";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  UncontrolledButtonDropdown,
} from "reactstrap";
import moment from "moment";
import { Skeleton } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { getCookie } from "../../Utils/commonUtils";
import { DashboardApi } from "../../Services/DashboardApi";
import { CustomerHistoryApi } from "../../Services/CustomerHistoryApi";
import { CircularProgress, InputAdornment, TextField } from "@material-ui/core";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchIcon from "@material-ui/icons/Search";
import NoRecords from "./Other-components/NoRecords";
import constantConfig from "../../constants/constantConfig";
Chart.register(Tooltip, Title, ArcElement, Legend, ChartDataLabels);
Chart.register(...registerables);
Chart.register(CategoryScale);

const useStyles = makeStyles((theme, props) => ({
  mainContainer: {
    padding: "80px 30px 20px 30px",
  },
  container: {
    display: "flex",
    backgroundColor: "#848891",
    padding: "10px",
    justifyContent: "space-between",
    borderRadius: "12px",
  },
  button: {
    textTransform: "none",
    backgroundColor: "#F1F1F1",
    width: "130px",
    color: "#3A1C67",
    fontSize: "12px",
    fontFamily: "open-sans",
    borderRadius: "7px",
    margin: "0px 10px",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  table: {
    marginTop: "30px",
    borderCollapse: "collapse",
    width: "100%",
    color: "#3A1C67",
  },
  cell: {
    fontSize: "12px",
    border: "1px solid #dddddd",
    padding: "8px",
  },
  boxContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  box: {
    textAlign: "center",
    padding: "15px",
    height: "80px",
    margin: "20px 0px",
    borderRadius: "10px",
    color: "#3A1C67",
    fontWeight: 700,
    width: "230px",
    backgroundColor: "#fff",
  },
  creditBox: {
    textAlign: "center",
    padding: "15px",
    height: "80px",
    margin: "20px 0px",
    borderRadius: "10px",
    color: "#3A1C67",
    fontWeight: 700,
    width: "155px",
    backgroundColor: "#fff",
  },
  chartContainer: {
    display: "flex",
    margin: "10px 0px",
    justifyContent: "space-between",
  },
  dateInputComp: {
    display: "flex",
    background: "white",
    alignItems: "center",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "6px",
    paddingBottom: "6px",
    gap: "10px",
  },
  greyBox: {
    paddingTop: "10px",
    backgroundColor: "#fff",
    borderRadius: "10px",
    height: "auto",
    width: "480px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  chartBox: {
    //height:'260px',
    padding: " 0px 0px",
    paddingLeft: "20px",
    paddingRight: "20px",
    backgroundColor: "#fff",
    marginRight: "20px",
    borderRadius: "10px",
  },
  ratingBox: {
    height: 190,
    width: 200,
    textAlign: "center",
    padding: "10px",
    backgroundColor: "#979CBA33",
    borderRadius: "10px",
    color: "#3A1C67",
    boxShadow: "0px 15px 10px -15px #111",
    margin: "10px 10px",
  },
  label: {
    fontSize: "14px",
    backgroundColor: "#fff",
    padding: 2,
    fontWeight: 700,
    margin: "10px 70px",
    border: "1px solid #3A1C67",
    borderRadius: 5,
  },
  dropdownBG: {
    display: 'flex',
    gap: '10px',
    background: 'white',
    color: 'rgb(58, 28, 103)',
    border: 'none',
    borderRadius: '5px',
    justifyContent: 'space-between'
  }
}));

const FinanceDashboard = ({
  filterData,
  filterDates,
  FinanceStatusDetails,
}) => {
  const statusDetailsId = FinanceStatusDetails.id;
  const [selected, setSelected] = useState(true);
  const [viewMore, setViewMore] = useState(false);
  const classes = useStyles({ selected });
  const [open, setOpen] = useState(false);
  const { userInfo } = useSelector((state) => state.login);
  const loginInfo = getCookie("callpal-user");
  const header = [
    "Bank Name",
    "Login Files",
    "Sanction Amount",
    "Disbursement Amount",
    "Reject Files",
  ];
  const loginIndSubType = loginInfo.userInfo.ind_sub_type
  const indSubType = constantConfig.INDUSTRY_SUB_TYPE.CREDIT_CARD;
 

  const [topCount, setTopCount] = useState();
  const [bankData, setBankData] = useState();
  const [loading, setLoading] = useState(false);
  const [companyName, setCompanyName] = useState("");

  // const [statusDetails, setStatusDetails] = useState({
  //   id: "",
  //   name: "",
  // });

  const [statusArr, setStatusArr] = useState([]);
  const [productLoader, setProductLoader] = useState(false);
  const [statusSearch, setStatusSearch] = useState("");
  const [productId, setProductId] = useState();

  const [toYearOptions, setToYearOptions] = useState([]);
  const [top3, setTop3] = useState([]);
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  // const [dates, setDates] = useState({
  //   from: moment().format("YYYY-MM-DD"),
  //   to: moment().format("YYYY-MM-DD"),
  // });
  const dates = filterDates;
  const currentYear = new Date().getFullYear();
  const numberOfYears = 5; // Adjust this value as needed

  const years = Array.from(
    { length: numberOfYears },
    (_, index) => currentYear - index
  );

  // State to manage the selected value
  const [selectedFromYear, setSelectedFromYear] = useState(
    currentYear.toString()
  );
  const [selectedToYear, setSelectedToYear] = useState(currentYear.toString());

  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: [
      {
        label: "Monthly Sales",
        data: [],
        borderColor: "rgba(75,192,192,1)",
        fill: false,
        borderWidth: 2,
        lineTension: 0.4,
        pointRadius: 0,
      },
    ],
  });
 

  const [filter, setFilter] = useState(filterData);
  let login = 0;
  let rejected = 0;
  let disburse = 0;
  let sanction = 0;
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState({
    datasets: [
      {
        data: [10, 20, 30, 40],
        backgroundColor: ["#3098FF", "#FFCA5F", "#B5EAD7", "#FF9AA2"],
        borderWidth: 5,
      },
    ],
    labels: ["Login", "Sanction", "Disbursement", "Rejected Files"],
  });

  const [boundData, setBoundData] = useState({
    datasets: [
      {
        data: [10, 20, 30],
        backgroundColor: ["#3098FF", "#B5EAD7", "#FF9AA2"],

        borderWidth: 5,
      },
    ],
    labels: ["Answered", "Incoming Personal", "Missed"],
  });

  useEffect(() => {
    getGraphParameters(selectedFromYear, selectedToYear);
    getTopEmployees();
    if (
      userInfo.hasOwnProperty("mobile_number") ||
      userInfo?.userInfo?.mobile_number
    ) {
      getDashboardInfo(statusDetailsId);
    }

    const getData = async () => {
      if (
        userInfo.hasOwnProperty("mobile_number") ||
        userInfo?.userInfo?.mobile_number
      ) {
        setLoader(true);
        Promise.all([
          // await getSactionData(),
          // await getRejectedData(),
          // await getDisburseData(),
          // await getLoginData(),
          // loginInfo.userInfo.fk_role_id == 2 && (await getTopEmployees()),
        ])
          .then(async (v) => {
            setData({
              ...data,
              datasets: [
                {
                  data: [login, sanction, disburse, rejected],
                  backgroundColor: ["#3098FF", "#FFCA5F", "#B5EAD7", "#FF9AA2"],

                  borderWidth: 5,
                },
              ],
            });
            setLoader(false);
          })
          .catch((e) => {
            setLoader(false);
          });
      }
    };
    getData();
   
  }, [userInfo, dates, statusDetailsId]);

  const getDashboardInfo = async (statusDetailsId) => {
    try {
      setLoading(true);
      const data = JSON.stringify({
        fromdate: dates.from,
        todate: dates.to,
        per_page_row: 10,
        page: 1,
        product_Id: statusDetailsId,
        industry_sub_type:loginInfo.userInfo.industry_sub_type,
      });
      const res = await DashboardApi.getDashboardInfo(data);
      if (res?.status) {
        setTopCount(res.topCount);
        setBankData(res.bankData);
        setCompanyName(res?.company?.company_name);
      }
    } catch (err) {
      setLoading(false);
      // console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const formatValue = (value) => {
    // Customize this function to format the value as needed
    if (value >= 1e7) {
      return (value / 1e7).toFixed(2) + " CR";
    } else if (value >= 1e5) {
      return (value / 1e5).toFixed(2) + " LPA";
    } else {
      return value.toFixed(2) + " N";
    }
  };

  // Example options with customization for the category scale
  const loptions = {
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          return formatValue(tooltipItem.value);
        },
      },
    },

    scales: {
      x: [
        {
          type: "category",
          labels: [
            "Jan",
            "Feb",
            "Mar",
            "April",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sept",
            "Oct",
            "Nov",
            "Dec",
          ],
          beginAtZero: true,
        },
      ],
      y: {
        type: "linear",
        display: true,
        position: "left",
        ticks: {
          callback: (value) => formatValue(value),
        },
      },
    },
    plugins: {
      datalabels: {
        display: false, // Hide data labels on the line
      },
    },
  };
  const handleChangeFromYear = (event) => {
    const fromYear = event.target.value;
    setSelectedFromYear(fromYear);
    // Call the API with the updated selected year
    const updatedToYearOptions = years.filter((year) => year >= fromYear);
    setToYearOptions(updatedToYearOptions);
    setSelectedToYear(fromYear);
    getGraphParameters(fromYear, fromYear);
  };

  const handleChangeToYear = (event) => {
    const toYear = event.target.value;
    setSelectedToYear(toYear);
    getGraphParameters(selectedFromYear, toYear);
  };

  const getGraphParameters = async (fromYear, toYear) => {
    try {
      setLoading(true);
      const data = JSON.stringify({
        fromyear: fromYear,
        toyear: toYear,
        // per_page_row: 10,
        // page: 1,
      });
      const res = await DashboardApi.getGraphData(data);
      if (res?.status) {
        const graphlabels = res.data.monthData;
        const datasets = res.data.graphsdata.map((graph) => {
          return {
            label: `${graph.title}`,
            data: graph.yearData.map((month) => month.amount),
            borderColor: graph.lineColor,
            fill: false,
            borderWidth: 2,
            lineTension: 0.4,
            pointRadius: 0,
          };
        });
        setGraphData({
          labels: graphlabels,
          datasets: datasets,
        });
      }
    } catch (err) {
      setLoading(false);
      // console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const getTopEmployees = async () => {
    try {
      const res = await DashboardApi.getTopThreeEmp();
      if (res.status) {
        setTop3(res.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      {!viewMore && (
        <>
          <div className={classes.boxContainer}>

            {loginIndSubType !== indSubType ? 
              <>
            {loader ? (
              <div className={classes.box}>
                <Skeleton variant="rect" />
              </div>
            ) : (
              <div
                className={classes.box}
                style={{
                  borderBottom: "10px solid #3098FF",
                  boxShadow: "0px 4px 4px 0px #00000040",
                }}
              >
                <div style={{ paddingBottom: "10px" }}>Total Login</div>
                <div>{topCount?.login}</div>
              </div>
            )}
            {loader ? (
              <div className={classes.box}>
                <Skeleton variant="rect" />
              </div>
            ) : (
              <div
                className={classes.box}
                style={{
                  borderBottom: "10px solid #FFCA5F",
                  boxShadow: "0px 4px 4px 0px #00000040",
                }}
              >
                <div style={{ paddingBottom: "10px" }}>Total Sanction</div>
                <div>
                  {topCount?.sanction > 0
                    ? "₹ " + topCount?.sanction
                    : topCount?.sanction}
                </div>
              </div>
            )}

            {loader ? (
              <div className={classes.box}>
                <Skeleton variant="rect" />
              </div>
            ) : (
              <div
                className={classes.box}
                style={{
                  borderBottom: "10px solid #B5EAD7",
                  boxShadow: "0px 4px 4px 0px #00000040",
                }}
              >
                <div style={{ paddingBottom: "10px" }}>Total Disbursement</div>
                <div>
                  {topCount?.disbursement > 0
                    ? "₹ " + topCount?.disbursement
                    : topCount?.disbursement}
                </div>
              </div>
            )}
            {loader ? (
              <div className={classes.box}>
                <Skeleton variant="rect" />
              </div>
            ) : (
              <div
                className={classes.box}
                style={{
                  borderBottom: "10px solid #FF9AA2",
                  boxShadow: "0px 4px 4px 0px #00000040",
                }}
              >
                <div style={{ paddingBottom: "10px" }}>
                  Total Rejected Files
                </div>
                <div>{topCount?.reject}</div>
              </div>
            )}
            </>
            : <> 
              <div
                className={classes.creditBox}
                style={{
                  borderBottom: "10px solid #ff8f00",
                  boxShadow: "0px 4px 4px 0px #00000040",
                }}
                >
                  <div style={{ paddingBottom: "10px" }}>Total QD</div>
                  <div>{topCount?.qd}</div>
                </div>
                <div
                className={classes.creditBox}
                style={{
                  borderBottom: "10px solid #3098FF",
                  boxShadow: "0px 4px 4px 0px #00000040",
                }}
                >
                  <div style={{ paddingBottom: "10px" }}>Total Login</div>
                  <div>{topCount?.login}</div>
                </div>

                <div
                className={classes.creditBox}
                style={{
                  borderBottom: "10px solid #22bb33",
                  boxShadow: "0px 4px 4px 0px #00000040",
                }}
                >
                  <div style={{ paddingBottom: "10px" }}>Total Approved</div>
                  <div>{topCount?.approved}</div>
                </div>

                <div
                className={classes.creditBox}
                style={{
                  borderBottom: "10px solid #8af2cd",
                  boxShadow: "0px 4px 4px 0px #00000040",
                }}
                >
                  <div style={{ paddingBottom: "10px" }}>Total KYC</div>
                  <div>{topCount?.kyc}</div>
                </div>

                <div
                className={classes.creditBox}
                style={{
                  borderBottom: "10px solid #fabf48 ",
                  boxShadow: "0px 4px 4px 0px #00000040",
                }}
                >
                  <div style={{ paddingBottom: "10px" }}>Total Dispatch</div>
                  <div>{topCount?.dispatched}</div>
                </div>

                <div
                className={classes.creditBox}
                style={{
                  borderBottom: "10px solid #f53b49",
                  boxShadow: "0px 4px 4px 0px #00000040",
                }}
                >
                  <div style={{ paddingBottom: "10px" }}>Total Reject</div>
                  <div>{topCount?.reject}</div>
                </div>
            
            </>

          }
          </div>
          <NoRecords rows={0} loading={loading} />

          <div className={classes.chartContainer}>
            <div className="row">
              <div className="col-6">
                <div className={classes.chartBox}>
                  <>
                    {/* options={loptions} */}

                    <div
                      style={{
                        display: "flex",
                        marginBottom: 50,
                        float: "right",
                      }}
                    >
                      <UncontrolledDropdown className="">
                        <div className="filters">
                          <DropdownToggle className={classes.dropdownBG}>
                            {selectedToYear}
                            <KeyboardArrowDown
                              style={{ fontSize: 19 }}
                            ></KeyboardArrowDown>
                          </DropdownToggle>
                        </div>

                        <DropdownMenu>
                          {toYearOptions.map((year) => (
                            <DropdownItem
                              value={year}
                              onClick={(e) => handleChangeToYear(e)}
                            >
                              {year}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        marginBottom: 50,
                        float: "right",
                        marginRight: "10px",
                      }}
                    >
                      <UncontrolledDropdown className="">
                        <div className="filters">
                          <DropdownToggle className={classes.dropdownBG}>
                            {selectedFromYear}
                            <KeyboardArrowDown
                              style={{ fontSize: 19 }}
                            ></KeyboardArrowDown>
                          </DropdownToggle>
                        </div>

                        <DropdownMenu>
                          {years.map((year) => (
                            <DropdownItem
                              value={year}
                              onClick={(e) => handleChangeFromYear(e)}
                            >
                              {year}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>

                    <Line data={graphData} options={loptions} />
                  </>
                </div>
              </div>
              {/* Out Bound */}

              <div className="col-6">
                <div className={classes.greyBox}>
                  <>
                    <div
                      style={{
                        fontSize: "16px",
                        color: "#3A1C67",
                        fontWeight: 700,
                      }}
                    >
                      Top 3 Employees Of the Month
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "20px 0px",
                      }}
                    >
                      {loader ? (
                        <Skeleton variant="rect" width={550} height={200} />
                      ) : top3 && top3.length > 0 ? (
                        top3.map((el, i) => (
                          <div
                            className={classes.ratingBox}
                            style={{ boxShadow: "0 4px 4px 0 #00000040" }}
                            key={i}
                          >
                            <div className={classes.label}>BY</div>
                            <Divider />
                            <div
                              style={{
                                fontSize: "14px",
                                fontWeight: 700,
                                margin: "10px 0px",
                              }}
                            >
                              {el.telecaller}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                fontSize: "10px",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>Disbursement Amount</div>
                              <div>{`₹ ${el.total_disbursement}`}</div>
                            </div>
                            <Box component="fieldset" borderColor="transparent">
                              <Rating
                                name="read-only"
                                max={3}
                                value={el.rating}
                                readOnly
                              />
                            </Box>
                            <div style={{ fontSize: "16px", fontWeight: 700 }}>
                              {el.heading}
                            </div>
                          </div>
                        ))
                      ) : (
                        <div>No records found </div>
                      )}
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <FileTable
        viewMore={viewMore}
        bankData={bankData}
        dashboardloading={loading}
        setViewMore={(f) => setViewMore(f)}
        dates={dates}
      />
    </div>
  );
};
export default FinanceDashboard;
