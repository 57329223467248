import { FormControlLabel, TextField, makeStyles } from "@material-ui/core"
import { useEffect, useState } from "react"
import { bankList } from "../../../Utils/constant"
import { OrangeSmallCheckbox, RadioButton } from "../../../Styles"

const useStyles = makeStyles({
    container:{
        padding:'10px 10px'
    },
    formControlLabel: {
        fontSize: 13,
        color: '#3A1C67',
   
    },
    singleContaner:{
        display:'flex',flexWrap:'wrap',width:500,maxHeight:420,overflowY:'scroll',
        paddingLeft:10,
        '&::-webkit-scrollbar': {
            width: '0.2em',
            display: 'block'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'darkgrey',
            outline: '1px solid slategrey',
            borderRadius: '5px'
        }
    },
    multipleContainer:{
        maxHeight:420,overflowY:'scroll',
        paddingLeft:10,
        '&::-webkit-scrollbar': {
            width: '0.2em',
            display: 'block'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'darkgrey',
            outline: '1px solid slategrey',
            borderRadius: '5px'
        }
    },
    root: {

        '& label.Mui-focused': {
            color: '#3A1C67',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#3A1C67',

            },
            '&:hover fieldset': {
                borderColor: '#3A1C67',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#3A1C67',
            },
        },
        width: '130px',
        marginBottom:5,    
        // [theme.breakpoints.down('xs')]: {
        //     width:'100%'
        //   },
    },
    multilineColor: {
        color: '#3A1C67',
        fontSize: 14
    },
})
const StatusView=({selectedVal,val})=>{
    const classes=useStyles()
    const [selectedBank,setSelectedBank]=useState('')
    const [list,setList]=useState([])
  
    return(
        <div className={classes.container}>
            <div className={classes.singleContaner}>
              
                {
                   val==='single' && bankList.map((el,i)=>{
                        return(
                            <div style={{width:150}}  key={i}>
                            <FormControlLabel
                           
                            classes={{ label: classes.formControlLabel }}
                            control={<RadioButton
                                checked={selectedBank === el}
                                onChange={(e)=>setSelectedBank(e.target.value)}
                                value={el}
                            />}
                            label={el}
                            labelPlacement="end"
                        />
                          </div>
                        )
                    })
                }
            
           
            
              
            
            </div>
            <div className={classes.multipleContainer}>
            {
                val==='multiple' && bankList.map((el,i)=>{
                    return(
                        <div key={i}>
                        <div style={{display:'flex', color: '#3A1C67',alignItems:'center',width:'100%'}}>
                         <div style={{width:'40%'}}>
                        <FormControlLabel
                        classes={{ label: classes.formControlLabel }}
                        control={<OrangeSmallCheckbox name="checkedG" />}
                        label={
                            el
                        }
                    />
                    </div>
                    <div style={{width:'30%'}}>
                    <select className="status-dropdown" placeholder="Select">
                            <option >Status</option>
                            <option>Interested</option>
                            <option>Log In</option>
                            <option>Sanction</option>
                            <option>Rejected</option>
                            <option>disbursement</option>
                        </select>
                    </div>
                    <div style={{width:'30%'}}>
                    <TextField
                                size="small"
                                placeholder="Amount"
                                inputProps={{
                                    className: classes.multilineColor,
                                    style: {
                                        height: "8px",
                                    },
                                }}
                                className={classes.root} id="outlined-basic" variant="outlined"

                            />
                    </div>
                    </div>
                    </div>
                    )
                })
            }
            </div>
        </div>
    )
}
export default StatusView