import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Tab,
  Tabs,
  TextField,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import EditIcon from "@material-ui/icons/Edit";
import UpdateIcon from "@material-ui/icons/Autorenew";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { MasterDataApi } from "../../../Services/MasterDataApi";
import { useDispatch, useSelector } from "react-redux";
import { setIsSubmitted } from "../../../Reducers/loginSlice";
import { CircularProgress, Container } from "@material-ui/core";
import { setIsAuthenticated, setUserInfo } from "../../../Reducers/loginSlice";

import { getCookie } from "../../../Utils/commonUtils";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "80px 30px 30px 30px",
    textAlign: "left",
  },
  heading: {
    color: "#3A1C67",
    fontSize: "18px",
    fontWeight: 700,
    textAlign: "left",
    marginBottom: 10,
    padding: "12px",
  },
  root: {
    minWidth: 275,
  },
  tab: {
    backgroundColor: "transparent",
    color: "#3f51b5",
    borderColor: "#3f51b5",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 10,
    textTransform: "none",
    marginRight: "1.2rem",
    padding: "6px 12px",
    "&:hover": {
      backgroundColor: "rgba(63, 81, 181, 0.1)",
    },
    "&.Mui-selected": {
      backgroundColor: "#3A1C67",
      color: "#fff",
    },
    "& .Mui-root": {
      padding: "0px",
    },
  },
  tabButton: {
    padding: "4px 8px", // Adjust the padding values as desired
  },
  tabContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: 0,
    "& .MuiTabs-indicator": {
      // Add this line
      display: "none", // Add this line
    }, // Add this line
  },
  form: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    marginTop: 20,
  },
  imageUpload: {
    border: "2px solid orange",
    marginRight: 20,
    width: "300px",
    height: "300px",
    borderRadius: 10,
  },
  inputFields: {
    //   flex: 1,
    //   display: 'grid',
    //   gridTemplateColumns: 'repeat(3, 1fr)',
    //   gap: 30,
    // -----------
    // '& .MuiTextField-root': {
    //   marginBottom: 20,
    //   '& .MuiOutlinedInput-root': {
    //     '& fieldset': {
    //       borderColor: 'orange',
    //     },
    //     '&:hover fieldset': {
    //       borderColor: 'orange',
    //     },
    //     '&.Mui-focused fieldset': {
    //       borderColor: 'orange',
    //     },
    //   },
    // },
  },

  inputField: {
    color: "orange",
    "& .MuiOutlinedInput-root": {
      borderRadius: 8, // Adjust the value to increase or decrease the roundness
      "& fieldset": {
        borderColor: "orange",
      },
      "&:hover fieldset": {
        borderColor: "orange",
      },
      "&.Mui-focused fieldset": {
        borderColor: "orange",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "11.5px 14px",
    },
  },
  uploadButton: {
    backgroundColor: "transparent",
    border: "2px solid orange",
    borderRadius: 10,
    color: "orange",
    textAlign: "center",
    padding: "10px 40px",
    boxShadow: "none",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(2),
    marginTop: theme.spacing(4), // Add some top margin
  },
  button: {
    display: "flex",
    alignItems: "center",
    borderRadius: 7,
    background: "#3A1C67",
    color: "white",
    padding: "2px 15px",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  // buttonsContainer :{
  //   float:'right',
  // },
  headingContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));
const MasterOverview = ({}) => {
  const classes = useStyles();
  const [imagePreview, setImagePreview] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const loginInfo=getCookie('callpal-user')



  useEffect(() => {
    GetCompanyInfo();
  }, []);

  const GetCompanyInfo = async () => {
    setLoading(true);
    try {
      const res = await MasterDataApi.GetCompanyInfoApi();

      if (res.status) {
        formik.setFieldValue("id", res.data.id ? res.data.id : "");
        formik.setFieldValue(
          "company_name",
          res.data.company_name ? res.data.company_name : ""
        );
        formik.setFieldValue(
          "address",
          res.data.address ? res.data.address : ""
        );
        formik.setFieldValue(
          "gst_number",
          res.data.gst_number ? res.data.gst_number : ""
        );
        formik.setFieldValue(
          "company_cin",
          res.data.company_cin ? res.data.company_cin : ""
        );
        formik.setFieldValue(
          "company_pan",
          res.data.company_pan ? res.data.company_pan : ""
        );
        formik.setFieldValue(
          "company_state",
          res.data.company_state ? res.data.company_state : ""
        );
        formik.setFieldValue(
          "company_city",
          res.data.company_city ? res.data.company_city : ""
        );
        formik.setFieldValue(
          "company_pincode",
          res.data.company_pincode ? res.data.company_pincode : ""
        );
      } else {
        formik.resetForm({
          values: {
            company_name: "",
            address: "",
            gst_number: "",
            company_cin: "",
            company_pan: "",
            company_state: "",
            company_city: "",
            company_pincode: "",
          },
        });
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    // Create a preview URL for the selected file
    const previewURL = URL.createObjectURL(file);
    setImagePreview(previewURL);
  };


  const validationSchema = Yup.object().shape({
    company_name: Yup.string().required("Company name is required"),
    address: Yup.string().required("Company address is required"),
    // gst_number: Yup.string().required("GST number is required"),
    // company_cin: Yup.string().required("Company CIN is required"),
    // company_pan: Yup.string().required("Company PAN is required"),
    // company_state: Yup.string().required("State is required"),
    // company_city: Yup.string().required("City code is required"),
    // company_pincode: Yup.string()
    //   .required("Pincode is required")
    //   .matches(/^\d{6}$/, "Pincode must be exactly 6 digits"),
  });

  const formik = useFormik({
    initialValues: {
      company_name: "",
      address: "",
      gst_number: "",
      company_cin: "",
      company_pan: "",
      company_state: "",
      company_city: "",
      company_pincode: "",
    },
    validationSchema: validationSchema,

    onSubmit: async (values, { resetForm }) => {
      

      setLoading(true);

      try {
        const data = await MasterDataApi.UpdateComponyInfoApi(values);
        if (data.status) {
          const updatedLoginInfo = {
            ...loginInfo,
            userInfo: {
              ...loginInfo.userInfo,
              company_name: values.company_name // Update the company name here
            }
          };
          dispatch(setUserInfo(updatedLoginInfo))
          dispatch(
            setIsSubmitted({
              open: true,
              severity: "success",
              msg: data.message,
            })
          );

          // resetForm();
          setLoading(false);
        } else {
          if (data.hasOwnProperty("errors")) {
            formik.setFieldError("company_name", data.errors.company_name[0]);
            formik.setFieldError("address", data.errors.address[0]);
            formik.setFieldError("gst_number", data.errors.gst_number[0]);
            formik.setFieldError("company_cin", data.errors.company_cin[0]);
            formik.setFieldError("company_pan", data.errors.company_pan[0]);
            formik.setFieldError("company_state", data.errors.company_state[0]);
            formik.setFieldError("company_city", data.errors.company_city[0]);
            formik.setFieldError(
              "company_pincode",
              data.errors.company_pincode[0]
            );
          } else {
            dispatch(
              setIsSubmitted({
                open: true,
                severity: "error",
                msg: data.message,
              })
            );
          }
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        console.error(e);
      }
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className={classes.form}>
          {/* <div >
        <div className={classes.imageUpload}>
            <Typography style={{ color: "orange" }} variant="body1"></Typography>
            {imagePreview && (
            <img
                src={imagePreview}
                alt="Preview"
                style={{
                width: '100%', // Adjust this value as needed
                height: '100%', // Adjust this value as needed
                objectFit: 'contain', // Maintain aspect ratio
                }}
            />
            )}
        </div>
        <input
            accept="image/*"
            style={{ display: 'none' }}
            id="contained-button-file"
            type="file"
            onChange={handleFileChange}
            name='company_logo'
        />
        <label htmlFor="contained-button-file">
            <Button
            className={classes.uploadButton}
            variant="contained"
            startIcon={<CloudUploadIcon />}
            color="primary"
            component="span"
            onClick={handleUpload}
            style={{ marginTop: 10, padding: '10px 80px' }}
            >
            Upload Photo
            </Button>
        </label>
        </div> */}
          <div className={classes.inputFields}>
            {/* <TextField
            className={classes.inputField}
            placeholder="Enter Registered Company Name"
            variant="outlined"
            fullWidth
            label={<span style={{ color: "orange", fontSize: "0.8rem" }}>Registered Company Name</span>}
        /> */}

            <div className="row">
              <div className="col-6" style={{ marginBottom: "18px" }}>
                <TextField
                  className={classes.inputField}
                  placeholder="Enter Company Name "
                  variant="outlined"
                  fullWidth
                  label={
                    <span style={{ color: "orange", fontSize: "0.8rem" }}>
                      Company Name{" "}
                    </span>
                  }
                  name="company_name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.company_name}
                />

                {formik.touched.company_name && formik.errors.company_name && (
                  <p className="text-danger formik-masterdata-msg text-small text-left formik-msg ">
                    {formik.errors.company_name}
                  </p>
                )}
              </div>
              <div className="col-6">
                <TextField
                  className={classes.inputField}
                  placeholder="Enter Company Address"
                  variant="outlined"
                  fullWidth
                  label={
                    <span style={{ color: "orange", fontSize: "0.8rem" }}>
                      Company Address
                    </span>
                  }
                  name="address"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address}
                />
                {formik.touched.address && formik.errors.address && (
                  <p className="text-danger formik-masterdata-msg text-small text-left formik-msg ">
                    {formik.errors.address}
                  </p>
                )}
              </div>

              <div className="col-6" style={{ marginBottom: "18px" }}>
                <TextField
                  className={classes.inputField}
                  placeholder="Enter State"
                  variant="outlined"
                  Name
                  fullWidth
                  label={
                    <span style={{ color: "orange", fontSize: "0.8rem" }}>
                      State
                    </span>
                  }
                  name="company_state"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.company_state}
                />
                {formik.touched.company_state &&
                  formik.errors.company_state && (
                    <p className="text-danger formik-masterdata-msg text-small text-left formik-msg ">
                      {formik.errors.company_state}
                    </p>
                  )}
              </div>

              <div className="col-6" style={{ marginBottom: "18px" }}>
                <TextField
                  className={classes.inputField}
                  placeholder="Enter City Name"
                  variant="outlined"
                  fullWidth
                  label={
                    <span style={{ color: "orange", fontSize: "0.8rem" }}>
                      City
                    </span>
                  }
                  name="company_city"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.company_city}
                />
                {formik.touched.company_city && formik.errors.company_city && (
                  <p className="text-danger formik-masterdata-msg text-small text-left formik-msg ">
                    {formik.errors.company_city}
                  </p>
                )}
              </div>

              <div className="col-6" style={{ marginBottom: "18px" }}>
                <TextField
                  className={classes.inputField}
                  placeholder="Enter Pincode"
                  variant="outlined"
                  fullWidth
                  label={
                    <span style={{ color: "orange", fontSize: "0.8rem" }}>
                      Pincode
                    </span>
                  }
                  name="company_pincode"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.company_pincode}
                />
                {formik.touched.company_pincode &&
                  formik.errors.company_pincode && (
                    <p className="text-danger formik-masterdata-msg text-small text-left formik-msg ">
                      {formik.errors.company_pincode}
                    </p>
                  )}
              </div>
              <div className="col-6" style={{ marginBottom: "18px" }}>
                <TextField
                  className={classes.inputField}
                  placeholder="Enter GST Number"
                  variant="outlined"
                  fullWidth
                  label={
                    <span style={{ color: "orange", fontSize: "0.8rem" }}>
                      GST Number
                    </span>
                  }
                  name="gst_number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.gst_number}
                />
                {formik.touched.gst_number && formik.errors.gst_number && (
                  <p className="text-danger formik-masterdata-msg text-small text-left formik-msg ">
                    {formik.errors.gst_number}
                  </p>
                )}
              </div>

              <div className="col-6">
                <TextField
                  className={classes.inputField}
                  placeholder="Enter Company CIN"
                  variant="outlined"
                  fullWidth
                  label={
                    <span style={{ color: "orange", fontSize: "0.8rem" }}>
                      Company CIN
                    </span>
                  }
                  name="company_cin"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.company_cin}
                />
                {formik.touched.company_cin && formik.errors.company_cin && (
                  <p className="text-danger formik-masterdata-msg text-small text-left formik-msg ">
                    {formik.errors.company_cin}
                  </p>
                )}
              </div>
              <div className="col-6" style={{ marginBottom: "18px" }}>
                <TextField
                  className={classes.inputField}
                  placeholder="Enter Company PAN"
                  variant="outlined"
                  fullWidth
                  label={
                    <span style={{ color: "orange", fontSize: "0.8rem" }}>
                      Company PAN
                    </span>
                  }
                  name="company_pan"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.company_pan}
                />
                {formik.touched.company_pan && formik.errors.company_pan && (
                  <p className="text-danger formik-masterdata-msg text-small text-left formik-msg ">
                    {formik.errors.company_pan}
                  </p>
                )}
              </div>
             

             

              {loading && (
                <div
                  className="col-8"
                  style={{ textAlign: "center", position: "absolute" }}
                >
                  <CircularProgress size={50} />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            startIcon={<UpdateIcon className={classes.icon} />}
            type="submit"
          >
            Update
          </Button>
        </div>
      </form>
    </>
  );
};

export default MasterOverview;
