import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { KeyboardArrowDown } from "@material-ui/icons";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import moment from "moment";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch, useSelector } from "react-redux";
import "../../../CustomCSS/CallLogs.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ReassignApi } from "../../../Services/ReassignApi";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  TablePagination,
} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useEffect } from "react";
import { useState } from "react";
import { getCookie } from "../../../Utils/commonUtils";
import NoRecords from "../Other-components/NoRecords";
import incoming from "../../../Images/Incoming.svg";
import outgoing from "../../../Images/Out going.svg";
import missedCall from "../../../Images/Missed Call.svg";
import { StyledTableCell, StyledTableRow } from "../../../Styles";
import { DateRangePicker } from "react-date-range";
import DatePicker from "react-date-picker";
import { Calendar } from "react-date-range";
import { DateRange, Filter } from "@material-ui/icons";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
  UncontrolledDropdown,
} from "reactstrap";

const useStyles = makeStyles({
  mainContainer: {
    padding: "80px 30px 30px 30px",
  },
  table: {
    minWidth: 700,
  },
  button: {
    backgroundColor: "#3A1C67",
    color: "#fff",
    marginLeft: "10px",
    borderRadius: "5px",
    fontSize: "12px",
    padding: "5px 20px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#7916BD",
    },
  },
  tableContainer: {
    margin: "20px 0px",
    maxHeight: 450,
    width: "100%",
    "&::-webkit-scrollbar": {
      width: "0.5em",
      display: "block",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "darkgrey",
      outline: "1px solid slategrey",
      borderRadius: "5px",
    },
  },
  filterHolder: {
    display: "flex",
    gap: "20px",
    justifyContent: "space-between",
  },
  dateInputComp: {
    display: "flex",
    background: "white",
    alignItems: "center",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "6px",
    paddingBottom: "6px",
    gap: "10px",
  },
  filterHolderComp: {
    display: "flex",
    alignItems: "center",
  },
  filterDropDown: {
    outline: "none !important",
    width: "200px",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "7px",
    paddingBottom: "7px",
    border: "none",
    color: "#3A1C67",
    fontSize: "medium",
  },
});

export default function ReassignHistory() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(1);
  const [updated, setUpdated] = useState(true);
  const loginInfo = getCookie("callpal-user");
  const { userInfo } = useSelector((state) => state.login);
  const [count, setCount] = useState(0);
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "months").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [filter, setFilter] = useState({
    overall: true,
    today: false,
    range: false,
  });
  const [dates, setDates] = useState({
    fromdate: startDate,
    todate: endDate,
    per_page_row: 10,
    page: 1,
  });

  const [isSelect, setIsSelect] = useState(false);
  const [reportArr, setReportArr] = useState([]);
  const [reportLoader, setReportLoader] = useState(false);
  const [reportSearch, setReportSearch] = useState("");
  const [rows, setRows] = useState([]);

  const handleChangePage = (event, newPage) => {
    setUpdated(true);
    setPage(newPage);
    let num = 0;
    if (newPage > page) {
      num = offset + 1;
    } else {
      num = offset - 1;
    }
    setOffset(num);
  };

  const handleChangeRowsPerPage = (event) => {
    setUpdated(true);
    setRowsPerPage(event.target.value);
    setPage(0);
    setOffset(0);
  };

  const heading = [
    "Reassign Customer",
    "Filters Applied",
    "By",
    // "Reassigned Status",
    "Created On",
    "Created At",
  ];

  useEffect(() => {
    if (
      ((userInfo.hasOwnProperty("mobile_number") ||
        userInfo?.userInfo?.mobile_number) &&
        moment(endDate).diff(moment(startDate), "days") >= 0) ||
      (startDate == "" && endDate == "")
    ) {
      getReassignHistory();
      setUpdated(false);
    }

    if (moment(endDate).diff(moment(startDate), "days") < 0) {
      setEndDate(startDate);
    }
  }, [userInfo, page, rowsPerPage, startDate, endDate]);

  const getReassignHistory = async () => {
    try {
      setLoading(true);
      const data = JSON.stringify({
        fromdate: startDate,
        todate: endDate,
        per_page_row: rowsPerPage,
        page: offset,
      });
      const res = await ReassignApi.getReassignHistoryList(data);
      if (res.status) {
        setCount(res.total_count);
        setRows([...res.data]);
      }
    } catch (err) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.filterHolder}>
        <div
          style={{
            color: "#3A1C67",
            textAlign: "center",
            fontFamily: "Open-sans",
            width: "fit-content",
          }}
          className={classes.filterHolderComp}
        >
          <h4 style={{ fontWeight: "600" }}>
            {" "}
            {count ? count : ""} Reassign History
          </h4>
        </div>
        <div style={{ display: "flex" }}>
          <div className={classes.dateInputComp}>
            <div className={classes.dateInputComp}>
              <div>
                <DateRange className="dateIcon" color="#3A1C67"></DateRange>
              </div>
              <div style={{ display: "flex", gap: "5px" }}>
                {" "}
                <DatePicker
                  style={{
                    display: "none",
                    color: "#3A1C67",
                    cursor: "pointer",
                  }}
                  calendarIcon={null}
                  clearIcon={null}
                  className="datePickerInput"
                  value={startDate}
                  onChange={(val) => {
                    const d = moment(val).format("YYYY-MM-DD");
                    setStartDate(d);
                  }}
                ></DatePicker>
                -
                <DatePicker
                  calendarIcon={null}
                  clearIcon={null}
                  style={{ display: "none", color: "#3A1C67" }}
                  value={endDate}
                  onChange={(val) => {
                    const d = moment(val).format("YYYY-MM-DD");
                    setEndDate(d);
                  }}
                  minDate={new Date(startDate)}
                  className="datePickerInput"
                ></DatePicker>
              </div>
            </div>
          </div>
        </div>
      </div>

      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table
          stickyHeader
          className={classes.table}
          aria-label="customized table"
          size="small"
        >
          <TableHead>
            <TableRow>
              {heading.map((el, i) => (
                <StyledTableCell key={i} align="center">
                  {el}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map((row) => (
              <StyledTableRow>
                <StyledTableCell align="center">{row.name}</StyledTableCell>
                <StyledTableCell align="center">{row.status}</StyledTableCell>
                <StyledTableCell align="center">
                  {row.telecaller}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.created_on}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.created_at}
                </StyledTableCell>
              </StyledTableRow>
            ))}
            <NoRecords rows={rows} loading={loading} />
          </TableBody>
        </Table>
      </TableContainer>

      <div>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          backIconButtonProps={{
            "aria-label": "Previous Page",
            style: {
              color: "#fff",
              backgroundColor: "#3A1C67",
              padding: 3,
              borderRadius: "5px",
              marginRight: "5px",
            },
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
            style: {
              color: "#fff",
              backgroundColor: "#3A1C67",
              padding: "3px",
              borderRadius: "5px",
            },
          }}
        />
      </div>
    </div>
  );
}
