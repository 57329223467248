// import React, { useRef, useState } from 'react';
// // Import Swiper React components
// import { Swiper, SwiperSlide } from 'swiper/react';

// // import required modules
// import { Pagination } from 'swiper/modules';

// // Import Swiper styles
// import 'swiper/css';
// import 'swiper/css/pagination';




import { makeStyles } from "@material-ui/core";
import Header from "../Header";
import Footer from "../Footer";
import ProjectSubscription from "../../Common/Product/ProjectSubscription";
import TrustedPartners from "../../Common/Product/TrustedPartners";
import { Container } from "@material-ui/core";
import CardHeader from "../../Common/Product/CardHeader";
import Caller from "../../Images/Caller.png";
import callManagement1 from "../../Images/callManagement1.png";
import dot from "../../Images/dot.png";
import LiveStatus from "../../Images/LiveStatus.png";
import FollowupNotifications from "../../Images/FollowupNotifications.png";
import InteractonHistory from "../../Images/InteractonHistory.png";
import RealTimePerformance from "../../Images/RealTimePerformance.png";
import Mobile from "../../Images/Mobile.png";
import Card from "../../Common/Product/Card";
import FlexCardLayout from "../../Common/Product/FlexCardLayout";
import CardWithoutImg from "../../Common/Product/CardWithoutImg";
import Home4 from "../Home/Home4";
const useStyles = makeStyles((theme) => ({
  redTheme: {
    color: "#E41C61",
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));
const CallManagement = () => {
  const classes = useStyles();
  return (
    <div className="pos_crm_page">
      <Header />
      <ProjectSubscription
        pageTitle="Call Management"
        pageDetail="Our feature-rich, cost-effective CRM application offers a fresh perspective on managing outbound sales and telecalling operations."
        pageDetailTheme={classes.redTheme}
      />
      <TrustedPartners />
      <div>
        <Container maxWidth="lg" style={{ padding: "20px 0px" }}>
          <CardHeader
            pageTitle="Apart"
            subTitle="A high-quality CRM application designed for Telesales, Telecalling, and Call Centers, providing a wide range of unique features."
            text1="What Sets Callpal"
            text2="?"
          />

          <div
            style={{
              padding: "30px 10px 0px 10px",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <Card
              img={Caller}
              title="Enhanced Caller ID"
              background="#FAEEE2"
              subTitle="An overlay window, with brief details about the customer before the conversation starts. Details like last integration disposition, notes, giving the caller the power of information before the start of conversation."
            />
            <Card
              img={LiveStatus}
              background="rgba(76, 96, 142, 0.1)"
              title="Real-time Team Status"
              subTitle="Imagine the level of control it offers to managers, especially for remotely working teams. Real-time status indicators such as on call, idle, on break, login/logout status are just a fingertip away."
            />
            <Card
              img={RealTimePerformance}
              background="rgba(221, 143, 121, 0.1)"
              title="Performance Analytics in Real Time"
              subTitle="Access real-time performance analytics for your teams. Receive easily understandable
input/output KPI reporting in real time to enhance calling process efficiency."
            />
            <Card
              img={FollowupNotifications}
              background="rgba(254, 103, 122, 0.1)"
              title="Callback Notifications"
              subTitle="Stay on top of every potential case with Callpal. We ensure no follow-up is missed, keeping your team informed and efficient."
            />
            <Card
              img={InteractonHistory}
              background="rgba(0, 214, 188, 0.1)"
              title="Overview of Interaction History"
              subTitle="Easily review customer interaction history, including calls, emails, and WhatsApp messages, presented in a convenient timeline."
            />

            

            {/* <Swiper
              slidesPerView={4}
              spaceBetween={30}
              centeredSlides={true}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              <SwiperSlide>Slide 1</SwiperSlide>
              <SwiperSlide>Slide 2</SwiperSlide>
              <SwiperSlide>Slide 3</SwiperSlide>
              <SwiperSlide>Slide 4</SwiperSlide>
              <SwiperSlide>Slide 5</SwiperSlide>
              <SwiperSlide>Slide 6</SwiperSlide>
              <SwiperSlide>Slide 7</SwiperSlide>
              <SwiperSlide>Slide 8</SwiperSlide>
              <SwiperSlide>Slide 9</SwiperSlide>
            </Swiper> */}
          </div>
          <CardHeader
            pageTitle=" Next-Generation Platform"
            subTitle="Transform Your Business with Our Affordable, High-Performance CRM - Build your brand and grow your business with Callpal."
            text1="Boost Your Outbound Sales with Our "
          />

          <FlexCardLayout
            img={Mobile}
            title="Are you on the hunt for a reliable call-center CRM, cutting-edge call tracking and recording software, or an efficient GSM sim-based call management CRM app, whether you're operating in India or anywhere around the globe? Look no further than Callpal. Our call management CRM not only simplifies the process of capturing essential customer information during calls but also ensures that all your calls are recorded in real-time, allowing for streamlined management reporting. What makes Callpal even more unique is its seamless integration with our field and sales CRM, offering a holistic and interconnected process experience that is unparalleled. Trust Callpal for all your CRM requirements and experience a new level of efficiency and productivity."
          />
          <CardHeader
            pageTitle="Sales Calls To The Next Level"
            subTitle="Gone are the days of complicated hardware and costly installations. Callpal simplifies your call operations, allowing you to optimize your sales calling team with a user-friendly, data-driven mobile app. You no longer need to invest heavily in bulky equipment. Instead, download the Callpal app from the Google Play Store, and you'll have a powerful tool at your fingertips to streamline your call management processes efficiently. Experience the freedom of mobility and cost-effective call management solutions with Callpal."
            text1="Take Your"
            text2="With our Data-Driven Mobile App"
          />

          <div
            style={{
              padding: "0px 10px 0px 10px",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <CardWithoutImg
              title="SIM cards are used as a mode of communication"
              background="rgba(250, 238, 226, 1)"
              textColor="#3A1C67"
            />
            <CardWithoutImg
              title="KPI reporting for output in real time"
              background="rgba(76, 96, 142, 0.1)"
              textColor="#D21755"
            />
            <CardWithoutImg
              title="Option for autodialing and clicking to dial"
              background="rgba(221, 143, 121, 0.1)"
              textColor="#5974C1"
            />
            <CardWithoutImg
              title="SIM Lock for increased data protection."
              background="rgba(254, 103, 122, 0.1)"
              textColor="#00989D"
            />
            <CardWithoutImg
              title="Integrate the lead source."
              background="rgba(0, 214, 188, 0.1)"
              textColor="#3A1C67"
            />
            <CardWithoutImg
              title="Uploader of documents."
              background="rgba(58, 47, 46, 0.2)"
              textColor="#EB5EB0"
            />
          </div>

          <Home4 img={dot} />
          <img
            loading="eager"
            src={callManagement1}
            alt=""
            width={"80%"}
            height={"auto"}
          />
        </Container>
      </div>
      <Footer />
    </div>
  );
};
export default CallManagement;
