import React, { useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputBase,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import SearchIcon from "@material-ui/icons/Search";
import { useState } from "react";
import { OrangeCheckbox, OrangeSmallCheckbox } from "../../../Styles";
import { getCookie } from "../../../Utils/commonUtils";
import { CommonApi } from "../../../Services/CommonApi";


import {
  addUserApi,
  isValidEmail,
  reportingMasterApi,
  roleMasterApi,
} from "../../../Utils/commonUtils";
import { useFormik } from "formik";
import * as Yup from "yup";
import { setIsSubmitted } from "../../../Reducers/loginSlice";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "80px 30px 30px 30px",
    textAlign: "left",
  },
  topCOntainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  heading: {
    color: "#3A1C67",
    fontSize: "18px",
    fontWeight: 700,
    textAlign: "left",
    // marginLeft: 20,
    marginBottom: 10,
  },
  button: {
    backgroundColor: "#3A1C67",
    color: "#fff",
    textTransform: "none",
    height: "34px",
    borderRadius: "10px",
    fontSize: "14px",
    marginLeft: 20,
    marginTop: 20,
    "&:hover": {
      backgroundColor: "#3A1C67",
    },
  },
  iconButton: {
    backgroundColor: "#3A1C67",
    color: "#fff",
    textTransform: "none",
    height: "34px",
    borderRadius: "0px",
    fontSize: "10px",

    marginTop: 20,
    "&:hover": {
      backgroundColor: "#3A1C67",
    },
  },
  saveButton: {
    backgroundColor: "#FA9510",
    color: "#fff",
    textTransform: "none",
    width: 100,
    height: "34px",
    borderRadius: "10px",
    fontSize: "14px",
    marginLeft: 40,
    marginTop: 20,
    "&:hover": {
      backgroundColor: "#FA7A21",
    },
  },

  whiteBox: {
    padding: 20,
    margin: 20,
    borderRadius: 10,
    backgroundColor: "#fff",
    color: "#3A1C67",
    textAlign: "left",
  },
  steps: {
    color: "#555555",
    fontSize: 13,
    // padding: '0px 20px'
  },
  allocated: {
    fontSize: 14,
    fontWeight: 700,
    marginRight: 20,
  },
  search: {
    marginTop: 7,
    position: "relative",
    borderRadius: "10px",
    padding: "0px 2px 0px 8px",
    border: "1px solid #848891",
    fontSize: "5px",
    width: "280px",
  },
  inputRoot: {
    color: "#848891",
    width: "240px",
    fontSize: 13,
  },
  searchIcon: {
    //padding: theme.spacing(0, 2),
    right: 2,
    height: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#848891",
    cursor: "pointer",
  },
}));

const AutoDialConfig = () => {
  const classes = useStyles();
  const [state, setState] = useState({
    checkedB: false,
  });

  const [statenew, setStatenew] = useState([]);
  const [checked, setChecked] = useState({
    adminA: false,
    adminB: false,
    adminC: false,
  });
  const [sec, setSec] = useState(0);
  const [reportLoader, setReportLoader] = useState(false);
  const loginInfo = getCookie("callpal-user");
  const [reportArr, setReportArr] = useState([]);
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');

   const handleChange = (event) => {
    setState({ ...state,  [event.target.name]: event.target.checked });
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter the reportArr based on the search query
  const filteredReportArr = reportArr.filter((report) =>
    report.reporting_to.toLowerCase().includes(searchQuery.toLowerCase())
  );


  // const handleChange = (event) => {
  //   setState({ ...state, [event.target.name]: event.target.checked });
  //   setStatenew({ ...state, 'user_id':event.target.checked });
  // };
  const [selectedValues, setSelectedValues] = useState([]);

  const handleChangeUser = (value) => {
    // Check if the value is already in the array
    const intValue = parseInt(value);

    const isSelected = selectedValues.includes(intValue);
    // If selected, remove from the array; if not selected, add to the array
    const updatedValues = isSelected
      ? selectedValues.filter((selected) => selected !== intValue)
      : [...selectedValues, intValue];
    // Update the state with the new array
    setSelectedValues(updatedValues);
  };


  const handleChange2 = (event) => {
    setChecked({ ...checked, [event.target.name]: event.target.checked });
  };
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getReportingData();
  },[]);
 
  const getReportingData = async () => {
    setReportLoader(true);
    const data = await CommonApi.getCompanyTeamLead(loginInfo,true);
    setReportLoader(false);
    if (!data) {
      setReportArr([]);
    } else {
      const reportingArray = Object.entries(data.data).map(
        ([id, reporting_to]) => ({ id, reporting_to })
      );
      setReportArr(reportingArray);
    }
  };


  // const validationSchema = Yup.object({
  //   auto_dialer_timing: Yup.number().min(1, 'The time elapsed between starting the next call must be greater than 0.'),
    
  // });
  

  const formik = useFormik({
    initialValues: {
      auto_dialer_timing: 0,
      flag: state.checkedB,
      //user_id:selectedValues
    },
   // validationSchema: validationSchema,
    onSubmit: async (values, {resetForm}) => {
      if(values.auto_dialer_timing <=0) {
        dispatch(
          setIsSubmitted({
            open: true,
            severity: "error",
            msg: 'delay between calls must be greater than 0.',
          })
        );
        return false;
      }

      if(!state.checkedB && selectedValues.length  == 0 ) {
        dispatch(
          setIsSubmitted({
            open: true,
            severity: "error",
            msg: 'Please ensure that at least one telecaller is selected.',
          })
        );
        return false;
      }
      const obj = {
          auto_dialer_timing: values.auto_dialer_timing,
          flag: state.checkedB ? 'yes' : 'no',
          user_id: !state.checkedB ? selectedValues : []
      };
      
    
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/auto-dial-setting-store`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
              Authorization: `Bearer ${loginInfo?.token}`,
            },
            body: JSON.stringify(obj),
          }
        );
        const data = await response.json();
        if (data.status) {
          dispatch(
            setIsSubmitted({
              open: true,
              severity: "success",
              msg: data.message,
            })
          );
          setLoading(false);
        } else {
          dispatch(
            setIsSubmitted({
              open: true,
              severity: "error",
              msg: data.message,
            })
          );
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        console.error(e);
      }
    },
  });







  return (

    <div className={classes.mainContainer}>
       <form onSubmit={formik.handleSubmit}>

            <div className="zero_margin">
              <div className={classes.heading}>Auto dial Configuration</div>
              <div className={classes.whiteBox} id="zero_margin">
            


                <div className={classes.heading}>settings</div>
                <div>
                  <div className={classes.steps}>
                    Delay Between Calls (In Seconds)
                  </div>
                  <div className={classes.steps}>
                    Time Elapsed Between Starting The Next Call After The Current Gets
                    Over
                  </div>
                </div>

                <div style={{ display: "flex" }}>
                <IconButton
                aria-label="delete"
                className={classes.iconButton}
                style={{ borderBottomLeftRadius: 10, borderTopLeftRadius: 10 }}
                onClick={() => formik.values.auto_dialer_timing > 0 && formik.setFieldValue('auto_dialer_timing', formik.values.auto_dialer_timing - 1)}
              >
                <RemoveIcon />
              </IconButton>
                  <Box
                    style={{
                      backgroundColor: "#3A1C67",
                      marginTop: 20,
                      padding: 5,
                      height: 34,
                      color: "#fff",
                      fontSize: 16,
                    }}
                  >
                    {/* {sec} */}
                    {formik.values.auto_dialer_timing}
                  </Box>
                  <IconButton
                    aria-label="delete"
                    className={classes.iconButton}
                    style={{ borderTopRightRadius: 10, borderBottomRightRadius: 10 }}
                    onClick={() =>formik.setFieldValue('auto_dialer_timing', formik.values.auto_dialer_timing + 1)}
                  >
                    <AddIcon />
                  </IconButton>
                </div>
                <div className="mt-2">
                {formik.touched.auto_dialer_timing && formik.errors.auto_dialer_timing ? (
                  <p className="text-danger formik-addUser-msg text-small text-left formik-msg ">
                    {formik.errors.auto_dialer_timing}
                  </p>
                ) : null}
                </div>
               
              </div>
              <div className={classes.whiteBox} id="zero_margin">
                <div style={{ paddingTop: 10 }}>
                  <FormControlLabel
                    control={
                      <OrangeCheckbox
                        checked={state.checkedB}
                        onChange={handleChange}
                        name="checkedB"
                      />
                    }
                    label={
                      <div className={classes.allocated}>
                        Set As Mandatory For All
                      </div>
                    }
                  />

                  <div style={{ color: "#555555", fontSize: 12, marginLeft: 30 }}>
                  De-select The Option To Chose The Selected Users.
                  </div>
                </div>
                {!state.checkedB && (
                  <div style={{ margin: "30px" }}>
                    <div className={classes.allocated}>
                      Select Mandatory Users{" "}
                      <span style={{ color: "#FA9510" }}>{selectedValues.length > 0 ? `(${selectedValues.length} users selected)` : ""}</span>
                    </div>
                    <div className={classes.search}>
                      <div
                        className={classes.searchIcon}
                      >
                        <SearchIcon fontSize={"small"} />
                      </div>
                      <InputBase
                        placeholder="Search…"
                        classes={{
                          root: classes.inputRoot,
                          input: classes.inputInput,
                        }}
                        inputProps={{ "aria-label": "search" }}
                        onChange={handleSearch}
                      />
                    </div>



                    


                    {filteredReportArr.length > 0 && !reportLoader && (
                        <div style={{ paddingTop: 10 }}>
                          {filteredReportArr.map((report, index) => (
                            <div key={index}>
                              <FormControlLabel
                                control={
                                  <OrangeSmallCheckbox
                                    checked={checked[report.reporting_to]}  // Assuming each report has a 'reporting_to' property
                                    onChange={(e) => handleChangeUser(e.target.value)}
                                    name={report.reporting_to}
                                    value={report.id}
                                  />
                                }
                                label={
                                  <div
                                    style={{
                                      color: checked[report.reporting_to] ? '#F86E20' : '#848891',
                                      fontWeight: 600,
                                      fontSize: 14,
                                    }}
                                  >
                                    {report.reporting_to}
                                  </div>
                                }
                              />
                            </div>
                          ))}
                        </div>
                      )}
                

                  </div>
                )}
              </div>
            </div>

            <div className="filter_boxes">
              <Button  type="submit" className={classes.saveButton}>Save</Button>
            </div>
      </form>
    </div>
  );
};
export default AutoDialConfig;
