import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import {
  CircularProgress,
  InputAdornment,
  TextField,
  FormControlLabel,
  Checkbox,
  colors,
} from "@material-ui/core";
import { OrangeCheckbox } from "../../../Styles";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { ArrowDropDown } from "@material-ui/icons";
import { useState } from "react";
// import {
//   addUserApi,
//   isValidEmail,
//   reportingMasterApi,
//   roleMasterApi,
// } from "../../../Utils/commonUtils";
import { useDispatch, useSelector } from "react-redux";
import { setIsSubmitted } from "../../../Reducers/loginSlice";
import { getCookie } from "../../../Utils/commonUtils";

import { useFormik } from "formik";
import * as Yup from "yup";
import { CommonApi } from "../../../Services/CommonApi";
import { TeamApi } from "../../../Services/TeamApi";
import viewIcon from "../../../Images/eye.svg";
import eyeCross from "../../../Images/eye-cross.svg";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  header: {
    height: "70px",
    color: "#fff",
    fontSize: "17px",
    backgroundColor: "#3A1C67",
    fontWeight: 600,
    padding: 20,
    textAlign: "left",
  },
  root: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& label.Mui-focused": {
      color: "#F86E20",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#F86E20",
      },
      "&:hover fieldset": {
        borderColor: "#F86E20",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#F86E20",
      },
    },
    margin: "15px 20px",
    width: "290px",
    // [theme.breakpoints.down('xs')]: {
    //     width:'100%'
    //   },
  },
  multilineColor: {
    color: "#F86E20",
  },
  formRow: {
    display: "flex",
  },
  buttonContainer: {
    display: "flex",
    margin: "20px 90px",
    justifyContent: "space-between",
  },
  saveButton: {
    backgroundColor: "#FA9510",
    color: "#fff",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#F76A1A",
    },
    width: "180px",
  },
  cancelButton: {
    backgroundColor: "#fff",
    border: "1px solid #848891",
    color: "#848891",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#E5E3E1",
    },
    width: "180px",
  },
  formContainer: {
    padding: "30px 20px",
  },
  dropdownItem: {
    maxHeight: 150,
    overflowY: "scroll",
  },
});

export default function AddUserPopper({
  handleChildData,
  open,
  setOpen,
  param,
  action,
  selectedUser,
  setUpdated,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.login);
  const [loading, setLoading] = useState(false);
  const [designArr, setDesignArr] = useState([]);
  const [reportArr, setReportArr] = useState([]);
  const [roleArr, setRoleArr] = useState([]);
  const [designLoader, setDesignLoader] = useState(false);
  const [reportLoader, setReportLoader] = useState(false);
  const [roleLoader, setRoleLoader] = useState(false);
  const [designSearch, setDesignSearch] = useState("");
  const [reportSearch, setReportSearch] = useState("");
  const [roleSearch, setRoleSearch] = useState("");
  const loginInfo = getCookie("callpal-user");
  const [isEdit, setIsEdit] = useState(false);
  const [updatedPassword, setUpdatedPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(true);

  const [state, setState] = useState({
    uploadType1: false,
    uploadType2: false,
    uploadType3: false,
    uploadType4: false,
  });

  const [userInput, setUserInput] = useState({
    id: "",
    name: "",
    email: "",
    mobile_number: "",
    designation_title: "",
    password: "",
    reporting_title: "",
    role_title: "",
  });

  const [roleId, setRoleId] = useState({
    report_id: "",
    design_id: "",
    role_id: "",
  });

  // Password validation regex: at least one uppercase letter, one lowercase letter, one number, one special character, and minimum 8 characters long
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  
    // Function to check if a string has consecutive digits more than `maxCount` times
    const hasConsecutiveDigits = (value, maxCount) => {
      const regex = /(.)\1{5,}/; // Matches any character repeated 6 or more times consecutively
      return regex.test(value);
    };

      
  // Email validation function
const isEmailValid = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};



  const validationSchema = Yup.object({
    first_name: Yup.string()
      .matches(/^[a-zA-Z\s]+$/, "Only letters are allowed")
      .required("First Name is required"),
    last_name: Yup.string()
      .matches(/^[a-zA-Z\s]+$/, "Only letters are allowed")
      .required("Last Name is required"),
      email: Yup.string().notRequired() // This makes the email field optional
      .email("Invalid email address")
      .test('is-valid-email', 'Invalid email address ', (value) => {
      if (!value) return true; // Skip validation if the email is not provided
      return isEmailValid(value);
    }),
    
    mobile_number: Yup.string()
      .matches(/^\d{10}$/, "Phone number must be 10 digits")
      .max(10, "Maximum 10 characters allowed")
      .required("Phone number is required")
      .test('no-consecutive-digits', 'Please enter a valid mobile number', (value) => {
        return !hasConsecutiveDigits(value, 6);
    }),
    designation_id: Yup.string().required("Designation is required"),
    // password:
    //   isEdit && !updatedPassword
    //     ? Yup.string().nullable()
    //     : Yup.string()
    //         .required("Please enter password")
    //         .min(8, "Password should be at least 8 characters long")
    //         .test('has-uppercase', 'Password must include at least one uppercase letter', (value) => {
    //           return /[A-Z]/.test(value);
    //       })
    //       .test('has-lowercase', 'Password must include at least one lowercase letter', (value) => {
    //           return /[a-z]/.test(value);
    //       })
    //       .test('has-number', 'Password must include at least one number', (value) => {
    //           return /\d/.test(value);
    //       })
    //       .test('has-special-char', 'Password must include at least one special character', (value) => {
    //           return /[!@#$%^&*()\-_=+{};:,<.>]/.test(value); // Add more special characters if needed
    //       })
    //       .matches(passwordRegex, 'Password must meet all criteria'),
    password: isEdit && !updatedPassword ? 
     Yup.string().nullable() : 
     Yup.string()
    .required("Please enter password")
    .min(8, "Password should be at least 8 characters long")
    ,
     reporting_to: Yup.string().required("Reports to is required"),
    role_id: Yup.string().required("Role is required"),
  });

  const formik = useFormik({
    initialValues: {
      id: selectedUser?.id ?? "",
      first_name: "",
      last_name: "",
      email: "",
      mobile_number: "",
      password: "",
      designation_id: "",
      reporting_to: "",
      role_id: "",
      is_password_update: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      try {
        const data = await TeamApi.addTeam(JSON.stringify(values));
        
        if (data.status) {
          dispatch(
            setIsSubmitted({
              open: true,
              severity: "success",
              msg: data.message,
            })
          );
          setOpen(false);
          setUpdated(true);
          resetForm();
          setLoading(false);
        } else {
          if (data.hasOwnProperty("errors")) {
            formik.setFieldError("email", data?.errors?.email?.[0] ?? "");
            formik.setFieldError("first_name", data?.errors?.first_name[0]);
            formik.setFieldError("last_name", data?.errors?.last_name[0]);
            formik.setFieldError("mobile_number", data?.errors?.mobile_number[0]);
            formik.setFieldError("password", data?.errors?.password[0]);
            formik.setFieldError(
              "designation_id",
              data?.errors?.designation_id[0]
            );
            formik.setFieldError("reporting_to", data?.errors?.reporting_to[0]);
            formik.setFieldError("role_id", data?.errors?.role_id[0]);
          } else {
            dispatch(
              setIsSubmitted({
                open: true,
                severity: "error",
                msg: data.message,
              })
            );
          }
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        console.error(e);
      }
    },
  });

  const handleChange = (name, val) => {
    setUserInput({ ...userInput, [name]: val });
  };

  useEffect(() => {
    if (action == "Edit" && selectedUser.hasOwnProperty("id")) {
      setUpdatedPassword(false);
      formik.setFieldValue("id", selectedUser.id ? selectedUser.id : "");
      formik.setFieldValue(
        "email",
        selectedUser.email ? selectedUser.email : ""
      );
      formik.setFieldValue(
        "first_name",
        selectedUser.first_name ? selectedUser.first_name : ""
      );
      formik.setFieldValue(
        "last_name",
        selectedUser.last_name ? selectedUser.last_name : ""
      );
      formik.setFieldValue(
        "mobile_number",
        selectedUser.mobile_number ? selectedUser.mobile_number : ""
      );
      formik.setFieldValue(
        "designation_id",
        selectedUser.fk_designation_id ? selectedUser.fk_designation_id : ""
      );
      formik.setFieldValue(
        "reporting_to",
        selectedUser.fk_reporting_id ? selectedUser.fk_reporting_id : ""
      );
      formik.setFieldValue(
        "role_id",
        selectedUser.fk_role_id ? selectedUser.fk_role_id : ""
      );
      formik.setFieldValue(
        "is_password_update",
        action == "Edit" ? updatedPassword : true
      );
      setUserInput({
        designation_title: selectedUser.designation
          ? selectedUser.designation
          : "",
        reporting_title: selectedUser.reports_to ? selectedUser.reports_to : "",
        role_title: selectedUser.role ? selectedUser.role : "",
      });
      setIsEdit(true);
    } else {
      formik.resetForm({
        values: {
          id: "",
          email: "",
          first_name: "",
          last_name: "",
          mobile_number: "",
          designation_id: "",
          reporting_to: "",
          password: "",
          role_id: "",
          is_password_update :  action == "Edit" ? updatedPassword : true
        },
      });
      setUserInput({
        designation_title: "",
        reporting_title: "",
        role_title: "",
      });
      setIsEdit(false);
    }
    
    // if (userInfo.hasOwnProperty("token") && open) {
    //   getDesignationData();
    //   getReportingData();
    //   getRoleData();
    // }
    if(open){
      getDesignationData();
      getReportingData();
      getRoleData();
    }

   
  }, [userInfo, selectedUser, action, open]);

  const getDesignationData = async () => {
    try {
      setDesignLoader(true);
      const res = await CommonApi.getDesignationData();
      if (res.status) {
        const designArrArray = Object.entries(res.data).map(
          ([id, designation]) => ({ id, designation })
        );
        setDesignArr(designArrArray);
      }
      setDesignLoader(false);
    } catch (err) {
      setDesignLoader(false);
    } finally {
      setDesignLoader(false);
    }
  };

  const getReportingData = async () => {
    try {
      setReportLoader(true);
      const obj = {
        telecaller: false,
      };
      const res = await CommonApi.getReportingData(obj);
      if (res.status) {
        const reportingArray = Object.entries(res.data).map(
          ([id, reporting_to]) => ({ id, reporting_to })
        );
        setReportArr(reportingArray);
      }
      setReportLoader(false);
    } catch (err) {
      setReportLoader(false);
    } finally {
      setReportLoader(false);
    }
  };

  const getRoleData = async () => {
    try {
      setRoleLoader(true);
      const obj = {
        telecaller: false,
      };
      const res = await CommonApi.getRoleData(obj);
      if (res.status) {
        const roleArray = Object.entries(res.data).map(([id, title]) => ({
          id,
          title,
        }));
        setRoleArr(roleArray);
      }
      setRoleLoader(false);
    } catch (err) {
      setRoleLoader(false);
    } finally {
      setRoleLoader(false);
    }
  };

  const toggleDrawer = (anchor, op) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(op);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      <SwipeableDrawer
        anchor={"right"}
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <div>
          <div className={classes.header}>
            {" "}
            {action == "Edit" ? "Edit" : "Add"} User
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className={classes.formContainer}>
              <div className={classes.formRow}>
                <div>
                  <TextField
                    size="small"
                    placeholder="First Name*"
                    inputProps={{
                      className: classes.multilineColor,
                    }}
                    className={classes.root}
                    name="first_name"
                    // value={userInput.first_name}
                    variant="outlined"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.first_name}
                  />

                  {formik.touched.first_name && formik.errors.first_name ? (
                    <p className="text-danger formik-addUser-msg text-small text-left formik-msg ">
                      {formik.errors.first_name}
                    </p>
                  ) : null}
                </div>
                <div>
                  <TextField
                    size="small"
                    placeholder="Last Name*"
                    inputProps={{
                      className: classes.multilineColor,
                    }}
                    className={classes.root}
                    name="last_name"
                    // value={userInput.last_name}
                    variant="outlined"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.last_name}
                  />
                  {formik.touched.last_name && formik.errors.last_name ? (
                    <p className="text-danger formik-addUser-msg text-small text-left formik-msg ">
                      {formik.errors.last_name}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className={classes.formRow}>
                <div>
                  <TextField
                    size="small"
                    placeholder="Email Address"
                    inputProps={{
                      className: classes.multilineColor,
                      //readOnly: isEdit && selectedUser.email  ? true : false,
                      readOnly: isEdit,
                    }}
                    className={classes.root}
                    name="email"
                    type="text"
                    // value={userInput.email}
                    variant="outlined"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <p className="text-danger formik-addUser-msg text-small text-left formik-msg ">
                      {formik.errors.email}
                    </p>
                  ) : null}
                </div>

                <div>
                  <TextField
                    size="small"
                    placeholder="Mobile Number*"
                    inputProps={{
                      maxLength: 10,
                      className: classes.multilineColor,
                    }}
                    className={classes.root}
                    name="mobile_number"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.mobile_number}
                    // value={userInput.phone_numbers}
                    variant="outlined"
                  />
                  {formik.touched.mobile_number &&
                  formik.errors.mobile_number ? (
                    <p className="text-danger formik-addUser-msg text-small text-left formik-msg ">
                      {formik.errors.mobile_number}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className={classes.formRow}>
                <div>
                  <UncontrolledDropdown
                    style={{ margin: "9px 20px", width: "290px" }}
                  >
                    <DropdownToggle
                      caret
                      style={{
                        width: "290px",
                        display: "flex",
                        justifyContent: "space-between",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        background: "white",
                        color: "#F86E20",
                        border: "1px solid #F86E20",
                        alignItems: "center",
                      }}
                    >
                      {userInput.designation_title.length != 0
                        ? userInput.designation_title
                        : "Designation"}

                      {/* <ArrowDropDown></ArrowDropDown> */}
                    </DropdownToggle>
                    <DropdownMenu style={{ width: "285px" }}>
                      {designArr.length > 0 && !designLoader && (
                        <>
                          <DropdownItem header>
                            <TextField
                              placeholder="Search Designation"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon />
                                  </InputAdornment>
                                ),
                              }}
                              size="small"
                              variant="outlined"
                              onChange={(e) => setDesignSearch(e.target.value)}
                            />
                          </DropdownItem>

                          <div className={classes.dropdownItem}>
                            {designArr.length > 0 &&
                              designArr
                                .filter((e) =>
                                  e.designation
                                    .toLowerCase()
                                    .includes(designSearch.toLowerCase())
                                )
                                .map((el, i) => (
                                  <DropdownItem
                                    key={el.id}
                                    onClick={() => {
                                      handleChange(
                                        "designation_title",
                                        el.designation
                                      );
                                      formik.setFieldValue(
                                        "designation_id",
                                        el.id
                                      );
                                      // setRoleId({
                                      //   ...roleId,
                                      //   design_id: el.user_id,
                                      // });
                                    }}
                                  >
                                    {el.designation}
                                  </DropdownItem>
                                ))}
                          </div>
                        </>
                      )}
                      {designLoader && (
                        <div style={{ textAlign: "center" }}>
                          <CircularProgress size={20} />
                        </div>
                      )}
                    </DropdownMenu>
                    <div
                      style={{ color: "red", fontSize: 12, textAlign: "left" }}
                    >
                      {userInput.designation_title.length == 0}
                    </div>
                  </UncontrolledDropdown>

                  {formik.touched.designation_id &&
                  formik.errors.designation_id ? (
                    <p className="text-danger formik-addUser-msg text-small text-left formik-msg ">
                      {formik.errors.designation_id}
                    </p>
                  ) : null}
                </div>

                <div>
                  <TextField
                    size="small"
                    placeholder="Password*"
                    inputProps={{
                      className: classes.multilineColor,
                      // readOnly: isEdit,
                      readOnly:
                        action === "Edit"
                          ? updatedPassword
                            ? false
                            : true
                          : false
                    }}
                    className={classes.root}
                    type={showPassword ? "password" : "text"}
                    name="password"
                    // onChange={(e) => handleChange(e.target.name, e.target.value)}
                    // value={userInput.password}
                    variant="outlined"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    disabled={
                      action === "Edit"
                      ? updatedPassword
                        ? false
                        : true
                      : false
                    }
                  />

                  <div>
                    <img
                      className="addUserPropsBtn"
                      src={showPassword ? eyeCross : viewIcon}
                      alt="Toggle Password Visibility"
                      onClick={toggleShowPassword}
                    />
                  </div>

                  {action == "Edit" ? (
                    <div style={{ margin: "-17px 0 0 23px" }}>
                      <FormControlLabel
                        control={
                          <OrangeCheckbox
                            //checked={formik.values.is_password_update}
                            onChange={(e) => {
                              //formik.handleChange(e);
                              //formik.setFieldValue("password", "");
                              formik.setFieldValue("is_password_update", e.target.checked);
                              setUpdatedPassword(e.target.checked);
                            }}
                            onBlur={formik.handleBlur}
                            name="is_password_update"
                          />
                        }
                        label={
                          <div style={{ display: "flex" }}>
                            <div className={classes.allocated}>
                            Allow Password Update
                            </div>
                          </div>
                        }
                      />
                    </div>
                  ) : (
                    <> </>
                  )}
                  

                  {formik.touched.password && formik.errors.password ? (
                    <p className="text-danger formik-addUser-msg text-small text-left formik-msg ">
                      {formik.errors.password}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className={classes.formRow}>
                <div>
                  <UncontrolledDropdown
                    style={{ margin: "9px 20px", width: "290px" }}
                  >
                    <DropdownToggle
                      caret
                      style={{
                        width: "290px",
                        display: "flex",
                        justifyContent: "space-between",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        background: "white",
                        color: "#F86E20",
                        border: "1px solid #F86E20",
                        alignItems: "center",
                      }}
                    >
                      {userInput.reporting_title.length != 0
                        ? userInput.reporting_title
                        : "Reports To"}

                      {/* <ArrowDropDown></ArrowDropDown> */}
                    </DropdownToggle>
                    <DropdownMenu style={{ width: "285px" }}>
                      {(reportArr.length > 0 && !reportLoader) > 0 && (
                        <>
                          <DropdownItem header>
                            <TextField
                              placeholder="Search Reports to User"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon />
                                  </InputAdornment>
                                ),
                              }}
                              size="small"
                              variant="outlined"
                              onChange={(e) => setReportSearch(e.target.value)}
                            />
                          </DropdownItem>
                          <div className={classes.dropdownItem}>
                            {reportArr.length > 0 &&
                              reportArr
                                .filter((e) =>
                                  e.reporting_to
                                    .toString()
                                    .toLowerCase()
                                    .includes(reportSearch.toLowerCase())
                                )
                                .map((el, i) => (
                                  <DropdownItem
                                    key={el.id}
                                    onClick={() => {
                                      handleChange(
                                        "reporting_title",
                                        el.reporting_to
                                      );
                                      formik.setFieldValue(
                                        "reporting_to",
                                        el.id
                                      );
                                      //setRoleId({ ...roleId, report_id: el.id });
                                    }}
                                  >
                                    {el.reporting_to}
                                  </DropdownItem>
                                ))}
                          </div>
                        </>
                      )}
                      {reportLoader && (
                        <div style={{ textAlign: "center" }}>
                          <CircularProgress size={20} />
                        </div>
                      )}
                    </DropdownMenu>
                    <div
                      style={{ color: "red", fontSize: 12, textAlign: "left" }}
                    >
                      {!userInput?.reportTo}
                    </div>
                  </UncontrolledDropdown>

                  {formik.touched.reporting_to && formik.errors.reporting_to ? (
                    <p className="text-danger formik-addUser-msg text-small text-left formik-msg ">
                      {formik.errors.reporting_to}
                    </p>
                  ) : null}
                </div>
                <div>
                  <UncontrolledDropdown
                    style={{ margin: "9px 20px", width: "290px" }}
                  >
                    <DropdownToggle
                      caret
                      style={{
                        width: "290px",
                        display: "flex",
                        justifyContent: "space-between",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        background: "white",
                        color: "#F86E20",
                        border: "1px solid #F86E20",
                        alignItems: "center",
                      }}
                    >
                      {userInput.role_title.length != 0
                        ? userInput.role_title
                        : "Role"}

                      {/* <ArrowDropDown></ArrowDropDown> */}
                    </DropdownToggle>
                    <DropdownMenu style={{ width: "285px" }}>
                      {(roleArr.length > 0 && !roleLoader) > 0 && (
                        <>
                          <DropdownItem header>
                            <TextField
                              placeholder="Search Role"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon />
                                  </InputAdornment>
                                ),
                              }}
                              size="small"
                              variant="outlined"
                              onChange={(e) => setRoleSearch(e.target.value)}
                            />
                          </DropdownItem>
                          <div className={classes.dropdownItem}>
                            {roleArr.length > 0 &&
                              roleArr
                                .filter((e) =>
                                  e.title
                                    .toString()
                                    .toLowerCase()
                                    .includes(roleSearch.toLowerCase())
                                )
                                .map((el, i) => (
                                  <DropdownItem
                                    key={el.id}
                                    onClick={() => {
                                      handleChange("role_title", el.title);
                                      formik.setFieldValue("role_id", el.id);
                                      // setRoleId({ ...roleId, role_id: el.id });
                                    }}
                                  >
                                    {el.title}
                                  </DropdownItem>
                                ))}
                          </div>
                        </>
                      )}
                      {roleLoader && (
                        <div style={{ textAlign: "center" }}>
                          <CircularProgress size={20} />
                        </div>
                      )}
                    </DropdownMenu>
                    <div
                      style={{ color: "red", fontSize: 12, textAlign: "left" }}
                    >
                      {userInput.role_title.length == 0}
                    </div>
                  </UncontrolledDropdown>
                  {formik.touched.role_id && formik.errors.role_id ? (
                    <p className="text-danger formik-addUser-msg text-small text-left formik-msg ">
                      {formik.errors.role_id}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="btns_add_user">
              <div className={classes.buttonContainer}>
                <Button type="submit" className={classes.saveButton}
               disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size={20} />
                  ) : action == "Edit" ? (
                    "Update"
                  ) : (
                    "Save"
                  )}
                </Button>
                <Button
                  className={classes.cancelButton}
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </form>
        </div>
      </SwipeableDrawer>
    </div>
  );
}
