import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Tab,
  Tabs,
  TextField,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { StyledTableCell } from "../../../Styles";
import { StyledTableRow } from "../../../Styles";
import EditIcon from "@material-ui/icons/Edit";
import trashIcon from "../../../Images/trash.svg";
import { MasterDataApi } from "../../../Services/MasterDataApi";
import NoRecords from "../../Dashboard/Other-components/NoRecords";
import ConfirmationPopper from "../../Dashboard/Other-components/ConfirmationPopper";
import { setIsSubmitted } from "../../../Reducers/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import { TablePagination } from "@material-ui/core";

const useStyles = makeStyles({
  mainContainer: {
    padding: "80px 30px 30px 30px",
    textAlign: "left",
  },
  heading: {
    color: "#3A1C67",
    fontSize: "18px",
    fontWeight: 700,
    textAlign: "left",
    marginBottom: 10,
    padding: "12px",
  },
  root: {
    minWidth: 275,
  },
  tab: {
    backgroundColor: "transparent",
    color: "#3f51b5",
    borderColor: "#3f51b5",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 10,
    textTransform: "none",
    marginRight: "1.2rem",
    padding: "6px 12px",
    "&:hover": {
      backgroundColor: "rgba(63, 81, 181, 0.1)",
    },
    "&.Mui-selected": {
      backgroundColor: "#3A1C67",
      color: "#fff",
    },
    "& .Mui-root": {
      padding: "0px",
    },
  },
  tabButton: {
    padding: "4px 8px", // Adjust the padding values as desired
  },
  tabContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: 0,
    "& .MuiTabs-indicator": {
      // Add this line
      display: "none", // Add this line
    }, // Add this line
  },
  form: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    marginTop: 20,
  },
  imageUpload: {
    border: "2px solid orange",
    padding: "80px 20px",
    marginRight: 20,
    borderRadius: 10,
  },
  inputFields: {
    flex: 1,
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: 30,
    // '& .MuiTextField-root': {
    //   marginBottom: 20,
    //   '& .MuiOutlinedInput-root': {
    //     '& fieldset': {
    //       borderColor: 'orange',
    //     },
    //     '&:hover fieldset': {
    //       borderColor: 'orange',
    //     },
    //     '&.Mui-focused fieldset': {
    //       borderColor: 'orange',
    //     },
    //   },
    // },
  },
  inputField: {
    color: "orange",
    "& .MuiOutlinedInput-root": {
      borderRadius: 8, // Adjust the value to increase or decrease the roundness
      "& fieldset": {
        borderColor: "orange",
      },
      "&:hover fieldset": {
        borderColor: "orange",
      },
      "&.Mui-focused fieldset": {
        borderColor: "orange",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "11.5px 14px",
    },
  },
  uploadButtom: {
    backgroundColor: "transparent",
    border: "2px solid orange",
    borderRadius: 10,
    color: "orange",
    textAlign: "center",
    padding: "10px 40px",
    boxShadow: "none",
  },
});


const headers = ["Processor Name","Action"];


const MasterProcessorList = ({processor, editProcessor}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [processorList, setProcessorList] = useState([]);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState("Add");
  const [selectedUser, setSelectedUser] = useState({});
  const [updated, setUpdated] = useState(true);
  const [confirm, setConfirm] = useState(false);
  const [deletedUser, setDeletedUser] = useState("");
  const [deleteLoader, setDeleteLoader] = useState(false);
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [offset, setOffset] = useState(1);
  const [count, setCount] = useState(0);


  useEffect(() => {
    if(!processor) {
      fetchProcessorList();
     }
  }, [processor, updated, rowsPerPage]);




  const handleChangePage = (event, newPage) => {
    setUpdated(true);
    setPage(newPage);
    let num = 0;
    if (newPage > page) {
      num = offset + 1;
    } else {
      num = offset - 1;
    }
    setOffset(num);
    fetchProcessorList();
  };


  const handleChangeRowsPerPage = (event) => {
    setUpdated(true);
    setRowsPerPage(event.target.value);
    setPage(0);
    setOffset(0);
  };


  const fetchProcessorList = async () => {
    try {
      setLoading(true);
      const data = JSON.stringify({
        per_page_row: rowsPerPage,
        page: offset,
       
      });
      const res = await MasterDataApi.MasterDataProcessorList(data);
      if (res.status) {
        setRows(res.data);
        setCount(res.total_count);

      }
    } catch (err) {
      setLoading(false);
      // console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const deleteUser = async () => {
    try {
      setUpdated(false);
      setDeleteLoader(true);
      const res = await MasterDataApi.deleteMasterProcessor(deletedUser);
      setDeleteLoader(false);
      setConfirm(false);
      if (res.status) {
        dispatch(
          setIsSubmitted({ open: true, severity: "success", msg: res.message })
        );
        setUpdated(true);
      } else {
        dispatch(
          setIsSubmitted({ open: true, severity: "error", msg: res.message })
        );
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>

      <TableContainer className={classes.tableContainer}>
        <Table
          stickyHeader
          className={classes.table}
          aria-label="customized table"
          size="small"
        >
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <StyledTableCell key={header} align="center">
                  {header}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          {rows.length > 0 && rows ? (
            <TableBody>
              {rows.map((row, index) => (
                <StyledTableRow key={index}>
                  {/* <StyledTableCell align="center">
                    {row.id}
                  </StyledTableCell> */}
                  <StyledTableCell align="center">
                    {row.process_name}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <EditIcon
                        style={{ height: "18px" }}
                        onClick={() => {
                          setAction("Add");
                          editProcessor(row.id);
                        }}
                      />
                      <img
                        src={trashIcon} // Ensure you have the correct path
                        alt="Delete"
                        width={18}
                        onClick={() => {
                          setConfirm(true);
                          setDeletedUser(row.id);
                        }}
                      />
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          ) : (
            <NoRecords rows={rows} loading={loading} />
          )}
        </Table>
      </TableContainer>

      <ConfirmationPopper
        loading={deleteLoader}
        open={confirm}
        setOpen={(f) => setConfirm(f)}
        deleteUser={() => {
          deleteUser();
        }}
      />

    <div>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          backIconButtonProps={{
            "aria-label": "Previous Page",
            style: {
              color: "#fff",
              backgroundColor: "#3A1C67",
              padding: 3,
              borderRadius: "5px",
              marginRight: "5px",
            },
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
            style: {
              color: "#fff",
              backgroundColor: "#3A1C67",
              padding: "3px",
              borderRadius: "5px",
            },
          }}
        />
      </div>
    </>
  );
};

export default MasterProcessorList;
