import { makeStyles,Button, TextField, FormControl,  Select, MenuItem} from "@material-ui/core";
import moment from "moment";
import { useState } from "react";
import DatePicker from 'react-date-picker'
const useStyles = makeStyles({
    mainContainer: {
      padding: '80px 30px 30px 30px',
    },
    subContainer:{
        display:'flex',
        justifyContent:'space-between'
    },
    card:{
        height:"660px",
        width:'49%',
        background: '#FFFFFF'
},

footer:{
    display:'flex',
    marginTop:'20px'
},
    header:{
        display:'flex',
        justifyContent:'space-between',
        background: '#3A1C67',
        fontSize:'20px',
        fontWeight:700,
        height:'50px',
        alignItems:'center',
        color: '#FFFFFF',
        paddingLeft:'10px',
        paddingRight:'10px',
        borderRadius:'10px 10px 0px 0px'

    },
    button:{
        background: '#FA9510',
        color: '#FFFFFF',
        '&:hover': {
            backgroundColor: '#FA9510',
          },

    },
    root: {
        "& input[type=number]": {
          "-moz-appearance": "textfield",
        },
        "& input[type=number]::-webkit-outer-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
        "& input[type=number]::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
        "& label.Mui-focused": {
          color: "#F86E20",
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "#F86E20",
          },
          "&:hover fieldset": {
            borderColor: "#F86E20",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#F86E20",
          },
        },
        margin: "10px",
      },
    formContainer: {
        padding: "20px 10px",
      },
    multilineColor: {
        color: "#F86E20",
        borderRadius:'10px'
      },
      formRow: {
        display: "flex",
        justifyContent:'space-between'
      },
      mobileNumber:{
        display:'flex'
      },
      cardFlex:{
        display:"flex"
    },
    paraHeding1: {
        fontSize: "12px",
        color:'#252525',
        fontWeight: 700,
        textAlign:"start",
        marginBottom:'0px'
      },
      paraHeding: {
        fontSize: "18px",
        color:'#FA9510',
        fontWeight: 700,
        textAlign:"start",
        marginBottom:'0px'
      },
      subTitleClass:{
        fontSize: "13px",
        color:'#252525',
        fontWeight: 400,
        textAlign:'start'
      },
      heading:{
        textAlign:'start',
        color: '#3A1C67',
        fontSize: "20px",
        fontWeight:700


      }
  })


const CrmForm=()=>{
    const classes = useStyles();
    const [startDate, setStartDate] = useState( moment(new Date()).format('MM/DD/YYYY'));
    return(
        <div className={classes.mainContainer}>
            <p className={classes.heading}>CRM Form</p>


            <div className={classes.subContainer}>

                <div className={classes.card}>
                        <div className={classes.header}>
                            <span>Personal Information</span>
                            <Button className={classes.button} >
                                Save
                            </Button>
                        </div>
                        <form className={classes.formContainer}>
                            <div className={classes.formRow}> 
                                <TextField
                                size="small"
                                placeholder="Name"
                                inputProps={{
                                    className: classes.multilineColor,
                                }}
                                className={classes.root}
                                name="name"
                                variant="outlined"
                                /> 
                                <TextField
                                size="small"
                                placeholder="Email Address"
                                className={classes.root}
                                inputProps={{
                                    className: classes.multilineColor,
                                }}
                                name="email"
                                variant="outlined"
                                />
                                
                            </div>
                            <div className={classes.formRow}> 
                            <div className={classes.mobileNumber}>
                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    defaultValue="91"
                                    style={{height:"42px",marginTop:'6px',marginLeft:'10px', color: '#FA9510', border:'1px solid #FA9510 ', borderRadius:'5px'
                                }}
                                >
                                    <MenuItem value={91}>91 +</MenuItem>
                                </Select>
                           </FormControl>
                                <TextField
                                size="small"
                                placeholder="Mobile Number"
                                inputProps={{
                                    className: classes.multilineColor,
                                }}
                                style={{width:'140px'}}
                                className={classes.root}
                                name="mobile"
                                variant="outlined"
                                />
                                </div> 
                                <div className={classes.mobileNumber}>
                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    defaultValue="91"
                                
                                    style={{height:"42px",marginTop:'6px', color: '#FA9510', border:'1px solid #FA9510 ', borderRadius:'5px'
                                }}
                                >
                                    <MenuItem value={91}>91 +</MenuItem>
                                </Select>
                           </FormControl>
                                <TextField
                                size="small"
                                placeholder="Mobile Number"
                                inputProps={{
                                    className: classes.multilineColor,
                                }}
                                style={{width:'140px'}}
                                className={classes.root}
                                name="mobile"
                                variant="outlined"
                                />
                                </div>    
                            </div>
                            <div style={{marginRight:'18px'}}>
                            <TextField
                                size="small"
                                placeholder="Address"
                                className={classes.root}
                                inputProps={{
                                    className: classes.multilineColor,
                                }}
                                name="address"
                                variant="outlined"
                                fullWidth
                                /> 
                                </div>
                            <div className={classes.formRow}> 
                            <FormControl sx={{ m: 1 }} size="small" fullWidth>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    defaultValue="City"
                                    style={{height:"45px", marginLeft:'7px', marginRight:'15px', color: '#FA9510', border:'1px solid #FA9510 ', borderRadius:'5px'
                                }}
                                >
                                    <MenuItem value={'City'}>City</MenuItem>
                                </Select>
                           </FormControl> 
                           <FormControl sx={{ m: 1}} size="small" fullWidth >
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    defaultValue="State"
                                    style={{height:"45px", marginRight:'9px', color: '#FA9510', border:'1px solid #FA9510 ', borderRadius:'5px'
                                }}
                                >
                                    <MenuItem value={'State'}>State</MenuItem>
                                </Select>
                           </FormControl>
                            </div>

                            <div className={classes.formRow} style={{marginTop:'10px'}}> 
                            <FormControl sx={{ m: 1, width:'200px'}} size="normal" >
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    defaultValue="Country"
                                    style={{width:'200px', marginTop:'5px', height:"45px", marginLeft:'7px', marginRight:'5px', color: '#FA9510', border:'1px solid #FA9510 ', borderRadius:'5px'
                                }}
                                >
                                    <MenuItem value={'Country'}>Country</MenuItem>
                                </Select>
                           </FormControl> 
                           
                           <TextField
                                size="small"
                                placeholder="Pin Code"
                                inputProps={{
                                    className: classes.multilineColor,
                                }}
                                className={classes.root}
                                name="pin"
                                variant="outlined"

                                style={{maxWidth:'210px'}}
                                />
                            </div>

                        </form>
                </div>
                <div className={classes.card}>
                        <div className={classes.header}>
                            <span>Personal Information</span>
                           
                        </div>
                        <form className={classes.formContainer}>
                            <div className={classes.formRow}> 
                                <TextField
                                size="small"
                                placeholder="Name"
                                inputProps={{
                                    className: classes.multilineColor,
                                }}
                                className={classes.root}
                                name="name"
                                variant="outlined"
                                /> 
                                <TextField
                                size="small"
                                placeholder="Email Address"
                                className={classes.root}
                                inputProps={{
                                    className: classes.multilineColor,
                                }}
                                name="email"
                                variant="outlined"
                                />
                                
                            </div>
                            <div className={classes.formRow}> 
                            <div className={classes.mobileNumber}>
                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    defaultValue="91"
                                    style={{height:"42px",marginTop:'6px',marginLeft:'10px', color: '#FA9510', border:'1px solid #FA9510 ', borderRadius:'5px'
                                }}
                                >
                                    <MenuItem value={91}>91 +</MenuItem>
                                </Select>
                           </FormControl>
                                <TextField
                                size="small"
                                placeholder="Mobile Number"
                                inputProps={{
                                    className: classes.multilineColor,
                                }}
                                style={{width:'140px'}}
                                className={classes.root}
                                name="mobile"
                                variant="outlined"
                                />
                                </div> 
                                <div className={classes.mobileNumber}>
                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    defaultValue="91"
                                    style={{height:"42px",marginTop:'6px', color: '#FA9510', border:'1px solid #FA9510 ', borderRadius:'5px'
                                }}
                                >
                                    <MenuItem value={91}>91 +</MenuItem>
                                </Select>
                           </FormControl>
                                <TextField
                                size="small"
                                placeholder="Mobile Number"
                                inputProps={{
                                    className: classes.multilineColor,
                                }}
                                style={{width:'140px'}}
                                className={classes.root}
                                name="mobile"
                                variant="outlined"
                                />
                                </div>    
                            </div>
                            <div style={{marginRight:'18px'}}>
                            <TextField
                                size="small"
                                placeholder="Address"
                                className={classes.root}
                                inputProps={{
                                    className: classes.multilineColor,
                                }}
                                name="address"
                                variant="outlined"
                                fullWidth
                                /> 
                                </div>
                            <div className={classes.formRow}> 
                            <FormControl sx={{ m: 1 }} size="small" fullWidth>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    defaultValue="City"
                                    style={{height:"45px", marginLeft:'7px', marginRight:'15px', color: '#FA9510', border:'1px solid #FA9510 ', borderRadius:'5px'
                                }}
                                >
                                    <MenuItem value={'City'}>City</MenuItem>
                                </Select>
                           </FormControl> 
                           <FormControl sx={{ m: 1}} size="small" fullWidth >
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    defaultValue="State"
                                    style={{height:"45px", marginRight:'9px', color: '#FA9510', border:'1px solid #FA9510 ', borderRadius:'5px'
                                }}
                                >
                                    <MenuItem value={'State'}>State</MenuItem>
                                </Select>
                           </FormControl>
                            </div>

                            <div className={classes.formRow} style={{marginTop:'10px'}}> 
                            <FormControl sx={{ m: 1, width:'200px'}} size="normal" >
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    defaultValue="Country"
                                    style={{width:'200px', marginTop:'5px', height:"45px", marginLeft:'7px', marginRight:'5px', color: '#FA9510', border:'1px solid #FA9510 ', borderRadius:'5px'
                                }}
                                >
                                    <MenuItem value={'Country'}>Country</MenuItem>
                                </Select>
                           </FormControl> 
                           <TextField
                                size="small"
                                placeholder="Pin Code"
                                inputProps={{
                                    className: classes.multilineColor,
                                }}
                                className={classes.root}
                                name="pin"
                                variant="outlined"
                                style={{maxWidth:'210px'}}
                                />
                            </div>
                            <div className={classes.formRow} style={{marginTop:'10px'}}> 
                            
                           <TextField
                                size="small"
                                placeholder="Existing EMI (amount)"
                                inputProps={{
                                    className: classes.multilineColor,
                                }}
                                className={classes.root}
                                name="pin"
                                style={{maxWidth:'200px'}}
                                variant="outlined"
                                />
                                <FormControl sx={{ m: 1}} size="normal" >
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    defaultValue="Select Product"
                                    style={{width:'210px', marginTop:'5px', height:"45px", marginLeft:'7px', marginRight:'5px', color: '#FA9510', border:'1px solid #FA9510 ', borderRadius:'5px'
                                }}
                                >
                                    <MenuItem value={'Select Product'}>Select Product</MenuItem>
                                </Select>
                           </FormControl> 
                            </div>

                            <div className={classes.formRow} style={{marginTop:'10px'}}> 
                            
                           <TextField
                                size="small"
                                placeholder="Loan Requirement (amount)"
                                inputProps={{
                                    className: classes.multilineColor,
                                }}
                                style={{maxWidth:'200px'}}
                                className={classes.root}
                                name="pin"
                                variant="outlined"
                                />
                                <FormControl sx={{ m: 1, width:'200px'}} size="normal" >
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    defaultValue="Priority"
                                    style={{width:'210px', marginTop:'5px', height:"45px", marginLeft:'7px', marginRight:'5px', color: '#FA9510', border:'1px solid #FA9510 ', borderRadius:'5px'
                                }}
                                >
                                    <MenuItem value={'Priority'}>Priority</MenuItem>
                                </Select>
                           </FormControl> 
                            </div>
                            <div className={classes.formRow}> 
                                <TextField
                                style={{maxWidth:'200px'}}
                                size="small"
                                placeholder="Latitude"
                                inputProps={{
                                    className: classes.multilineColor,
                                }}
                                className={classes.root}
                                name="latitude"
                                variant="outlined"
                                /> 
                                <TextField
                                style={{width:'200px'}}
                                size="small"
                                placeholder="Longitude"
                                className={classes.root}
                                inputProps={{
                                    className: classes.multilineColor,
                                }}
                                name="longitude"
                                variant="outlined"
                                />
                                
                            </div>
                            <div className={classes.formRow}> 
                            <div style={{border:'1px solid #FA9510 ', borderRadius:'5px' ,width:'45%', padding:'5px 0px' ,height:"42px"}} className={classes.root}>
                            <DatePicker
                               style={{ display: 'none', color: '#3A1C67', cursor: 'pointer' }}
                               className='datePickerInput'
                               value={startDate}
                               onChange={(val)=>{
                                 const d= moment(val).format('YYYY-MM-DD')
                                 setStartDate(d)
                                 
                               }}
                                />
                                
                                </div>
                                <div style={{border:'1px solid #FA9510 ', borderRadius:'5px' ,width:'45%', padding:'5px 0px' ,height:"42px"}} className={classes.root}>
                                        <DatePicker
    
                                            selected={startDate}
                                            timeInputLabel="Time:"
                                            dateFormat="MM/dd/yyyy h:mm aa"
                                            showTimeInput
                                            value={startDate}
                                            onChange={(val)=>{
                                              const d= moment(val).format('YYYY-MM-DD')
                                              setStartDate(d)
                                              
                                            }}
                                            />
                                
                                </div>
                                
                            </div>
                        </form>
                </div>
                
            </div>
            <div className={classes.footer}>
                <TextField
                                size="small"
                                fullWidth
                                placeholder="Notes:"
                                inputProps={{
                                    className: classes.multilineColor,
                                }}
                                className={classes.root}
                                name="name"
                                variant="outlined"
                                /> 
                                 <FormControl sx={{ m: 1 }} size="small" fullWidth>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    
                                    style={{color:'white' ,height:"50px", marginLeft:'7px', marginRight:'15px', color: '#FA9510', border:'1px solid #FA9510 ', borderRadius:'5px'
                                }}
                                >
                                    <MenuItem value={'Assign to'}>Assign to</MenuItem>
                                </Select>
                           </FormControl> 
                </div>
        </div>
    )
}
export default CrmForm