

const CircleComponent = ({outer,inner}) => {
  return (
    <div className={outer}>
    {inner && <div className={inner}></div>}
</div>
  );
};
export default CircleComponent;
