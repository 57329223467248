import {
  Box,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputBase,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import { OrangeCheckbox, OrangeSmallCheckbox } from "../../../../Styles";
import updateIcon from "../../../../Images/history 2.svg";
import { useDispatch, useSelector } from "react-redux";
import { setIsSubmitted } from "../../../../../src/Reducers/loginSlice";
import { useEffect, useState } from "react";
import { getCookie } from "../../../../../src/Utils/commonUtils";
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
} from "reactstrap";
import SearchIcon from "@material-ui/icons/Search";
import { CircularProgress, InputAdornment, colors } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { BankApi } from "../../../../Services/BankApi";
import { CustomerHistoryApi } from "../../../../Services/CustomerHistoryApi";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "80px 30px 20px 30px",
  },
  heading: {
    color: "#3A1C67",
    fontSize: "18px",
    fontWeight: 700,
    textAlign: "left",
    marginLeft: 20,
  },
  sectionContainer: {
    display: "flex",
  },
  section1: {
    // container height
    // height: 500,
    height: 275,
    backgroundColor: "#fff",
    margin: 10,
    width: "100%",
    borderRadius: 10,
    // zIndex: 1111111111111,
  },
  sectionHeading: {
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 16,
    backgroundColor: "#3A1C67",
    height: 50,
    padding: "0px 30px",
    textAlign: "left",
    fontWeight: 700,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  historyContainer: {
    padding: 15,
    height: 450,
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "100%",
      display: "block",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "darkgrey",
      outline: "1px solid slategrey",
      borderRadius: "5px",
    },
  },
  card: {
    width: "100%",
    paddingBottom: 10,
    backgroundColor: "#F1F1F1",
    textAlign: "left",
    fontSize: 12,
    color: "#FA9510",
    boxShadow:
      "rgba(0, 0, 0, 0.22) 0px 1px 5px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
  },
  dot: {
    height: 10,
    width: " 100%",
    border: "1px solid #FA9510",
    borderRadius: "50%",
  },
  indicator: {
    borderLeft: "2px solid #848891",
    height: 60,
    marginLeft: 4,
  },
  saveButton: {
    backgroundColor: "#FA9510",
    color: "#fff",
    textTransform: "none",
    width: "100%",
    height: "30px",
    borderRadius: "10px",
    fontSize: "14px",
    marginLeft: 10,
    "&:hover": {
      backgroundColor: "#FA7A21",
    },
  },
  formContainer: {
    padding: 15,
    width: "100%",
    // height: 450,
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "100%",
      display: "block",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "darkgrey",
      outline: "1px solid slategrey",
      borderRadius: "5px",
    },
  },
  statusButton: {
    backgroundColor: "#fff",
    color: "#3A1C67",
    textTransform: "none",
    height: "35px",
    borderRadius: "5px",
    border: "1px solid #3A1C67",
    fontSize: "14px",
    marginBottom: 5,
    // [theme.breakpoints.down("xs")]: {
    //   width: "100%",
    // },
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  root: {
    "& label.Mui-focused": {
      color: "#F86E20",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#F86E20",
      },
      "&:hover fieldset": {
        borderColor: "#F86E20",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#F86E20",
      },
    },
    margin: "5px 5px",
    width: "100%",
    // [theme.breakpoints.down('xs')]: {
    //     width:'110'
    //   },
  },
  multilineColor: {
    color: "#F86E20",
    fontSize: 14,
  },
}));
const LoginBank = ({
  bankList,
  statusLoginArr,
  firstBankSection,
  setUpdated,
  currentProductDetail,
  isProductEnable
}) => {
  const classes = useStyles();
  const params = useParams();
  const leadId = atob(params.leadId);
  const loginInfo = getCookie("callpal-user");
  const [loading, setLoading] = useState(false);
  const [bankSearch, setBankSearch] = useState("");
  const [statusSearch, setStatusSearch] = useState("");
  const [loginSectionData, setLoginSectionData] = useState([]);
  const [firstSection, setFirstSection] = useState(firstBankSection);
  const [errorMsg, setErrorMsg] = useState(Array(firstSection.length).fill(""));
  const [productSearch, setProductSearch] = useState("");
  const [productList, setProductList] = useState();
  const dispatch = useDispatch();
  const [selectedProductId, setSelectedProductId] = useState(currentProductDetail.id);
  const [selectedProductName, setSelectedProductName] = useState(currentProductDetail.product);

  useEffect(() => {
    getProductMaster();
  }, []);

  const getProductMaster = async () => {
    try {
      // setLoading(true)
      const res = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/product-master`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${loginInfo.token}`,
          },
        }
      );
      const data = await CustomerHistoryApi.getProductMaster();
      console.log("res", data);

      if (data.status) {
        const productArrArray = Object.entries(data.data).map(
          ([id, product]) => ({ id, product })
        );
        setProductList(productArrArray);
      }


      console.log("current_product", data.current_product)
      // setDesignLoader(false);
    } catch (e) {
      console.log(e.message);
      // setLoading(false)
    }
  };

  const addRow = () => {
    setFirstSection([
      ...firstSection,
      {
        fk_lead_id: leadId,
        fk_bank_id: "",
        fk_bank_name: "",
        fk_status_id: "",
        fk_status_name: "",
        login_amount: "",
        sanction_amount: "",
        is_sanction: false,
        status: [],
      },
    ]);
  };

  const handleBankChange = (index, value, bankName) => {
    const updatedRows = [...firstSection];
    updatedRows[index].fk_bank_id = value;
    updatedRows[index].fk_bank_name = bankName;
    setFirstSection(updatedRows);
  };

  const handleStatusChange = (index, value, statusName) => {
    const updatedRows = [...firstSection];
    updatedRows[index].fk_status_id = value;
    updatedRows[index].fk_status_name = statusName;
    if (statusName == "Sanction") {
      updatedRows[index].is_sanction = true;
    } else {
      updatedRows[index].is_sanction = false;
    }
    setFirstSection(updatedRows);
  };

  const handleLoginAmountChange = (index, value) => {
    // if (!isNaN(value)) {
    const updatedRows = [...firstSection];
    updatedRows[index].login_amount = value;
    setFirstSection(updatedRows);
    // } else {
    //   setErrorMsg('Please enter a valid number.');
    // }
  };

  const handleSanctionAmountChange = (index, value) => {
    const updatedRows = [...firstSection];
    updatedRows[index].sanction_amount = value;
    setFirstSection(updatedRows);

    return false;
  };

  const deleteRow = (index) => {
    const updatedRows = [...firstSection];
    updatedRows.splice(index, 1);
    setFirstSection(updatedRows);
  };

  const calculateContainerHeight = () => {
    // Adjust the multiplier as needed based on your design
    return 250 + firstSection.length * 50; // Assuming each row adds 40 pixels to the height
  };

  const LoginSubmit = async () => {
    try {
      if (!selectedProductId) {
        dispatch(
          setIsSubmitted({
            open: true,
            severity: "error",
            msg: "Please select a product",
          })
        );
        // alert("Please select a product")
        return;
      }
      const data = JSON.stringify({
        lead_login_bank: firstSection,
        product_id: selectedProductId,
        lead_id:leadId,
      });
      const res = await BankApi.addBankLogin(data);
      if (res.status) {
        dispatch(
          setIsSubmitted({
            open: true,
            severity: "success",
            msg: res.message,
          })
        );
        setUpdated(true);
        window.location.reload();
        setLoading(false);
      } else {
        dispatch(
          setIsSubmitted({
            open: true,
            severity: "error",
            msg: res.message,
          })
        );

        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      console.error("Error in LoginSubmit:", e.message || e.toString());
    }
  };

  return (
    <div className={classes.sectionContainer}>
      <div
        className={classes.section1}
        style={{
          height: "auto",
        }}
      >
        <div className={classes.sectionHeading}>
          {/* <FormControlLabel
                  control={
                    <></>
                  }
                  
                /> */}
          <div>{loading ? <CircularProgress size={20} /> : "Login"}</div>
          <div style={{ display: "flex" }}>
            <div className="btns_add_user">
              <div style={{ width: "100%" }}>
                <div className={classes.formRow}>
                  <UncontrolledDropdown
                    style={{ margin: "0px 5px", width: "213px" }}
                    disabled={!isProductEnable ? true : false}
                    >
                    <DropdownToggle
                      caret
                      style={{
                        width: "213px",
                        display: "flex",
                        justifyContent: "space-between",
                        paddingTop: "10px",
                        paddingBottom: "6px",
                        background: "white",
                        color: "#F86E20",
                        border: "1px solid #F86E20",
                        alignItems: "center",
                        fontSize: "14px",
                      }}
                    >
                    {selectedProductName ? selectedProductName : "Select product name"}
                    </DropdownToggle>
                    <DropdownMenu style={{ width: "222px" }}  >
                      <>
                        <DropdownItem header>
                          <TextField
                            placeholder="Search Product"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                            size="small"
                            variant="outlined"
                            onChange={(e) => setProductSearch(e.target.value)}
                          />
                        </DropdownItem>
                        <div className={classes.dropdownItem}>
                          {productList && productList.length > 0 ? (
                              productList
                              .filter((e) =>
                                  e.product.toLowerCase().includes(productSearch.toLowerCase())
                              )
                              .map((el, i) => (
                                <DropdownItem
                                  key={el.id}
                                  align="center"
                                  style={{ overflowWrap: "break-word" }}
                                  onClick={() => {
                                    setSelectedProductName(el.product);
                                    setSelectedProductId(el.id);
                                  }}
                                >
                                  {el.product}
                                </DropdownItem>
                              ))
                          ) : (
                            <DropdownItem align="center">
                              No Product available
                            </DropdownItem>
                          )}
                        </div>
                      </>
                    </DropdownMenu>
                    <div
                      style={{
                        color: "red",
                        fontSize: 12,
                        textAlign: "left",
                      }}
                    ></div>
                  </UncontrolledDropdown>
                </div>
              </div>
            </div>
            <div className="btns_add_user">
              <Button
                type="submit"
                className={classes.saveButton}
                startIcon={<img src={updateIcon} width="100%" alt="" />}
                onClick={LoginSubmit}
              >
                Save
              </Button>
            </div>
          </div>
        </div>

        <div style={{ padding: "1rem 1rem" }}>
          {firstSection.map((row, index) => (
            <div className="row" key={index}>
              {/* Bank Dropdown */}
              <div className="col-sm-3">
                <div className={classes.formRow}>
                  <UncontrolledDropdown
                    style={{ margin: "0px 5px", width: "100%" }}
                  >
                    <DropdownToggle
                      caret
                      style={{
                        width: "95%",
                        display: "flex",
                        justifyContent: "space-between",
                        paddingTop: "10px",
                        paddingBottom: "6px",
                        background: "white",
                        color: "#F86E20",
                        border: "1px solid #F86E20",
                        alignItems: "center",
                        fontSize: "14px",
                      }}
                    >
                      {row.fk_bank_name ? row.fk_bank_name : "Select a Bank"}
                    </DropdownToggle>
                    <DropdownMenu style={{ width: "100%" }}>
                      <DropdownItem header>
                        <TextField
                          placeholder="Search Bank"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                          size="small"
                          variant="outlined"
                          onChange={(e) => setBankSearch(e.target.value)}
                        />
                      </DropdownItem>

                      <div style={{ maxHeight: 95, overflowY: "scroll" }}>
                        {bankList && bankList.length > 0 ? (
                          bankList
                            .filter((e) =>
                              e.bank
                                .toString()
                                .toLowerCase()
                                .includes(bankSearch.toLowerCase())
                            )
                            .map((el, i) => (
                              <DropdownItem
                                key={el.id}
                                onClick={() =>
                                  handleBankChange(index, el.id, el.bank)
                                }
                              >
                                {el.bank}
                              </DropdownItem>
                            ))
                        ) : (
                          <DropdownItem align="center">
                            No Bank available
                          </DropdownItem>
                        )}
                      </div>
                    </DropdownMenu>
                    <div
                      style={{
                        color: "red",
                        fontSize: 12,
                        textAlign: "left",
                      }}
                    ></div>
                  </UncontrolledDropdown>
                </div>
              </div>

              {/* Status Dropdown */}
              <div className="col-sm-3">
                <div className={classes.formRow}>
                  <UncontrolledDropdown
                    style={{ margin: "0px 5px 0px -3px", width: "100%" }}
                  >
                    <DropdownToggle
                      caret
                      style={{
                        width: "95%",
                        display: "flex",
                        justifyContent: "space-between",
                        paddingTop: "10px",
                        paddingBottom: "6px",
                        background: "white",
                        color: "#F86E20",
                        border: "1px solid #F86E20",
                        alignItems: "center",
                        fontSize: "14px",
                      }}
                    >
                      {row.fk_status_name
                        ? row.fk_status_name
                        : "Select Status"}
                    </DropdownToggle>
                    <DropdownMenu style={{ width: "100%" }}>
                      <DropdownItem header>
                        <TextField
                          placeholder="Search"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                          size="small"
                          variant="outlined"
                          onChange={(e) => setStatusSearch(e.target.value)}
                        />
                      </DropdownItem>
                      {Object.entries(row?.status).length > 0 ? (
                        <div className={classes.dropdownItem}>
                          {row.status ? (
                            Object.entries(row.status)
                              .map(([id, status]) => ({ id, status }))
                              .filter(
                                (e) =>
                                  e.status &&
                                  e.status
                                    .toString()
                                    .toLowerCase()
                                    .includes(statusSearch.toLowerCase())
                              )
                              .map((el, i) => (
                                <DropdownItem
                                  key={el.id}
                                  onClick={() =>
                                    handleStatusChange(index, el.id, el.status)
                                  }
                                >
                                  {el.status}
                                </DropdownItem>
                              ))
                          ) : (
                            <DropdownItem align="center">
                              No Status available
                            </DropdownItem>
                          )}
                        </div>
                      ) : (
                        <div className={classes.dropdownItem}>
                          {statusLoginArr && statusLoginArr.length > 0 ? (
                            statusLoginArr
                              .filter(
                                (e) =>
                                  e.status &&
                                  e.status
                                    .toString()
                                    .toLowerCase()
                                    .includes(statusSearch.toLowerCase())
                              )
                              .map((el, i) => (
                                <DropdownItem
                                  key={el.id}
                                  onClick={() =>
                                    handleStatusChange(index, el.id, el.status)
                                  }
                                >
                                  {el.status}
                                </DropdownItem>
                              ))
                          ) : (
                            <DropdownItem align="center">
                              No Status available
                            </DropdownItem>
                          )}
                        </div>
                      )}
                    </DropdownMenu>
                    <div
                      style={{
                        color: "red",
                        fontSize: 12,
                        textAlign: "left",
                      }}
                    ></div>
                  </UncontrolledDropdown>
                </div>
              </div>

              {/* Amount Field */}
              <div className="col-sm-2">
                <TextField
                  size="small"
                  placeholder="Login Amount"
                  inputProps={{
                    className: classes.multilineColor,
                    style: {
                      height: "13px",
                    },
                  }}
                  className={classes.root}
                  id="login_amount"
                  variant="outlined"
                  name="login_amount"
                  onChange={(e) =>
                    handleLoginAmountChange(index, e.target.value)
                  }
                  value={row.login_amount}
                />
              </div>

              <div className="col-sm-2">
                {row.is_sanction && (
                  <TextField
                    size="small"
                    placeholder="Sanction Amount"
                    inputProps={{
                      className: classes.multilineColor,
                      style: {
                        height: "13px",
                      },
                    }}
                    className={classes.root}
                    id="sanction_amount"
                    variant="outlined"
                    name="sanction_amount"
                    onChange={(e) =>
                      handleSanctionAmountChange(index, e.target.value)
                    }
                    value={row.sanction_amount}
                  />
                )}
              </div>

              {/* Delete Button */}
              <div className="col-sm-2" style={{ paddingLeft: "50px" }}>
                {index === 0 ? (
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#28a745",
                      color: "#FFF",
                      paddingLeft: "26px",
                      paddingRight: "25px",
                    }}
                    onClick={() => addRow()}
                  >
                    ADD
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => deleteRow(index)}
                  >
                    Delete
                  </Button>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default LoginBank;
