import { useRouteError } from "react-router-dom"

const ErrorBoundary=()=>{
    const error=useRouteError()
    return(
        <div>
            Oops, Something went wrong!
        </div>
    )
}

export default ErrorBoundary;