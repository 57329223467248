const firebaseConfig = {
    apiKey: "AIzaSyD8WFkkkuxyo4ifH_ZKtNcdaE1wdhqoaQA",
    authDomain: "callpall-1cb16.firebaseapp.com",
    projectId: "callpall-1cb16",
    storageBucket: "callpall-1cb16.appspot.com",
    messagingSenderId: "554565339177",
    appId: "1:554565339177:web:f77c14e43a09c34ac97438",
    measurementId: "G-RTHLP90C72",
  };
  
  module.exports = firebaseConfig;