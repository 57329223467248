import React from "react";
import { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import { makeStyles,useTheme } from "@material-ui/core/styles";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import "./TeamStatus.css";
import useMediaQuery from '@mui/material/useMediaQuery';
import { TeamLiveStatusApi } from "../../Services/TeamLiveStatusApi";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { getCookie } from "../../Utils/commonUtils";
import { useParams } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import FilterListRoundedIcon from "@material-ui/icons/FilterListRounded";
const firebaseConfig = require('../../constants/firebaseConfig');


const useStyles = makeStyles({
  cardContainerOne: {
    margin: "20px",
    background: "#deffd3",
    borderRadius: "30px !important",
    padding: "20px",
  },
  cardContainerTwo: {
    margin: "20px",
    background: "#fbdbff",
    borderRadius: "30px !important",
    padding: "20px",
  },
  cardContainerThree: {
    margin: "20px",
    background: "#fffaca",
    borderRadius: "30px !important",
    padding: "20px",
  },
});

function createData(number, item, qty, price) {
  return { number, item, qty, price };
}

export default function TeamStatus() {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const [liveStatusDetails, setLiveStatusDetails] = useState([]);
  const [onCallLiveCount, setOnCallLiveCount] = useState(0);
  const [onBreakCount, setOnBreakCount] = useState(0);
  const [teamLiveStatusDetails, setTeamLiveStatusDetails] = useState([]);
  const loginInfo = getCookie("callpal-user");

  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

  const params = useParams();
  const id = params.id != "" && params.id != undefined  ? atob(params.id)
      : loginInfo.userInfo.id;

  const [userData, setUserData] = useState(null);
  useEffect(() => {

    const fetchData = async () => {
      try {
        // Your initial fetches and setup
        const res = await TeamLiveStatusApi.getTeamLiveStatus();
        if (res.status) {
          const result = res.data.find(item => item.status === 'on_break');
          const onBreakCountVal = result ? result.count : 0;
          setOnBreakCount(onBreakCountVal);
          const db = firebase.firestore();
          const telecallersArray = Object.keys(res.telecallers).map((key) =>
            String(key)
          );
          const onCallUserList = db
        //  alert(process.env.REACT_APP_FIREBASE_CALL_TABLE)
            .collection(process.env.REACT_APP_FIREBASE_CALL_TABLE)
            .where(
              firebase.firestore.FieldPath.documentId(),
              "in",
              telecallersArray
            );
          // Set up real-time listener for the onCallUserList query

          const unsubscribe = onCallUserList.onSnapshot((snapshot) => {
            const numberOfUsers = snapshot.size;
            setOnCallLiveCount(numberOfUsers);
            const documentDataArray = [];
            // setLiveStatusDetails([]);
            snapshot.forEach((doc) => {
              const timestamp = parseInt(doc.data().call_started_on); // Unix timestamp
               // Convert timestamp to moment object in UTC and adjust for timezone offset to IST
              const startTime = moment.utc(timestamp).utcOffset('+05:30');

              // Calculate duration using current time
              const duration = moment.duration(moment().diff(startTime));
              // Get hours, minutes, and seconds from the duration
              const hours = Math.floor(duration.asHours());
              const minutes = duration.minutes();
              const seconds = duration.seconds();
              // Create formatted string
              // Create formatted string
              let formattedTime = '';
              if (hours > 0) {
                  formattedTime += hours.toString().padStart(2, '0') + ':';
              }
              if (minutes > 0 || hours > 0) {
                  formattedTime += minutes.toString().padStart(2, '0') + ':';
              }
              formattedTime += seconds.toString().padStart(2, '0'); 

              // formattedTime += isNaN(formattedTime) ? 0 : formattedTime


              // Add units to the formatted string
              if (hours > 0) {
                  formattedTime += ' H';
              } else if (minutes > 0) {
                  formattedTime += ' M';
              } else {
                  formattedTime += ' S';
              }
              
              const dataObject = {
                name: res.telecallers[doc.id],
                doc_id: res.telecallers[doc.id],
                time_or_count: formattedTime,
              };
              // Push the object into the documentDataArray
              documentDataArray.push(dataObject);
            });
            setLiveStatusDetails(documentDataArray);
          });
          // Return cleanup function to unsubscribe from the listener
          return () => unsubscribe();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData(); // Call the async function immediately
    fetchTeamLiveStatusDetails();
  }, []);

   // const fetchTeamLiveStatusDetails = async ( activeStatus,startDate, endDate,page) => {
    const fetchTeamLiveStatusDetails = async () => {
      try {
        const data = JSON.stringify({
          per_page_row: 2,
          page: 1,
          status: 'on_break',
          fromdate: startDate,
          todate: endDate,
          team_lead_id: parseInt(id),
        });
        const res = await TeamLiveStatusApi.getTeamLiveStatusDetails(data);
        if (res.status) {
          setTeamLiveStatusDetails(res.data);
        }
      } catch (e) {
        console.error(e);
      }
    };
  

  return (
    <Card style={{marginTop: isMobile ? 20 : 0, height:"600px"}}>
      <h2 className="team-Heading text-center">Team Status</h2>
      {/* <h4 className="text-center">Coming Soon</h4> */}
      <Card className={classes.cardContainerOne}>
      <div className="team-header-flex">
        <p className="card-heading-one">On Call {onCallLiveCount}</p>
        {onCallLiveCount > 0 && (
          <div className="team-see-con">
            <p className="see-all-txt">
              <NavLink to={"/dashboard/team-live-status"} style={{textDecoration:"none",color:"rgba(0, 0, 0, 0.87)"}} >
                See All
              </NavLink>
            </p>
            <ArrowForwardIosRoundedIcon className="arrow-icon" />
          </div>
        )}
      </div>
        <div className="mt-2 mb-2">
           { liveStatusDetails && liveStatusDetails.slice(0,2).map((item, index) => (
           <div className="team-content-flex">
            <p className="con-txt">{item.name}</p>
            <p className="con-txt">{item.time_or_count ? item.time_or_count : "-"}</p>
          </div>
           ))}
        </div>
      </Card>
      {/* <Card className={classes.cardContainerTwo}>
        <div className="team-header-flex">
          <p className="card-heading-two">Idle 2</p>
          <div className="team-see-con">
            <p className="see-all-txt">See All </p>
            <ArrowForwardIosRoundedIcon className="arrow-icon" />
          </div>
        </div>
        <div className="mt-2 mb-2">
          <div className="team-content-flex">
            <p className="con-txt">EID 32535</p>
            <p className="con-txt">EID 32535</p>
          </div>
        </div>
        <div className="team-content-flex">
          <p className="con-txt">EID 32535</p>
          <p className="con-txt">EID 32535</p>
        </div>
      </Card> */}
      <Card className={classes.cardContainerThree}>
        <div className="team-header-flex">
          <p className="card-heading-three">On Break {onBreakCount}</p> 
           {onBreakCount > 0 && (
            <div className="team-see-con">
              <p className="see-all-txt">
                <NavLink to={"/dashboard/team-live-status"} style={{textDecoration:"none",color:"rgba(0, 0, 0, 0.87)"}} >
                  See All
                </NavLink>
              </p>
              <ArrowForwardIosRoundedIcon className="arrow-icon" />
            </div>
            )}
          </div>
          <div className="mt-2 mb-2">
          {teamLiveStatusDetails && teamLiveStatusDetails.map((item, index) => (
          <div className="team-content-flex">
              <p className="con-txt">{item.name}</p>
              <p className="con-txt">{item.time_or_count ? item.time_or_count : "-"}</p>
          </div>
          ))}
          </div>
      </Card>
    </Card>
  );
}
