import { Button, Container } from "@material-ui/core";
import plate from "../../Images/plate.png";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../constants/routes";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "300px",
    height: "300px",
    backgroundColor: "#F9F4F0",
    transition: ".5s all ease-in-out;",
    "&:hover": {
      backgroundColor: "#fff",
      boxShadow: "10px 5px 10px 5px #D7D2D1",
      cursor: "pointer",
    },
    borderRadius: "12px",
    marginRight: "20px",
    marginTop: "40px",
    [theme.breakpoints.down("xs")]: {
      marginRight: "0px",
    },
  },
  icons: {
    padding: "20px 0px",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
}));

const Home3 = () => {
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const navigate = useNavigate();
  return (
    <div>
      <Container maxWidth="md" style={{ padding: "60px 20px" }}>
        <div>
          <div style={{ fontSize: "30px", color: "#3A1C67", fontWeight: 700 }}>
            The <span style={{ color: "#F86E20" }}>Product</span> We Work With.
          </div>
          <div style={{ fontSize: "18px", color: "#848891" }}>
            Get supremely efficient tools to get rid of most manual and tiresome
            processes and boost your business with better time-management.
          </div>
        </div>
        <div className={classes.container}>
          <div
            className={classes.card}
            onClick={() => navigate(routes.postCrm)}
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
          >
            <img loading="eager" src={plate} style={{ padding: "20px" }} />
            <div style={{ padding: "0px 40px" }}>
              <div
                style={{ fontSize: "26px", color: "#3A1C67", padding: "0px" }}
              >
                POS CRM
              </div>
              {/* <div style={{ color: "#B32B88" }}>
                Elit esse cillum dolore eu fugiat nulla pariatur
              </div> */}
              {show && (
                <div className={classes.icons}>
                  <Button>
                    <ArrowRightAltIcon />
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div
            className={classes.card}
            onClick={() => navigate(routes.fieldCrm)}
            onMouseEnter={() => setShow1(true)}
            onMouseLeave={() => setShow1(false)}
          >
            <img loading="eager" src={plate} style={{ padding: "20px" }} />
            <div style={{ padding: "0px 40px" }}>
              <div
                style={{ fontSize: "26px", color: "#3A1C67", padding: "0px" }}
              >
                Field CRM
              </div>
              {/* <div style={{ color: "#B32B88" }}>
                Elit esse cillum dolore eu fugiat nulla pariatur
              </div> */}
              {show1 && (
                <div className={classes.icons}>
                  <Button>
                    <ArrowRightAltIcon />
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default Home3;
