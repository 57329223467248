import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CircularProgress } from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {  makeStyles, Radio } from '@material-ui/core';
import { useState } from 'react';
import { OrangeCheckbox } from '../../Styles';
import { useFormik } from "formik";
import * as Yup from "yup";
const useStyles = makeStyles({
  button:{
    
         backgroundColor: "#3A1C67",
         color: "#fff",
         textTransform: "none",
         "&:hover": {
           backgroundColor: "#F76A1A",
         },
         border:'1px solid #fff'
  },
  clearButton:
  {
    
     backgroundColor: "#FA9510",
     color: "#fff",
     textTransform: "none",
     "&:hover": {
       backgroundColor: "#F76A1A",
     },
 },
 empolyeeName:{
     fontSize:'16px',
     color:'#fff'
 },
 content:{
   minWidth:'400px',
   '&::-webkit-scrollbar': {
     width: '0.5em',
     display: 'block',
   },
   '&::-webkit-scrollbar-thumb': {
     backgroundColor: 'darkgrey',
     outline: '1px solid slategrey',
     borderRadius: '5px',
   },
 }
 })
export default function IndustrySubTypePopper({industrySubTypeArry, isOpen , updateUserIndustrySubType}) {
  const classes = useStyles();
  const array = industrySubTypeArry; 
  const [open, setOpen] = useState(isOpen);
  const [state, setState] = useState({
    uploadType1: false,
    uploadType2: false,
    uploadType3: false,
    uploadType4: false,
  })
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const validationSchema = Yup.object({
    industry_sub_type: Yup.string().required('Please select an industry sub-type'),
  });

  const formik = useFormik({
    initialValues: {
      industry_sub_type: '',
      industry_sub_type_name: '',
      ind_sub_type: '',
    },
    validationSchema: validationSchema,
    onSubmit: (data) => {
     updateUserIndustrySubType(data)

    },
  });

  return (
    <div>
      <Dialog
        open={isOpen}
        // open={true}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      > 
        {/* <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           Select any one Industry Sub type
          </DialogContentText>
        </DialogContent>
        <DialogContent>
        <form onSubmit={formik.handleSubmit}>
      {array && array.map((el) => (
        <div key={el.id} style={{ paddingTop: 10 }}>
          <FormControlLabel
            control={
              <Radio
                checked={formik.values.ind_sub_type === el.type}
                onChange={(event) => {
                  formik.setFieldValue('industry_sub_type', el.id);
                  formik.setFieldValue('industry_sub_type_name', el.name);
                  formik.setFieldValue('ind_sub_type', el.type);
                }}
                onBlur={formik.handleBlur}
                value={el.type}
                name="industry_sub_type"
              />
            }
            label={
              <div style={{ display: 'flex' }}>
                <div className={classes.allocated}>
                  {el.name} {/* Use the name from the array item */}
                </div>
              </div>
            }
          />
        </div>
      ))}
      {formik.touched.industry_sub_type && formik.errors.industry_sub_type ? (
        <div style={{ color: 'red' }}>{formik.errors.industry_sub_type}</div>
      ) : null}
      <DialogActions  style={{ display: 'flex',justifyContent:"center" }}>
        <div className="btns_add_user">
          <Button type="submit" style={{ color: '#fff', backgroundColor: '#3A1C67' }}>
            {formik.isSubmitting ? <CircularProgress size={25} style={{ color: '#fff' }} /> : 'Continue'}
          </Button>
        </div>
      </DialogActions>
    </form>




        {/* <RadioGroup value={selectedValue} onChange={handleChange}>
      {array.map((el) => (
        <FormControlLabel
          key={el.id}
          control={
            <Radio
              value={el.id.toString()}
              name="industry-sub-type" 
            />
          }
          label={
            // className={classes.empolyeeName}
            <div >
              {el.name}
            </div>
          }
        />
      ))}
    </RadioGroup> */}
    </DialogContent>


        {/* <DialogActions>
            <div className="btns_add_user" > */}
            {/* onClick={()=>deleteUser()} */}
          {/* <Button  style={{color:'#fff',backgroundColor:'#3A1C67'}}>
            {loading?<CircularProgress size={25} style={{color:'#fff'}}/>:'Continue'}
          </Button>
          </div>
          {/* <div className="btns_add_user">
          <Button onClick={handleClose} style={{color:'#fff',backgroundColor:'#FA9510'}}>
            Cancel
          </Button>
          </div> */}
        {/* </DialogActions>  */}
      </Dialog>
    </div>
  );
}