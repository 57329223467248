import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormControlLabel, makeStyles } from '@material-ui/core';
import { OrangeCheckbox } from '../../../Styles';
import { useState } from 'react';
import UseGetUserData from '../../../Hooks/UseGetUserData';
const useStyles = makeStyles({
 button:{
   
        backgroundColor: "#3A1C67",
        color: "#fff",
        textTransform: "none",
        "&:hover": {
          backgroundColor: "#F76A1A",
        },
        border:'1px solid #fff'
 },
 clearButton:
 {
   
    backgroundColor: "#FA9510",
    color: "#fff",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#F76A1A",
    },
},
empolyeeName:{
    fontSize:'16px',
    color:'#fff'
},
content:{
  minWidth:'400px',
  '&::-webkit-scrollbar': {
    width: '0.5em',
    display: 'block',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'darkgrey',
    outline: '1px solid slategrey',
    borderRadius: '5px',
  },
}
})
export default function ReassignSelectUser({anchorEl,anchorE2,setAnchorE1,setSelectedUsers}) {
 const classes=useStyles()
 const data=UseGetUserData()
const [arr,setArr]=useState([])
const [open,setOpen]=useState(false)

useEffect(()=>{
setArr(data)
},[data])

useEffect(()=>{
  if(anchorEl){
    setOpen(true)
  }
  else if(anchorE2){
    const f=[]
    arr.forEach((el)=>{
      f.push(el.id)
    })
    setSelectedUsers(f)
  }
  else{
    clearAll()
  }

 

},[anchorEl,anchorE2])



  const handleChange=(e,val)=>{
    
    const i= arr.findIndex(el=>el.id==val.id)
    if(i>-1){
        const f=[...arr]
        f[i].id=val.id
        f[i].checked=e.target.checked
        f[i].reporting_to=val.reporting_to
        setArr(f)
    }

  }

  const selectAll=()=>{
    const f=[]
    arr.forEach((el,i)=>{
        f.push({
            id:el.id,
            checked:true,
            reporting_to:el.reporting_to
        })

    })
    setArr([...f])
  }
  const clearAll=()=>{
    const f=[]
    arr.forEach((el,i)=>{
        f.push({
            id:el.id,
            checked:false,
            reporting_to:el.reporting_to
        })

    })
    setArr([...f])
    setSelectedUsers([])
  }

  const handleLeadImport=()=>{
    let userArr=[]
    arr.forEach((el=>{
      if(el.checked){
        userArr.push(el.id)
      }
    }))
    
    setSelectedUsers(userArr)
  }

  return (
    <div>
      
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
            style: {
              backgroundColor: '#3A1C67',
            borderRadius:14
            },
          }}  
      >
        <DialogTitle id="alert-dialog-title">
            <div style={{textAlign:'center',color:'#FA9510',fontWeight:600,fontSize:16}}>
                Select users
            </div>
            <div style={{display:'flex',justifyContent:'space-between',marginTop:20}}>
            <Button className={classes.button} onClick={selectAll}>
                Select all
            </Button>
            <Button className={classes.button}>
                Select my team
            </Button>
            <Button className={classes.clearButton} onClick={clearAll}>
                Clear all
            </Button>
          </div>
        </DialogTitle>
        <DialogContent className={classes.content}>
          
          
          { arr.length>0 &&arr.map((el,i)=>
          (
            <div >
            <FormControlLabel
            key={el.id}
                  control={
                    <OrangeCheckbox
                      checked={el.checked}
                      onChange={(e)=>handleChange(e,el)}
                      name={el.id}
                    />
                  }
                  label={
                      <div className={classes.empolyeeName}>
                        {el.reporting_to}
                      </div>
                  }
                />
                 </div>
          ))}
         
        </DialogContent>
        <DialogActions style={{display:'flex',justifyContent:'center',padding:'20px 0px'}}>
          <Button onClick={()=>{clearAll(); setOpen(false);setAnchorE1(false);}} className={classes.button}>
           Discard
          </Button>
          <Button onClick={()=>{handleLeadImport(); setOpen(false);}} className={classes.clearButton}>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
