import axios from "axios"
import { getCookie } from '../Utils/commonUtils'

export const TeamLiveStatusApi = {
  getTeamLiveStatusInfo: async (data) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/data-allocation`, data, {
              headers: {
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getCookie('callpal-user').token}`
              }
            });
            return response; // Assuming your data is in response.data
          } catch (error) {
            console.error('Error in getTeamLiveStatusInfo:', error);
            return false
            //throw error; // Re-throw the error to handle it at the calling site if needed
          }
    },
  //   getTeamLiveStatus: async (data) => {
  //     try {
  //         const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/data-allocation`, data, {
  //           headers: {
  //             'Accept': 'application/json',
  //             'Access-Control-Allow-Origin': '*',
  //             'Content-Type': 'application/json',
  //             'Authorization': `Bearer ${getCookie('callpal-user').token}`
  //           }
  //         });
  //         return response; // Assuming your data is in response.data
  //       } catch (error) {
  //         console.error('Error in getTeamLiveStatusInfo:', error);
  //         return false
  //         //throw error; // Re-throw the error to handle it at the calling site if needed
  //       }
  // },
  getTeamLiveStatus: async (data) => {
    try {
       
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/team-live-status`, {
          headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getCookie('callpal-user').token}`
          }
        });
        return response; // Assuming your data is in response.data
      } catch (error) {
        console.error('Error in getTeamLiveStatus:', error);
        return false
        //throw error; // Re-throw the error to handle it at the calling site if needed
      }
},
getTeamLiveStatusDetails: async (data) => {
  try {
      const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/team-live-status-detail`, data, {
        headers: {
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getCookie('callpal-user').token}`
        }
      });
      return response; // Assuming your data is in response.data
    } catch (error) {
      console.error('Error in teamLiveStatusDetails:', error);
      return false
      //throw error; // Re-throw the error to handle it at the calling site if needed
    }
},


}