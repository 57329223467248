import { Box, Button, Checkbox, Container, FormControlLabel, TextField, makeStyles } from "@material-ui/core";
import leftVector from '../../Images/Left_Vector.png'
import note from "../../Images/note.png"
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        backgroundColor: '#FFC28033',
        padding: '30px 120px',
        //textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            textAlign:'center',
            padding: '40px',
           },
    },
    form: {
        //marginTop:'20px',
        width: '70%',
        backgroundColor: '#fff',
        padding: '30px 60px 30px 30px',
        textAlign: 'left',
        borderRadius: '12px',
        height: '370px',
        //marginLeft:'20px',
        position: 'absolute',

        left: '400px',
        bottom: '100px',
        [theme.breakpoints.down('sm')]: {
           position:'relative',
           left: '0px',
        bottom: '0px',
        width:'100%',
        marginTop:'40px'
          },
    
    },

    root: {

        '& label.Mui-focused': {
            color: '#FA9510',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#FA9510',

            },
            '&:hover fieldset': {
                borderColor: '#FA9510',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#FA9510',
            },
        },
        marginBottom: '20px',
        width: '100%'
    },
    multilineColor: {
        color: '#FA9510'
    },
    button: {
        backgroundColor: '#FA9510',
        color: '#fff',
        textTransform: 'none', marginTop: '20px',
        width: '200px',
        transition:'.4s all ease-in-out',
        '&:hover':{
            backgroundColor:'#FA9510',
            boxShadow:'-2px 5px 8px #575f6b80'
          }
    },
    imgContainer: {
       
        position: 'relative', 
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            alignItems:'center',
            flexDirection:'column-reverse'
           },
    },
    image:{
        width:'75%',
        height:'auto',
        [theme.breakpoints.down('xs')]: {
          width:'100%'
           },
           [theme.breakpoints.down('sm')]: {
            width:'60%'
             },
    }
    
}))
const PartnerForm = () => {
    const classes = useStyles()
    return (

        <Box className={classes.container}>
            <Box className={classes.imgContainer}>
                <Box className={classes.form}>
                    <Box style={{ color: '#3A1C67', marginBottom: '20px', fontWeight: 600 }}>
                        Get 10 Days Free Trial
                    </Box>
                    <TextField
                        size="small"
                        placeholder="Name*"
                        inputProps={{
                            className: classes.multilineColor
                        }}
                        className={classes.root} id="outlined-basic" variant="outlined" />
                    <TextField
                        size="small"
                        placeholder="Email Address*"
                        inputProps={{
                            className: classes.multilineColor
                        }}
                        className={classes.root} id="outlined-basic" variant="outlined" />
                    <TextField
                        size="small"
                        placeholder="Phone Number*"
                        inputProps={{
                            className: classes.multilineColor
                        }}
                        className={classes.root} id="outlined-basic" variant="outlined" />
                    <div>
                        <label style={{ color: '#848891', fontSize: '14px', paddingBottom: '20px' }}>
                            <input type="checkbox" style={{margin: "0 5px 0 0"}} />
                            By clicking here I agree to the privacy policy
                        </label>
                    </div>
                    <Button className={classes.button} id="free_trial">
                        Try Free Trial
                    </Button>
                </Box>
                <img loading="eager" src={note} className={classes.image} />
                
            </Box>


        </Box>


    )
}
export default PartnerForm;
