
import Footer from "../Footer";
import Header from "../Header";
// import Transforming from '../../Images/Transforming.png'
import blog1 from '../../Images/Blog-1.jpg'
// import crm2 from '../../Images/crm2.png'
import c1 from '../../Images/c1.png'
import c2 from '../../Images/c2.png'
import c3 from '../../Images/c3.png'
import c4 from '../../Images/c4.png'
import c5 from '../../Images/c5.png'
import c6 from '../../Images/c6.png'
import c7 from '../../Images/c7.png'
import dot from '../../Images/dot.png'
import { makeStyles } from "@material-ui/core";
import HeadingPara from "../../Common/HeadingPara";
import Home4 from "../Home/Home4";
const useStyles = makeStyles((theme) => ({
    cardWithImg:{
        marginTop:"66px",
        display:'flex',
        background: '#F0DBD8',
        marginBottom: "25px"

    },
    paraCard:{
        marginLeft:"80px",
        marginRight:"80px",
        background:"#F9F4F0"
    },
    heading:{
        color:"#FA9510",
        fontWeight:"700px",
        fontSize:"30px",
        textAlign:"start"
    },
    img:{
        width:"346",
        marginRight:"20px"
    },
    cardFlex:{
        display:"flex",
        marginLeft:"80px",
        marginRight:"80px",
        marginTop:"15px"
    },
    paraHeding: {
        fontSize: "18px",
        color:'#B32B88',
        fontWeight: 700,
        textAlign:"start",
        marginBottom:'10px'
      },

      faq: {
        fontSize: "30px",
        color:'#3A1C67',
        fontWeight: 700,
        textAlign:"start",
        marginLeft:"80px",
        marginTop:"20px"
      },
      paraHeding2: {
        fontSize: "24px",
        color:'#3A1C67',
        fontWeight: 600,
        textAlign:"start",
        marginBottom:'0px'
      },
      questions: {
        fontSize: "24px",
        color:'#3C1D68',
        fontWeight: 600,
        textAlign:"start",
        marginBottom:'0px',
        marginTop:"15px"
      },
      boxContainer:{
        display:'flex',
        flexDirection:'column ',
        width:'45%',
        background: '#F0DBD8'

      },
      crm1:{
        width:'55%',
        height: '394px'
      },
      title:{
        fontSize:'20px',
        fontWeight:700,
        color: '#3A1C67',
        marginTop:'10px'
      }
  }))

const Blogs1=()=>{
    const classes = useStyles()
    return(
        <div>
            <Header/>
            <div className={classes.cardWithImg}>
            <img src={blog1} loading='eager' alt='' className="feature_img" />
             {/* <div className={classes.boxContainer}>
                  <p className={classes.title}>Transforming Your Business Process with Callpal's Unique Field CRM</p>
                    <img src={crm2} loading='eager' alt=''  />
            </div> */}

            </div>
            <div className={classes.paraCard} > 
            <HeadingPara color='#B32B88'  subTitle="As someone who runs several businesses, you probably want to keep all your data in one place. It can be frustrating to switch between different CRM tools. We understand this problem, and that's why we created a solution that can help you manage all your businesses in one spot. Our solution, called Callpal, comes with multiple CRM options and makes it easy for you to connect and oversee all your businesses on one single platform. You won't need to switch between different CRMs anymore, saving you time and effort. Our goal is to make managing multiple businesses simpler. With Callpal's multiple CRMs, you can keep your data and communication in one place. This way, you can be more efficient and focus on growing your businesses." />
            <HeadingPara  title="What is Filed CRM?" subTitle="Field CRM is a software solution designed to optimize field operations for businesses with a significant presence in the field. It is a powerful tool for sales teams, service technicians, and other personnel working outside traditional office settings. Field CRM is a technology-driven solution to meet the evolving needs of modern businesses in the dynamic world of customer relationship management.Field CRM streamlines and automates field-related tasks, enhancing efficiency and decision-making. It offers features like real-time data sharing, location tracking, task assignment, customer data access, communication tools, and analytics, making it an invaluable asset for businesses in diverse industries. This technology enhances customer service, increases productivity, and improves decision-making." />
            <HeadingPara  title="How Callpal’s Field CRM help you grow your business?" subTitle="In the fast-paced world of customer relationship management (CRM), the advent of Field CRM has brought about a significant transformation in the way businesses manage their field operations. Callpal, with its feature-rich Field CRM, stands out as a pioneer in this realm, providing a solution that makes field operations not just manageable but hassle-free. What sets Callpal's Field CRM apart from other mobile-based CRMs are its distinct and efficient characteristics." />
            <p className={classes.heading}>Lets learn about our Field CRM and how it can help you.</p>
            </div>
            <div className={classes.cardFlex}>
                 <img loading="eager" src={c1} alt="" className={classes.img}/>
                <HeadingPara color="#252525" headingClass={classes.paraHeding} title="Track Location in Real Time During Live Events" subTitle="One of the standout features of Callpal's Field CRM is its real-time and event-based location tracking. In the hustle and bustle of live events, staying informed about your staff's activities, their whereabouts, and the timing of their tasks is vital. Callpal's Field CRM ensures that you have a comprehensive mobile solution that covers these aspects seamlessly enabling better coordination and management." />
           </div>
           <div className={classes.cardFlex}>
                 <img loading="eager" src={c2} alt="" className={classes.img}/>
                <HeadingPara color="#252525" headingClass={classes.paraHeding} title="Real-time Sales Opportunity Analytics" subTitle="In the world of sales, timing is everything. Field CRM by Callpal takes the guesswork out of sales opportunities by providing real-time visibility into each unfolding scenario in the field. Sales managers gain access to staff assignments, schedules, sales reports, deals, and ongoing projects, allowing them to stay constantly updated on the progress of business ventures. This invaluable feature empowers businesses to make informed decisions and seize opportunities as they arise." />
           </div>
           <div className={classes.cardFlex}>
                 <img loading="eager" src={c3} alt="" className={classes.img}/>
                <HeadingPara color="#252525" headingClass={classes.paraHeding} title="Real-time Task Notifications" subTitle="In a dynamic business environment, agility is key. Callpal's Field CRM enables you to send crucial tasks to your team in real-time, providing them with immediate guidance for lead conversion and deal closure while they're on the move. This feature ensures that no opportunity is missed and tasks are efficiently managed, all with a few taps on your mobile device." />
           </div>
           <div className={classes.cardFlex}>
                 <img loading="eager" src={c4} alt="" className={classes.img}/>
                <HeadingPara color="#252525" headingClass={classes.paraHeding} title="Monitoring of Employee Performance in Real Time" subTitle="Effective management hinges on staying informed. With Field CRM, you gain real-time access to staff performance data through an integrated platform. Sales managers can track employee performance and take action as needed to complete transactions or gain useful insights. This level of monitoring and adaptability is a game-changer in optimizing business processes." />
           </div>
           <div className={classes.cardFlex}>
                 <img loading="eager" src={c5} alt="" className={classes.img}/>
                <HeadingPara color="#252525" headingClass={classes.paraHeding} title="Comprehensive Customer Data at Your Fingertips" subTitle="In the modern business landscape, having access to customer data is non-negotiable. Callpal ensures that your sales team is well-equipped with complete customer data even while on the move. This means that your team can provide a personalized experience for clients, enhancing customer satisfaction and fostering stronger relationships." />
           </div>
           <div className={classes.cardFlex}>
                 <img loading="eager" src={c6} alt="" className={classes.img}/>
                <HeadingPara color="#252525" headingClass={classes.paraHeding} title="Efficient Multi-channel Communication with E-mails and SMS" subTitle="Communication is the backbone of a successful sales operation. Field CRM by Callpal empowers your sales managers with the tools they need to communicate efficiently. Instant SMS and email functionalities allow them to relay task schedules, to-do lists, and urgent messages to the sales team. This streamlined communication not only saves time but also ensures that everyone is on the same page." />
           </div>
           <div className={classes.cardFlex}>
                 <img loading="eager" src={c7} alt="" className={classes.img}/>
                <HeadingPara color="#252525" headingClass={classes.paraHeding} title="Streamlined Lead Allocation" subTitle="Efficiency is the cornerstone of any successful business process. Callpal's Field CRM takes the hassle out of lead distribution with automation. This promotes seamless collaboration and improves the efficiency of the sales process. Leads are assigned swiftly, ensuring that opportunities are capitalized upon." />
           </div>
           <div className={classes.cardFlex}>
           <HeadingPara color='#B32B88' title="Conclusion" subTitle="Callpal's Field CRM is not just a CRM; it's a transformational tool for businesses looking to excel in field operations. With features designed to enhance location tracking, real-time analytics, task management, employee monitoring, data accessibility, communication, and lead allocation, it stands as a unique and efficient solution in the CRM industry. If you're seeking to elevate your business processes, Callpal's Field CRM is the answer you've been looking for." />
           </div>
           <Home4 img={dot}/>

           
            <Footer/>
          
        </div>
    )

}
export default Blogs1;