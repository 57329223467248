import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormControlLabel, TextField, makeStyles } from '@material-ui/core';
import { OrangeSmallCheckbox } from '../../../Styles';
import { bankList } from '../../../Utils/constant';

const useStyles = makeStyles({
    container: {
        width: '100%',
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            width: '0.5em',
            display: 'block'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'darkgrey',
            outline: '1px solid slategrey',
            borderRadius: '5px'
        }
    },
    subContainer: {
        backgroundColor: '#3A1C67',
        color: '#fff'
    },
    formControlLabel: {
        fontSize: 13,
        color: '#3A1C67',

    },
    root: {

        '& label.Mui-focused': {
            color: '#3A1C67',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#3A1C67',

            },
            '&:hover fieldset': {
                borderColor: '#3A1C67',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#3A1C67',
            },
        },
        width: '130px',
        marginBottom: 5,
    },
    multilineColor: {
        color: '#3A1C67',
        fontSize: 14
    },
})

export default function BankListPopper({ showList, setShowList }) {
    const classes = useStyles()
    const handleClose = () => {
        setShowList(false);
    };

    return (
        <div>
            <Dialog
                fullWidth
                open={showList}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle className={classes.subContainer} id="alert-dialog-title">List of Multiple Banks</DialogTitle>
                <DialogContent className={classes.container}>
                    <div >
                        {
                            bankList.map((el, i) => {
                                return (
                                    <div key={i}>
                                        <div style={{ display: 'flex', color: '#3A1C67', alignItems: 'center', width: '100%' }}>
                                            <div style={{ width: '40%' }}>
                                                <FormControlLabel
                                                    classes={{ label: classes.formControlLabel }}
                                                    control={<OrangeSmallCheckbox name="checkedG" />}
                                                    label={
                                                        el
                                                    }
                                                />
                                            </div>
                                            <div style={{ width: '30%' }}>
                                                Status
                                            </div>
                                            <div style={{ width: '30%' }}>
                                                Amount
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>

                </DialogActions>
            </Dialog>
        </div>
    );
}