import { useEffect, useState } from "react"
import { getCookie } from "../Utils/commonUtils"


const UseGetUserData=()=>{
const [userData,setUserData]=useState([])
const loginInfo=getCookie('callpal-user')
  useEffect(()=>{
    const getData=async()=>{
        try{
            // const obj={
            //   telecaller: true
            // };
            // const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/reporting-master`, {
            const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/get-company-team-lead`, {
              method: 'GET',
              headers: {
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${loginInfo.token}`
              },
            })
            const data = await res.json();
            const roleArray = Object.entries(data.data).map(([id, reporting_to]) => ({
              id,
              reporting_to,
            }));
            if (data.status) {
              let arr=[]
              roleArray.forEach(el => {
                arr.push({...el,checked:false})
              });
              setUserData(arr)
            }
        
          }
          catch (e) {
            console.log(e.message)
        
          }
    }
   getData()
  },[])
  return userData;
}
export default UseGetUserData;