import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { Box, Button, TablePagination } from '@material-ui/core'
import closeIcon from '../../Images/close.svg'
import checkIcon from '../../Images/chekSIgn.svg'
import trashIcon from '../../Images/trash.svg'
import { useState } from 'react'
import { StyledTableCell, StyledTableRow } from '../../Styles'
import {
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@material-ui/icons'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap'

const useStyles = makeStyles({
  mainContainer: {
    padding: '80px 30px 30px 30px',
  },
  table: {
    minWidth: 700,
  },
  paginationClass: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  paginationArrows: {
    display: 'flex',
    gap: '10px',
  },
  paginationDrop: {
    display: 'flex',
  },
  button: {
    backgroundColor: '#3A1C67',
    color: '#fff',
    marginLeft: '10px',
    borderRadius: '5px',
    fontSize: '12px',
    padding: '5px 20px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#7916BD',
    },
  },
  tableContainer: {
    margin: '20px 0px',
    maxHeight: '480px',
    width: '100%',
    '&::-webkit-scrollbar': {
      width: '0.5em',
      display: 'block',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'darkgrey',
      outline: '1px solid slategrey',
      borderRadius: '5px',
    },
  },
})

export default function CompanyDetails() {
  const classes = useStyles()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [open, setOpen] = useState(false)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const headers = [
    'Company Name',
    'Address',
    'City',
    'State',
    'Pin Code',
    'GST',
    'Action',
  ]

  const rows = [
    {
      name: 'Brawizz',
      address: 'SB road pune',
      city: 'pune',
      state: 'Maharashtra',
      pinCode: '411043',
      gst: '12345',
    },
    {
      name: 'Brawizz',
      address: 'SB road pune',
      city: 'pune',
      state: 'Maharashtra',
      pinCode: '411043',
      gst: '12345',
    },
    {
      name: 'Brawizz',
      address: 'SB road pune',
      city: 'pune',
      state: 'Maharashtra',
      pinCode: '411043',
      gst: '12345',
    },
    {
      name: 'Brawizz',
      address: 'SB road pune',
      city: 'pune',
      state: 'Maharashtra',
      pinCode: '411043',
      gst: '12345',
    },
    {
      name: 'Brawizz',
      address: 'SB road pune',
      city: 'pune',
      state: 'Maharashtra',
      pinCode: '411043',
      gst: '12345',
    },
    {
      name: 'Brawizz',
      address: 'SB road pune',
      city: 'pune',
      state: 'Maharashtra',
      pinCode: '411043',
      gst: '12345',
    },
    {
      name: 'Brawizz',
      address: 'SB road pune',
      city: 'pune',
      state: 'Maharashtra',
      pinCode: '411043',
      gst: '12345',
    },
    {
      name: 'Brawizz',
      address: 'SB road pune',
      city: 'pune',
      state: 'Maharashtra',
      pinCode: '411043',
      gst: '12345',
    },
    {
      name: 'Brawizz',
      address: 'SB road pune',
      city: 'pune',
      state: 'Maharashtra',
      pinCode: '411043',
      gst: '12345',
    },
    {
      name: 'Brawizz',
      address: 'SB road pune',
      city: 'pune',
      state: 'Maharashtra',
      pinCode: '411043',
      gst: '12345',
    },
    {
      name: 'Brawizz',
      address: 'SB road pune',
      city: 'pune',
      state: 'Maharashtra',
      pinCode: '411043',
      gst: '12345',
    },
    {
      name: 'Brawizz',
      address: 'SB road pune',
      city: 'pune',
      state: 'Maharashtra',
      pinCode: '411043',
      gst: '12345',
    },
    {
      name: 'Brawizz',
      address: 'SB road pune',
      city: 'pune',
      state: 'Maharashtra',
      pinCode: '411043',
      gst: '12345',
    },
    {
      name: 'Brawizz',
      address: 'SB road pune',
      city: 'pune',
      state: 'Maharashtra',
      pinCode: '411043',
      gst: '12345',
    },
    {
      name: 'Brawizz',
      address: 'SB road pune',
      city: 'pune',
      state: 'Maharashtra',
      pinCode: '411043',
      gst: '12345',
    },
    {
      name: 'Brawizz',
      address: 'SB road pune',
      city: 'pune',
      state: 'Maharashtra',
      pinCode: '411043',
      gst: '12345',
    },
  ]

  return (
    <div className={classes.mainContainer}>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table
          stickyHeader
          className={classes.table}
          aria-label='customized table'
          size='small'
        >
          <TableHead>
            <TableRow>
              {headers.map((el, i) => {
                return (
                  <StyledTableCell key={el} align='center'>
                    {el}
                  </StyledTableCell>
                )
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <StyledTableRow>
                  <StyledTableCell align='center'>{row.name}</StyledTableCell>
                  <StyledTableCell align='center'>
                    {row.address}
                  </StyledTableCell>
                  <StyledTableCell align='center'>{row.city}</StyledTableCell>
                  <StyledTableCell align='center'>{row.state}</StyledTableCell>
                  <StyledTableCell align='center'>
                    {row.pinCode}
                  </StyledTableCell>
                  <StyledTableCell align='center'>{row.gst}</StyledTableCell>
                  <StyledTableCell align='center'>
                    {
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <img src={checkIcon} alt='' width={18} />
                        <img src={closeIcon} alt='' width={18} />
                        <img src={trashIcon} alt='' width={18} />
                      </div>
                    }
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* <div className={classes.paginationClass}>
        <div className={classes.paginationDrop}>
          <UncontrolledDropdown
            // onChange={(e) => {
            //   console.log(e)
            // }}
          >
            <DropdownToggle
              style={{ display: 'flex', gap: '5px', background: '#3A1C67' }}
            >
              <div style={{ fontSize: '15px' }}>{rowsPerPage}</div>
              <KeyboardArrowDown
                style={{ fontSize: '20px' }}
              ></KeyboardArrowDown>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  setRowsPerPage(5)
                }}
              >
                5
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  setRowsPerPage(10)
                }}
              >
                10
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  setRowsPerPage(25)
                }}
              >
                25
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <div style={{ marginTop: '10px', marginLeft: '7px' }}>
            Rows Per Page
          </div>
        </div>
        <div className={classes.paginationArrows}>
          <div style={{ marginTop: '10px' }}>
            1-{rowsPerPage} of {rows.length}
          </div>
          <div
            style={{
              background: '#3A1C67',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              paddingLeft: '4px',
              paddingRight: '4px',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
            onClick={() => {
              if (page !== 0) {
                setPage(page - 1)
              }
            }}
          >
            <KeyboardArrowLeft style={{ fontSize: '20px' }}></KeyboardArrowLeft>
          </div>
          <div
            style={{
              background: '#3A1C67',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              fontSize: '10px',
              paddingLeft: '4px',
              paddingRight: '4px',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
            onClick={() => {
              setPage(page + 1)
            }}
          >
            <KeyboardArrowRight
              style={{ fontSize: '20px' }}
            ></KeyboardArrowRight>
          </div>
        </div>
      </div> */}

      <div style={{ position: 'absolute', bottom: 0, right: 30 }}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
            style: {
              color: '#fff',
              backgroundColor: '#3A1C67',
              padding: 3,
              borderRadius: '5px',
              marginRight: '5px',
            },
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
            style: {
              color: '#fff',
              backgroundColor: '#3A1C67',
              padding: '3px',
              borderRadius: '5px',
            },
          }}
        />
      </div>
    </div>
  )
}
