import React from 'react'
import {Navigate, useLocation} from "react-router-dom"
import { routes } from '../constants/routes';
import { getCookie } from '../Utils/commonUtils';

const ProtectedRoute = ({children}) => {
   

    let location = useLocation();
  
        const loginInfo=getCookie('callpal-user')
     

    if(!loginInfo) {
        return <Navigate to={routes.signIn} state={{ from: location}} replace />
    }
 return children
  
};

export default ProtectedRoute;