import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Button from "@material-ui/core/Button";
import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SmsTemplateApi } from "../../../Services/SmsTemplateApi";
import { WhatsappTemplateApi } from "../../../Services/WhatsappTemplateApi";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {
  TextField,
  TextareaAutosize,
  colors,
  CircularProgress,
} from "@material-ui/core";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { KeyboardArrowDown } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { setIsSubmitted } from "../../../Reducers/loginSlice";
import { getCookie } from "../../../Utils/commonUtils";

const useStyles = makeStyles((theme) => ({
  header: {
    height: "70px",
    color: "#fff",
    fontSize: "17px",
    backgroundColor: "#3A1C67",
    fontWeight: 600,
    padding: 20,
    textAlign: "left",
  },
  root: (props) => ({
    "& label.Mui-focused": {
      color: props.template == "sms" ? "#F86E20" : "#1C9231",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: props.template == "sms" ? "#F86E20" : "#1C9231",
      },
      "&:hover fieldset": {
        borderColor: props.template == "sms" ? "#F86E20" : "#1C9231",
      },
      "&.Mui-focused fieldset": {
        borderColor: props.template == "sms" ? "#F86E20" : "#1C9231",
      },
    },
    margin: "15px 20px",
    width: "500px",

    // [theme.breakpoints.down('xs')]: {
    //     width:'100%'
    //   },
  }),
  multilineColor: (props) => ({
    color: props.template === "sms" ? "#F86E20" : "#1C9231",
  }),
  formRow: {
    display: "flex",
  },
  buttonContainer: {
    display: "flex",
    margin: "20px 20px",
    justifyContent: "center",
    gap: "20px",
  },
  AddFieldButton: (props) => ({
    backgroundColor: props.template == "sms" ? "#3A1C67" : "#1C9231",
    color: "#fff",
    textTransform: "none",
    "&:hover": {
      backgroundColor: props.template == "sms" ? "#3A1C67" : "#1C9231",
    },
    width: "130px",
    padding: "0px 10px",
    height: 30,
    marginRight: 25,
  }),
  saveButton: (props) => ({
    backgroundColor: props.template == "sms" ? "#FA9510" : "#1C9231",
    color: "#fff",
    textTransform: "none",
    "&:hover": {
      backgroundColor: props.template == "sms" ? "#F76A1A" : "#27AE60",
    },
    width: "fit-content",
    textAlign: "center",
  }),

  cancelButton: {
    backgroundColor: "#fff",
    border: "1px solid #848891",
    color: "#848891",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#E5E3E1",
    },
    width: "fit-content",
    textAlign: "center",
  },
  formContainer: {
    padding: "30px 20px",
  },
}));

export default function CreateTemplatePopper({
  open,
  setOpen,
  template,
  setUpdated,
  selectedUser,
  action,
}) {
  const dispatch = useDispatch();
  const classes = useStyles({ template: template });
  const [name, setName] = useState("");
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const { userInfo } = useSelector((state) => state.login);
  const loginInfo = getCookie("callpal-user");
  const [err, setErr] = useState({
    template_name: "",
    message: "",
  });
  const [isEdit, setIsEdit] = useState(false);



  const [userInput, setUserInput] = useState({
    template_name: "",
    message: "",
  });





  useEffect(() => {
    if (action == "Edit" && selectedUser.hasOwnProperty("id")) {
      formik.setFieldValue("id",selectedUser.id ? selectedUser.id : "");
      formik.setFieldValue("template_name",selectedUser.template_name ? selectedUser.template_name : "");
      formik.setFieldValue("message",selectedUser.message ? selectedUser.message : "");
     
      setIsEdit(true);
    } else {

      formik.resetForm({ values: { id: '', template_name: '', message: '', } });
      setIsEdit(false);
    }

   
    

    

    
  }, [userInfo, selectedUser, action]);
   
  const toggleDrawer = (anchor, op) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(op);
  };

  const validationSchema = Yup.object({
    template_name: Yup.string().required("Template Name is required"),
    message: Yup.string().required("Template Message is required"),
  });
  const formik = useFormik({
    initialValues: {
      template_name: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      try {
       const data = template === "sms" 
        ? await SmsTemplateApi.submitSmsTemplate(values) 
        : await WhatsappTemplateApi.submitWhatsappTemplate(values);
       
        if (data.status) {
          dispatch(
            setIsSubmitted({
              open: true,
              severity: "success",
              msg: data.message,
            })
          );
          setOpen(false);
          setUpdated(Math.floor(Math.random() * 90000) + 10000);
          resetForm();
          setLoading(false);
          // navigate(routes.signIn);
        } else {
          // Handle errors specific to the API call
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        console.error(e);
      }
    },
    
  });

  return (
    <div>
      <SwipeableDrawer
        anchor={"right"}
        open={open}
        onClose={() => {
          formik.resetForm();
          setOpen(false);
        }}
        onOpen={() => setOpen(true)}
      >
        <div>
          <div className={classes.header}>
            {action == "Edit" ? "Edit" : "Add"}{" "}
            {template === "sms" ? "SMS" : " WhatsApp"} Template
          </div>

          <form onSubmit={formik.handleSubmit}>
            <div className={classes.formContainer}>
              <div>
                <TextField
                  size="small"
                  placeholder="Template Name"
                  inputProps={{
                    className: classes.multilineColor,
                    // readOnly: isEdit,
                  }}
                  className={classes.root}
                  variant="outlined"
                  name="template_name"
                  // helperText={formik.touched.template_name && formik.errors.template_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.template_name}
                />
                {formik.touched.template_name && formik.errors.template_name ? (
                  <p className="text-danger formik-addUser-msg text-small text-left formik-msg ">
                    {formik.errors.template_name}
                  </p>
                ) : null}
              </div>
              <div>
                <TextareaAutosize
                  size="small"
                  minRows={10}
                  placeholder="Template Message"
                  inputProps={{
                    className: classes.multilineColor,
                    // readOnly: isEdit,
                  }}
                  name="message"
                  // helperText={formik.touched.message && formik.errors.message}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.message}
                  style={{
                    borderColor: template === "sms" ? "#FA9510" : "#1C9231",
                    color: template === "sms" ? "#FA9510" : "#1C9231",
                    fontSize: 16,
                    width: 500,
                    margin: "0 20px",
                    fontSize: 17,
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                  className={
                    template === "sms" ? "template" : "whatsapp-template"
                  }
                />

                {formik.touched.message && formik.errors.message ? (
                  <p className="text-danger formik-addUser-msg text-small text-left formik-msg ">
                    {formik.errors.message}
                  </p>
                ) : null}
              </div>
            </div>

            <div className={classes.buttonContainer}>
              <div className="btns_add_user">
                <Button
                  type="submit"
                  className={classes.saveButton}
                  disabled={loading || !formik.isValid}
                >
                  {loading ? (
                    <CircularProgress size={20} />
                  ) : action == "Edit" ? (
                    "Update"
                  ) : (
                    "Save"
                  )}
                </Button>
              </div>
              <div className="btns_add_user">
                <Button
                  className={classes.cancelButton}
                  onClick={() => {
                    formik.resetForm();
                    setOpen(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </form>
        </div>
      </SwipeableDrawer>
    </div>
  );
}
