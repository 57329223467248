import React from 'react'
import {  makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'

import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import {  Button, TablePagination } from '@material-ui/core'
import { json, useNavigate } from "react-router-dom";
import { useEffect } from 'react'
import AddUserPopper from './Other-components/AddUserPopper'
import { useState } from 'react'
import { StyledTableCell, StyledTableRow } from '../../Styles'
import EditIcon from '@material-ui/icons/Edit';
import trashIcon from '../../Images/trash.svg'
import { useDispatch, useSelector } from 'react-redux'
import NoRecords from './Other-components/NoRecords'
import AddIcon from '@material-ui/icons/Add';
import { setIsSubmitted } from '../../Reducers/loginSlice'
import ConfirmationPopper from './Other-components/ConfirmationPopper'
import { getAllUsers, getFilteredUsers } from '../../Redux/features/users/userSlice'
import { getCookie } from '../../Utils/commonUtils'
import { useParams } from "react-router-dom";


const useStyles = makeStyles({
  mainContainer: {
    padding: '80px 30px 30px 30px',
  },
  table: {
    minWidth: 700,
  },

  button: {
    backgroundColor: '#3A1C67',
    color: '#fff',
    marginLeft: '10px',
    borderRadius: '5px',
    fontSize: '12px',
    padding: '5px 20px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#7916BD',
    },
  },
  tableContainer: {
    margin: '20px 0px',
    maxHeight: 450,
    width: '100%',
    '&::-webkit-scrollbar': {
      width: '0.5em',
      display: 'block',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'darkgrey',
      outline: '1px solid slategrey',
      borderRadius: '5px',
    },
  },
})

export default function TeamDetail(props) {
  const classes = useStyles();
  const dispatch = useDispatch()
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState({})
  const [action, setAction] = useState('Add')
  const { userInfo } = useSelector((state) => state.login)
  // const { filteredUsers ,loading,totalCount} = useSelector((state) => state.users)
  const [offset, setOffset] = useState(1)
  const [updated, setUpdated] = useState(true)
  const [confirm,setConfirm]=useState(false)
  const [deletedUser,setDeletedUser]=useState('')
  const [deleteLoader,setDeleteLoader]=useState(false);
  const loginInfo=getCookie('callpal-user'); 
  const [rows, setRows] = useState([]);
  const [count,setCount]=useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    let num = 0
    if (newPage > page) {
      num = offset + 1
    }
    else {
      num = offset - 1
    }

    setOffset(num)
  };


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setOffset(0)
  };

  const headers = ['Employee Id', 'User Name', 'Email', 'Phone No.', 'Role', 'Reports To']

  useEffect(() => {
    
    if (loginInfo?.userInfo?.mobile_number && updated) {
      getUserData()
    }
    
    //console.log(updated);
    // if (loginInfo?.userInfo?.email && updated) {
    //   dispatch(getFilteredUsers( {
    //     user_id: loginInfo.userInfo.id,
    //     per_page_row: rowsPerPage,
    //     //limit: rowsPerPage,
    //     page: offset
    //   }))
    // }
  }, [userInfo, page, rowsPerPage, updated])

 
  const getUserData = async () => {
    try {
      setLoading(true)
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/get-user-detail/${id}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${loginInfo.token}`
        },
        // body: JSON.stringify({
        //   user_id: loginInfo.userInfo.id,
        //   per_page_row: rowsPerPage,
        //   //limit: rowsPerPage,
        //   page: offset
        // })
      })
      const data = await res.json()
      setLoading(false)
      if (data.status) {
        setRows([data.data])
        setCount(1)
      }
    }
    catch (e) {
      console.log(e.message)
      setLoading(false)
    }
  }

  const backToTeam = () => {
    // Use history.push to navigate to the desired route
    navigate(`/dashboard/team`);
  };

  return (
    <div className={classes.mainContainer}>
      <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          className={classes.filterHolderComp}
        >

        <div style={{ fontWeight: 600, fontSize: '20px', color: '#F86E20' }}>
          {count > 0  ? count : "" } Teams
        </div>
        <Button className={classes.button}  onClick={backToTeam}>
         Back
        </Button>
      </div>
      <AddUserPopper  open={open} setOpen={(f) => setOpen(f)} param={'team'} action={action}
        selectedUser={selectedUser} setUpdated={(f) => setUpdated(f)}  />
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table
          stickyHeader
          className={classes.table}
          aria-label='customized table'
          size='small'
        >
          <TableHead>
            <TableRow>
              {headers.map((el, i) => {
                return (
                  <StyledTableCell key={el} align='center'>
                    {el}
                  </StyledTableCell>
                )
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {
              rows.length > 0 && !loading && rows.map((row) => (
                <StyledTableRow>
                  <StyledTableCell align="center">{row.employee_id}</StyledTableCell>
                  <StyledTableCell align="center">{row.name}</StyledTableCell>
                  <StyledTableCell align="center">{row.email}</StyledTableCell>
                  <StyledTableCell align="center">{row.mobile_number}</StyledTableCell>
                  <StyledTableCell align="center">{row.role}</StyledTableCell>
                  <StyledTableCell align="center">{row.reports_to}</StyledTableCell>
                </StyledTableRow>
              ))}
            <NoRecords rows={rows} loading={loading} />
          </TableBody>
        </Table>
      </TableContainer>

      {/* <div >
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          backIconButtonProps={{
            "aria-label": "Previous Page",
            style: { color: '#fff', backgroundColor: '#3A1C67', padding: 3, borderRadius: '5px', marginRight: '5px' },
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
            style: { color: '#fff', backgroundColor: '#3A1C67', padding: '3px', borderRadius: '5px' },
          }}
        />
      </div> */}
    </div>
  )
}
