import React from 'react'
import {Navigate, useLocation} from "react-router-dom"
import { getCookie } from '../Utils/commonUtils';

const Protected = ({children}) => {
   
    let location = useLocation();
  
        const loginInfo=getCookie('callpal-user')

    if(loginInfo) {
        return <Navigate to={'/dashboard/dashboard'} state={{ from: location}} replace />
    }
 return children
  
};

export default Protected;