import axios from "axios"
import { getCookie } from '../Utils/commonUtils'

export const DRRApi = {

  // ----------------------------------------------------------------------------------------------------
    // DDR Dashboard page

    DRRDashboard: async (data) => {
      try {
           
          const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/drr-dashboard`, data, {
            headers: {
              'Accept': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getCookie('callpal-user').token}`
            }
          });
          return response; // Assuming your data is in response.data
        } catch (error) {
          console.error('Error in submitCalendarStatus:', error);
          return false
          //throw error; // Re-throw the error to handle it at the calling site if needed
        }
    },



  
  // ----------------------------------------------------------------------------------------------------


  submitCalendarStatus: async (data) => {
    try {
         
        const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/store-working-day`, data, {
          headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getCookie('callpal-user').token}`
          }
        });
        return response; // Assuming your data is in response.data
      } catch (error) {
        console.error('Error in submitCalendarStatus:', error);
        return false
        //throw error; // Re-throw the error to handle it at the calling site if needed
      }
  },
  getCalendarList: async (data) => {
    try {
         
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/get-working-day`, {
          headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getCookie('callpal-user').token}`
          }
        });
        return response; // Assuming your data is in response.data
      } catch (error) {
        console.error('Error in getCalendarList:', error);
        return false
        //throw error; // Re-throw the error to handle it at the calling site if needed
      }
},
   
// -------------------------------------------------------------------------------------------

// Team Lead Api
 getDRRTargetTeamLeadList: async (data) => {
  try {
       
      const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/team-lead-drr-target`, data, {
        headers: {
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getCookie('callpal-user').token}`
        }
      });
      return response; // Assuming your data is in response.data
    } catch (error) {
      console.error('Error in getDRRTarget:', error);
      return false
      //throw error; // Re-throw the error to handle it at the calling site if needed
    }
}, 
getDRRPerDayTargetList: async (data) => {
  try {
       
      const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/team-lead-day-wise-disbursement`, data, {
        headers: {
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getCookie('callpal-user').token}`
        }
      });
      return response; // Assuming your data is in response.data
    } catch (error) {
      console.error('Error in getDRRPerDayTargetList:', error);
      return false
      //throw error; // Re-throw the error to handle it at the calling site if needed
    }
}, 

updateTeamLeadTarget: async (data) => {
  try {
       
      // const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/add-team-lead-target`, data, {
      //   headers: {
      //     'Accept': 'application/json',
      //     'Access-Control-Allow-Origin': '*',
      //     'Content-Type': 'application/json',
      //     'Authorization': `Bearer ${getCookie('callpal-user').token}`
      //   }
      // });
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/add-team-lead-target`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie('callpal-user').token}`,
          },
          body: data,
        }
      );
      return response.json(); // Assuming your data is in response.data
    } catch (error) {
      console.error('Error in getDRRPerDayTargetList:', error);
      return false
      //throw error; // Re-throw the error to handle it at the calling site if needed
    }
}, 

// Team lead api end 
// -------------------------------------------------------------------------------------------------


//  Tele Callar 

getDRRTaleCallerTargetList: async (data) => {
  try {
       
      const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/team-lead-telecaller-drr-target`, data, {
        headers: {
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getCookie('callpal-user').token}`
        }
      });
      return response; // Assuming your data is in response.data
    } catch (error) {
      console.error('Error in getDRRPerDayTargetList:', error);
      return false
      //throw error; // Re-throw the error to handle it at the calling site if needed
    }
}, 


updateTaleCallerTarget: async (data) => {
  try {
       
      // const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/add-telecaller-target`, data, {
      //   headers: {
      //     'Accept': 'application/json',
      //     'Access-Control-Allow-Origin': '*',
      //     'Content-Type': 'application/json',
      //     'Authorization': `Bearer ${getCookie('callpal-user').token}`
      //   }
      // });
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/add-telecaller-target`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie('callpal-user').token}`,
          },
          body: data,
        }
      );
      return response.json(); // Assuming your data is in response.data
    } catch (error) {
      console.error('Error in getDRRPerDayTargetList:', error);
      return false
      //throw error; // Re-throw the error to handle it at the calling site if needed
    }
},

getDRRPerDayTaleCallerTargetList: async (data) => {
  try {
       
      const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/telecaller-day-wise-disbursement`, data, {
        headers: {
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getCookie('callpal-user').token}`
        }
      });
      return response; // Assuming your data is in response.data
    } catch (error) {
      console.error('Error in getDRRPerDayTargetList:', error);
      return false
      //throw error; // Re-throw the error to handle it at the calling site if needed
    }
}, 






}