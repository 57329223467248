import React, { useState, useEffect } from "react";
import {
  SwipeableDrawer,
  TextField,
  Button,
  Typography,
  MenuItem,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import UpdateIcon from "@material-ui/icons/Update";
import { makeStyles } from "@material-ui/core/styles";
import { Box, CircularProgress, Container } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { setIsSubmitted } from "../../../Reducers/loginSlice";
import { MasterDataApi } from "../../../Services/MasterDataApi";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { getCookie } from "../../../Utils/commonUtils";

import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
const AddOfficeTimings = ({ open, onClose, shiftAction, userShiftEditId }) => {
  const [newDepartment, setNewDepartment] = useState("");
  // const handleAddDepartment = () => {
  //   onAddDepartment(newDepartment);
  //   setNewDepartment('');
  //   onClose();
  // };
  const [shift_starttime, setshift_starttime] = useState("");
  const [shift_endtime, setshift_endtime] = useState("");
  const [shiftName, setShiftName] = useState("");
  const dispatch = useDispatch();
  const loginInfo = getCookie("callpal-user");

  const useStyles = makeStyles((theme) => ({
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: "70px",
      color: "#fff",
      fontSize: "17px",
      backgroundColor: "#3A1C67",
      fontWeight: 600,
      padding: "0 20px",
      width: "40rem",
    },
    iconButton: {
      color: "#fff",
      border: "1px solid orange",
      padding: "10px 30px",
      margin: theme.spacing(1),
    },
    textField: {
      "& label.Mui-focused": {
        color: "#F86E20",
      },
      "& .MuiInputBase-input": {
        color: "#F86E20",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#F86E20",
          borderWidth: "2px",
        },
        "&:hover fieldset": {
          borderColor: "darkorange",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#F86E20",
          borderWidth: "2px",
        },
      },
      margin: theme.spacing(1),
      width: "90%",
    },
    timeContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      margin: theme.spacing(1),
    },
    timeDropdown: {
      width: "160px", // Adjust width as necessary
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#F86E20", // Orange border
          borderWidth: "2px", // Making the border thicker
        },
        "&:hover fieldset": {
          borderColor: "darkorange", // Darker orange on hover
        },
        "&.Mui-focused fieldset": {
          borderColor: "#F86E20", // Orange border on focus
          borderWidth: "2px", // Maintain thick border on focus
        },
      },
      "& .MuiInputBase-root": {
        color: "#F86E20", // Text color
        "& .MuiSelect-select": {
          paddingRight: "22px", // Adjust padding to ensure the dropdown arrow and text do not overlap
        },

        "& .MuiOutlinedInput-input ": {
          padding: "5.5px 10px",
        },
      },
    },
    label: {
      color: "#F86E20", // Orange color for labels
      marginRight: theme.spacing(2),
    },
    buttonContainerNew: {
      display: "flex",
      margin: "20px 90px",
      justifyContent: "space-between",
    },
    saveButton: {
      backgroundColor: "#FA9510",
      color: "#fff",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#F76A1A",
      },
      width: "180px",
    },
    cancelButton: {
      backgroundColor: "#fff",
      border: "1px solid #848891",
      color: "#848891",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#E5E3E1",
      },
      width: "180px",
    },
  }));



  useEffect(() => {
    if (shiftAction === "Edit") {
     editOfficeShiftPopper(userShiftEditId)
    }else{
      formik.setFieldValue("id",  "");
      formik.setFieldValue("shift_for",  "");
      formik.setFieldValue("shift_name", "");
      formik.setFieldValue("shift_starttime",  "");
      formik.setFieldValue("shift_endtime", "");
      formik.setFieldValue("shift_status", "");
    }
    
  
  }, [shiftAction]);


  const editOfficeShiftPopper  =async(userShiftEditId)=> {
    setLoading(true)
    try {
      const res = await MasterDataApi.EditMasterDataOfficeTimingApi(userShiftEditId);
      
      if (res.status) {
        formik.setFieldValue("id", userShiftEditId ? userShiftEditId : "");
        formik.setFieldValue("shift_for", res.data.shift_for ? res.data.shift_for : "");
        formik.setFieldValue("shift_name", res.data.shift_name ? res.data.shift_name : "");
        formik.setFieldValue("shift_starttime", res.data.shift_starttime ? res.data.shift_starttime : "");
        formik.setFieldValue("shift_endtime", res.data.shift_endtime ? res.data.shift_endtime : "");
        formik.setFieldValue("shift_status", res.data.shift_status ? res.data.shift_status : "");

      } else {
        formik.resetForm({
          values: {
            id:"",
            department_name: "",
            department_status: ""
          },
        });
       
        }
        setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  }





  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const timeSlots = [];
  const displayTimeSlots = [];

  for (let hour = 0; hour <= 23; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const time24 = `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}:00`;
      const time12 = new Date(0, 0, 0, hour, minute).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      }).replace('am', 'AM').replace('pm', 'PM');
      timeSlots.push(time24);
      displayTimeSlots.push(time12);
    }
  }
  const validationSchema = Yup.object().shape({
    shift_for: Yup.string().required('Shift for is required'),
    shift_name: Yup.string().required('Shift name is required'),
    shift_starttime: Yup.string().required('Start time is required'),
    shift_endtime: Yup.string().required('End time is required').test(
      'is-greater',
      'End time must be greater than start time',
      function (value) {
        const { shift_starttime } = this.parent;
        return value > shift_starttime;
      }
    ),
    shift_status: Yup.string().required('Status is required'),
  });

  const formik = useFormik({
    initialValues: {
      id: "",
      shift_for: "",
      shift_name: "",
      shift_starttime: "",
      shift_endtime: "",
      shift_status:"",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
     
      try {
        const data = await MasterDataApi.UpdateMasterDataOfficeTimings(values);

        if (data.status) {
          dispatch(
            setIsSubmitted({
              open: true,
              severity: "success",
              msg: data.message,
            })
          );
          onClose(false);
          resetForm();
          setLoading(false);
        } else {
          if (data.hasOwnProperty("errors")) {
            formik.setFieldError(
              "shift_for",
              data.errors.shift_for[0]
            );
            formik.setFieldError(
              "shift_name",
              data.errors.shift_name[0]
            );
            formik.setFieldError(
              "shift_starttime",
              data.errors.shift_starttime[0]
            );
            formik.setFieldError(
              "shift_endtime",
              data.errors.shift_endtime[0]
            );
            formik.setFieldError(
              "shift_status",
              data.errors.shift_status[0]
            );
          } else {
            dispatch(
              setIsSubmitted({
                open: true,
                severity: "error",
                msg: data.message,
              })
            );
          }
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        console.error(e);
      }
    },
  });


  const handleTimeSelect = (field, displayTime) => {
    const index = displayTimeSlots.indexOf(displayTime);
    const internalTime = timeSlots[index];
    formik.setFieldValue(field, internalTime);
  };

  const getDisplayTime = (value) => {
    const index = timeSlots.indexOf(value);
    return index >= 0 ? displayTimeSlots[index] : 'Select time';
  };


  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={onClose}
      onOpen={() => {}}
    >
      <div className={classes.header}>
        <Typography variant="h6">
          {" "}
          {shiftAction === "Edit" ? "Edit" : "Add"} Office Timings
        </Typography>
        <div className={classes.buttonContainer}>
          {/* <Button className={classes.iconButton}>
          <EditIcon style={{ marginRight: '1.2rem' }} /> Edit
        </Button>
        <Button className={classes.iconButton}>
          <UpdateIcon style={{ marginRight: '1.2rem' }} /> Update
        </Button> */}
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
      <div className="row">
         <div className="col-sm-12">
        <TextField
          label="Shift Name"
          variant="outlined"
          // value={shiftName}
          // onChange={(e) => setShiftName(e.target.value)}
          className={classes.textField}
          style={{ width: "95%", borderRadius: 10 }}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.shift_name}
          name="shift_name"
          // fullWidth
        />

          {formik.touched.shift_name &&
            formik.errors.shift_name && (
              <p className="text-danger formik-masterdata-msg text-small text-left formik-msg ">
                {formik.errors.shift_name}
              </p>
          )}
       </div>  </div>
       <div className="row">
         <div className="col-sm-6">
       
        <UncontrolledDropdown
          className={classes.textField}
          style={{ margin: "9px 8px" }}
        >
          <DropdownToggle
            caret
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "10px",
              paddingBottom: "10px",
              background: "white",
              color: "#F86E20",
              border: "2px solid #F86E20",
              alignItems: "center",
            }}
          >
            {formik.values.shift_status
              ? formik.values.shift_status === "active" ? "Active" : "De-active"
              : "Select Status"}
          </DropdownToggle>
          <DropdownMenu >
            <>
              <div className={classes.dropdownItem}>
                <DropdownItem
                  name="active"
                  onClick={() => {
                    formik.setFieldValue("shift_status", "active");
                  }}
                >
                  Active
                </DropdownItem>
                <DropdownItem
                  name="deactive"
                  onClick={() => {
                    formik.setFieldValue("shift_status", "deactive");
                  }}
                >
                  De-active
                </DropdownItem>
              </div>
            </>
          </DropdownMenu>
        </UncontrolledDropdown>
        {formik.touched.shift_status &&
          formik.errors.shift_status && (
            <p className="text-danger formik-masterdata-msg text-small text-left formik-msg ">
              {formik.errors.shift_status}
            </p>
          )}

    </div>

        {/* ----------Shift--------------- */}

        <div className="col-sm-6">
        <UncontrolledDropdown
          className={classes.textField}
          style={{ margin: "9px 8px"}}
        >
          <DropdownToggle
            caret
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "10px",
              paddingBottom: "10px",
              background: "white",
              color: "#F86E20",
              border: "2px solid #F86E20",
              alignItems: "center",
            }}
          >
            {formik.values.shift_for
              ? formik.values.shift_for === "teamlead" ? "Team Lead " :  "Tale Caller"
              : "Select shift for"}
          </DropdownToggle>
          <DropdownMenu>
            <>
              <div className={classes.dropdownItem}>
              {loginInfo.userInfo.fk_role_id === 2 &&
                <DropdownItem
                  name="teamlead"
                  onClick={() => {
                    formik.setFieldValue("shift_for", "teamlead");
                  }}
                >
                  Team Lead
                </DropdownItem>
                }
                <DropdownItem
                  name="talecaller"
                  onClick={() => {
                    formik.setFieldValue("shift_for", "talecaller");
                  }}
                >
                  Tale Caller
                </DropdownItem>
              </div>
            </>
          </DropdownMenu>
        </UncontrolledDropdown>
        
        {formik.touched.shift_for &&
          formik.errors.shift_for && (
            <p className="text-danger formik-masterdata-msg text-small text-left formik-msg ">
              {formik.errors.shift_for}
            </p>
          )}
        </div>
        </div>

        {/* <div className={classes.timeContainer}> */}
         
        <div className="row">
        <div className="col-sm-6">
          <UncontrolledDropdown
            className={classes.textField}
            style={{ margin: '9px 8px' }}
          >
            <DropdownToggle
              caret
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                paddingTop: "10px",
                paddingBottom: "10px",
                background: "white",
                color: "#F86E20",
                border: "2px solid #F86E20",
                alignItems: "center",
              }}
            >
              {formik.values.shift_starttime
                ? getDisplayTime(formik.values.shift_starttime)
                : 'Select shift start time'}
            </DropdownToggle>
            <DropdownMenu>
              {displayTimeSlots.map((time, index) => (
                <DropdownItem
                  key={timeSlots[index]}
                  onClick={() => handleTimeSelect('shift_starttime', time)}
                >
                  {time}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
          {formik.touched.shift_starttime &&
            formik.errors.shift_starttime && (
              <p className="text-danger formik-masterdata-msg text-small text-left formik-msg">
                {formik.errors.shift_starttime}
              </p>
            )}
        </div>

        <div className="col-sm-6">
          <UncontrolledDropdown
            className={classes.textField}
            style={{ margin: '9px 8px' }}
          >
            <DropdownToggle
              caret
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                paddingTop: '10px',
                paddingBottom: '10px',
                background: 'white',
                color: '#F86E20',
                border: '2px solid #F86E20',
                alignItems: 'center',
              }}
            >
              {formik.values.shift_endtime
                ? getDisplayTime(formik.values.shift_endtime)
                : 'Select shift end time'}
            </DropdownToggle>
            <DropdownMenu>
              {displayTimeSlots.map((time, index) => (
                <DropdownItem
                  key={timeSlots[index]}
                  onClick={() => handleTimeSelect('shift_endtime', time)}
                >
                  {time}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
          {formik.touched.shift_endtime &&
            formik.errors.shift_endtime && (
              <p className="text-danger formik-masterdata-msg text-small text-left formik-msg">
                {formik.errors.shift_endtime}
              </p>
            )}
        </div>
        </div>


           
         
        {/* </div> */}

        <div className="btns_add_user">
          <div className={classes.buttonContainerNew}>
            <Button type="submit" className={classes.saveButton}>
              {loading ? (
                <CircularProgress size={20} />
              ) : shiftAction == "Edit" ? (
                "Update"
              ) : (
                "Save"
              )}
            </Button>
            <Button
              className={classes.cancelButton}
              onClick={() => onClose(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </SwipeableDrawer>
  );
};

export default AddOfficeTimings;
