import { Checkbox, Radio, TableCell, TableRow, withStyles } from "@material-ui/core";

export const OrangeCheckbox = withStyles({
    root: {
        color: '#848891',
        '&$checked': {
            color: '#F86E20',
        },
    },
    checked: {},
})((props) => <Checkbox color='primary' {...props} />);

export const OrangeSmallCheckbox = withStyles({
    root: {
        color: '#848891',
        '&$checked': {
            color: '#F86E20',
        },
    },
    checked: {},
})((props) => <Checkbox size='small' color='primary' {...props} />);

export const StyledTableCell = withStyles((theme) => ({

    head: {
      backgroundColor: '#F1F1F1',
      color: '#3A1C67',
      fontWeight: 700,
      fontSize:'14px',
      padding:10
    },
    body: {
      fontSize: 14,
      padding:10,
      color: '#3A1C67',
    },
  }))(TableCell);
  
 export const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  export const RadioButton = withStyles({
    root: {
      "& .MuiSvgIcon-root": {
        height: 15,
        width: 15,
        marginRight:0
      },
      color: '#FA9510',
      '&$checked': {
        color: '#FA9510',
      },
    },
      
    checked: {},
  })((props) => <Radio color="default"  {...props} />);