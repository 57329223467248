import axios from "axios";
export const getUserData = (payload) => {
    return axios.post(`${process.env.REACT_APP_API_ENDPOINT}/show-users`, {  
              user_id: payload?.id,
            },
        // {headers: {
        //   'Accept': 'application/json',
        //   'Access-Control-Allow-Origin': '*',
        //   'Content-Type': 'application/json',
        //   Authorization: `Bearer ${payload?.token}`
        // }}
        // ,
        )
  };


  export const getFilteredUsersData = (payload) => {
    return axios.post(`${process.env.REACT_APP_API_ENDPOINT}/show-users`, payload)
  };

   export const deleteUserData = (payload) => {
    return axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/team/users/${payload}`)
  };

  export const addUserData = (payload) => {
    return axios.post(`${process.env.REACT_APP_API_ENDPOINT}/team/add_update_team_user`, payload?.payload,
        {headers: {
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${payload?.token}`
        }},)
  };

