import axios from "axios"
import { getCookie } from '../Utils/commonUtils'

export const CustomerHistoryApi = {
  getLeadDetail: async (customerID) => {
        try {
             
            const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/lead-detail`, customerID, {
              headers: {
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getCookie('callpal-user').token}`
              }
            });
            return response; // Assuming your data is in response.data
          } catch (error) {
            console.error('Error in getTeamList:', error);
            return false
            //throw error; // Re-throw the error to handle it at the calling site if needed
          }
    },
    getCustomerDetails: async (customerID) => {
      try {
         
          const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/lead-detail/${customerID}`, {
            headers: {
              'Accept': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getCookie('callpal-user').token}`
            }
          });
          return response; // Assuming your data is in response.data
        } catch (error) {
          console.error('Error in getCustomerDetails:', error);
          return false
          //throw error; // Re-throw the error to handle it at the calling site if needed
        }
  },

  getgetCountryDetails: async (data) => {
    try {
       
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/country-master`, {
          headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getCookie('callpal-user').token}`
          }
        });
        return response; // Assuming your data is in response.data
      } catch (error) {
        console.error('Error in getgetCountryDetails:', error);
        return false
        //throw error; // Re-throw the error to handle it at the calling site if needed
      }
},

getStateListDetails: async (value) => {
  try {
     
      const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/state-master/${value}`, {
        headers: {
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getCookie('callpal-user').token}`
        }
      });
      return response; // Assuming your data is in response.data
    } catch (error) {
      console.error('Error in getStateListDetails:', error);
      return false
      //throw error; // Re-throw the error to handle it at the calling site if needed
    }
},
getCityListDetails: async (value) => {
  try {
     
      const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/city-master/${value}`, {
        headers: {
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getCookie('callpal-user').token}`
        }
      });
      return response; // Assuming your data is in response.data
    } catch (error) {
      console.error('Error in getCityListDetails:', error);
      return false
      //throw error; // Re-throw the error to handle it at the calling site if needed
    }
},

getProductMaster: async (value) => {
  try {
     
      const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/product-master`, {
        headers: {
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getCookie('callpal-user').token}`
        }
      });
      return response; // Assuming your data is in response.data
    } catch (error) {
      console.error('Error in getProductMaster:', error);
      return false
      //throw error; // Re-throw the error to handle it at the calling site if needed
    }
},

getSourceList: async (value) => {
  try {
     
      const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/source-list`, {
        headers: {
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getCookie('callpal-user').token}`
        }
      });
      return response; // Assuming your data is in response.data
    } catch (error) {
      console.error('Error in getSourceList:', error);
      return false
      //throw error; // Re-throw the error to handle it at the calling site if needed
    }
},
    submitCustomerLead: async (data) => {
      try {
         
          const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/lead-update`, data, {
            headers: {
              'Accept': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getCookie('callpal-user').token}`
            }
          });
          return response; // Assuming your data is in response.data
        } catch (error) {
          console.error('Error in submitCustomerLead:', error);
          return false
          //throw error; // Re-throw the error to handle it at the calling site if needed
        }
  },
  getCustomerList: async (data) => {
    try {
       
        const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/customer-list`, data, {
          headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getCookie('callpal-user').token}`
          }
        });
        return response; // Assuming your data is in response.data
      } catch (error) {
        console.error('Error in getTeamList:', error);
        return false
        //throw error; // Re-throw the error to handle it at the calling site if needed
      }
  },

  getLeadBankHistory: async (customerID) => {
    try {
       
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/get-lead-bank-history/${customerID}`, {
          headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getCookie('callpal-user').token}`
          }
        });
        return response; // Assuming your data is in response.data
      } catch (error) {
        console.error('Error in getCustomerHistory:', error);
        return false
        //throw error; // Re-throw the error to handle it at the calling site if needed
      }
},
getCustomersTeamLead: async (data) => {
  try {
      const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/s-customer-list`, data, {
        headers: {
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getCookie('callpal-user').token}`
        }
      });
      return response; // Assuming your data is in response.data
    } catch (error) {
      console.error('Error in getTeamList:', error);
      return false
      //throw error; // Re-throw the error to handle it at the calling site if needed
    }
},


UpdateCustomerStatus: async (id) => {
  try {
     
      const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/lead-set-not-interested/${id}`, {
        headers: {
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getCookie('callpal-user').token}`
        }
      });
      return response; // Assuming your data is in response.data
    } catch (error) {
      console.error('Error in UpdateCustomerStatus:', error);
      return false
      //throw error; // Re-throw the error to handle it at the calling site if needed
    }
},

}