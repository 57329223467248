
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import logo from '../Images/Logo.png'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CloseIcon from '@material-ui/icons/Close';
import {
    useNavigate,useLocation
} from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { routes } from '../constants/routes';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import {  Collapse, List, ListItem, ListItemText, SwipeableDrawer } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    mobileRoot: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
    root: {
        flexGrow: 1,
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    button: {
        textTransform: 'none', color: '#3A1C67', fontWeight: 400, margin: '5px',
        fontSize:'15px',
        '&:hover':{
        backgroundColor:'#fff',
        color:'#FC0303'
        }
    },
    loginButton: {
        backgroundColor: '#3A1C67',
        color: '#fff',
        textTransform: 'none',
        padding: '5px 20px',
        '&:hover': {
            backgroundColor: '#7053AF'
        }
    },
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    mobileButton: {
        textTransform: 'none',
        color: '#3A1C67',
        fontWeight: 400,
        margin: '5px',
        justifyContent: 'left',
        fontSize:'15px',
        '&:hover':{
            backgroundColor:'#fff',
            color:'#FC0303'
            }
    }
}));

export default function Header() {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorE3, setAnchorE3] = React.useState(null);
    const [product, setProduct] = useState(false)
    const [policy, setPolicy] = useState(false)
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
   
    const path=useLocation().pathname

    const handleClick1 = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose1 = () => {
        setAnchorEl(null);
    };


    const handleClick3 = (event) => {
        setAnchorE3(event.currentTarget);
    };

    const handleClose3 = () => {
        setAnchorE3(null);
    };
    const toggleDrawer = (flag) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setOpen(flag)
    };
    let anchor = 'right'
    return (
        <div>
            <div className={classes.root}>
                <AppBar position="fixed" style={{ backgroundColor: '#fff', color: '#3A1C67' }}>
                    <Toolbar>
                        <IconButton onClick={() => { navigate(routes.home); }} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                            <img src={logo} alt="" loading="eager" width={'40px'} height={'auto'} />
                        </IconButton>

                        <div className={classes.title}>

                            <Button className={classes.button} style={{fontWeight:path===routes.home|| path===routes.fieldCrm||path===routes.postCrm?700:500}} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick1} endIcon={<ArrowDropDownIcon />}>
                                Product
                            </Button>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose1}
                                style={{ marginTop: '40px' }}
                            >
                                <MenuItem onClick={() => {navigate(routes.callManagement); handleClose1() }}>Call Management CRM</MenuItem>
                                <MenuItem onClick={() => {navigate(routes.postCrm);handleClose1() }}>POS CRM</MenuItem>
                                <MenuItem onClick={() => { navigate(routes.fieldCrm); handleClose1() }}>Field CRM</MenuItem>
                            </Menu>
                            <Button className={classes.button} aria-controls="simple-menu" aria-haspopup="true"
                             style={{fontWeight:path===routes.pricing?900:500}}
                                onClick={() => { navigate(routes.pricing); }}
                            >
                                Price
                            </Button>
                            <Button className={classes.button} aria-controls="simple-menu" aria-haspopup="true" onClick={() => { navigate(routes.contactUs);}}
                            style={{fontWeight:path===routes.contactUs?900:500}}
                            >
                                Contact Us
                            </Button>


                            {/* <Button className={classes.button} aria-controls="simple-menu"
                            style={{fontWeight:path===routes.terms||path===routes.usagePolicy||path===routes.privacyPolicy||path===routes.partners?900:500}}
                            endIcon={<ArrowDropDownIcon />} aria-haspopup="true" onClick={handleClick3}>
                                Privacy
                            </Button>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorE3}
                                keepMounted
                                open={Boolean(anchorE3)}
                                onClose={handleClose3}
                                style={{ marginTop: '40px' }}
                            >
                                <MenuItem onClick={() => { navigate(routes.terms); handleClose3() }}>Terms & Condition</MenuItem>
                                <MenuItem onClick={() => { navigate(routes.usagePolicy);handleClose3() }}>Usage Policy</MenuItem>
                                <MenuItem onClick={() => { navigate(routes.privacyPolicy);handleClose3() }}>Privacy Policy</MenuItem>
                                <MenuItem onClick={() => { navigate(routes.partners); handleClose3() }}>Partners</MenuItem>

                            </Menu> */}




                            <Button className={classes.button} aria-controls="simple-menu"
                            style={{fontWeight:path===routes.terms||path===routes.usagePolicy||path===routes.privacyPolicy||path===routes.partners?900:500}}
                            endIcon={<ArrowDropDownIcon />} aria-haspopup="true" onClick={handleClick3}>
                                About Us
                            </Button>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorE3}
                                keepMounted
                                open={Boolean(anchorE3)}
                                onClose={handleClose3}
                                style={{ marginTop: '40px' }}
                            >
                            <MenuItem onClick={() => { navigate(routes.partners); handleClose3() }}>Partners</MenuItem>

                                <MenuItem onClick={() => { navigate(routes.blogs);handleClose3() }}>Blogs</MenuItem>

                            </Menu>

                        </div>
                        <Button className={classes.loginButton} onClick={() => { navigate(routes.signIn) }}>
                            
                                Log In
                            
                        </Button>
                    </Toolbar>
                </AppBar>
            </div>

            <div className={classes.mobileRoot}>
                <AppBar position="fixed" style={{ backgroundColor: '#fff', color: '#3A1C67' }}>
                    <Toolbar style={{paddingRight:'30px'}}>
                        <IconButton onClick={() => { navigate(routes.home); }} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                            <img src={logo} alt="" loading="eager" width={'40px'} height={'auto'} />
                        </IconButton>

                        <div className={classes.title}>
                        </div>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="end"
                            onClick={() => setOpen(true)}

                        >
                            <MenuIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </div>
            <SwipeableDrawer
                anchor={anchor}
                open={open}
                onClose={toggleDrawer(anchor, false)}
                onOpen={toggleDrawer(anchor, true)}
                PaperProps={{
                    style:{
                        width:'280px',
                        paddingLeft:'30px'
                    }
                }}
            >
                <Button

                    onClick={() => setOpen(false)}
                    style={{ justifyContent: 'end' }}
                >
                    <CloseIcon />
                </Button>
                {/* <Divider /> */}
                <div style={{ color: '#3A1C67' }}>
                    <div>
                    <Button className={classes.mobileButton} style={{fontWeight:path===routes.home|| path===routes.fieldCrm||path===routes.postCrm?900:500}} onClick={() => setProduct((!product))} endIcon={!product ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}>
                        Product
                    </Button>

                    <Collapse in={product} >
                        <List>


                            <ListItem button >

                                <ListItemText onClick={() => {navigate(routes.home);}} primary={'Call Management CRM'} />
                            </ListItem>
                            <ListItem button >

                                <ListItemText onClick={() => {navigate(routes.postCrm);}} primary={'POS CRM'} />
                            </ListItem>
                            <ListItem button>

                                <ListItemText onClick={() => {navigate(routes.fieldCrm);}} primary={'Field CRM'} />
                            </ListItem>

                        </List>
                    </Collapse>
                    </div>
                    {/* <Divider /> */}

                    <div>
                    <Button className={classes.mobileButton} style={{fontWeight:path===routes.pricing?700:500}} onClick={() => {navigate(routes.pricing)}} >
                        Price
                    </Button>
                    </div>
                    {/* <Divider /> */}

                    <div>
                    <Button className={classes.mobileButton} style={{fontWeight:path===routes.contactUs?700:500}} onClick={() => {navigate(routes.contactUs); }} >
                        Contact Us
                    </Button>
                    </div>
                    {/* <Divider /> */}

                    <div>
                    <Button className={classes.mobileButton} style={{fontWeight:path===routes.terms|| path===routes.usagePolicy||path===routes.privacyPolicy||path===routes.partners?700:500}} onClick={() => setPolicy(!policy)} endIcon={!policy ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}>
                        Policy
                    </Button>
                    <Collapse in={policy}>

                        <List>


                            <ListItem button >

                                <ListItemText onClick={() => {navigate(routes.terms)}} primary={'Terms and conditions'} />
                            </ListItem>
                            <ListItem button >

                                <ListItemText onClick={() => {navigate(routes.usagePolicy)}} primary={'Usage Policy'} />
                            </ListItem>
                            <ListItem button>

                                <ListItemText onClick={() => {navigate(routes.privacyPolicy)}} primary={'Privacy Policy'} />
                            </ListItem>
                            <ListItem button>

                                <ListItemText onClick={() =>{navigate(routes.partners)}} primary={'Partners'} />
                            </ListItem>

                        </List>
                    </Collapse>
                    </div>
                    {/* <Divider /> */}
                    <div>
                    <Button className={classes.mobileButton} >
                    <Link style={{ textDecoration: 'none', color: '#3A1C67' }} to={routes.signIn} target='_blank'>
                                Log In
                            </Link>
                    </Button>
                    </div>
                    {/* <Divider /> */}
                </div>
            </SwipeableDrawer>
        </div>
    );
}
