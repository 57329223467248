import {
  Button,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  makeStyles,
} from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import { useEffect } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  UncontrolledButtonDropdown,
} from "reactstrap";
import { LeadTransferApi } from "../../Services/LeadTransferApi";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchIcon from "@material-ui/icons/Search";
import { CircularProgress, InputAdornment, TextField } from "@material-ui/core";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsSubmitted } from "../../Reducers/loginSlice";
import { getCookie } from "../../Utils/commonUtils";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import NoRecords from "../Dashboard/Other-components/NoRecords";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "80px 30px 30px 30px",
  },
  heading: {
    color: "#3A1C67",
    fontSize: "20px",
    fontWeight: 700,
    textAlign: "left",
    marginLeft: 20,
  },
  subHeading: {
    fontWeight: 700,
    fontSize: 18,
    marginBottom: 10,
  },
  whiteBox: {
    padding: 30,

    borderRadius: 10,
    backgroundColor: "#fff",
    color: "#3A1C67",
    textAlign: "left",
    //display: "flex",
    //width:'100%'
  },

  transferBox: {
    // marginRight: 80,
  },
  button: {
    backgroundColor: "#3A1C67",
    color: "#fff",
    textTransform: "none",
    height: "34px",
    borderRadius: "10px",
    fontSize: "14px",
    marginLeft: 30,
    paddingLeft: 20,
    paddingRight: 20,
    "&:hover": {
      backgroundColor: "#3A1C67",
    },
  },
}));

const LeadTransfer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loginInfo = getCookie("callpal-user");
  const [productLoader, setProductLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  // Transfer To -------------------------------------------------------

  const [statusArr, setStatusArr] = useState([]);
  const [statusSearch, setStatusSearch] = useState("");
  const [transferFromDetails, setTransferFromDetails] = useState({
    id: "",
    name: "",
  });
  //-------------------------------------------------------

  // Transfer To -------------------------------------------------------

  const [transferFromArr, setTransferFromArr] = useState([]);
  const [emptyData, setEmptyData] = useState([]);
  const [transferFromSearch, setTransferFromSearch] = useState("");
  const [transferToDetails, setTransferToDetails] = useState({
    id: "",
    name: "",
  });
  //-------------------------------------------------------

  useEffect(() => {
    getLeadTransferList();
  }, []);

  const getLeadTransferList = async () => {
    try {
      setProductLoader(true);
      const res = await LeadTransferApi.getLeadTransferList(); // assuming getLeadTransferList() returns an object with status and data properties
      if (res.status) {
        const statusArray = Object.entries(res.data).map(([id, status]) => ({
          id,
          status,
        }));
        setStatusArr(statusArray);
        setTransferFromArr(statusArray);
      }
      setProductLoader(false);
    } catch (err) {
      console.error("Error fetching lead transfer list:", err);
      setProductLoader(false);

    } finally {
      setProductLoader(false);

    }
  };
 

  const validationSchema = Yup.object().shape({
    from_user_id: Yup.string().required("Transfer from user is required"),
    to_user_id: Yup.string()
      .required("Transfer to user is required")
      .test(
        "not-same",
        "Transfer to cannot be the same as Transfer from",
        function (value) {
          const { from_user_id } = this.parent;
          return value !== from_user_id;
        }
      ),
  });

  const formik = useFormik({
    initialValues: {
      from_user_id: "",
      to_user_id: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true)

        const res = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/lead-transfer`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
              Authorization: `Bearer ${loginInfo.token}`,
            },
            body: JSON.stringify(values),
          }
        );
        const data = await res.json();
        setLoading(true)
        if (data.status) {
          dispatch(
            setIsSubmitted({
              open: true,
              severity: "success",
              msg: data.message,
            })
          );
        } else {
          dispatch(
            setIsSubmitted({ open: true, severity: "error", msg: data.message })
          );
        }
        setLoading(false);

      } catch (e) {
        console.log(e.message);
        setLoading(false)
      }
    },
  });

  return (
    <Formik>
      <form>
      
        <div className={classes.mainContainer}>
          <div className={classes.heading}>Transfer Data</div>
          <div className={classes.whiteBox} id="lead_transfer_margin">

            <div className="row">
              <div className="col-md-4 col-sm-4">
                <div className={classes.transferBox}>
                  <div className={classes.subHeading}>Transfer From</div>


                  <UncontrolledButtonDropdown >
                    <DropdownToggle
                      style={{
                        background: "white",
                        color: "#808080",
                        width: "250px",
                        textAlign: "left",
                        display: "flex",
                        justifyContent: "space-between",
                        fontSize: "14px",
                        borderRadius:"5px"
                      }}
                      name="from_user_id"
                    >
                      {transferFromDetails.name.length === 0
                        ? "Select from user"
                        : transferFromDetails.name}
                      <ArrowDropDownIcon></ArrowDropDownIcon>
                    </DropdownToggle>
                    <DropdownMenu>
                      {transferFromArr.length > 0 && !productLoader && (
                        <>
                          <DropdownItem header>
                            <TextField
                              placeholder="Search Product"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon />
                                  </InputAdornment>
                                ),
                              }}
                              size="small"
                              variant="outlined"
                              onChange={(e) =>
                                setTransferFromSearch(e.target.value)
                              }
                            />
                          </DropdownItem>
                          <div style={{ maxHeight: 150, overflowY: "scroll" }}>
                            {transferFromArr
                              .filter((e) =>
                                e.status
                                  .toString()
                                  .toLowerCase()
                                  .includes(transferFromSearch.toLowerCase())
                              )
                              .map((el, i) => (
                                <DropdownItem
                                  key={el.id}
                                  onClick={() => {
                                    formik.setFieldValue("from_user_id", el.id); 
                                    setTransferFromDetails({
                                      id: el.id,
                                      name: el.status,
                                    });
                                  }}
                                >
                                  {el.status}
                                </DropdownItem>
                              ))}
                          </div>
                        </>
                      )}
                      {productLoader && (
                        <div style={{ textAlign: "center" }}>
                          <CircularProgress size={20} />
                        </div>
                      )}
                    </DropdownMenu>
                  
                  </UncontrolledButtonDropdown>

                

              

                
                </div>
              </div>
              
              <div className="col-md-8 col-sm-8">
                
                <div className={classes.transferBox}>
                  <div className={classes.subHeading}>Transfer To</div>


                  <UncontrolledButtonDropdown >
                    <DropdownToggle
                      style={{
                        background: "white",
                        color: "#808080",
                        width: "250px",
                        textAlign: "left",
                        display: "flex",
                        justifyContent: "space-between",
                        fontSize: "14px",
                        borderRadius:"5px"
                      }}
                      name="to_user_id "
                    >
                      {transferToDetails.name.length == 0
                        ? "Select Transfet to Name"
                        : transferToDetails.name}
                      <ArrowDropDownIcon></ArrowDropDownIcon>
                    </DropdownToggle>
                    <DropdownMenu>
                      {statusArr.length > 0 && !productLoader && (
                        <>
                          <DropdownItem header>
                            <TextField
                              placeholder="Search Product"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon />
                                  </InputAdornment>
                                ),
                              }}
                              size="small"
                              variant="outlined"
                              onChange={(e) => setStatusSearch(e.target.value)}
                            />
                          </DropdownItem>
                          <div style={{ maxHeight: 150, overflowY: "scroll" }}>
                            {/* <DropdownItem
                          onClick={() => {
                            setTransferToDetails({ id: "", name: "All Product" });
                            handleTransferId("");
                          }}
                        >
                          All Product
                        </DropdownItem> */}
                            {statusArr.length > 0 &&
                              statusArr
                                .filter((e) =>
                                  e.status
                                    .toString()
                                    .toLowerCase()
                                    .includes(statusSearch.toLowerCase())
                                )
                                .map((el, i) => (
                                  <DropdownItem
                                    key={el.id}
                                    onClick={() => {
                                      // handleTransferToId(el.id);
                                      setTransferToDetails({
                                        id: el.id,
                                        name: el.status,
                                      });
                                      formik.setFieldValue("to_user_id", el.id); // Set formik field value
                                    }}
                                  >
                                    {el.status}
                                  </DropdownItem>
                                ))}
                          </div>
                        </>
                      )}
                      {productLoader && (
                        <div style={{ textAlign: "center" }}>
                          <CircularProgress size={20} />
                        </div>
                      )}
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                  
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                {formik.touched.from_user_id && formik.errors.from_user_id ? (
                    <span className="text-danger  text-small text-left mt-2 ">
                      {formik.errors.from_user_id}
                    </span>
                  ) : null}
                </div>
                <div className="col-md-8 col-sm-8">
                      {formik.touched.to_user_id && formik.errors.to_user_id ? (
                        <span className="text-danger text-small text-left ">
                          {formik.errors.to_user_id}
                        </span>
                      ) : null}
                </div>
                
            </div>


          
          </div>
                {loading &&  
                <div style={{
                  position: "absolute",
                  top: "35%",
                  left: "45%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 9999, // Ensure it's on top of other content
                  }}>
                  <CircularProgress size={40} />
              </div>
             }

          

          <div style={{ textAlign: "left" }}>
            <Button onClick={formik.handleSubmit} className={classes.button}>
              Initiate Transfer  
            </Button>
          </div>
        </div>
      </form>
    </Formik>
  );
};
export default LeadTransfer;
