import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { setIsSubmitted } from '../Reducers/loginSlice';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

export default function MessagePopper() {
const {isSubmitted}=useSelector((state)=>state.login)
const dispatch=useDispatch()
  const [state, setState] = React.useState({
    vertical: 'top',
    horizontal: 'right',
  });


  const handleClose = () => {
    dispatch(setIsSubmitted({open:false,msg:'',severity:''}))
  };

 
  return (
    <div>
       <Snackbar open={isSubmitted.open} autoHideDuration={6000} onClose={handleClose}
       anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
       >
        <Alert onClose={handleClose} severity={isSubmitted.severity}>
        {isSubmitted.msg}
        </Alert>
      </Snackbar>
    </div>
  );
}