import Footer from "../Footer";
import Header from "../Header";
import PartnerForm from "./PartnerForm";
import Program from "./Program";

const Partners=()=>{
    return(
        <div>
            <Header/>
            <Program/>
            <PartnerForm/>
            <Footer/>
        </div>
    )
}
export default Partners;