import {
  Button,
  CircularProgress,
  Fade,
  FormControlLabel,
  IconButton,
  Paper,
  Popper,
  TextField,
  makeStyles,
  InputAdornment,

} from '@material-ui/core'
import { useState, useRef, useEffect } from 'react'
// -----------------------------------------------------

import computerImg from "../../Images/computer 1.png";
import { setIsSubmitted } from "../../Reducers/loginSlice";
import nextImg from "../../Images/next1.svg";
import cancelImg from "../../Images/close.svg";
import polygon from "../../Images/Polygon 7.svg";
import downloadImg from "../../Images/download 1.svg";
import submitImg from "../../Images/submit 1.svg";
import greenPoly from "../../Images/green-poly.svg";
import tickImg from "../../Images/tick.svg";

import { OrangeCheckbox } from '../../Styles'
import FileUpload from "../../Components/Dashboard/Other-components/FileUpload";
import BulkDataSelectUser from "../../Components/Dashboard/Other-components/BulkDataSelectUser";

import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from "reactstrap";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchIcon from "@material-ui/icons/Search";
import { getCookie } from "../../Utils/commonUtils";


//------------------------------------------------------

// import { OrangeCheckbox } from '../../../Styles'
// import FileUpload from '../Other-components/FileUpload'
// import BulkDataSelectUser from '../Other-components/BulkDataSelectUser'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { BulkUploadApi } from '../../Services/BulkUploadApi';
const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 70,
    display: 'flex',
    textAlign: 'left',
    height: '100%',
  },
  section1: {
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: '30px',
    },
  },
  section2: {
    width: '30%',
    backgroundColor: '#3A1C6733',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '100%',
    //alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  buttonSection: {
    display: 'flex',
    justifyContent: 'center',
  },

  root: {
    '& label.Mui-focused': {
      color: '#3A1C67',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#3A1C67',
      },
      '&:hover fieldset': {
        borderColor: '#3A1C67',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#3A1C67',
      },
    },
    margin: '10px 0px',
    width: '370px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  multilineColor: {
    color: '#3A1C67',
  },
  computerImg: {
    width: '200px',
    height: 'auto',
  },
  button1: {
    backgroundColor: '#3A1C67',
    color: '#fff',
    textTransform: 'none',
    marginTop: '20px',
    height: '38px',
    borderRadius: '10px',
    fontSize: '14px',
    margin: 20,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    transition: '.4s all ease-in-out',
    '&:hover': {
      backgroundColor: '#3A1C67',
      boxShadow: '-2px 7px 8px #575f6b80',
    },
  },
  button2: {
    backgroundColor: '#fff',
    color: '#3A1C67',
    textTransform: 'none',
    marginTop: '20px',
    height: '38px',
    borderRadius: '10px',
    border: '1px solid #3A1C67',
    fontSize: '14px',
    margin: 20,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    transition: '.4s all ease-in-out',
    '&:hover': {
      backgroundColor: '#fff',
      boxShadow: '-2px 7px 8px #575f6b80',
    },
  },
  iconButton: {
    //position: 'absolute',
    //right: '90%',
    marginLeft: -25,
    height: 50,
    width: 50,
    backgroundColor: '#3A1C67',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#3A1C67',
    },
  },
  iconButton2: {
    //position: 'absolute',
    //right: '90%',
    marginLeft: -25,
    height: 50,
    width: 50,
    backgroundColor: '#969696',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#3A1C67',
    },
  },
  allocated: {
    fontSize: 16,
    color: '#3A1C67',
    fontWeight: 700,
    marginRight: 20,
  },
  steps: {
    color: '#848891',
    fontSize: 14,
    padding: '7px 0px',
  },
  cardHeading: {
    color: '#3A1C67',
    fontSize: '14px',
    fontWeight: 700,
    paddingBottom: 5,
  },
  cardContent: {
    color: '#3A1C67',
    fontSize: '12px',
    padding: '5px 0px',
  },
  tooltip: {
    marginLeft: '29px',
    width: '215px',
    padding: 20,
    backgroundColor: '#F9F4F0',
    boxShadow: '5px 10px 18px #888888',
  },
  tooltipInactive: {
    marginLeft: '29px',
    width: '215px',
    padding: 20,
    backgroundColor: 'transparent',
    boxShadow: '5px 10px 18px #888888',
  },
}))

const BulkUploadCallAllocation = () => {
  const classes = useStyles()
  const note1Ref = useRef()
  const note2Ref = useRef()
  const note3Ref = useRef()
  const note4Ref = useRef()
  const [state, setState] = useState({
    uploadType1: false,
    uploadType2: false,
    uploadType3: false,
    uploadType4: false,
  })
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorE2, setAnchorE2] = useState(null)
  const [anchorE3, setAnchorE3] = useState(null)
  const [anchorE4, setAnchorE4] = useState(null)
  const [isNext, setIsNext] = useState(true)
  const [selectedUsers, setSelectedUsers] = useState([])
  const [selectedFile, setSelectedFile] = useState('')
  const { userInfo } = useSelector((state) => state.login)
  const [loading, setLoading] = useState(false)
  const [processList, setProcessList]= useState({
    id: "",
    name: "",
  });

  const [processArr, setProcessArr] = useState([]);
  const [statusSearch, setStatusSearch] = useState("");
  const [productLoader, setProductLoader] = useState(false);
  const [ sampleFileUrl, setSampleFileUrl]=useState()
  const loginInfo = getCookie("callpal-user");

  
  useEffect(() => {
    setAnchorEl(note1Ref.current)
    setAnchorE2(note2Ref.current)
    setAnchorE3(note3Ref.current) 
    setAnchorE4(note4Ref.current)
    fetchProcessArr();

  }, [isNext])


  const handleChange = (event) => {
    if(event.target.name=='uploadType3')
    {
    setState({ ...state, [event.target.name]: true,uploadType4:false })
    }
    if(event.target.name=='uploadType4'){
      setState({ ...state, [event.target.name]: true,uploadType3:false })
    }
  }


  const handleSubmit = async () => {
    setLoading(true);
    try {
      let formData = new FormData();
      if (!state.uploadType3 && !state.uploadType4) {
              dispatch(setIsSubmitted({
                open: true,
                severity: 'error',
                msg: 'Select any one upload type.'
              }));
              setLoading(false);
              return false;
      }
      if (state.uploadType4 && selectedUsers.length == 0) {
        dispatch(setIsSubmitted({
          open: true,
          severity: 'error',
          msg: 'Please select user.'
        }));
        setLoading(false);
        return false;
      }
      if (!selectedFile) {
          dispatch(setIsSubmitted({ open: true, severity: 'error', msg: 'The lead file field is required.' }));
          setLoading(false);
          return false;
      }
      formData.append('lead_file', selectedFile);
      formData.append('fk_process_id', statusSearch);
      formData.append('is_common_pool', state.uploadType3);
      formData.append('industry_sub_type', loginInfo?.userInfo?.industry_sub_type);
      formData.append('user_ids',state.uploadType4 ? JSON.stringify(selectedUsers) : []);
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/lead-import`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          'Access-Control-Allow-Origin': '*',
        },
        body: formData
      })
      .then(response => response.json())
      .then(res => {
        if(res.status == 'true'){
            dispatch(setIsSubmitted({ open: true, severity: 'success', msg: (res.message) }))
        } else {
          if(res.status == 'false'){
            dispatch(setIsSubmitted({ open: true, severity: 'error', msg: (res.message) }))
          } 
          if (res.hasOwnProperty("errors")) {
            dispatch(setIsSubmitted({ open: true, severity: 'error', msg: res?.errors?.lead_file[0] }));
          }
        }
        setLoading(false);
      }).catch((e)=>{
        
        if(e.hasOwnProperty('response')){
        if(!e.response.data.status){
          if(e.response.data.hasOwnProperty('message')){
            dispatch(setIsSubmitted({ open: true, severity: 'error', msg:e.response.data.message  }));
        }
        if(e.response.data.hasOwnProperty('data')){
          if(e.response.data.data.length==0){
            dispatch(setIsSubmitted({ open: true, severity: 'error', msg: e.response.data.message }))
          }
        }
        }
        setLoading(false);
      }
      if(e.code==='ERR_NETWORK'){
        dispatch(setIsSubmitted({ open: true, severity: 'info', msg: 'Please upload file again!' }))
      }
      })
    }
    catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.message) {
        errorMessage = e.message;
      }
      dispatch(setIsSubmitted({ open: true, severity: 'error', msg: errorMessage }));
      setLoading(false)
    }
  }


  const fetchProcessArr= async () => {
    try {
      const res = await BulkUploadApi.getProcessList();
      if (res.status) {
        setProcessArr(Object.entries(res.data).map(([id, name]) => ({ id, status: name })));
        setProductLoader(false);
        setSampleFileUrl(res.sample_file_url);
      }
    } catch (e) {
      console.log(e.message);
      // setLoading(false)
    }
  };

  

  return (
    <> 
    

       
        <div style={{ textAlign: 'left', marginTop: 30, marginLeft: 40 }}>

        <div >
      <div className={classes.allocated}>
        Select Process
      </div>

       <div style={{ color: '#3A1C67', fontWeight: 700, fontSize: 17 }}>
        <UncontrolledButtonDropdown>
        <DropdownToggle
          style={{
            display: "flex",
            gap: "10px",
            background: "white",
            color: "#3A1C67",
            border: "none",
            borderRadius: "5px",
            marginBottom:"20px",
            marginTop:"10px",
            width: "250px",
            justifyContent: "space-between",
          }}
        >
          {processList.name.length === 0
            ? "Default Process"
            : processList.name}
          <ArrowDropDownIcon />
        </DropdownToggle>
        <DropdownMenu>
          {processArr.length > 0 && (
            <>
              <DropdownItem header>
                <TextField
                  placeholder="Search Process"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                  variant="outlined"
                  onChange={(e) => setStatusSearch(e.target.value)}
                />
              </DropdownItem>
              <div style={{ maxHeight: 150, overflowY: "scroll" }}>
                <DropdownItem
                  onClick={() => {
                    setProcessList({ id: "", name: "Default Process" });
                    //handleProcessID("");
                  }}
                >
                  Default Process
                </DropdownItem>
                {processArr
                  .filter((e) =>
                    e.status
                      .toString()
                      .toLowerCase()
                      .includes(statusSearch.toLowerCase())
                  )
                  .map((el, i) => (
                    <DropdownItem
                      key={el.id}
                      onClick={() => {
                        //handleProcessID(el.id);
                        setProcessList({ id: el.id, name: el.status });
                      }}
                    >
                      {el.status}
                    </DropdownItem>
                  ))}
              </div>
            </>
          )}
          {productLoader && (
            <div style={{ textAlign: "center" }}>
              <CircularProgress size={20} />
            </div>
          )}
        </DropdownMenu>
      </UncontrolledButtonDropdown>
        </div>
    </div>


    


          <div style={{ color: '#3A1C67', fontWeight: 700, fontSize: 17 }}>
            Select Upload Type
          </div>

          {!isNext && (
            <div>
              <div style={{ paddingTop: 10 }}>
                <FormControlLabel
                  control={
                    <OrangeCheckbox
                      checked={state.uploadType1}
                      onChange={handleChange}
                      name='uploadType1'
                    />
                  }
                  label={
                    <div style={{ display: 'flex' }}>
                      <div className={classes.allocated}>
                        Allocated{' '}
                        <span
                          style={{
                            fontSize: 12,
                            color: '#848891',
                            fontWeight: 500,
                          }}
                        >
                          (default)
                        </span>
                      </div>
                      <div className={classes.allocated}>
                        Max{' '}
                        <span style={{ color: '#848891', fontWeight: 500 }}>
                          (Records : 5,000)
                        </span>
                      </div>
                    </div>
                  }
                />
                <div className={classes.steps}>
                  1. Creates or overwrites{' '}
                  <span style={{ fontSize: 12 }}>(if already exists)</span>{' '}
                  allocates
                </div>
              </div>
              <div style={{ paddingTop: 10 }}>
                <FormControlLabel
                  control={
                    <OrangeCheckbox
                      checked={state.uploadType2}
                      onChange={handleChange}
                      name='uploadType2'
                    />
                  }
                  label={
                    <div style={{ display: 'flex' }}>
                      <div className={classes.allocated}>
                        Allocated{' '}
                        <span
                          style={{
                            fontSize: 12,
                            color: '#848891',
                            fontWeight: 500,
                          }}
                        >
                          (default)
                        </span>
                      </div>
                      <div className={classes.allocated}>
                        Max{' '}
                        <span style={{ color: '#848891', fontWeight: 500 }}>
                          (Records : 2,000)
                        </span>
                      </div>
                    </div>
                  }
                />
                <div className='upload_file_list'>
                  <div className={classes.steps}>
                    1. Upload and creates Customer base
                  </div>
                  <div className={classes.steps}>
                    2. Creates an Interaction{' '}
                    <span style={{ fontSize: 12 }}>(with Status)</span> and will
                    make it part of customer history
                  </div>
                </div>
              </div>
            </div>
          )}
          {isNext && (
            <div>
              <div style={{ paddingTop: 10 }}>
                <FormControlLabel
                  control={
                    <OrangeCheckbox
                      checked={state.uploadType3}
                      onChange={handleChange}
                      name='uploadType3'
                    />
                  }
                  label={
                    <div style={{ display: 'flex' }}>
                      <div className={classes.allocated}>
                        Assign Leads In Common Pool
                      </div>
                    </div>
                  }
                />
                <div className='upload_file_list'>
                  <div className={classes.steps}>
                    1. If enabled, leads will be uploaded into common pool of
                    allocations.
                  </div>
                  <div className={classes.steps}>
                    2. Common pool of allocations are available for all the
                    users under the selected process.
                  </div>
                  <div className={classes.steps}>
                    3. These allocations can only be accessed through auto dial
                    option in the app.
                  </div>
                </div>
              </div>
              <div style={{ paddingTop: 10 }}>
                <FormControlLabel
                  control={
                    <OrangeCheckbox
                      checked={state.uploadType4}
                      onChange={handleChange}
                      name='uploadType4'
                    />
                  }
                  label={
                    <div style={{ display: 'flex' }}>
                      <div className={classes.allocated}>
                        Auto allocate the uploaded data
                      </div>
                    </div>
                  }
                />
                <div className='upload_file_list'>
                  <div className={classes.steps}>
                    1. If enabled, will automatically distribute the allocation
                    data among the selected team members.
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {isNext && (
          <div>
            <div style={{ textAlign: 'center' }}>
              <a
                href={`${process.env.REACT_APP_API_BASE_URL}/${sampleFileUrl}`}
                // href={`${process.env.REACT_APP_API_BASE_URL}/Sample_File.xlsx`}
                //href="https://callpal.co.in/callpal/public/Sample_File.xlsx" 
                download="Example-PDF-document"
                rel="noopener noreferrer"
              >
                <Button
                  variant='contained'
                  color='secondary'
                  className={classes.button1}
                  endIcon={<img src={downloadImg} alt='' />}
                //onClick={downloadTemplate}
                >
                  Download Sample Templates
                </Button>
              </a>
            </div> 
            <div style={{ textAlign: 'center' }}>
              <FileUpload setSelectedFile={(f) => setSelectedFile(f)} />
            </div>
          </div>
        )}

        {
          <div className={classes.buttonSection}>
            {/* <Button
              variant='contained'
              color='secondary'
              className={classes.button2}
              endIcon={!isNext ? <img src={cancelImg} alt='' /> : null}
              startIcon={isNext ? <img src={prevImg} alt='' /> : null}
              onClick={() => {
                !isNext ? setIsNext(false) : setIsNext(false)
              }}
            >
              {!isNext ? 'Cancel' : 'Previous'}
            </Button> */}
            <Button
              variant='contained'
              color='secondary'
              className={classes.button1}
              endIcon={
                isNext ? (
                  <img src={submitImg} alt='' />
                ) : (
                  <img src={nextImg} alt='' />
                )
              }
              onClick={() => { !isNext ? setIsNext(true) : handleSubmit() }}
            >
              {!isNext ? 'Next' : (loading?<CircularProgress size='20px'/>:'Submit')}
            </Button>
          </div>
        }
         <BulkDataSelectUser
        anchorEl={state.uploadType4}
        setAnchorE1={(f) => setState({ ...state, uploadType4: f })}
        setSelectedUsers={(u) => setSelectedUsers([...u])}
        anchorE2={state.uploadType3}
      />
     </>
  )
}
export default BulkUploadCallAllocation;
