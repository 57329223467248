import { Box, Container, makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    subTitle: {
    fontSize: "17px",
    color: "#252525",
    fontWeight: 400,
    textAlign:"start"
  },
  title: {
    fontSize: "30px",
    color: "#3A1C67",
    fontWeight: 700,
    textAlign:"start",
    marginBottom:'0px'
  },
  boxContainer: {
    display:'flex',
    flexDirection:'column',
    marginTop:"10px",
    justifyContent: "center"
  },
  
}));
const HeadingPara = ({title,subTitle,color,headingClass,subTitleClass}) => {
  const classes = useStyles();
  return (
      <Box className={classes.boxContainer}>
            {title && <p className={headingClass?headingClass:classes.title} >{title}</p>}
            <p className={subTitleClass?subTitleClass:classes.subTitle} style={{color:color?color:'#252525'}}>{subTitle}</p>
          </Box>
  );
};
export default HeadingPara;
