import { Box, Container, makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: "30px",
    color: "#3A1C67",
    fontWeight: 700,
  },
  subHeading: {
    fontSize: "18px",
    color: "#848891",
    padding: "0px 200px",
    paddingTop: "10px",
    paddingBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 20px",
    },
  },
  boxCotainer: {
    height: "356px",
    margin: "90px 30px",
    width: "350px",
    backgroundColor: "#00D6BC1A",
    borderRadius: "12px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      margin: "90px 20px",
    },
  },
}));
const Card = ({img,title,subTitle,background}) => {
  const classes = useStyles();
  return (
    <div>
      <Container maxWidth="lg" style={{ padding: "20px 0px" }}>
      <Box
            className={classes.boxCotainer}
            style={{ backgroundColor: background }}
          >
            <Box style={{ position: "absolute", left: "5%", bottom: "250px" }}>
              <img loading="eager" src={img} alt="" style={{marginLeft:"15px"}}/>
            </Box>
            <Box
              style={{
                paddingTop: "130px",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <Box
                style={{ color: "#3A1C67", fontSize: "20px", fontWeight: 600 }}
              >
                {title}
              </Box>
              <Box
                style={{
                  color: "#848891",
                  fontSize: "14px",
                  padding: "20px 0px",
                }}
              >
                {subTitle}
              </Box>
            </Box>
          </Box>
      </Container>
    </div>
  );
};
export default Card;
