import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Box, Button, TablePagination } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useEffect } from 'react';
import { StyledTableCell, StyledTableRow } from '../../Styles';
import { useSelector } from 'react-redux';
import { Skeleton } from '@material-ui/lab';
import { getCookie } from '../../Utils/commonUtils'
import NoRecords from './Other-components/NoRecords';
import constantConfig from '../../constants/constantConfig';


const useStyles = makeStyles({
  table: {
    minWidth: 220,
  },
  viewButton: {
    backgroundColor: '#3A1C67',
    color: '#fff',
    marginLeft: '10px',
    borderRadius: '5px',
    fontSize: '5px',
    padding: '0px 3px',
    cursor: 'pointer'
  },
  tableContainer: {
    margin: '20px 0px',
    maxHeight: 420,
    // width: '100%',
    display: 'flex',
    '&::-webkit-scrollbar': {
      width: '0.5em',
      display: 'block'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'darkgrey',
      outline: '1px solid slategrey',
      borderRadius: '5px'
    }
  },

});

export default function FileTable({ viewMore, setViewMore, dates, bankData, dashboardloading }) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { userInfo } = useSelector((state) => state.login)
  
  
  const [loader, setLoader] = useState(false)
  const [offset, setOffset] = useState(0)
  const [count, setCount] = useState(20)
  const [loginCount, setLoginCount] = useState([])
  const [sanctionCount, setSanctionCount] = useState([])
  const [disburseCount, setDisburseCount] = useState([])
  const [rejectCount, setRejectCount] = useState([])
  const loginInfo=getCookie('callpal-user')
  const loginIndSubType = loginInfo.userInfo.ind_sub_type
  const indSubType = constantConfig.INDUSTRY_SUB_TYPE;
  const rows = bankData?.data || [];
  const loading = dashboardloading;
  

  const loanHeader = [
    { label: 'Bank Name', colSpan: 2 },
    { label: 'Login Files', colSpan: 2 },
    { label: 'Sanction Amount', colSpan: 2 },
    { label: 'Disbursement Amount', colSpan: 2 },
    { label: 'Rejected Files', colSpan: 2 },
  ];

  const CreditCardHeader = [
    { label: 'Bank Name', colSpan: 2 },
    { label: 'Total QD', colSpan: 2 },
    { label: 'Total Login', colSpan: 2 },
    { label: 'Total Approved', colSpan: 2 },
    { label: 'Total KYC', colSpan: 2 },
    { label: 'Total Dispatch', colSpan: 2 },
    { label: 'Total Reject', colSpan: 2 },

  ];


  const headers = loginInfo.userInfo.ind_sub_type === indSubType.CREDIT_CARD? CreditCardHeader : loanHeader;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
        let num=0
        if(newPage>page){
            num=offset+rowsPerPage
        }
        else{
            num=offset-rowsPerPage
        }
       
        setOffset(num)
  };
 
  

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setOffset(0)
  };
  useEffect(() => {
    if (!viewMore) {
      setRowsPerPage(3)
    }
    else {
      setRowsPerPage(10)
    }
  }, [viewMore])

  // const headers = ['Login Files']

 
  useEffect(() => {
   
  
  }, [userInfo, dates,rowsPerPage,offset])


  return (
    <div>
      {loader ?
        <Skeleton variant="rect" width={'100%'} height={200} style={{margin:'20px 0px '}}/>
        :
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table stickyHeader className={classes.table} aria-label="customized table" size='small'>
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <StyledTableCell key={index} colSpan={header.colSpan} align="center">
                  {header.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>


            <TableBody>

              {rows && rows.length > 0 ?
              rows.length>0 &&  rows.map((row,i) => (
                loginInfo.userInfo.ind_sub_type === indSubType.CREDIT_CARD ? 

                <StyledTableRow id={row.id}>
                <StyledTableCell  colSpan={2}  align='center'>{row.name}</StyledTableCell>
                <StyledTableCell  colSpan={2}  align='center'>{row.qd}</StyledTableCell>
                <StyledTableCell  colSpan={2}  align='center'>{row.login}</StyledTableCell>
                <StyledTableCell  colSpan={2}  align='center'>{row.approved}</StyledTableCell>
                <StyledTableCell  colSpan={2}  align='center'>{row.kyc}</StyledTableCell>
                <StyledTableCell  colSpan={2}  align='center'>{row.dispatched}</StyledTableCell>
                <StyledTableCell  colSpan={2}  align='center'>{row.reject}</StyledTableCell>

             
              </StyledTableRow>
              :
                <StyledTableRow id={row.id}>
                  <StyledTableCell  colSpan={2}  align='center'>{row.name}</StyledTableCell>
                  <StyledTableCell  colSpan={2}  align='center'>{row.login}</StyledTableCell>
                  <StyledTableCell  colSpan={2}  align='center'>{row.sanction > 0 ? '₹ '+row.sanction : row.sanction}</StyledTableCell>
                  <StyledTableCell  colSpan={2}  align='center'>{row.disbursement > 0 ? '₹ '+row.disbursement : row.disbursement}</StyledTableCell>
                  <StyledTableCell  colSpan={2}  align='center'>{row.reject}</StyledTableCell>
               
                </StyledTableRow>
                
               
              )) :


            
                 <NoRecords rows={rows} loading={loading} />
             
              
              
              
              }
             

            
             
            </TableBody>
          </Table>

        </TableContainer>
      }
      {/* {
        !loader && loginCount.length==0 && sanctionCount.length==0 &&
        !disburseCount.length==0 && rejectCount.length==0 &&
        <div>
          No records found
        </div>
      } */}


      {/* <Box style={{ textAlign: viewMore ? 'left' : 'right', position: viewMore ? 'absolute' : 'relative', bottom: 0, left: viewMore ? 270 : 0 }}>{!viewMore ? 'View More' : ''}
        <button onClick={() => setViewMore(!viewMore)} size='small' className={classes.viewButton}>
          {!viewMore ? <ArrowForwardIosIcon style={{ width: 15 }} /> : <ArrowBackIosIcon style={{ width: 15 }} />}
        </button>
      </Box>
      <div style={{ position: 'absolute', bottom: 0, right: 30 }}>
        {
          viewMore &&
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            backIconButtonProps={{
              "aria-label": "Previous Page",
              style: { color: '#fff', backgroundColor: '#3A1C67', padding: 3, borderRadius: '5px', marginRight: '5px' },
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
              style: { color: '#fff', backgroundColor: '#3A1C67', padding: '3px', borderRadius: '5px' },
            }}
          />}
      </div> */}
    </div>
  );
}
