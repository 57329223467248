import { Box, CircularProgress, TableCell, TableRow } from "@material-ui/core"

const NoRecords=({rows,loading})=>{
    return(
        <>
             {
             loading &&
              <TableRow>
                  <TableCell colSpan={7}>
                    <Box
                      textAlign='center'
                      style={{
                        color: '#BE0A00',
                        alignItems: 'center',
                        backgroundColor: '',
                        display: 'flex',
                        justifyContent: 'center',
                        height: '60vh',
                        position: "absolute",
                        // top: 0,
                        // bottom: 0,
                        left: 0,
                        right: 0,
                      }}
                    >
                      <CircularProgress style={{color:'#3A1C67'}} />
                    </Box>
                  </TableCell>
                </TableRow>
            }
            {
              rows.length == 0 && !loading &&
              <div className="col-12" style={{
                display: 'flex',
                justifyContent: 'center',
              }}> 
                No records found
              </div>
            }
        </>
    )
}
export default NoRecords;