import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Login } from '../Redux/features/login/loginCrud';
const initialState = {
    isError:false,
    result:{},
    isLoading:false,
    isAuthenticated:false,
    userInfo:{},
    isSubmitted:{
        open:false,
        msg:'',
        severity:''
    }
  }

  export const userLogin = createAsyncThunk(
    "login/userLogin",
    async (payload, { rejectWithValue }) => {
      try {
        const res = await Login(payload);
        if (res.status) {
          return res.data;
        } else {
          throw new Error(res.message);
        }
      } catch (error) {
        console.log("catch",error)
        return rejectWithValue(error?.response);
      }
    }
  );



  
const loginSlice = createSlice({
 name: "login",
 initialState,
 reducers:{
   setIsAuthenticated:(state,action)=>{
    state.isAuthenticated=action.payload
   },
   setUserInfo:(state,action)=>{
    state.userInfo=action.payload
   },
   setIsSubmitted:(state,action)=>{
   
    state.isSubmitted=action.payload
   },
 },
 extraReducers:{
  [userLogin.pending]:(state)=>{
    state.loading=true
  },
  [userLogin.fulfilled]:(state,action)=>{
    state.loading=false
    state.isAuthenticated=true
  },
  [userLogin.rejected]:(state,action)=>{
    state.loading=false
    state.isAuthenticated=false
  }
 }

});

export const { setIsAuthenticated,setUserInfo,setIsSubmitted} = loginSlice.actions

export default loginSlice.reducer;