
import {  makeStyles, } from '@material-ui/core/styles'
import { Divider,  } from '@mui/material'
import CircleComponent from '../../Common/CircleComponent';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddIcon from "@material-ui/icons/Add";
import { routes } from "../../constants/routes";
import {   TablePagination,  Button, } from '@material-ui/core'
import { useNavigate } from 'react-router-dom';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor:"#F1F1F1",
      color: '#3A1C67',
      fontWeight:700,
      fontSize:16

    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  




const useStyles = makeStyles({
    mainContainer: {
      padding: '80px 30px 30px 30px',
    },
    headerContaniner: {
      display:'flex',
      flexDirection:'row',
      justifyContent:'space-between',
      marginBottom:'10px'
    },
    headerRight:{
        display:'flex',
        justifyContent:'space-between',
        marginLeft:"400px"
    
    },
    headerLeft:{
        display:'flex',
      flexDirection:'column',
    },
    title:{
        fontSize:'20px',
        fontWeight:'700',
        color: '#FA9510'
    },
    subTitle:{
        display:'flex',
        flexDirection:'row',
        textAlign:'start',
        fontSize:'12px',
        fontWeight:'400',
    },
    divider:{
        marginLeft:'5px !important',
        marginRight:'5px !important'
    },
    expired:{
        background: "#F32839",
        width:'16px',
        height:'16px',
        borderRadius: "50%",
        marginRight:'5px'
    },
    active:{
        background: "#138A00",
        width:'16px',
        height:'16px',
        borderRadius: "50%",
        display:'flex',
        justifyContent:'space-around',
        alignItems:'center',
        marginRight:'5px'
    },
    inner:{
        background: "white",
        width:'10px',
        height:'10px',
        borderRadius: "50%"
    },
    headerRightSub:{
        display:'flex',
        fontSize:'16px',
        fontWeight:'400',
        alignItems:'center',
        marginLeft:'20px',
        color: '#3A1C67'

    },
    button: {
      backgroundColor: "#3A1C67",
      color: "#fff",
      textTransform: "none",
      height: "32px",
      fontSize: "12px",
      width: 140,
      marginBottom: 20,
      marginTop:"10px"
    },
  })

const Subscription=()=>{
    const classes = useStyles();
    const navigate =useNavigate()
    const headers = ['Status', 'Assigned To', 'Designation', 'Created On', 'Expiry Date']
    const rows=[{status:false, assignedTo:'Purvi Jain' , designation:'Tele caller' ,createdOn:'09/10/2023' ,expiryDate:"09/11/2023"},
    {status:true, assignedTo:'Purvi Jain' , designation:'Tele caller' ,createdOn:'09/10/2023' ,expiryDate:"09/11/2023"},
    {status:true, assignedTo:'Purvi Jain' , designation:'Tele caller' ,createdOn:'09/10/2023' ,expiryDate:"09/11/2023"}]
   

      const addMembers =()=>{
        navigate("/dashboard/add-subscription-member")
      }
   
    return(



        <div className={classes.mainContainer}>
            <div className={classes.headerContaniner}>
                <div className={classes.headerLeft}>
                    <span className={classes.title}> 3 subscriptions </span>
                    <div className={classes.subTitle} >
                        <span style={{color:'#1C9231'}}> 1 {' '} {' ' } used</span>
                        <Divider orientation='vertical' className={classes.divider}/>
                        <span style={{color:'#F32839'}}> 1 {' '} {' ' } Unused</span>
                    </div>
                </div>

                <div className={classes.headerRight}>
             <div className={classes.headerRightSub} >
                <CircleComponent outer={classes.active} inner={classes.inner} />
                Active
             </div>
             <div className={classes.headerRightSub}>
                <CircleComponent outer={classes.expired}  />
                Expired
             </div>
             </div>
                <div>
          
             <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            // startIcon={<img src={AddIcon} alt="" />}
            // onClick={() => handleLogout()}
            style={{
              float: "left"
            }}
            onClick={addMembers}
          >
             <AddIcon />
            Add Members
          </Button>
                </div>

            </div>

        <TableContainer component={Paper} >
            <Table
             sx={{ minWidth: 700 }} aria-label="customized table"
            >
            <TableHead>
                <TableRow>
                {headers.map((el, i) => {
                    return (
                    <StyledTableCell key={el} align='center'>
                        {el}
                    </StyledTableCell>
                    )
                })}
                </TableRow>
            </TableHead>

            <TableBody>
                {
                rows.length > 0  && rows.map((row) => (
                    <StyledTableRow key={row.name}>
                    <StyledTableCell  align="center"><div style={{marginLeft:'30px'}}>{row.status?<CircleComponent outer={classes.active} inner={classes.inner} />:<CircleComponent outer={classes.expired} />}</div></StyledTableCell>
                    <StyledTableCell align="center">{row.assignedTo}</StyledTableCell>
                    <StyledTableCell align="center">{row.designation}</StyledTableCell>
                    <StyledTableCell align="center">{row.createdOn}</StyledTableCell>
                    <StyledTableCell align="center">{row.expiryDate}</StyledTableCell>
                    </StyledTableRow>
                ))}
                {/* <NoRecords rows={rows} loading={loading} />2 */}
            </TableBody>
            </Table>
      </TableContainer>

      <div >
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={3}
          rowsPerPage={10}
          page={1}
          onPageChange={()=>{}}
          onRowsPerPageChange={()=>{}}
          backIconButtonProps={{
            "aria-label": "Previous Page",
            style: { color: '#fff', backgroundColor: '#3A1C67', padding: 3, borderRadius: '5px', marginRight: '5px' },
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
            style: { color: '#fff', backgroundColor: '#3A1C67', padding: '3px', borderRadius: '5px' },
          }}
        />
      </div>   
        </div>
    )
}
export default Subscription