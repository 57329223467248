import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import saveIcon from '../../../Images/history 2.svg'
import { FormControl, FormControlLabel, FormLabel, InputBase, Radio, RadioGroup, TextField, colors } from '@material-ui/core';
import { RadioButton } from '../../../Styles';
import SearchIcon from '@material-ui/icons/Search';
import StatusView from './StatusView';

const useStyles = makeStyles({

    header: {
        height: '50px',
        color: '#fff', fontSize: '17px',
        backgroundColor: '#3A1C67', fontWeight: 600,
        padding: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    saveButton: {
        backgroundColor: '#FA9510',
        color: '#fff',
        textTransform: 'none',
        width: 100,
        height: '30px',
        borderRadius: '10px',
        fontSize: '14px',
        marginLeft: 10,
        '&:hover': {
            backgroundColor: '#FA7A21',
        },
    },
    formContainer: {
        padding: '20px 20px'
    },

    formControlLabel: {
        fontSize: 14,
        color: '#3A1C67'
    },
    search: {
        position: 'relative',
        borderRadius: '4px',
        padding: '1px 2px 1px 8px',
        border: '1px solid #848891',
        fontSize: '10px',
        width: '280px',
    },
    inputRoot: {
        color: '#848891',
        width: '240px',
        fontSize: 14
    },
    searchIcon: {
        //padding: theme.spacing(0, 2),
        right: 2,
        height: '100%',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#848891',
        cursor: 'pointer',

    },
    root: {

        '& label.Mui-focused': {
            color: '#3A1C67',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#3A1C67',

            },
            '&:hover fieldset': {
                borderColor: '#3A1C67',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#3A1C67',
            },
        },
       margin: '5px 10px',
        // [theme.breakpoints.down('xs')]: {
        //     width:'100%'
        //   },
    },
    multilineColor: {
        color: '#3A1C67',
        fontSize: 14
    },
});

export default function CustomerStatusPopper({ open, setOpen }) {
    const classes = useStyles();


    const toggleDrawer = (anchor, op) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setOpen(op);
    };
    const [selectedValue, setSelectedValue] = React.useState('login');
    const [val, setVal] = React.useState('single');

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };


    return (
        <div>

            <SwipeableDrawer
                anchor={'right'}
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                <div>
                    <div className={classes.header}>
                        <div>Status</div>
                        <Button className={classes.saveButton} startIcon={<img src={saveIcon} width='19px' alt='' />}>
                            Save
                        </Button>
                    </div>
                    <div className={classes.formContainer}>
                        <div style={{paddingBottom:10}}>
                            <FormControl component="fieldset">
                                <RadioGroup row aria-label="position" name="position" defaultValue="top">

                                    <FormControlLabel
                                        classes={{ label: classes.formControlLabel }}
                                        control={<RadioButton
                                            checked={selectedValue === 'interested'}
                                            onChange={handleChange}
                                            value="interested"
                                        />}
                                        label={'Interested'}
                                        disabled
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        classes={{ label: classes.formControlLabel }}
                                        control={<RadioButton
                                            checked={selectedValue === 'login'}
                                            onChange={handleChange}
                                            value="login"
                                        />}
                                        label={'Log-In'}
                                        labelPlacement="end"
                                    />

                                    <FormControlLabel
                                        classes={{ label: classes.formControlLabel }}
                                        control={<RadioButton
                                            checked={selectedValue === 'sanction'}
                                            onChange={handleChange}
                                            value="sanction"
                                        />}
                                        label={'sanction'}
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        classes={{ label: classes.formControlLabel }}
                                        control={<RadioButton
                                            checked={selectedValue === 'disbursement'}
                                            onChange={handleChange}
                                            value="disbursement"
                                        />}
                                        label={'disbursement'}
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        classes={{ label: classes.formControlLabel }}
                                        control={<RadioButton
                                            checked={selectedValue === 'rejected'}
                                            onChange={handleChange}
                                            value="rejected"
                                        />}
                                        label={'Rejected'}
                                        labelPlacement="end"
                                    />

                                </RadioGroup>
                            </FormControl>
                        </div>

                        <div style={{paddingBottom:10}}>
                            <FormControl component="fieldset">
                                <RadioGroup row aria-label="position" name="position" defaultValue="top">
                                    <FormControlLabel
                                        classes={{ label: classes.formControlLabel }}
                                        control={<RadioButton
                                            checked={val === 'single'}
                                            onChange={(e) => setVal(e.target.value)}
                                            value="single"
                                        />}
                                        label={'Single'}

                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        classes={{ label: classes.formControlLabel }}
                                        control={<RadioButton
                                            checked={val === 'multiple'}
                                            onChange={(e) => setVal(e.target.value)}
                                            value="multiple"
                                        />}
                                        label={'Multiple'}

                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className={classes.search}>
                                <div className={classes.searchIcon}>
                                    <SearchIcon fontSize={'small'} />
                                </div>
                                <InputBase
                                    placeholder="Search…"
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    inputProps={{ 'aria-label': 'search' }}
                                />
                            </div>
                            {val==='single' && <TextField
                                size="small"
                                placeholder="Amount"
                                inputProps={{
                                    className: classes.multilineColor,
                                    style: {
                                        height: "13px",
                                    },
                                }}
                                className={classes.root} id="outlined-basic" variant="outlined"

                            />}
                        </div>
                        <StatusView val={val} selectedValue={selectedValue} />
                    </div>
                </div>
            </SwipeableDrawer>

        </div>
    );
}