import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Logo from "../../Images/Logo.png";
import LogoSvg from "../../Images/logo.svg";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch, useSelector } from "react-redux";


import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  Avatar,
  Box,
  Button,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { routes } from "../../constants/routes";
import { useNavigate, useLocation, Routes, Route } from "react-router-dom";
import MuiListItem from "@material-ui/core/ListItem";
import { dashboardList, superAdminList } from "../../Utils/constant";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import logout from "../../Images/logout.svg";
import constantConfig from "../../constants/constantConfig";

import Screens from "../../Screens";
import { setIsAuthenticated, setUserInfo } from "../../Reducers/loginSlice";
import { getCookie } from "../../Utils/commonUtils";

const drawerWidth = "250px";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#fff",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    "&::-webkit-scrollbar": {
      width: "0.5em",
      display: "block",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "darkgrey",
      outline: "1px solid slategrey",
      borderRadius: "5px",
    },
  },
  content: {
    flexGrow: 1,
    //padding: theme.spacing(3),
    backgroundColor: "#F8F9F9",
    width:"100%"
  },
  orange: {
    color: "#fff",
    backgroundColor: "#F86E20",
  },
  mobileButton: {
    textTransform: "none",
    color: "#3A1C67",
    fontWeight: 400,
    justifyContent: "left",
    fontSize: "15px",
    paddingLeft: "25px",
    // '&:hover':{
    //     backgroundColor:'#fff',
    //     color:'#FC0303'
    //     }
  },
  listText: {
    fontSize: "12px",
    color: "#F86E20",
  },
  selectedText: {
    color: "#3A1C67",
  },
  search: {
    position: "relative",
    borderRadius: "10px",
    padding: "0px 27px 0px 5px",
    border: "1px solid #848891",
    fontSize: "5px",
    marginRight: 10,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  closeIcon: {
    //padding: theme.spacing(0, 2),
    right: 1,
    height: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#848891",
    cursor: "pointer",
  },
  inputRoot: {
    color: "#848891",
  },
  inputInput: {
    // vertical padding + font size from searchIcon
    //paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    fontSize: "14px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  button: {
    backgroundColor: "#3A1C67",
    color: "#fff",
    textTransform: "none",
    height: "32px",
    fontSize: "12px",
    width: 120,
    marginBottom: 20,
  },
}));
const ListItem = withStyles({
  root: {
    "&:hover": {
      backgroundColor: "#A3B8D7",
      color: "white",
      "& .MuiListItemIcon-root": {
        color: "white",
      },
    },
  },
  selected: {},
})(MuiListItem);

export default function Dashboard() {
  const classes = useStyles();
  const dispatch=useDispatch()
  const theme = useTheme();
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(true);
  const path = useLocation().pathname.substring(10);
  const [greet, setGreet] = useState("");
 const loginInfo=getCookie('callpal-user')
 const [anchorEl, setAnchorEl] = React.useState(false);
 const inputEl = useRef(null);
 const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
 const [open, setOpen] = useState(true);
 const { userInfo } = useSelector((state) => state.login);
 const ind_type = userInfo?.userInfo?.ind_type;
 const industryType = constantConfig.INDUSTRY_TYPE.OTHER;

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  var myDate = new Date();

  useEffect(() => {
    var hrs = myDate.getHours();

    if (hrs < 12) {
      setGreet("Good Morning");
    } else if (hrs >= 12 && hrs <= 17) {
      setGreet("Good Afternoon");
    } else if (hrs >= 17 && hrs <= 24) {
      setGreet("Good Evening");
    }
    const getData=async()=>{
      if(loginInfo){
        dispatch(setIsAuthenticated(true)) 
        dispatch(setUserInfo(loginInfo))
     }
     }
     
  getData()
  }, []);

  const handleLogout = () => {
    dispatch(setIsAuthenticated(false))
    dispatch(setUserInfo({}))
    document.cookie =
      "callpal-user" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    navigate(routes.signIn)
  }
  const handleEnquiries = () => {
    setAnchorEl(false);
};
const handleClick = () => {
  setAnchorEl(!anchorEl);
};
const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: false,
        })}
      >
        <Toolbar>
        <IconButton
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            style={{display : isMobile ? "block" : "none"}}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon sx={{ color: '#000' }} />
          </IconButton>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            // onClick={handleDrawerOpen}
            edge="start"
          >
            <img src={LogoSvg} width="42" alt="Logo" />
          </IconButton>
          <div
            noWrap
            style={{ flexGrow: 1, textAlign: "left", paddingLeft : isMobile ? 5 : 20 }}
          >
            <div style={{ color: "#3A1C67", fontSize: isMobile ? 12 : 14}}>{greet}</div>
            <div style={{ color: "#848891", fontSize: isMobile ? 12 : 14 }}>
              Welcome to dashboard!
            </div>
          </div>

          {/* <div className={classes.search}>
            <div
              className={classes.closeIcon}
            >
              <CloseIcon fontSize={"small"} />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
            />
          </div> */}

          <div style={{ display: "flex" }}>
            <Avatar style={{
            width: isMobile ? 30 : 40, // Adjust the width for small screens
            height: isMobile ? 30 : 40, // Adjust the height for small screens
            fontSize: isMobile ? 14 : 15
          }} className={classes.orange}>{`${loginInfo.userInfo.com_intials}`}</Avatar>
            <div style={{ textAlign: "left", paddingLeft: "15px" }}>
              <Box style={{ color: "#3A1C67", fontSize:  15 }}>
              {`${loginInfo.userInfo.first_name}`}
              </Box>
              <Box style={{ color: "#F86E20", fontSize:  13 }}>
              {`${userInfo?.userInfo?.company_name ? userInfo?.userInfo?.company_name : ""}`}
              </Box>
            </div>
          </div>
        </Toolbar>
      </AppBar>


      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        open={isMobile ? open : true}
        onClose={isMobile ? handleDrawerToggle : undefined}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerToggle}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        {/* <Divider /> */}
        <List >


          {loginInfo.userInfo.fk_role_id!=2 && dashboardList.map((el, index) => (
            <ListItem onClick={() => navigate(`/dashboard${el.route.replace(":id","")}`)} button key={el.path} style={{ borderLeft: path == el.route || path == el.route ? '4px solid #3A1C67' : null, backgroundColor: (path == el.route || path == el.route) && '#F1F1F1' }}>
              <ListItemIcon>{<img src={el.icon} alt='' width={25} /> }
              </ListItemIcon>

              <ListItemText
                primaryTypographyProps={{ style: { fontSize: '12px', color: '#3A1C67', fontWeight: 700, width: '150', textWrap: 'wrap' } }} primary={el.path} />
            
            {el?.isArrow &&   
            <div> 
              <ListItemIcon onClick={handleClick}> {anchorEl?<KeyboardArrowDownIcon style={{width:'15px' ,color:'#3A1C67'}}/>:<ArrowForwardIosIcon style={{width:'15px',color:'#3A1C67'}}/>} 
                 
              </ListItemIcon>
                             <Menu
                            id="simple-menu"
                            anchorEl={inputEl.current}
                            style={{marginLeft:'200px'}}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleEnquiries} >
                                <MenuItem onClick={() => {handleEnquiries() }}>Live Demo Enquiry</MenuItem>
                                <MenuItem onClick={() => {handleEnquiries() }}>Free Trial Enquiry</MenuItem>
                                <MenuItem onClick={() => { handleEnquiries() }}>Register User</MenuItem>
                            </Menu>
              </div>
              }
            </ListItem>
          ))}

        

          {loginInfo.userInfo.fk_role_id==2 && superAdminList.map((el, index) => (

            el.route === "/team-view" && userInfo?.userInfo?.ind_type == constantConfig.INDUSTRY_TYPE.OTHER ? (
            <></>
            ): <ListItem onClick={() => navigate(`/dashboard${el.route.replace(":id","")}`)} button key={el.path} style={{ borderLeft: path == el.route || path == el.route ? '4px solid #3A1C67' : null, backgroundColor: (path == el.route || path == el.route) && '#F1F1F1' }}>
            <ListItemIcon>{<img src={el.icon} alt='' width={25} />}</ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ style: { fontSize: '12px', color: '#3A1C67', fontWeight: 700, width: '150', textWrap: 'wrap' } }} primary={el.path} />
          </ListItem>   

          ))}

        </List>
        <div
          style={{
            position: !isAdmin ? "absolute" : "relative",
            right: !isAdmin ? 65 : 5,
            bottom: 0,
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            startIcon={<img src={logout} alt="" />}
            onClick={() => handleLogout()}
          >
            Logout
          </Button>
        </div>
      </Drawer>


      <main id="bg-light-color" className={classes.content} style={{overflowX: "auto"}}>
        <Screens />
      </main>
    </div>
  );
}
