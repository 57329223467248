import {
  Box,
  TextField,
  Button,
  CircularProgress,
  InputAdornment,
  makeStyles,
} from "@material-ui/core";
import updateIcon from "../../../../Images/history 2.svg";
import { useDispatch, useSelector } from "react-redux";
import { setIsSubmitted } from "../../../../Reducers/loginSlice";
import { useEffect, useState } from "react";
import { getCookie } from "../../../../Utils/commonUtils";
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
} from "reactstrap";
import SearchIcon from "@material-ui/icons/Search";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "80px 30px 20px 30px",
  },
  heading: {
    color: "#3A1C67",
    fontSize: "18px",
    fontWeight: 700,
    textAlign: "left",
    marginLeft: 20,
  },
  sectionContainer: {
    display: "flex",
  },
  section1: {
    height: "auto",
    backgroundColor: "#fff",
    margin: 10,
    width: "100%",
    borderRadius: 10,
  },
  sectionHeading: {
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 16,
    backgroundColor: "#3A1C67",
    height: 50,
    padding: "0px 30px",
    textAlign: "left",
    fontWeight: 700,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  saveButton: {
    backgroundColor: "#FA9510",
    color: "#fff",
    textTransform: "none",
    width: "100%",
    height: "30px",
    borderRadius: "10px",
    fontSize: "14px",
    marginLeft: 10,
    "&:hover": {
      backgroundColor: "#FA7A21",
    },
  },
  formRow: {
    marginBottom: "1rem",
  },
}));

const CustomerQD = ({
  bankList,
  status,
  creditCardQD,
  isDisabled,
  saveCreditCardRecord,
}) => {
  const classes = useStyles();
  const params = useParams();
  const leadId = atob(params.leadId);
  const [loading, setLoading] = useState(false);
  const [bankSearch, setBankSearch] = useState("");
  const [statusSearch, setStatusSearch] = useState("");

  useEffect(() => {
    if (creditCardQD) {
      formik.setFieldValue("fk_bank_name", creditCardQD.fk_bank_name || "");
      formik.setFieldValue("fk_bank_id", creditCardQD.fk_bank_id || "");
      formik.setFieldValue("status", creditCardQD.status_name || "");
      formik.setFieldValue("fk_status_id", creditCardQD.fk_status_id || "");
    }
  }, [creditCardQD]);

  const validationSchema = Yup.object({
    fk_bank_name: Yup.string().required("Bank name is required"),
    fk_bank_id: Yup.number().required("Bank name is required"),
    status: Yup.string().required("Status is required"),
    fk_status_id: Yup.number().required("Status is required"),
  });

  const formik = useFormik({
    initialValues: {
      fk_bank_name: "",
      fk_bank_id: "",
      status: "",
      fk_status_id: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      saveCreditCardRecord(values);
      console.log(values);
    },
  });

  const statusArray = Object.entries(status).map(([id, status]) => ({
    id,
    status,
  }));
  const filteredStatuses = statusArray.filter((e) =>
    e.status.toLowerCase().includes(statusSearch.toLowerCase())
  );

  return (
    <div className={classes.sectionContainer}>
      <div className={classes.section1}>
        <form onSubmit={formik.handleSubmit}>
          <div className={classes.sectionHeading}>
            <div>{loading ? <CircularProgress size={20} /> : "QD"}</div>
            <div style={{ display: "flex" }}>

              {isDisabled &&
              <Button
                type="submit"
                className={classes.saveButton}
                startIcon={<img src={updateIcon} width="100%" alt="" />}
              >
                Save
              </Button>
              }
            </div>
          </div>

          <div style={{ padding: "1rem 1rem" }}>
            <div className="row">
              <div className="col-sm-4">
                <div className={classes.formRow}>
                  <UncontrolledDropdown style={{ margin: "0px 5px", width: "100%" , pointerEvents: isDisabled ? "auto" :"none" }}>
                    <DropdownToggle
                      caret
                      style={{
                        width: "95%",
                        display: "flex",
                        justifyContent: "space-between",
                        paddingTop: "10px",
                        paddingBottom: "6px",
                        background: "white",
                        color: "#F86E20",
                        border: "1px solid #F86E20",
                        alignItems: "center",
                        fontSize: "14px",
                      }}
                    >
                      {formik.values.fk_bank_name || "Select a Bank"}
                    </DropdownToggle>
                    <DropdownMenu style={{ width: "100%" }}>
                      <DropdownItem header>
                        <TextField
                          placeholder="Search Bank"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                          size="small"
                          variant="outlined"
                          onChange={(e) => setBankSearch(e.target.value)}
                        />
                      </DropdownItem>
                      <div style={{ maxHeight: 95, overflowY: "scroll" }}>
                        {bankList && bankList.length > 0 ? (
                          bankList
                            .filter((e) =>
                              e.bank.toLowerCase().includes(bankSearch.toLowerCase())
                            )
                            .map((el) => (
                              <DropdownItem
                                key={el.id}
                                onClick={() => {
                                  formik.setFieldValue("fk_bank_name", el.bank);
                                  formik.setFieldValue("fk_bank_id", el.id);
                                }}
                              >
                                {el.bank}
                              </DropdownItem>
                            ))
                        ) : (
                          <DropdownItem align="center">No Bank available</DropdownItem>
                        )}
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  {formik.touched.fk_bank_id && formik.errors.fk_bank_id ? (
                    <p className="text-danger text-small mt-2" style={{ textAlign: "left", marginLeft: "8px" }}>
                      {formik.errors.fk_bank_id}
                    </p>
                  ) : null}
                </div>
              </div>

              <div className="col-sm-4">
                <div className={classes.formRow}>
                  <UncontrolledDropdown style={{ margin: "0px 5px", width: "100%",  pointerEvents: isDisabled ? "auto" :"none" }}>
                    <DropdownToggle
                      caret
                      style={{
                        width: "95%",
                        display: "flex",
                        justifyContent: "space-between",
                        paddingTop: "10px",
                        paddingBottom: "6px",
                        background: "white",
                        color: "#F86E20",
                        border: "1px solid #F86E20",
                        alignItems: "center",
                        fontSize: "14px",
                      }}
                    >
                      {formik.values.status || "Select Status"}
                    </DropdownToggle>
                    <DropdownMenu style={{ width: "100%" }}>
                      <DropdownItem header>
                        <TextField
                          placeholder="Search"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                          size="small"
                          variant="outlined"
                          onChange={(e) => setStatusSearch(e.target.value)}
                        />
                      </DropdownItem>
                      {filteredStatuses.length > 0 ? (
                        <div className="dropdown-item">
                          {filteredStatuses.map((el) => (
                            <DropdownItem
                              key={el.id}
                              onClick={() => {
                                formik.setFieldValue("status", el.status);
                                formik.setFieldValue("fk_status_id", el.id);
                              }}
                            >
                              {el.status}
                            </DropdownItem>
                          ))}
                        </div>
                      ) : (
                        <div className="dropdown-item">
                          <DropdownItem align="center">No Status available</DropdownItem>
                        </div>
                      )}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  {formik.touched.fk_status_id && formik.errors.fk_status_id ? (
                    <p className="text-danger text-small mt-2" style={{ textAlign: "left", marginLeft: "8px" }}>
                      {formik.errors.fk_status_id}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="col-sm-2"></div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CustomerQD;
