import { Box, Container, makeStyles } from "@material-ui/core";
import img2 from "../../Images/Live & Event-based Location Tracking.png";
import img1 from "../../Images/Real-time Knowledge of  Sales Opportunities.png";
import img3 from "../../Images/E-mails-SMS-Communication 1.png";
import img4 from "../../Images/Automated-Lead-Allocation.png";
import img5 from "../../Images/06.png";
import img6 from "../../Images/Notification of Important Tasks.png";
import img7 from "../../Images/Real-time Performance  Monitoring.png";
const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: "30px",
    color: "#3A1C67",
    fontWeight: 700,
  },
  subHeading: {
    fontSize: "18px",
    color: "#848891",
    padding: "0px 200px",
    paddingTop: "10px",
    paddingBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 20px",
    },
  },
  boxCotainer: {
    height: "356px",
    margin: "90px 25px",
    width: "350px",
    backgroundColor: "#00D6BC1A",
    borderRadius: "12px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      margin: "90px 20px",
    },
  },
}));
const Page2_2 = () => {
  const classes = useStyles();
  return (
    <div>
      <Container maxWidth="lg" style={{ padding: "20px 0px" }}>
        <div>
          <Box className={classes.heading}>
            What makes our <span style={{ color: "#F86E20" }}>Field CRM</span>{" "}
            special?
          </Box>
          <Box className={classes.subHeading}>
            Dealing with field operations are not a headache anymore. Field CRM
            has many built in features that truly makes it stand out among most
            other mobile based CRMs out there.
          </Box>
        </div>
        <div
          style={{
            padding: "60px 20px 0px 20px",
            display: "flex",
            flexWrap: "wrap",
            /* justifyContent: "center", */
          }}
        >
          <Box
            className={classes.boxCotainer}
            style={{ backgroundColor: "#47478E1A" }}
          >
            <Box style={{ position: "absolute", left: "15%", bottom: "250px" }}>
              <img loading="eager" src={img2} alt="" />
            </Box>
            <Box
              style={{
                paddingTop: "130px",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <Box
                style={{ color: "#3A1C67", fontSize: "20px", fontWeight: 600 }}
              >
                Live & Event-based Location Tracking
              </Box>
              <Box
                style={{
                  color: "#848891",
                  fontSize: "14px",
                  padding: "20px 0px",
                }}
              >
                An inclusive mobile solution, Field CRM lets you have real-time
                and event-based updates from the work-field. We enable you to
                know who of your staff is doing what, where and when.
              </Box>
            </Box>
          </Box>
          <Box
            className={classes.boxCotainer}
            style={{ backgroundColor: "#FFE9481A" }}
          >
            <Box style={{ position: "absolute", left: "10%", bottom: "250px" }}>
              <img loading="eager" src={img1} alt="" />
            </Box>
            <Box
              style={{
                paddingTop: "130px",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <Box
                style={{ color: "#3A1C67", fontSize: "20px", fontWeight: 600 }}
              >
                Real-time Knowledge of Sales Opportunities
              </Box>
              <Box
                style={{
                  color: "#848891",
                  fontSize: "14px",
                  padding: "20px 0px",
                }}
              >
                You keep a tab on every sales-opportunity happening in the
                field. Sales-managers can make access to staff-assignments,
                schedules, sales-reports, deals and projects to keep themselves
                continually informed.
              </Box>
            </Box>
          </Box>
          <Box
            className={classes.boxCotainer}
            style={{ backgroundColor: "#3A2F2E1A" }}
          >
            <Box style={{ position: "absolute", left: "15%", bottom: "250px" }}>
              <img loading="eager" src={img3} alt="" />
            </Box>
            <Box
              style={{
                paddingTop: "130px",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <Box
                style={{ color: "#3A1C67", fontSize: "20px", fontWeight: 600 }}
              >
                E-mails and SMS for Multi-channel Communication
              </Box>
              <Box
                style={{
                  color: "#848891",
                  fontSize: "14px",
                  padding: "20px 0px",
                }}
              >
                Instantaneous SMSes and E-mails help your sales-managers
                big-time to communicate task-scheduling, to-do lists and urgent
                posts to the sales-team.
              </Box>
            </Box>
          </Box>
          <Box
            className={classes.boxCotainer}
            style={{ backgroundColor: "#FE677A1A" }}
          >
            <Box style={{ position: "absolute", left: "5%", bottom: "250px" }}>
              <img loading="eager" src={img4} alt="" />
            </Box>
            <Box
              style={{
                paddingTop: "130px",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <Box
                style={{ color: "#3A1C67", fontSize: "20px", fontWeight: 600 }}
              >
                Automated Lead Allocation
              </Box>
              <Box
                style={{
                  color: "#848891",
                  fontSize: "14px",
                  padding: "20px 0px",
                }}
              >
                Automated distribution of leads to your sales-staff brings in
                smooth collaboration and better efficiency in your
                sales-processes.
              </Box>
            </Box>
          </Box>
          <Box
            className={classes.boxCotainer}
            style={{ backgroundColor: "#D0471933" }}
          >
            <Box style={{ position: "absolute", left: "10%", bottom: "250px" }}>
              <img loading="eager" src={img5} alt="" />
            </Box>
            <Box
              style={{
                paddingTop: "130px",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <Box
                style={{ color: "#3A1C67", fontSize: "20px", fontWeight: 600 }}
              >
                Complete Database Management
              </Box>
              <Box
                style={{
                  color: "#848891",
                  fontSize: "14px",
                  padding: "20px 0px",
                }}
              >
                We keep your sales-team constantly armed, even on the road, with
                the complete individual data of various customers.
              </Box>
            </Box>
          </Box>
          <Box
            className={classes.boxCotainer}
            style={{ backgroundColor: "#DD8F791A" }}
          >
            <Box style={{ position: "absolute", left: "15%", bottom: "250px" }}>
              <img loading="eager" src={img6} alt="" />
            </Box>
            <Box
              style={{
                paddingTop: "130px",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <Box
                style={{ color: "#3A1C67", fontSize: "20px", fontWeight: 600 }}
              >
                Notification of Important TasksDatabase Management
              </Box>
              <Box
                style={{
                  color: "#848891",
                  fontSize: "14px",
                  padding: "20px 0px",
                }}
              >
                You can convey important tasks to your staff and guide them on
                the go to make a lead-conversion or close a deal.
              </Box>
            </Box>
          </Box>
          <Box
            className={classes.boxCotainer}
            style={{ backgroundColor: "#0068BB1A" }}
          >
            <Box style={{ position: "absolute", left: "15%", bottom: "250px" }}>
              <img loading="eager" src={img7} alt="" />
            </Box>
            <Box
              style={{
                paddingTop: "130px",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <Box
                style={{ color: "#3A1C67", fontSize: "20px", fontWeight: 600 }}
              >
                Real-time Performance Monitoring
              </Box>
              <Box
                style={{
                  color: "#848891",
                  fontSize: "14px",
                  padding: "20px 0px",
                }}
              >
                Our unified platform helps sales-managers to make a real-time
                access to staff performance to provide the useful insights to
                them to close the deals or stop them from going awry.
              </Box>
            </Box>
          </Box>
        </div>
      </Container>
    </div>
  );
};
export default Page2_2;
