import { makeStyles } from "@material-ui/core";
import Header from "../Header";

import Footer from "../Footer";
import Page2_2 from "./Page2_2";
import Page3_2 from "./Page3_2";
import ProjectSubscription from "../../Common/Product/ProjectSubscription";

const useStyles = makeStyles((theme) => ({
   container:{
    display:'flex'
   },
   info:{
    color:'#848891',width:'60%',
    [theme.breakpoints.down('sm')]: {
      width:'100%'
    },
  }
    }))
const Screen2=()=>{
    const classes=useStyles()
    return(
      <div className="pos_crm_page">
        <Header/>
        <ProjectSubscription pageTitle='POS CRM' pageDetail="For you as a business-owner, Callpal’s POS CRM comes as a supremely efficient tool to exclude the most manual and tiresome transactional processes and turns every customer into a loyal one for you.
            " pageDetailTheme={classes.info}/>
     
        <Page3_2/>
        <Page2_2/>
        <Footer/>
      </div>
    )
}
export default Screen2;
