import axios from "axios";


export const Login = (payload) => {
  return axios.post(`${process.env.REACT_APP_API_ENDPOINT}/login`,JSON.stringify(payload), {
                
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
    
});
};