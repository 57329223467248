import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addContactUS } from "./homeCrud";


export const addContactUSDetail = createAsyncThunk(
    "home/addContactUSDetail",
    async ( payload ,{ rejectWithValue }) => {
      try {
        const res = await addContactUS(payload);
        if (res.status) {
          return res;
        } else {
          throw new Error(res.message);
        }
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );


const homeSlice = createSlice({
  name: "home",
  initialState: {
    loading: false,
  },
  extraReducers: {
    [addContactUSDetail.pending]: (state) => {
        state.loading = true;
      },
      [addContactUSDetail.fulfilled]: (state, action) => {
        state.loading = false;
      },
      [addContactUSDetail.rejected]: (state, error) => {
        state.loading = false;
      }
  },
});

export default homeSlice.reducer;
