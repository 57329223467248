import { Button, TextareaAutosize, makeStyles, CircularProgress } from "@material-ui/core";
import editIcon from '../../../Images/Edit 1.svg'
import duplicateIcon from '../../../Images/duplicate 1.svg'
import closeIcon from '../../../Images/close.svg'
import trashIcon from '../../../Images/whiteTrash.svg'
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsSubmitted } from "../../../Reducers/loginSlice";
const useStyles = makeStyles((theme) => ({
    mainContainer: {
        padding: '80px 30px 30px 30px'
    },
    heading: {
        color: '#3A1C67',
        fontSize: '18px',
        fontWeight: 700, textAlign: 'left', marginLeft: 20
    },
    button: {
        backgroundColor: '#3A1C67',
        color: '#fff',
        textTransform: 'none',
        height: '32px',
        borderRadius: '10px',
        fontSize: '14px',
    },

    editContainer: {
        width: '50%',
        backgroundColor: '#fff',
        border: '14px',
        margin: '0px 10px',
        padding: '20px',
    },
    editContent: {
        width: 'auto',
        height: '250px',
        overflowY: 'scroll',
        textAlign: 'left',
        fontSize: '12px',
        margin: '20px 10px',
        padding: 20,
        color: '#3A1C67',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;',
        '&::-webkit-scrollbar': {
            width: '0.4em',
            display: 'block'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'darkgrey',
            outline: '1px solid slategrey',
            borderRadius: '5px'
        }
    },
    cancelButton: {
        backgroundColor: '#fff',
        color: '#3A1C67',
        textTransform: 'none',
        height: '32px',
        borderRadius: '10px',
        border: '1px solid #3A1C67',
        fontSize: '14px',
    },

}));
const EditTemplate = ({ selected, setCancelled, setUpdated }) => {
    const classes = useStyles()
    const editRef = useRef()
    const [disable, setDisable] = useState(true)
    const { userInfo } = useSelector((state) => state.login)
    const [msg, setMsg] = useState('')
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        
        setMsg(selected.message)
        setDisable(true)
    }, [selected])

    useEffect(() => {
        if (!disable) {
            editRef.current.focus()
        }
    }, [disable]);

    const editTemplate = async () => {
        try {
            setLoading(true)
            const res = await fetch(`https://callpal.in/public/api/update-sms-template/${selected.id}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${userInfo.token}`
                },
                body: JSON.stringify({
                    template_title: selected.template_title,
                    message: msg
                })
            })
            const data = await res.json()
         
            if (data.message == 'template updated successfully') {
                dispatch(setIsSubmitted({ open: true, msg: 'Sms template updated successfully', severity: 'success' }))
                setUpdated(true)
            }
            setLoading(false)
        }
        catch (e) {
            console.log(e.message)
            setLoading(false)
        }
    }
    return (
        <div className={classes.editContainer}>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 20px' }}>
                <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    startIcon={<img src={duplicateIcon} alt='' />}

                >
                    Duplicate
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    startIcon={<img src={editIcon} alt='' />}
                    onClick={() => { setDisable(false); }}
                >
                    Edit
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    startIcon={<img src={trashIcon} alt='' />}

                >
                    Delete
                </Button>
            </div>
            <div className={classes.heading}>
            {selected.template_title}
            </div>
            {/* <div className={classes.editContent}>
            {selected.content}
        </div> */}
            <div style={{margin:'20px 10px'}}>
                <TextareaAutosize
                    size="small"
                    minRows={12}
                    placeholder="Template Message"
                    ref={editRef}
                    value={msg}
                    onChange={(e) => setMsg(e.target.value)}
                    className={'edit-template'} id="outlined-basic" variant="outlined"
                    disabled={disable}
                />
            </div>
            <div style={{ textAlign: 'right' }}>
                <Button
                    variant="contained"
                    color="secondary"
                    className={classes.cancelButton}
                    style={{ marginRight: 10 }}
                    onClick={editTemplate}
                    disabled={msg==''}
                >
                    {loading ? <CircularProgress size={20} /> : 'Save'}
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    className={classes.cancelButton}
                    endIcon={<img src={closeIcon} alt='' />}
                    onClick={() => setCancelled(true)}
                >
                    Cancel
                </Button>
            </div>
        </div>
    )
}
export default EditTemplate