import React, { useState, useRef } from "react";
import {
  Checkbox,
  FormControlLabel,
  makeStyles,
} from "@material-ui/core";
import { OrangeCheckbox } from "../../../Styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "80px 30px 30px 30px",
  },
  topCOntainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  heading: {
    color: "#3A1C67",
    fontSize: "18px",
    fontWeight: 700,
    textAlign: "left",
    marginLeft: 20,
  },
  whiteBox: {
    padding: 20,
    margin: 20,
    borderRadius: 10,
    backgroundColor: "#fff",
    color: "#3A1C67",
    textAlign: "left",
  },
  allocated: {
    fontSize: 14,
    fontWeight: 700,
    marginRight: 20,
  },
}));

const ManageModulePermission = () => {
  const classes = useStyles();
  // const [permission, setPermission]= useState([])
  const [modulePermission,setModulePermission] = useState({})
  const [submenuPermission,setSubmenuPermission] = useState({})
  const [actionPermission,setActionPermission] = useState({})



  var actionName = {
    'is_add' : "Add",
    'is_edit' : "Edit",
    'is_delete' : "Delete",
    'is_export' : "Export"
  }


  
  const usePermission =  [
    {
      module_id: 1,
      submenu: [],
    },
    {
      module_id: 2,
      submenu: [
        {
          submenu_id: 21,
          submenu_modul_permission:true,
            actions_permission: {
            is_add: true,
            is_edit: true,
            is_delete: true,
            is_export: false,
          },
        },
        {
          submenu_id: 22,
          submenu_modul_permission:false,
          actions_permission:{
            is_adde: false,
            is_edite: false,
            is_deletee: false,
          },
        },
      ],
    },
    {
      module_id: 3,
      actionse:{
        is_add: true,
        is_edit: false,
        is_delete: false,
      },
    },
  ];

  const permissions = {};

  usePermission.forEach(item => {
    // Check if module has modul_permission
    if (item.modul_permission !== undefined) {
      permissions[`module_${item.module_id}`] = item.modul_permission;
    }
  
    // Check if module has submenu
    if (item.submenu && item.submenu.length > 0) {
      item.submenu.forEach(subItem => {
        // Check if submenu has submenu_modul_permission
        if (subItem.submenu_modul_permission !== undefined) {
          permissions[`submenu_${subItem.submenu_id}`] = subItem.submenu_modul_permission;
        }
     
        // Check if actionMenu_ has actions_permission
        if (subItem.actions_permission) {
          Object.entries(subItem.actions_permission).forEach(([key, value]) => {
            permissions[`action_${key}`] = value;
           
          });
        }
      });
    }
  
    // Check if module has actions_permission
    if (item.actions_permission) {
      Object.entries(item.actions_permission).forEach(([key, value]) => {
        permissions[`action_${key}`] = value;
      });
    }
  });
  


  const data = [
    {
      module_id: 1,
      label: "Dashboard",
      submenu: [],
    },
    {
      module_id: 2,
      label: "User",
      submenu: [
        {
          submenu_id: 21,
          label: "Call Log",
          actions_label: {
            is_add: false,
            is_edit: false,
            is_delete: false,
            is_export: false,
          },
        },
        {
          submenu_id: 22,
          label: "Bulk Upload",
          actions_label:{
            is_add: false,
            is_edit: false,
            is_delete: false,
          },
        },
      ],
    },
    {
      module_id: 3,
      label: "Team",
      actions_label:{
        is_add: false,
        is_edit: false,
        is_delete: false,
      },
    },
  ];

  return (
    <div className={classes.mainContainer}>
       <div className={classes.topCOntainer}>
        <div className={classes.heading}>Manage Module Permission</div>
      </div>
      <div className={classes.whiteBox}>
        <div style={{ fontWeight: 700, fontSize: 16 }}>Team Lead</div>

        <div className="col-lg-6">
          <div id="treeview-checkbox-demo">
            <br />
            <ul className="listtypenone">
              {data.map((item) => (
                <li key={item.module_id} className="marginroles">
                  <div style={{ paddingTop: 10 }}>
                    <FormControlLabel
                      control={
                        <OrangeCheckbox
                        checked={permissions[`module_${item.module_id}`] || false}
                          name={`module_${item.module_id}`}
                        />
                      }
                      label={
                        <div style={{ display: "flex" }}>
                          <div className={classes.allocated}>
                            {item.label}
                          </div>
                        </div>
                      }
                    />
                  </div>


                  {!item.submenu && item.actions_label && (
                              <ul style={{ listStyleType: "none" }}>
                                <div className="row">
                                  {Object.entries(item.actions_label).map(
                                    ([key, label]) => (


                                      <div style={{}} className="col-lg-3">
                                      <FormControlLabel
                                        control={
                                          <OrangeCheckbox
                                          checked={ permissions[`action_${key}`]|| false}
                                            // name={`${key}_${item.module_id}`}
                                            name={`action_${key}`}
                                          />
                                        }
                                        label={
                                          <div style={{ display: "flex" }}>
                                            <div className={classes.allocated}>
                                              {actionName[key]}
                                              
                                            </div>
                                          </div>
                                        }
                                      />
                                    </div>
                                    )
                                  )}
                                </div>
                              </ul>
                            )}

                  {item.submenu && !item.actions_label && item.submenu.length > 0 && (
                    <ul className="listtypenone">
                      {item.submenu &&
                        item.submenu.map((subItem) => (
                          <li
                            key={subItem.submenu_id}
                            className="marginroles"
                          >
                            <div style={{ paddingTop: 10 }}>
                              <FormControlLabel
                                control={
                                  <OrangeCheckbox
                                  checked={permissions[`submenu_${subItem.submenu_id}`] || false}
                                    name={`submenu_${subItem.submenu_id}`}
                                  />
                                }
                                label={
                                  <div style={{ display: "flex" }}>
                                    <div className={classes.allocated}>
                                      {subItem.label}
                                    </div>
                                  </div>
                                }
                              />
                            </div>

                            {subItem.actions_label && (
                              <ul style={{ listStyleType: "none" }}>
                                <div className="row">
                                  {Object.entries(subItem.actions_label).map(
                                    ([key, label]) => (


                                      <div style={{}} className="col-lg-3">

                                        
                                      <FormControlLabel
                                        control={
                                          <OrangeCheckbox
                                          // permissions[`actionMenu_${key}`] 
                                          checked={permissions[`actionMenu_${key}`] || false}
                                           name={`actionMenu_${key}`}
                                          />
                                        }
                                        label={
                                          <div style={{ display: "flex" }}>
                                            <div className={classes.allocated}>
                                            {actionName[key]}
                                            </div>
                                          </div>
                                        }
                                      />
                                    </div>
                                    )
                                  )}
                                </div>
                              </ul>
                            )}
                          </li>
                        ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageModulePermission;
