const constantConfig = {
    INDUSTRY_TYPE: {
        DEBT_RECOVERY:'DEBT_RECOVERY',
        FINTECH:'FINTECH',
        INSURANCE:'INSURANCE',
        OTHER:'OTHER'
    },

    INDUSTRY_SUB_TYPE:{
        CREDIT_CARD:'CREDIT_CARD',
        LOAN:'LOAN'
    },


  };
  
  module.exports = constantConfig;