

import axios from "axios"
import { getCookie } from '../Utils/commonUtils'

export const DashboardApi = {
    getDashboardInfo: async (data) => {
      try {
           
          const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/dashboard-lead-count`, data, {
            headers: {
              'Accept': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getCookie('callpal-user').token}`
            }
          });
          return response; // Assuming your data is in response.data
        } catch (error) {
          console.error('Error in getTeamList:', error);
          return false
          //throw error; // Re-throw the error to handle it at the calling site if needed
        }
    },
    getDashboardInboundOutbound: async (data) => {
      try {
         
          const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/inbound-outbound`,
           data, {
            headers: {
              'Accept': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getCookie('callpal-user').token}`
            }
          });
          return response; // Assuming your data is in response.data
        } catch (error) {
          console.error('Error in getCallLog:', error);
          return false
          //throw error; // Re-throw the error to handle it at the calling site if needed
        }
    },
    getTopThreeEmp: async (data) => {
      try {
           
          const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/top-three-employee`, {
            headers: {
              'Accept': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getCookie('callpal-user').token}`
            }
          });
          return response; // Assuming your data is in response.data
        } catch (error) {
          console.error('Error in getTopThreeEmp:', error);
          return false
          //throw error; // Re-throw the error to handle it at the calling site if needed
        }
  },
  getGraphData: async (data) => {
    try {
         
        const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/year-wise-graph`, data, {
          headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getCookie('callpal-user').token}`
          }
        });
        return response; // Assuming your data is in response.data
      } catch (error) {
        console.error('Error in getGraphData:', error);
        return false
        //throw error; // Re-throw the error to handle it at the calling site if needed
      }
  },
  getDataAccuracy: async (data) => {
    try {
         
        const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/data-accuracy`, data, {
          headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getCookie('callpal-user').token}`
          }
        });
        return response; // Assuming your data is in response.data
      } catch (error) {
        console.error('Error in getDataAccuracy:', error);
        return false
        //throw error; // Re-throw the error to handle it at the calling site if needed
      }
  },
  getDashboardCallingTimming: async (data) => {
    try {
         
        const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/dashboard-calling-timming`, data, {
          headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getCookie('callpal-user').token}`
          }
        });
        return response; // Assuming your data is in response.data
      } catch (error) {
        console.error('Error in getDashboardCallingTimming:', error);
        return false
        //throw error; // Re-throw the error to handle it at the calling site if needed
      }
  },
  getDRRData: async (data) => {
    try {
         
        const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/dashboard-lead-count`, data, {
          headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getCookie('callpal-user').token}`
          }
        });
        return response; // Assuming your data is in response.data
      } catch (error) {
        console.error('Error in getTeamList:', error);
        return false
        //throw error; // Re-throw the error to handle it at the calling site if needed
      }
  },
  
  
}