import { makeStyles } from "@material-ui/core";
import FilterSection from "../Other-components/FilterSection";
import SortIcon from '@mui/icons-material/Sort';
import { useState } from "react";
import DownloadIcon from '@mui/icons-material/Download';
import EmailIcon from '@mui/icons-material/Email';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const useStyles = makeStyles({
    mainContainer: {
      padding: '80px 30px 30px 30px',
    },
    subContainer:{
        display:'flex',
        justifyContent:'space-between'
    },
    card:{
        height:"581px",
        width:'49%',
        height:'581px',
        background: '#FFFFFF'
},
    header:{
        display:'flex',
        justifyContent:'space-between',
        background: '#3A1C67',
        fontSize:'20px',
        fontWeight:700,
        height:'50px',
        alignItems:'center',
        color: '#FFFFFF',
        paddingLeft:'10px',
        paddingRight:'10px',
        borderRadius:'10px 10px 0px 0px'

    },
    button:{
        background: '#FA9510',
        color: '#FFFFFF',

    },
    root: {
        "& input[type=number]": {
          "-moz-appearance": "textfield",
        },
        "& input[type=number]::-webkit-outer-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
        "& input[type=number]::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
        "& label.Mui-focused": {
          color: "#F86E20",
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "#F86E20",
          },
          "&:hover fieldset": {
            borderColor: "#F86E20",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#F86E20",
          },
        },
        margin: "10px",
      },
    formContainer: {
        padding: "20px 10px",
      },
    multilineColor: {
        color: "#F86E20",
      },
      formRow: {
        display: "flex",
        justifyContent:'space-between'
      },
      mobileNumber:{
        display:'flex'
      },
      cardFlex:{
        display:"flex"
    },
    paraHeding1: {
        fontSize: "12px",
        color:'#252525',
        fontWeight: 700,
        textAlign:"start",
        marginBottom:'0px'
      },
      paraHeding: {
        fontSize: "18px",
        color:'#FA9510',
        fontWeight: 700,
        textAlign:"start",
        marginBottom:'0px'
      },
      subTitleClass:{
        fontSize: "13px",
        color:'#252525',
        fontWeight: 400,
        textAlign:'start'
      },
      card:{
        height:'581px',
        background: '#FFFFFF',
        marginTop:'10px',
        borderRadius:"10px 0px"
        },
    headerContainer:{
        display:'flex',
        justifyContent:'space-between',
        background: '#3A1C67',
        borderRadius:'10px 10px 0px 0px'
    },

    headerActions:{
        display:'flex',
        justifyContent:'space-between',
        width:"30%",
        alignItems:'center',
        color: '#FFFFFF',
        marginRight:'10px'
    },
    header:{
        width:'50%',
        display:'flex',
        justifyContent:'space-between',
        fontSize:'20px',
        fontWeight:700,
        height:'50px',
        alignItems:'center',
        color: '#FFFFFF',
        paddingLeft:'10px',
        paddingRight:'10px',
        

    },
  })


const Reports=()=>{
    const classes = useStyles();
    const [dates,setDates]=useState({
        from:'',
        to:''
      })
      const [empDetails,setEmpDetails]=useState({
        id:'',
        name:''
      });
    return(
        <div className={classes.mainContainer}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ fontWeight: 600, fontSize: '20px', color: '#F86E20' }}>
           Reports
          </div>
          <FilterSection setIsCreateLead={()=>{}} setDates={(d)=>setDates(d)} setEmpDetails={(d)=>setEmpDetails(d)} empDetails=''/>
        </div>
        <div className={classes.card}>
            <div className={classes.headerContainer}>
                <div className={classes.header}>
                            <span>Basic Reports</span>
                </div>
                <div className={classes.headerActions}>
                    <span>Filter {' '} {' '} <SortIcon /></span>
                    <span>Download {' '} {' '} <DownloadIcon /></span>
                    <span>Email {' '} {' '} <EmailIcon /></span>
                </div>
            </div>
            <div style={{textAlign:'start', marginLeft:'20px'}}>
            <FormControl>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="Login"
        name="radio-buttons-group"
      >
        <FormControlLabel value="Login" control={<Radio  sx={{color: '#FA9510','&.Mui-checked': {color: '#FA9510',}}} />} label="Login" />
        <FormControlLabel value="Sanction" control={<Radio sx={{color: '#FA9510','&.Mui-checked': {color: '#FA9510',}}}/>} label="Sanction" />
        <FormControlLabel value="Disbursement" control={<Radio sx={{color: '#FA9510','&.Mui-checked': {color: '#FA9510',}}}/>} label="Disbursement" />
        <FormControlLabel value="Reject" control={<Radio sx={{color: '#FA9510','&.Mui-checked': {color: '#FA9510',}}}/>} label="Reject" />
        <FormControlLabel value="Interested" control={<Radio sx={{color: '#FA9510','&.Mui-checked': {color: '#FA9510',}}}/>} label="Interested" />
        <FormControlLabel value="Not Interested" control={<Radio sx={{color: '#FA9510','&.Mui-checked': {color: '#FA9510',}}} />} label="Not Interested" />
        <FormControlLabel value="Followup" control={<Radio sx={{color: '#FA9510','&.Mui-checked': {color: '#FA9510',}}} />} label="Followup" />
        <FormControlLabel value="Call Not Received" control={<Radio sx={{color: '#FA9510','&.Mui-checked': {color: '#FA9510',}}}/>} label="Call Not Received" />
        <FormControlLabel value="Switch Off" control={<Radio sx={{color: '#FA9510','&.Mui-checked': {color: '#FA9510',}}} />} label="Switch Off" />
        <FormControlLabel value="Others" control={<Radio sx={{color: '#FA9510','&.Mui-checked': {color: '#FA9510',}}}/>} label="Others" />
      </RadioGroup>
    </FormControl>
            </div>
        </div>
        </div>
    )
}
export default Reports