import { Box, makeStyles } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    heading: {
      fontSize: "30px",
      color: "#3A1C67",
      fontWeight: 700,
    },
    subHeading: {
      fontSize: "18px",
      color: "#848891",
      padding: "0px 200px",
      paddingTop: "10px",
      paddingBottom: "20px",
      [theme.breakpoints.down("sm")]: {
        padding: "20px 20px",
      },
    },
  }));
const CardHeader=({pageTitle,subTitle,text1,text2})=>{
    const classes=useStyles()
    return(
        <div>
        <Box className={classes.heading}>
        {text1} {" "}
        <span style={{ color: "#F86E20" }}>{pageTitle}</span>{" "}
          {text2 && text2?.length<6? text2 :<div>{text2}</div>}
        </Box>
        <Box className={classes.subHeading}>
          {subTitle}
        </Box>
      </div>
    )
}
export default CardHeader;
