import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getDashboardData } from "./dashboardCrud";
export const getDashboardCountDetails = createAsyncThunk(
  "dashboard/getDashboardCountDetails",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await getDashboardData(payload);
      if (res.status) {
        return res;
      } else {
        throw new Error(res.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);




const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    loading: false,
    dashboardCountDetail: {login:0,sanction:0,disbursement:0,reject:0},
  },
  reducers: {
    emptyDashboard: (state, action) => {
      state.alldashboard = {};
    },
  },
  extraReducers: {
    /* 
    getting all dashboard from server 
    */
    [getDashboardCountDetails.pending]: (state) => {
       state.loading = true;
    },
    [getDashboardCountDetails.fulfilled]: (state, action) => {
      state.loading = false;
      let data={}
      action.payload?.lead?.forEach((item)=>{
        const key=Object.values(item || {})?.filter(k=>isNaN(Number(k)))[0]
        const value=Object.values(item || {})?.filter(k=>!isNaN(Number(k)))[0]
        data[key]=value
      })
     
      state.dashboardCountDetail = data;
    },
    [getDashboardCountDetails.rejected]: (state, error) => {
      state.loading = false;
    }

     
  },
});

export const { emptyDashboard } = dashboardSlice.actions;
export default dashboardSlice.reducer;
