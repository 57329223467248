import { Button, Container, makeStyles } from "@material-ui/core"
import { useNavigate } from "react-router-dom"
import { routes } from "../../constants/routes"

const useStyles = makeStyles((theme) => ({

    button: {
        backgroundColor: '#FA9510',
        textTransform: 'none',
        color: '#fff',
        marginRight: '20px',
        transition: '.4s all ease-in-out;',
        '&:hover': {
            backgroundColor: '#FA9510',
            boxShadow: '0px 10px 10px 0px grey'
        }
    },
    button2: {
        color: '#FA9510', textTransform: 'none', borderColor: '#FA9510',
        '&:hover': {

            boxShadow: '0px 10px 10px 0px grey'
        }
    }
}))
const Home4 = ({img}) => {
    const classes = useStyles()
    const navigate = useNavigate()
    return (
        <div style={{ backgroundColor: '#EFE3FA',boxShadow: '0px 7px 10px 0px #00000038', marginBottom: '20px' }}>
{img && <img loading="eager" src={img} alt="" style={{marginTop:"40px",
    position: "absolute",
    left: "50px"}}/>}
            <Container maxWidth="sm" style={{ padding: '60px 10px', backgroundColor: '#EFE3FA', marginTop: '80px' }}>
           
                <div style={{ color: '#3A1C67', fontWeight: 700, fontSize: '30px' }}>
                    Are You Ready To <br/>
                    ‘superlead’ Your Business?
                </div>
                <div style={{ marginTop: '40px' }}>
                    <Button className={classes.button} onClick={() => { navigate(routes.liveDemo,{ state: { id: 2 }}) }}>
                        Request a Live Demo
                    </Button>
                    <Button variant="outlined" className={classes.button2} onClick={() => { navigate(routes.freeTrial,{ state: { id: 3 }}) }}>
                        Get Free Trial
                    </Button>
                </div>
            </Container>

        </div>
    )
}
export default Home4