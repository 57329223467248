import {
  Button,
  Checkbox,
  FormControlLabel,
  Menu,
  MenuItem,
  makeStyles,
  withStyles,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useNavigate } from "react-router-dom";
import historyIcon from '../../../Images/history 1.svg'
import history2Icon from '../../../Images/history 2.svg'
import { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setIsSubmitted } from '../../../Reducers/loginSlice'
import axios from 'axios'
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { OrangeCheckbox } from '../../../Styles'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap'
import { KeyboardArrowDown } from '@material-ui/icons'
import ReassignHistory from './ReassignHistory'
import ReassignSelectUser from '../Other-components/ReassignSelectUser'
import Select from 'react-select';
import { ReassignApi } from '../../../Services/ReassignApi'
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: '80px 30px 30px 30px',
  },
  topCOntainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  heading: {
    color: '#3A1C67',
    fontSize: '18px',
    fontWeight: 700,
    textAlign: 'left',
    marginLeft: 20,
  },
  button: {
    backgroundColor: '#3A1C67',
    color: '#fff',
    textTransform: 'none',
    height: '34px',
    borderRadius: '10px',
    fontSize: '14px',
    marginLeft: 40,
    '&:hover': {
      backgroundColor: '#3A1C67',
    },
  },
  matchButton: {
    backgroundColor: '#3A1C67',
    color: '#fff',
    textTransform: 'none',
    height: '34px',
    borderRadius: '10px',
    fontSize: '14px',
    marginTop: 20,
    '&:hover': {
      backgroundColor: '#3A1C67',
    },
  },
  whiteBox: {
    padding: 20,
    margin: 20,
    borderRadius: 10,
    backgroundColor: '#fff',
    color: '#3A1C67',
    textAlign: 'left',
  },
  steps: {
    color: '#555555',
    fontSize: 12,
    padding: '0px 30px',
  },
  allocated: {
    fontSize: 14,
    fontWeight: 700,
    marginRight: 20,
  },
}))

const ReassignCustomer = () => {

  const [isHistory,setIsHistory]=useState(false);
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))
  const [selectedOption, setSelectedOption] = useState([]);
  const navigate = useNavigate()


  const classes = useStyles()
  const note1Ref = useRef()
  const note2Ref = useRef()
  const note3Ref = useRef()
  const note4Ref = useRef()
  const [state, setState] = useState({
    uploadType1: false,
    uploadType2: false,
    uploadType3: false,
    uploadType4: false,
  })
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorE2, setAnchorE2] = useState(null)
  const [anchorE3, setAnchorE3] = useState(null)
  const [anchorE4, setAnchorE4] = useState(null)
  const [isNext, setIsNext] = useState(true)
  const [selectedUsers, setSelectedUsers] = useState([])
  const [selectedFile, setSelectedFile] = useState('')
  const { userInfo } = useSelector((state) => state.login)
  const [loading, setLoading] = useState(false)
  const [statusList, setStatusList] = useState([]);
  const [matchCustomerCount, setMatchCustomerCount] = useState(0)
  const selectRef = useRef();


  const handleChange = (event) => {
    if(event.target.name=='uploadType3')
    {
    setState({ ...state, [event.target.name]: true,uploadType4:false })
    }
    if(event.target.name=='uploadType4'){
      setState({ ...state, [event.target.name]: true,uploadType3:false })
    }
  }

  useEffect(() => {
    getReassigneStatus();
    setAnchorEl(note1Ref.current)
    setAnchorE2(note2Ref.current)
    setAnchorE3(note3Ref.current)
    setAnchorE4(note4Ref.current)
  }, [isNext])


  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (!state.uploadType3 && !state.uploadType4) {
              dispatch(setIsSubmitted({
                open: true,
                severity: 'error',
                msg: 'Select any one assign type.'
              }));
              setLoading(false);
              return false;
      }
      if (state.uploadType4 && selectedUsers.length == 0) {
        dispatch(setIsSubmitted({
          open: true,
          severity: 'error',
          msg: 'Please select user.'
        }));
        setLoading(false);
        return false;
      }

      if (selectedOption && selectedOption == 0) {
        dispatch(setIsSubmitted({
          open: true,
          severity: 'error',
          msg: 'Please select status.'
        }));
        setLoading(false);
        return false;
      }
    
      const data = {
        user_ids:selectedUsers,
        status: selectedOption,
        fromdate: startDate,
        todate: endDate,
      };
      axios.post(`${process.env.REACT_APP_API_ENDPOINT}/reassign-lead`,data, {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          }
      }).then((res) => {
        if(res.status){
            dispatch(setIsSubmitted({ open: true, severity: 'success', msg: (res.message) }))
            if (selectRef.current) {
              selectRef.current.select.clearValue();
            }
            setMatchCustomerCount(0);
            setState({ ...state, uploadType4: false ,uploadType3: false });
           
        } else {
          dispatch(setIsSubmitted({ open: true, severity: 'error', msg: (res.message) }))
        }
        setLoading(false);
      }).catch((e)=>{
        
        if(e.hasOwnProperty('response')){
        if(!e.response.data.status){
          if(e.response.data.hasOwnProperty('message')){
            dispatch(setIsSubmitted({ open: true, severity: 'error', msg:e.response.data.message  }));
        }
        if(e.response.data.hasOwnProperty('data')){
          if(e.response.data.data.length==0){
            dispatch(setIsSubmitted({ open: true, severity: 'error', msg: 'Mobile number in this file is already taken!' }))
          }
        }
        }
        setLoading(false);
      }
      if(e.code==='ERR_NETWORK'){
        dispatch(setIsSubmitted({ open: true, severity: 'info', msg: 'Please upload file again!' }))
      }
      })
    }
    catch (e) {
      setLoading(false)
    }
  }

  // const classes = useStyles()
  // const [state, setState] = useState({
  //   checkedG: false,
  //   checkedB: false,
  // })
  
  // const [anchorEl, setAnchorEl] = useState(null)
  //

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  // const handleChange = (event) => {
  //   setState({ ...state, [event.target.name]: event.target.checked })
  // }

  const handleDateChange = (date) => {
    setStartDate(moment(date).format('YYYY-MM-DD'));
  }
  const handleDateChange2 = (date) => {
    setEndDate(moment(date).format('YYYY-MM-DD'));
  }

  const getReassigneStatus= async () => {
    try {
      setLoading(true)
      const data = await ReassignApi.getReassignApi();
      if (data.status) {
        const ReassignStatusArray = Object.entries(data.data).map(
          ([value, label]) => ({value, label })
        );
        setStatusList(ReassignStatusArray)
        // setBankList(bankListArrArray);
      }
    } catch (e) {
      console.log(e.message);
       setLoading(false)
    }
  };

  const handleMatchedCustomes = async () => {
    try {
      setLoading(true);
      if (selectedOption && selectedOption == 0) {
        dispatch(setIsSubmitted({
          open: true,
          severity: 'error',
          msg: 'Please select status.'
        }));
        setLoading(false);
        return false;
      }
      const data = JSON.stringify({
        status: selectedOption,
        fromdate: startDate,
        todate: endDate,
      });
      const res = await ReassignApi.matchCustomer(data);
      if (res.status) {
          setMatchCustomerCount(res.reassignLead);
      } else {
        setMatchCustomerCount(0);
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

   // handle onChange event of the dropdown
   const handleStatusChange = (e) => {
    setSelectedOption(Array.isArray(e) ? e.map(x => x.value) : []);
  }
const handleNavHistory = () => {
  navigate(`/dashboard/reassign-history`);

}



  
  return (
    <div className={classes.mainContainer}>
      {!isHistory&&<>
      <div className={classes.topCOntainer}>
        <div className={classes.heading}>Reassign Customers</div>
        <div>
          {/* <Button
            variant='contained'
            color='secondary'
            className={classes.button}
            startIcon={<ExpandMoreIcon />}
            onClick={handleClick}
          >
            Filter
          </Button>
          <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            style={{ marginTop: '45px' }}
          >
            <MenuItem
              style={{ color: '#3A1C67', fontSize: 14, fontWeight: 600 }}
              onClick={handleClose}
            >
              Interested
            </MenuItem>
            <MenuItem
              style={{ color: '#3A1C67', fontSize: 14, fontWeight: 600 }}
              onClick={handleClose}
            >
              Not-Interested
            </MenuItem>
          </Menu> */}
          <Button
            variant='contained'
            color='secondary'
            className={classes.button}
            startIcon={<img src={historyIcon} alt='' width={20} />}
            onClick={()=>handleNavHistory()}
            
          >
            History
          </Button>
        </div>
      </div>
      <div className={classes.whiteBox}>
        <div style={{ fontWeight: 700, fontSize: 16 }}>Choose Date Range</div>
        <div style={{ display: 'flex', gap: '210px', marginTop: '20px' }}>
          <div className=''>Start Date</div>
          <div className=''>End Date</div>
        </div>
        <div class="row">
          <div class="col-sm-3" style={{padding:"0px"}}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant='inline'
              inputVariant='outlined'
              format='MM/dd/yyyy'
              margin='normal'
              id='date-picker-inline'
              //   label="Start Date"
              autoOk // Automatically close the date picker after selection
              value={startDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              InputProps={{
                style: {
                  fontSize: 14,
                  height: 34,
                },
              }}
              style={{ marginRight: 20 }}
            />
          </MuiPickersUtilsProvider>
          </div>
          <div class="col-sm-3" style={{padding:"0px"}}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant='inline'
              inputVariant='outlined'
              format='MM/dd/yyyy'
              margin='normal'
              id='date-picker-inline'
              //   label="End Date"
              autoOk // Automatically close the date picker after selection
              value={endDate}
              onChange={handleDateChange2}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              InputProps={{
                style: {
                  fontSize: 14,
                  height: 34,
                },
              }}
              style={{ marginRight: 20 }}
            />
          </MuiPickersUtilsProvider>
              </div>
       
          <div class="col-sm-3 mt-10" style={{marginTop:"11px",padding:"0px"}}>
          <Select
            isMulti
            ref={selectRef}
            defaultValue={selectedOption}
            onChange={handleStatusChange}
            options={statusList}

          />
          {/* <Button
            variant='contained'
            color='secondary'
            className={classes.button}
            startIcon={<ExpandMoreIcon />}
            onClick={handleClick}
          >
            Filter
          </Button> */}
          {/* <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            style={{ marginTop: '45px' }}
          >
            <MenuItem
              style={{ color: '#3A1C67', fontSize: 14, fontWeight: 600 }}
              onClick={handleClose}
            >
              Interested
            </MenuItem>
            <MenuItem
              style={{ color: '#3A1C67', fontSize: 14, fontWeight: 600 }}
              onClick={handleClose}
            >
              Not-Interested
            </MenuItem>
          </Menu> */}
          </div>
       

          <div class="col-sm-3" style={{marginTop:"-8px"}}>
          <Button
            variant='contained'
            color='secondary'
            className={classes.matchButton}
            endIcon={<img src={history2Icon} alt='' width={20} />}
            onClick={handleMatchedCustomes}
            
          >
            Matched Cust.. ({matchCustomerCount}) 
          </Button>
         
        </div>
        </div>
        <div style={{ paddingTop: 10 }}>
          <FormControlLabel
            control={
              <OrangeCheckbox
              checked={state.uploadType3}
              onChange={handleChange}
              name='uploadType3'
              />
            }
            label={
              <div style={{ display: 'flex' }}>
                <div className={classes.allocated}>Assign in common pool</div>
              </div>
            }
          />
          <div className={classes.steps}>
            Allowed if user has access to web allocation permission
          </div>
        </div>
        <div style={{ paddingTop: 10 }}>
          <FormControlLabel
            control={
              <OrangeCheckbox
              checked={state.uploadType4}
              onChange={handleChange}
              name='uploadType4'
              />
            }
            label={
              <div style={{ display: 'flex' }}>
                <div className={classes.allocated}>
                  Assign to specific users
                </div>
              </div>
            }
          />
          {/* <div style={{ marginLeft: 30 }}>
            <select className='assign-dropdown' placeholder='Assign To'>
              <option>Assign To</option>
              <option>select 2 </option>
              <option>select 3</option>
            </select>
            <UncontrolledDropdown>
              <DropdownToggle
                style={{
                  background: 'white',
                  color: '#808080',
                  fontSize: '14px',
                }}
              >
                Assign To <KeyboardArrowDown></KeyboardArrowDown>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>Option 1</DropdownItem>
                <DropdownItem>Option 2</DropdownItem>
                <DropdownItem>Option 3</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div> */}
        </div>
      </div>
      <div style={{ textAlign: 'left' }}>
        <Button
        // type='submit'
          variant='contained'
          color='secondary'
          className={classes.button}
          onClick={() => { !isNext ? setIsNext(true) : handleSubmit() }}
          disabled={matchCustomerCount > 0 ? false : true}
        >
          Initiate
        </Button>
      </div>
      </>}
      {/* {isHistory && <ReassignHistory/>} */}
        <ReassignSelectUser anchorEl={state.uploadType4} setAnchorE1={(f) => setState({ ...state, uploadType4: f })}
       setSelectedUsers={(u) => setSelectedUsers([...u])} anchorE2={state.uploadType3}/>
    </div>
    
  )


}
export default ReassignCustomer
