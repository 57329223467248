import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";

import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Button, TablePagination } from "@material-ui/core";

import { useEffect } from "react";
import AddUserPopper from "../Dashboard/Other-components/AddUserPopper";
import { useState } from "react";
import { StyledTableCell, StyledTableRow } from "../../Styles";
import EditIcon from "@material-ui/icons/Edit";
import trashIcon from "../../Images/trash.svg";
import { useDispatch, useSelector } from "react-redux";
import NoRecords from "../Dashboard/Other-components/NoRecords";
import AddIcon from "@material-ui/icons/Add";
import { setIsSubmitted } from "../../Reducers/loginSlice";
import ConfirmationPopper from "../Dashboard/Other-components/ConfirmationPopper";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
  } from "@material-ui/pickers";
  import DateFnsUtils from "@date-io/date-fns";
  import moment from "moment";

import {
  getAllUsers,
  getFilteredUsers,
} from "../../Redux/features/users/userSlice";
import { getCookie } from "../../Utils/commonUtils";
import { HashLink as Link } from "react-router-hash-link";
import { TeamApi } from "../../Services/TeamApi";
import { DataAccuracyApi } from "../../Services/DataAccuracyApi";
import { useNavigate, useParams } from "react-router-dom";

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
const useStyles = makeStyles({
  mainContainer: {
    padding: "80px 30px 30px 30px",
   
  },
  table: {
    minWidth: 700,
  },

  button: {
    backgroundColor: "#3A1C67",
    color: "#fff",
    marginLeft: "10px",
    borderRadius: "5px",
    fontSize: "12px",
    padding: "5px 20px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#7916BD",
    },
  },
  tableContainer: {
    widows: "100%",
    margin: "20px 0px",
    maxHeight: 450,
    width: "100%",
    "&::-webkit-scrollbar": {
      width: "0.5em",
      display: "block",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "darkgrey",
      outline: "1px solid slategrey",
      borderRadius: "5px",
    },
  },
});

export default function TaleCallerDataAccuracy(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const param = useParams();
  const navigate = useNavigate()
  const team_lead_id = atob(param.lead_id);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [action, setAction] = useState("Add");
  const { userInfo } = useSelector((state) => state.login);
  // const { filteredUsers ,loading,totalCount} = useSelector((state) => state.users)
  const [offset, setOffset] = useState(1);
  const [updated, setUpdated] = useState(true);
  const [confirm, setConfirm] = useState(false);
  const [deletedUser, setDeletedUser] = useState("");
  const [deleteLoader, setDeleteLoader] = useState(false);
  const loginInfo = getCookie("callpal-user");
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [exportExcelLoading, setExportExcelLoading] = useState(false);

  const handleChangePage = (event, newPage) => {
    setUpdated(true);
    setPage(newPage);
    let num = 0;
    if (newPage > page) {
      num = offset + 1;
    } else {
      num = offset - 1;
    }
    setOffset(num);
  };

  const handleChangeRowsPerPage = (event) => {
    setUpdated(true);
    setRowsPerPage(event.target.value);
    setPage(0);
    setOffset(0);
  };

  const headers = [
    "User Name",
    "Role",
    "Total Data",
    "Today Data Allocation",
    "Pending data",
    "Total calls",
    "Interested",
    "Not Interested",
    "Not Connected",
    "Switch off",
    "Follow Up",
    "Total personal calls",
    "Total talk time",
    "Average talk time",
    "Break time",
    "Check in time",
    "Check out time",

  ];

  useEffect(() => {
    if (loginInfo?.userInfo?.mobile_number && updated) {
        getDataAccuracyTeamLead(startDate);
      setUpdated(false);
    }
  }, [ page, rowsPerPage, updated]);

  const getDataAccuracyTeamLead = async (searchDate) => {
    try {
    
      setLoading(true);
      const data = JSON.stringify({
        for_user:"tele_caller",
        teamlead_id: team_lead_id,
        per_page_row: rowsPerPage,
        page: offset,
        date: searchDate ,
      });
      const res = await DataAccuracyApi.DataAccuracyList(data);
      if (res.status) {
        setRows([...res.data]);
        setCount(res.total_count);
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const deleteUser = async () => {
    try {
      setUpdated(false);
      setDeleteLoader(true);
      const res = await TeamApi.deleteTeam(deletedUser);
      setDeleteLoader(false);
      setConfirm(false);
      if (res.status) {
        dispatch(
          setIsSubmitted({ open: true, severity: "success", msg: res.message }),
        );
        setUpdated(true);
      } else {
        dispatch(
          setIsSubmitted({ open: true, severity: "error", msg: res.message }),
        );
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (selectedDate) => {
    const date = moment(selectedDate).format("YYYY-MM-DD")
    setStartDate(date);
    getDataAccuracyTeamLead(date);
  };




  const handleExportExcel = async() => {
    try {
      setExportExcelLoading(true);
      const data = JSON.stringify({
        for_user:"tele_caller",
        teamlead_id: team_lead_id,
        per_page_row: count,
        page: offset,
        date: startDate ,
      });
      const res = await DataAccuracyApi.DataAccuracyList(data);
      if (res.status) {
        const formattedData = res.data.map(row => ({
          "User Name": row.full_name,
          "Role": row.role_name,
          "Total Data": row.pending_leads_counts.totalPendingLeadsCount || 0,
          "Today Data Allocation": row.pending_leads_counts.todaysPendingLeadCount || 0,
          "Pending data": row.pending_leads_counts.previousPendingLeadsCount || 0,
          "Total calls": row.leads_count.total_count || 0,
          "Interested": row.leads_count.interested || 0,
          "Not Interested": row.leads_count.not_intersted || 0,
          "Not Connected": row.leads_count.not_connected || 0,
          "Switch off": row.leads_count.switch_off || 0,
          "Follow Up": row.leads_count.follow_up || 0,
          "Total personal calls": row.leads_count.personal || 0,
          "Total talk time": row.total_talk_time || 0,
          "Average talk time": row.average_talk_time || 0,
          "Break time": row.break_time || 0,
          "Check in time": (row.checkout_talktime && row.checkout_talktime.check_in) || 0,
          "Check out time": (row.checkout_talktime && row.checkout_talktime.check_out) || 0
        }));
  
  
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(formattedData, { header: headers});
  
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
        const downloadDate = moment().format("YYYY-MM-DD");
        saveAs(dataBlob, `data-accuracy.xlsx`);
      }
    } catch (err) {
      setExportExcelLoading(false);
      console.error(err);
    } finally {
      setExportExcelLoading(false);
    }
  }

  return (
    <div className={classes.mainContainer}  >
      <div className="row"  >
        <div className="col-5" style={{ fontWeight: 600, fontSize: '20px', color: '#F86E20', textAlign: 'left' }}>
          {count > 0  ? count : "" } Data Accuracy Tale Caller
        </div>
          <div className="col-4">
      
         
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                //   label="Start Date"
                autoOk // Automatically close the date picker after selection
                value={startDate}
                // onChange={(date)=> handleDateChange(moment(date).format("YYYY-MM-DD"))}
                onChange={handleDateChange}

                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                InputProps={{
                  style: {
                    fontSize: 14,
                    height: 34,
                  },
                }}
                style={{ marginRight: 20, background: "white" , marginTop: "2px"}}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className="col-2">

          <Button className={classes.button} onClick={() => handleExportExcel()}  > 
              {exportExcelLoading ? "Please wait...." : "Export " } 
          </Button>
          </div>
          <div className="col-1">

          {loginInfo.userInfo.fk_role_id == 2 && (
          <Button className={classes.button} onClick={() => navigate(-1)}> 
               Back
          </Button>
          )}
          </div>

      </div>


      <div>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table
          stickyHeader
          className={classes.table}
          aria-label="customized table"
          size="small"
        >
          <TableHead>
            <TableRow>
              {headers.map((el, i) => {
                return (
                  <StyledTableCell key={el} align="center">
                    {el}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.length > 0 &&
              !loading &&
              rows.map((row) => (
                <StyledTableRow>
                  <StyledTableCell align="center">{row.full_name}</StyledTableCell>
                  <StyledTableCell align="center">{row.role_name  }</StyledTableCell>
                  <StyledTableCell align="center">{row.pending_leads_counts.totalPendingLeadsCount ? row.pending_leads_counts.totalPendingLeadsCount : "0"}</StyledTableCell>
                  <StyledTableCell align="center">{row.pending_leads_counts.todaysPendingLeadCount ? row.pending_leads_counts.todaysPendingLeadCount : "0"}</StyledTableCell>
                  <StyledTableCell align="center">{row.pending_leads_counts.previousPendingLeadsCount ? row.pending_leads_counts.previousPendingLeadsCount: "0"}</StyledTableCell>
                  <StyledTableCell align="center">{row.leads_count.total_count ? row.leads_count.total_count : "0"}</StyledTableCell>
                  <StyledTableCell align="center">{row.leads_count.interested ? row.leads_count.interested : "0"}</StyledTableCell>
                  <StyledTableCell align="center">{row.leads_count.not_intersted ? row.leads_count.not_intersted : "0"}</StyledTableCell>
                  <StyledTableCell align="center">{row.leads_count.not_connected ? row.leads_count.not_connected : "0"}</StyledTableCell>
                  <StyledTableCell align="center">{row.leads_count.switch_off ? row.leads_count.switch_off: "0"}</StyledTableCell>
                  <StyledTableCell align="center">{row.leads_count.follow_up ? row.leads_count.follow_up: "0"}</StyledTableCell>
                  <StyledTableCell align="center">{row.leads_count.personal ? row.leads_count.personal : "0" }</StyledTableCell>
                  <StyledTableCell align="center">{row.total_talk_time ? row.total_talk_time : "0" }</StyledTableCell>
                  <StyledTableCell align="center">{row.average_talk_time ? row.average_talk_time : "0" }</StyledTableCell>
                  <StyledTableCell align="center">{row.break_time ? row.break_time : "0" }</StyledTableCell>
                  <StyledTableCell align="center">{row.checkout_talktime.check_in ? row.checkout_talktime.check_in : "0" } </StyledTableCell>
                  <StyledTableCell align="center">{row.checkout_talktime.check_out ? row.checkout_talktime.check_out : "0" }</StyledTableCell>

                </StyledTableRow>
              ))}
            <NoRecords rows={rows} loading={loading} />
          </TableBody>
        </Table>
      </TableContainer>
      </div>
      <div>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          backIconButtonProps={{
            "aria-label": "Previous Page",
            style: {
              color: "#fff",
              backgroundColor: "#3A1C67",
              padding: 3,
              borderRadius: "5px",
              marginRight: "5px",
            },
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
            style: {
              color: "#fff",
              backgroundColor: "#3A1C67",
              padding: "3px",
              borderRadius: "5px",
            },
          }}
        />
      </div>
    </div>
  );
}
