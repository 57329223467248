import { Button, TextField, makeStyles } from "@material-ui/core";
import editIcon from "../../../Images/Edit 1.svg";
import updateIcon from "../../../Images/history 2.svg";
import CustomerStatusPopper from "./CustomerStatusPopper";
import { useEffect, useState } from "react";
import BankListPopper from "./BankListPopper";
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
} from "reactstrap";
import { KeyboardArrowDown } from "@material-ui/icons";
import { getCookie } from "../../../Utils/commonUtils";
import SearchIcon from "@material-ui/icons/Search";
import { useDispatch, useSelector } from "react-redux";
import { setIsSubmitted } from "../../../Reducers/loginSlice";
import { routes } from "../../../constants/routes";

import { CircularProgress, InputAdornment, colors } from "@material-ui/core";
import { useParams, useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";
import { CustomerHistoryApi } from "../../../Services/CustomerHistoryApi";
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "80px 30px 20px 30px",
  },
  heading: {
    color: "#3A1C67",
    fontSize: "18px",
    fontWeight: 700,
    textAlign: "left",
    marginLeft: 20,
  },
  sectionContainer: {
    display: "flex",
  },
  section1: {
    height: 500,
    backgroundColor: "#fff",
    margin: 10,
    width: "50%",
    borderRadius: 10,
  },
  sectionHeading: {
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 16,
    backgroundColor: "#3A1C67",
    height: 50,
    padding: "0px 30px",
    textAlign: "left",
    fontWeight: 700,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  historyContainer: {
    padding: 15,
    height: 450,
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4em",
      display: "block",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "darkgrey",
      outline: "1px solid slategrey",
      borderRadius: "5px",
    },
  },
  button: {
    backgroundColor: '#3A1C67',
    color: '#fff',
    marginLeft: '10px',
    borderRadius: '5px',
    fontSize: '12px',
    padding: '5px 20px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#7916BD',
    },
  },
  card: {
    width: "100%",
    paddingBottom: 10,
    backgroundColor: "#F1F1F1",
    textAlign: "left",
    fontSize: 12,
    color: "#FA9510",
    boxShadow:
      "rgba(0, 0, 0, 0.22) 0px 1px 5px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
  },
  dot: {
    height: 10,
    width: 10,
    border: "1px solid #FA9510",
    borderRadius: "50%",
  },
  indicator: {
    borderLeft: "2px solid #848891",
    height: 60,
    marginLeft: 4,
  },
  saveButton: {
    backgroundColor: "#FA9510",
    color: "#fff",
    textTransform: "none",
    width: 100,
    height: "30px",
    borderRadius: "10px",
    fontSize: "14px",
    marginLeft: 10,
    "&:hover": {
      backgroundColor: "#FA7A21",
    },
  },
  formContainer: {
    padding: 15,
    width: "100%",
    height: 450,
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4em",
      display: "block",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "darkgrey",
      outline: "1px solid slategrey",
      borderRadius: "5px",
    },
  },
  statusButton: {
    backgroundColor: "#fff",
    color: "#3A1C67",
    textTransform: "none",
    height: "35px",
    borderRadius: "5px",
    border: "1px solid #3A1C67",
    fontSize: "14px",
    marginBottom: 5,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  root: {
    "& label.Mui-focused": {
      color: "#F86E20",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#F86E20",
      },
      "&:hover fieldset": {
        borderColor: "#F86E20",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#F86E20",
      },
    },
    margin: "5px 5px",
    width: "95%",
    // [theme.breakpoints.down('xs')]: {
    //     width:'100%'
    //   },
  },
  multilineColor: {
    color: "#F86E20",
    fontSize: 14,
  },
}));
const CustomerHistory = (action) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [showList, setShowList] = useState(false);
  const [countryList, setCountryList] = useState();
  const loginInfo = getCookie("callpal-user");
  const [countrySearch, setcountrySearch] = useState("");
  const [stateList, setStateList] = useState();
  const [stateSearch, setStateSearch] = useState("");
  const [cityList, setCityList] = useState();
  const [citySearch, setCitySearch] = useState("");
  const [productList, setProductList] = useState();
  const [productSearch, setProductSearch] = useState("");
  const [sourceList, setSourceList] = useState();
  const [sourceSearch, setSourceSearch] = useState("");
  const [bankList, setBankList] = useState();
  const [bankSearch, setBankSearch] = useState();
  const [customerInfo, setCustomerInfo] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [callHistory, setCallHistory] = useState();
  const params = useParams();
  const customerID = params.customerID;
 
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobile_number: "",
      alternate_mobile_number: "",
      address: "",
      country_id: "",
      state_id: "",
      city_id: "",
      pincode: "",
      company_name: "",
      product_id: "",
      loan_requirment: "",
      existing_emi: "",
      platform_id: "",
      remarks: "",
    },
    validationSchema: Yup.object().shape({
      // name: Yup.string().required("Name is required"),
      // // .required('Email is required')
      // email: Yup.string().email("Invalid email"),
      // mobile_number: Yup.string().required("Mobile Number is required"),
      // alternate_mobile_number: Yup.string(),

      // address: Yup.string().required("Address is required"),
      // fk_country_name: Yup.string().required("Country is required"),

      // fk_state_name: Yup.string().required("State is required"),
      // fk_city_name: Yup.string().required("City is required"),
      // pincode: Yup.string().required("Pin Code is required"),
      // company_name: Yup.string().required("Company name is required"),
      // fk_product_name: Yup.string().required("Product  name is required"),
      // loan_requirment: Yup.string().required("Loan Amount is required"),
      // existing_emi: Yup.string().required("Loan EMI is required"),
      // fk_platform_name: Yup.string().required("Platform name is required"),
      //remarks: Yup.string(),
    }),

    onSubmit: async (values) => {
      const customerId = customerID; // Assuming customerID is accessible here
      // Add customerID to values object
      values.id = customerId;
      setLoading(true);
      try {
       
        const data = await CustomerHistoryApi.submitCustomerLead(values)
        if (data.status) {
          dispatch(
            setIsSubmitted({
              open: true,
              severity: "success",
              msg: data.message,
            })
          );
          navigate(-1); 
          //navigate('/dashboard'+routes.customer.replace(":id",""));
          setOpen(false);
          // setUpdated(Math.floor(Math.random() * 90000) + 10000);
          setLoading(false);
          // navigate(routes.signIn);
        } else {
          // if (data.hasOwnProperty("errors")) {
          //   formik.setFieldError("email", data.errors.email[0]);
          //   formik.setFieldError("first_name", data.errors.first_name[0]);
          //   formik.setFieldError("last_name", data.errors.last_name[0]);
          //   formik.setFieldError("mobile_number", data.errors.mobile_number[0]);
          //   formik.setFieldError("password", data.errors.password[0]);
          //   formik.setFieldError("designation_id",data.errors.designation_id[0]);
          //   formik.setFieldError("reporting_to", data.errors.reporting_to[0]);
          //   formik.setFieldError("role_id", data.errors.role_id[0]);
          // }
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        console.error(e);
      }
    },
  });
  useEffect(() => {
    getCustomerDetails();

    getCountry();
    getProductMaster();
    getSourceList();
    // getBankList();
  }, []);

  // console.log(customerID.action);

  const getCustomerDetails = async () => {
    setLoading(true);
    try {
      if (!customerID || !customerID) {
        // Handle the case where customerID or customerID.customerID is not valid
        console.error("Invalid customer ID");
        return;
      }
     
      const data =  await CustomerHistoryApi.getCustomerDetails(customerID);
      
      if (data.status) {
        
     
        setCustomerInfo(data.data);
        setCallHistory(data.data.lead_log);
       
        formik.setFieldValue("name", data.data.name ? data.data.name : "");
        formik.setFieldValue("email", data.data.email ? data.data.email : "");
        formik.setFieldValue(
          "mobile_number",
          data.data.mobile_number ? data.data.mobile_number : ""
        );
        formik.setFieldValue(
          "alternate_mobile_number",
          data.data.alternate_mobile_number
            ? data.data.alternate_mobile_number
            : ""
        );
        formik.setFieldValue(
          "address",
          data.data.address ? data.data.address : ""
        );
        formik.setFieldValue(
          "fk_country_name",
          data.data.fk_country_name ? data.data.fk_country_name : ""
        );
        formik.setFieldValue(
          "country_id",
          data.data.fk_country_id ? data.data.fk_country_id : ""
        );

        if(data.data.fk_country_id) {
          getStateList(data.data.fk_country_id);
        }

        formik.setFieldValue(
          "fk_state_name",
          data.data.fk_state_name ? data.data.fk_state_name : ""
        );
        formik.setFieldValue(
          "state_id",
          data.data.fk_state_id ? data.data.fk_state_id : ""
        );
        if(data.data.fk_state_id) {
        getCityList(data.data.fk_state_id);
        }
        formik.setFieldValue(
          "fk_city_name",
          data.data.fk_city_name ? data.data.fk_city_name : ""
        );
        formik.setFieldValue(
          "city_id",
          data.data.fk_city_id ? data.data.fk_city_id : ""
        );
        formik.setFieldValue(
          "pincode",
          data.data.pincode ? data.data.pincode : ""
        );
        formik.setFieldValue(
          "company_name",
          data.data.lead_professional_info.company_name
            ? data.data.lead_professional_info.company_name
            : ""
        );
        formik.setFieldValue(
          "product_id",
          data.data.lead_professional_info.fk_product_id
            ? data.data.lead_professional_info.fk_product_id
            : ""
        );
        formik.setFieldValue(
          "fk_product_name",
          data.data.lead_professional_info.fk_product_name
            ? data.data.lead_professional_info.fk_product_name
            : ""
        );
        formik.setFieldValue(
          "loan_requirment",
          data.data.lead_professional_info.loan_requirment
            ? data.data.lead_professional_info.loan_requirment
            : ""
        );
        formik.setFieldValue(
          "existing_emi",
          data.data.lead_professional_info.existing_emi
            ? data.data.lead_professional_info.existing_emi
            : ""
        );
        formik.setFieldValue(
          "fk_platform_name",
          data.data.lead_professional_info.fk_platform_name
            ? data.data.lead_professional_info.fk_platform_name
            : ""
        );
        formik.setFieldValue(
          "platform_id",
          data.data.lead_professional_info.fk_platform_id
            ? data.data.lead_professional_info.fk_platform_id
            : ""
        );
        formik.setFieldValue(
          "remarks",
          data.data.remarks ? data.data.remarks : ""
        );
        setLoading(false);
      }
      // setDesignLoader(false);
    } catch (e) {
      setLoading(false);
      console.log(e.message);
      // setLoading(false)
    }
  };

  const getCountry = async () => {
    try {
      // setLoading(true)
     
      const data = await CustomerHistoryApi.getgetCountryDetails();
      if (data.status) {
        const countryArrArray = Object.entries(data.data).map(
          ([id, country]) => ({ id, country })
        );
        setCountryList(countryArrArray);
      }

      formik.resetForm({ values: { state_id: "" } });

      // setDesignLoader(false);
    } catch (e) {
      console.log(e.message);
      // setLoading(false)
    }
  };

  const getStateList = async (value) => {
    try {
      // setLoading(true)
      const data = await CustomerHistoryApi.getStateListDetails(value);
      if (data.status) {
        const stateArrArray = Object.entries(data.data).map(([id, state]) => ({
          id,
          state,
        }));
        setStateList(stateArrArray);
      }
      // setDesignLoader(false);
    } catch (e) {
      console.log(e.message);
      // setLoading(false)
    }
  };

  const getCityList = async (value) => {
    try {
      // setLoading(true)
      const data = await CustomerHistoryApi.getCityListDetails(value);
      if (data.status) {
        const cityArrArray = Object.entries(data.data).map(([id, city]) => ({
          id,
          city,
        }));
        setCityList(cityArrArray);
      }
      // setDesignLoader(false);
    } catch (e) {
      console.log(e.message);
      // setLoading(false)
    }
  };

  const getProductMaster = async () => {
    try {
      // setLoading(true)
      const res = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/product-master`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${loginInfo.token}`,
          },
        }
      );
      const data =  await CustomerHistoryApi.getProductMaster();

      if (data.status) {
        const productArrArray = Object.entries(data.data).map(
          ([id, product]) => ({ id, product })
        );
        setProductList(productArrArray);
      }
      // setDesignLoader(false);
    } catch (e) {
      console.log(e.message);
      // setLoading(false)
    }
  };

  const getSourceList = async () => {
    try {
      // setLoading(true)
      const data = await CustomerHistoryApi.getSourceList()
      if (data.status) {
        const sourceArrArray = Object.entries(data.data).map(
          ([id, source]) => ({ id, source })
        );
        setSourceList(sourceArrArray);
      }
      // setDesignLoader(false);
    } catch (e) {
      console.log(e.message);
      // setLoading(false)
    }
  };

  return (
    <div>
      <div className={classes.mainContainer}>
     <div>
     <div className={classes.heading}>Customer
          <Button className={classes.button} style={{float:"right"}} onClick={() => navigate(-1)}> 
               Back
          </Button>
     </div>
          
     </div>
        <form onSubmit={formik.handleSubmit}>
          
          <div className={classes.sectionContainer} style={{clear:"both"}}>
            <div className={classes.section1}>
              <div className={classes.sectionHeading}>
                <div>Call History</div>
                {/* <div>
                <UncontrolledDropdown>
                  <DropdownToggle
                    style={{ display: "flex", background: "#FA9510" }}
                  >
                    <div>All</div>
                    <KeyboardArrowDown></KeyboardArrowDown>
                  </DropdownToggle>
                  <DropdownMenu></DropdownMenu>
                </UncontrolledDropdown>
              </div> */}
              
              </div>
              <div className={classes.historyContainer}>
                <div>
                  {callHistory &&
                  Array.isArray(callHistory) &&
                  callHistory.length > 0 ? (
                    callHistory.map((e, i) => (
                      <div
                        style={{ display: "flex", marginBottom: 10 }}
                        key={i}
                      >
                        <div
                          style={{
                            color: "#FA9510",
                            paddingRight: 30,
                            width: 120,
                          }}
                        >
                          <div>{e.date}</div>
                          <div>{e.time}</div>
                        </div>
                        <div style={{ marginRight: 15 }}>
                          <div className={classes.dot} />
                          <div className={classes.indicator} />
                        </div>
                        <div className={classes.card}>
                          <ul>
                            <li style={{ color: "#3A1C67" }}>
                              <div
                                style={{ display: "flex", color: "#FA9510" }}
                              >
                                <div>Others</div>
                                <div
                                  style={{ paddingLeft: 20, paddingRight: 25 }}
                                >
                                  {e.comment}
                                </div>
                              </div>
                            </li>
                          </ul>
                          <div style={{ display: "flex", padding: "0px 25px" }}>
                            <div style={{ color: "#3A1C67", fontWeight: 600 }}>
                              By {e.name}
                            </div>
                            <div style={{ paddingLeft: 20 }}>
                              Status: {e.status}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div style={{ textAlign: "center", color: "#888" }}>
                      No call history available
                    </div>
                  )}
                </div>

                {/* <div style={{ display: "flex", marginBottom: 10 }}>
                <div style={{ color: "#FA9510", paddingRight: 30, width: 120 }}>
                  <div>01 Sep</div>
                  <div>01:00 AM</div>
                </div>
                <div style={{ marginRight: 15 }}>
                  <div className={classes.dot} />
                  <div className={classes.indicator} />
                </div>
                <div className={classes.card}>
                  <ul>
                    <li style={{ color: "#3A1C67" }}>
                      <div style={{ display: "flex", color: "#FA9510" }}>
                        <div>Others</div>
                        <div style={{ paddingLeft: 20, paddingRight: 25 }}>
                          location - abc, 10:00 AM
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div style={{ display: "flex", padding: "0px 25px" }}>
                    <div style={{ color: "#3A1C67", fontWeight: 600 }}>
                      By Yadav
                    </div>
                    <div style={{ paddingLeft: 20 }}>Source - NA</div>
                  </div>
                </div>
              </div> */}
              </div>
            </div>

            <div className={classes.section1}>
              <div className={classes.sectionHeading}>
                <div>
                  {" "}
                  {loading ? (
                    <CircularProgress size={20} />
                  ) : (
                    "Customer Details"
                  )}
                </div>
                <div style={{ display: "flex" }}>
                  <div className="btns_add_user">
                    {/* <Button
                    className={classes.saveButton}
                    startIcon={<img src={editIcon} width="19px" alt="" />}
                  >
                    Edit
                  </Button> */}
                  </div>
                  <div className="btns_add_user">
                    <Button
                      type="submit"
                      className={classes.saveButton}
                      startIcon={<img src={updateIcon} width="19px" alt="" />}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>

              <div className={classes.formContainer}>
                {/* <Button
                fullWidth
                onClick={() => setOpen(true)}
                className={classes.statusButton}
              >
                Status - Interested
              </Button> */}
                <div style={{ display: "flex" }}>
                  <div style={{ width: " 100%" }}>
                    <TextField
                      size="small"
                      placeholder="Name of the Customer"
                      inputProps={{
                        className: classes.multilineColor,
                        style: {
                          height: "13px",
                        },
                      }}
                      className={classes.root}
                      id="name"
                      variant="outlined"
                      name="name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                    />

                    {formik.touched.name && formik.errors.name ? (
                      <p className="text-danger formik-addUser-msg text-small text-left formik-msg" style={{ textAlign: 'left', marginLeft: '8px', marginTop: '-4px' }}>
                        {formik.errors.name}
                      </p>
                    ) : null}
                  </div>

                  <div style={{ width: " 100%" }}>
                    <TextField
                      size="small"
                      placeholder="Email Address"
                      inputProps={{
                        className: classes.multilineColor,
                        style: {
                          height: "13px",
                        },
                        readOnly:true
                      }}
                      className={classes.root}
                      id="eamil"
                      variant="outlined"
                      name="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />

                    {formik.touched.email && formik.errors.email ? (
                      <p className="text-danger formik-addUser-msg text-small text-left formik-msg" style={{ textAlign: 'left', marginLeft: '8px', marginTop: '-4px' }}>
                        {formik.errors.email}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ width: " 100%" }}>
                    <TextField
                      size="small"
                      placeholder="Mobile Number*"
                      inputProps={{
                        className: classes.multilineColor,
                        style: {
                          height: "13px",
                        },
                        readOnly:true
                      }}
                      className={classes.root}
                      id=" mobile_number"
                      variant="outlined"
                      name=" mobile_number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.mobile_number}
                    />
                    {formik.touched.mobile_number &&
                    formik.errors.mobile_number ? (
                      <p className="text-danger formik-addUser-msg text-small text-left formik-msg" style={{ textAlign: 'left', marginLeft: '8px', marginTop: '-4px' }}>
                        {formik.errors.mobile_number}
                      </p>
                    ) : null}
                  </div>
                  <div style={{ width: " 100%" }}>
                    <TextField
                      size="small"
                      placeholder="Alternative Phone Number"
                      inputProps={{
                        className: classes.multilineColor,
                        style: {
                          height: "13px",
                        },
                      }}
                      className={classes.root}
                      id="alternate_mobile_number"
                      variant="outlined"
                      name="alternate_mobile_number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.alternate_mobile_number}
                    />
                    {formik.touched.alternate_mobile_number &&
                    formik.errors.alternate_mobile_number ? ( 
                      <p className="text-danger formik-addUser-msg text-small text-left formik-msg" style={{ textAlign: 'left', marginLeft: '8px', marginTop: '-4px' }}>
                        {formik.errors.alternate_mobile_number}
                      </p>
                    ) : null}
                  </div> 
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ width: " 50%" }}>
                    <TextField
                      size="small"
                      placeholder="Address"
                      inputProps={{
                        className: classes.multilineColor,
                        style: {
                          height: "13px",
                        },
                      }}
                      className={classes.root}
                      id="address"
                      variant="outlined"
                      name="address"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.address}
                    />
                    {formik.touched.address && formik.errors.address ? (
                      <p className="text-danger formik-addUser-msg text-small text-left formik-msg" style={{ textAlign: 'left', marginLeft: '8px', marginTop: '-4px' }} >
                        {formik.errors.address}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div style={{ display: "flex", marginBottom: "5px" }}>
                  {/* country  DroupDown Start*/}
                  <div style={{ width: " 100%" }}>
                    <div className={classes.formRow}>
                      <UncontrolledDropdown
                        style={{ margin: "0px 5px", width: "223px" }}
                      >
                        <DropdownToggle
                          caret
                          style={{
                            width: "215px",
                            display: "flex",
                            justifyContent: "space-between",
                            paddingTop: "10px",
                            paddingBottom: "6px",
                            background: "white",
                            color: "#F86E20",
                            border: "1px solid #F86E20",
                            alignItems: "center",
                            fontSize: "14px",
                          }}
                        >
                          {formik.values.fk_country_name
                            ? formik.values.fk_country_name
                            : "Select a country"}
                        </DropdownToggle>
                        <DropdownMenu style={{ width: "222px" }}>
                          <>
                            <DropdownItem header>
                              <TextField
                                placeholder="Search country"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <SearchIcon />
                                    </InputAdornment>
                                  ),
                                }}
                                size="small"
                                variant="outlined"
                                onChange={(e) =>
                                  setcountrySearch(e.target.value)
                                }
                              />
                            </DropdownItem>

                            <div className={classes.dropdownItem}>
                              {countryList && countryList.length > 0 ? (
                                countryList
                                  .filter((e) =>
                                    e.country
                                      .toLowerCase()
                                      .includes(countrySearch.toLowerCase())
                                  )
                                  .map((el, i) => (
                                    <DropdownItem
                                      key={el.id}
                                      align="center"
                                      style={{ overflowWrap: "break-word" }}
                                      onClick={() => {
                                        getStateList(el.id);
                                        formik.setFieldValue(
                                          "fk_country_name",
                                          el.country
                                        );
                                        formik.setFieldValue(
                                          "country_id",
                                          el.id
                                        );
                                      }}
                                    >
                                      {el.country}
                                    </DropdownItem>
                                  ))
                              ) : (
                                <DropdownItem align="center">
                                  No countries available
                                </DropdownItem>
                              )}
                            </div>
                          </>
                        </DropdownMenu>
                        <div
                          style={{
                            color: "red",
                            fontSize: 12,
                            textAlign: "left",
                          }}
                        ></div>
                      </UncontrolledDropdown>
                    </div>
                    {formik.touched.fk_country_name &&
                    formik.errors.fk_country_name ? (
                      <p className="text-danger formik-addUser-msg text-small text-left formik-msg" style={{ textAlign: 'left', marginLeft: '8px' }}>
                        {formik.errors.fk_country_name}
                      </p>
                    ) : null}
                  </div>

                  {/* State  DroupDown Start*/}
                  <div style={{ width: " 100%" }}>
                    <div className={classes.formRow}>
                      <UncontrolledDropdown
                        style={{ margin: "0px 5px 0px -3px", width: "213px" }}
                      >
                        <DropdownToggle
                          caret
                          style={{
                            width: "213px",
                            display: "flex",
                            justifyContent: "space-between",
                            paddingTop: "10px",
                            paddingBottom: "6px",
                            background: "white",
                            color: "#F86E20",
                            border: "1px solid #F86E20",
                            alignItems: "center",
                            fontSize: "14px",
                          }}
                        >
                          {formik.values.fk_state_name
                            ? formik.values.fk_state_name
                            : "Select a state"}
                        </DropdownToggle>
                        <DropdownMenu style={{ width: "222px" }}>
                          <>
                            <DropdownItem header>
                              <TextField
                                placeholder="Search state"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <SearchIcon />
                                    </InputAdornment>
                                  ),
                                }}
                                size="small"
                                variant="outlined"
                                onChange={(e) => setStateSearch(e.target.value)}
                              />
                            </DropdownItem>

                            <div className={classes.dropdownItem}>
                              {stateList && stateList.length > 0 ? (
                                stateList
                                  .filter((e) =>
                                    e.state
                                      .toLowerCase()
                                      .includes(stateSearch.toLowerCase())
                                  )
                                  .map((el, i) => (
                                    <DropdownItem
                                      key={el.id}
                                      align="center"
                                      style={{ overflowWrap: "break-word" }}
                                      onClick={() => {
                                        getCityList(el.id);
                                        formik.setFieldValue(
                                          "fk_state_name",
                                          el.state
                                        );
                                        formik.setFieldValue("state_id", el.id);
                                      }}
                                    >
                                      {el.state}
                                    </DropdownItem>
                                  ))
                              ) : (
                                <DropdownItem align="center">
                                  No State available
                                </DropdownItem>
                              )}
                            </div>
                          </>
                        </DropdownMenu>
                        <div
                          style={{
                            color: "red",
                            fontSize: 12,
                            textAlign: "left",
                          }}
                        ></div>
                      </UncontrolledDropdown>
                    </div>
                    {formik.touched.fk_state_name &&
                    formik.errors.fk_state_name ? (
                      <p className="text-danger formik-addUser-msg text-small text-left formik-msg" style={{ textAlign: 'left', marginLeft: '8px' }}>
                        {formik.errors.fk_state_name}
                      </p>
                    ) : null}
                  </div>

                  {/* State  DroupDown End*/}
                </div>

                <div style={{ display: "flex" }}>
                  <div style={{ width: " 100%" }}>
                    <div className={classes.formRow}>
                      <UncontrolledDropdown
                        style={{
                          margin: "0px 5px",
                          width: "213px",
                          marginRight: "8px",
                        }}
                      >
                        <DropdownToggle
                          caret
                          style={{
                            width: "215px",
                            display: "flex",
                            justifyContent: "space-between",
                            paddingTop: "10px",
                            paddingBottom: "6px",
                            background: "white",
                            color: "#F86E20",
                            border: "1px solid #F86E20",
                            alignItems: "center",
                            fontSize: "14px",
                          }}
                        >
                          {formik.values.fk_city_name
                            ? formik.values.fk_city_name
                            : "Select a city"}
                        </DropdownToggle>
                        <DropdownMenu style={{ width: "222px" }}>
                          <>
                            <DropdownItem header>
                              <TextField
                                placeholder="Search city"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <SearchIcon />
                                    </InputAdornment>
                                  ),
                                }}
                                size="small"
                                variant="outlined"
                                onChange={(e) => setCitySearch(e.target.value)}
                              />
                            </DropdownItem>

                            <div className={classes.dropdownItem}>
                              {cityList && cityList.length > 0 ? (
                                cityList
                                  .filter((e) =>
                                    e.city
                                      .toLowerCase()
                                      .includes(citySearch.toLowerCase())
                                  )
                                  .map((el, i) => (
                                    <DropdownItem
                                      key={el.id}
                                      align="center"
                                      style={{ overflowWrap: "break-word" }}
                                      onClick={() => {
                                        formik.setFieldValue(
                                          "fk_city_name",
                                          el.city
                                        );
                                        formik.setFieldValue("city_id", el.id);
                                      }}
                                    >
                                      {el.city}
                                    </DropdownItem>
                                  ))
                              ) : (
                                <DropdownItem align="center">
                                  No city available
                                </DropdownItem>
                              )}
                            </div>
                          </>
                        </DropdownMenu>
                        <div
                          style={{
                            color: "red",
                            fontSize: 12,
                            textAlign: "left",
                          }}
                        ></div>
                      </UncontrolledDropdown>
                    </div>
                    {formik.touched.fk_city_name &&
                    formik.errors.fk_city_name ? (
                      <p className="text-danger formik-addUser-msg text-small text-left formik-msg" style={{ textAlign: 'left', marginLeft: '8px' }}>
                        {formik.errors.fk_city_name}
                      </p>
                    ) : null}
                  </div>
                  <div style={{ width: " 100%" }}>
                    <TextField
                      size="small"
                      placeholder="Pin Code"
                      inputProps={{
                        className: classes.multilineColor,
                        style: {
                          height: "13px",
                          // paddingLeft:"4px"
                          marginRight: " 0px",
                        },
                      }}
                      className={classes.root}
                      id="pincode"
                      variant="outlined"
                      name="pincode"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.pincode}
                    />
                    {formik.touched.pincode &&
                    formik.errors.pincode ? (
                      <p className="text-danger formik-addUser-msg text-small text-left formik-msg" style={{ textAlign: 'left', marginLeft: '8px', marginTop: '-4px' }}>
                        {formik.errors.pincode}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ width: " 100%" }}>
                    <TextField
                      size="small"
                      placeholder="Company Name"
                      inputProps={{
                        className: classes.multilineColor,
                        style: {
                          height: "13px",
                        },
                      }}
                      className={classes.root}
                      id="company_name"
                      variant="outlined"
                      name="company_name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.company_name}
                    />
                    {formik.touched.company_name &&
                    formik.errors.company_name ? (
                      <p className="text-danger formik-addUser-msg text-small text-left formik-msg" style={{ textAlign: 'left', marginLeft: '8px', marginTop: '-4px' }}>
                        {formik.errors.company_name}
                      </p>
                    ) : null}
                  </div>

                  <div style={{ width: " 100%" }}>
                    <div className={classes.formRow}>
                      <UncontrolledDropdown
                        style={{ margin: "0px 5px", width: "213px" }}
                      >
                        <DropdownToggle
                          caret
                          style={{
                            width: "213px",
                            display: "flex",
                            justifyContent: "space-between",
                            paddingTop: "10px",
                            paddingBottom: "6px",
                            background: "white",
                            color: "#F86E20",
                            border: "1px solid #F86E20",
                            alignItems: "center",
                            fontSize: "14px",
                          }}
                        >
                          {formik.values.fk_product_name
                            ? formik.values.fk_product_name
                            : "Select product name"}
                        </DropdownToggle>
                        <DropdownMenu style={{ width: "222px" }}>
                          <>
                            <DropdownItem header>
                              <TextField
                                placeholder="Search Product"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <SearchIcon />
                                    </InputAdornment>
                                  ),
                                }}
                                size="small"
                                variant="outlined"
                                onChange={(e) =>
                                  setProductSearch(e.target.value)
                                }
                              />
                            </DropdownItem>

                            <div className={classes.dropdownItem}>
                              {productList && productList.length > 0 ? (
                                productList
                                  .filter((e) =>
                                    e.product
                                      .toLowerCase()
                                      .includes(productSearch.toLowerCase())
                                  )
                                  .map((el, i) => (
                                    <DropdownItem
                                      key={el.id}
                                      align="center"
                                      style={{ overflowWrap: "break-word" }}
                                      onClick={() => {
                                        getCityList(el.id);
                                        formik.setFieldValue(
                                          "fk_product_name",
                                          el.product
                                        );
                                        formik.setFieldValue(
                                          "product_id",
                                          el.id
                                        );
                                      }}
                                    >
                                      {el.product}
                                    </DropdownItem>
                                  ))
                              ) : (
                                <DropdownItem align="center">
                                  No Product available
                                </DropdownItem>
                              )}
                            </div>
                          </>
                        </DropdownMenu>
                        <div
                          style={{
                            color: "red",
                            fontSize: 12,
                            textAlign: "left",
                          }}
                        ></div>
                      </UncontrolledDropdown>
                    </div>
                    {formik.touched.fk_product_name &&
                    formik.errors.fk_product_name ? (
                      <p className="text-danger formik-addUser-msg text-small text-left formik-msg" style={{ textAlign: 'left', marginLeft: '8px' }}>
                        {formik.errors.fk_product_name}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ width: " 100%" }}>
                    <TextField
                      size="small"
                      placeholder="Loan Requirement Amount"
                      inputProps={{
                        className: classes.multilineColor,
                        style: {
                          height: "13px",
                        },
                      }}
                      className={classes.root}
                      id="loan_requirment"
                      variant="outlined"
                      name="loan_requirment"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.loan_requirment}
                    />{" "}
                    {formik.touched.loan_requirment &&
                    formik.errors.loan_requirment ? (
                      <p className="text-danger formik-addUser-msg text-small text-left formik-msg" style={{ textAlign: 'left', marginLeft: '8px'}}>
                        {formik.errors.loan_requirment}
                      </p>
                    ) : null}
                  </div>
                  <div style={{ width: " 100%" }}>
                    <TextField
                      size="small"
                      placeholder="Existing EMI Amount"
                      inputProps={{
                        className: classes.multilineColor,
                        style: {
                          height: "13px",
                        },
                      }}
                      className={classes.root}
                      id="existing_emi"
                      variant="outlined"
                      name="existing_emi"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.existing_emi}
                    />

                    {formik.touched.existing_emi &&
                    formik.errors.existing_emi ? (
                      <p className="text-danger formik-addUser-msg text-small text-left formik-msg" style={{ textAlign: 'left', marginLeft: '8px', marginTop: '-4px' }}>
                        {formik.errors.existing_emi}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ width: " 100%" }}>
                    <div className={classes.formRow}>
                      <UncontrolledDropdown
                        style={{ margin: "0px 5px", width: "215px" }}
                      >
                        <DropdownToggle
                          caret
                          style={{
                            width: "215px",
                            display: "flex",
                            justifyContent: "space-between",
                            paddingTop: "10px",
                            paddingBottom: "6px",
                            background: "white",
                            color: "#F86E20",
                            border: "1px solid #F86E20",
                            alignItems: "center",
                            fontSize: "14px",
                          }}
                        >
                          {formik.values.fk_platform_name
                            ? formik.values.fk_platform_name
                            : "Select a platform name"}
                        </DropdownToggle>
                        <DropdownMenu style={{ width: "222px" }}>
                          <>
                            <DropdownItem header>
                              <TextField
                                placeholder="Search platform"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <SearchIcon />
                                    </InputAdornment>
                                  ),
                                }}
                                size="small"
                                variant="outlined"
                                onChange={(e) =>
                                  setSourceSearch(e.target.value)
                                }
                              />
                            </DropdownItem>

                            <div className={classes.dropdownItem}>
                              {sourceList && sourceList.length > 0 ? (
                                sourceList
                                  .filter((e) =>
                                    e.source
                                      .toLowerCase()
                                      .includes(sourceSearch.toLowerCase())
                                  )
                                  .map((el, i) => (
                                    <DropdownItem
                                      key={el.id}
                                      align="center"
                                      style={{ overflowWrap: "break-word" }}
                                      onClick={() => {
                                        getStateList(el.id);
                                        formik.setFieldValue(
                                          "fk_platform_name",
                                          el.source
                                        );
                                        formik.setFieldValue(
                                          "platform_id",
                                          el.id
                                        );
                                      }}
                                    >
                                      {el.source}
                                    </DropdownItem>
                                  ))
                              ) : (
                                <DropdownItem align="center">
                                  No countries available
                                </DropdownItem>
                              )}
                            </div>
                          </>
                        </DropdownMenu>
                        <div
                          style={{
                            color: "red",
                            fontSize: 12,
                            textAlign: "left",
                          }}
                        ></div>
                      </UncontrolledDropdown>
                    </div>

                    {formik.touched.fk_platform_name &&
                    formik.errors.fk_platform_name ? (
                      <p className="text-danger formik-addUser-msg text-small text-left formik-msg" style={{ textAlign: 'left', marginLeft: '8px' }}>
                        {formik.errors.fk_platform_name}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div>
                  <textarea
                    rows={3}
                    style={{ width: "100%" }}
                    id="remarks"
                    placeholder="Notes"
                    className="customer-notes"
                    name="remarks"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.remarks}
                  />

                  {formik.touched.remarks && formik.errors.remarks ? (
                    <p className="text-danger formik-addUser-msg text-small text-left formik-msg" style={{ textAlign: 'left', marginLeft: '8px', marginTop: '-4px' }}>
                      {formik.errors.remarks}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </form>

        <CustomerStatusPopper open={open} setOpen={(f) => setOpen(f)} />
        <BankListPopper
          showList={showList}
          setShowList={(f) => setShowList(f)}
        />
      </div>
    </div>
  );
};
export default CustomerHistory;
