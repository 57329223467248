import { Box, Button, Divider, makeStyles, MenuItem } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Doughnut, Line } from "react-chartjs-2";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import {
  Chart,
  Tooltip,
  Title,
  ArcElement,
  Legend,
  scales,
  CategoryScale,
  registerables,
} from "chart.js";
import Calldash from "./table";
import TeamStatus from "./TeamStatus";
import DRR from "./DRR";
import "./newDashboard.css"
import Rating from "@material-ui/lab/Rating";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { DateRange, KeyboardArrowDown } from "@material-ui/icons";
import DatePicker from "react-date-picker";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  UncontrolledButtonDropdown,
} from "reactstrap";
import moment from "moment";
import { Skeleton } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { getCookie } from "../../Utils/commonUtils";
import { DashboardApi } from "../../Services/DashboardApi";
import { CustomerHistoryApi } from "../../Services/CustomerHistoryApi";
import { CircularProgress, InputAdornment, TextField } from "@material-ui/core";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchIcon from "@material-ui/icons/Search";
import { CommonApi } from "../../Services/CommonApi";
import FinanceDashboard from "./FinanceDashboard";
import SalesDashboard from "./SalesDashboard";
import constantConfig from "../../constants/constantConfig";
Chart.register(Tooltip, Title, ArcElement, Legend, ChartDataLabels);
Chart.register(...registerables);
Chart.register(CategoryScale);

const useStyles = makeStyles((theme, props) => ({
  mainContainer: {
    padding: "80px 20px 20px 20px",
  },
  container: {
    display: "flex",
    padding: "10px",
    justifyContent: "space-between",
    borderRadius: "12px",
    textAlign: "right"
  },
  button: {
    textTransform: "none",
    backgroundColor: "#F1F1F1",
    width: "130px",
    color: "#3A1C67",
    fontSize: "12px",
    fontFamily: "open-sans",
    borderRadius: "7px",
    margin: "0px 10px",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  table: {
    marginTop: "30px",
    borderCollapse: "collapse",
    width: "100%",
    color: "#3A1C67",
    borderRadius: "30px",
  },
  cell: {
    fontSize: "12px",
    border: "1px solid #dddddd",
    padding: "8px",
  },

  boxContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  box: {
    textAlign: "center",
    padding: "15px",
    height: "80px",
    margin: "20px 0px",
    borderRadius: "10px",
    color: "#3A1C67",
    fontWeight: 700,
    width: "230px",
    backgroundColor: "#fff",
  },
  chartContainer: {
    width: "100%",
    marginBottom: "2em",
  },
  dateInputComp: {
    display: "flex",
    background: "white",
    alignItems: "center",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "6px",
    paddingBottom: "6px",
    gap: "10px",
  },
  greyBox: {
    paddingTop: "10px",
    backgroundColor: "#fff",
    borderRadius: "10px",
    height: "auto",
    width: "480px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  chartBox: {
    //height:'260px',
    padding: " 0px 0px",
    padding: "20px",
    backgroundColor: "#fff",
    // marginRight: "20px",
    borderRadius: "10px",
    height: "100%",
  },
  ratingBox: {
    height: 190,
    width: 200,
    textAlign: "center",
    padding: "10px",
    backgroundColor: "#979CBA33",
    borderRadius: "10px",
    color: "#3A1C67",
    boxShadow: "0px 15px 10px -15px #111",
    margin: "10px 10px",
  },
  label: {
    fontSize: "14px",
    backgroundColor: "#fff",
    padding: 2,
    fontWeight: 700,
    margin: "10px 70px",
    border: "1px solid #3A1C67",
    borderRadius: 5,
  },
  statusBox: {
    width: 140,
    backgroundColor: "#fff",
    borderRadius: 10,
    color: "#3A1C67",
    fontWeight: 600,
    padding: 4,
    marginRight: 30,
    cursor: "pointer",
    border:"1px solid #cbcdce"
  },
}));

const SuperAdmin = () => {
  const [selected, setSelected] = useState(true);
  const [viewMore, setViewMore] = useState(false);
  const classes = useStyles({ selected });
  const [open, setOpen] = useState(false);
  const { userInfo } = useSelector((state) => state.login);
  const ind_type = userInfo?.userInfo?.ind_type;
  const industryType = constantConfig.INDUSTRY_TYPE.OTHER;
  const loginInfo = getCookie("callpal-user");
  const loginIndSubType = loginInfo.userInfo.ind_sub_type
  const indSubType = constantConfig.INDUSTRY_SUB_TYPE.CREDIT_CARD;
// -------------------------------------------------------------------


  const [activeDashboard, setActiveDashboard]=useState("sales");


  // ---------------------------------------------------------------

  const [statusDetails, setStatusDetails] = useState({
    id: "",
    name: "",
  });
  const [statusArr, setStatusArr] = useState([]);
  const [productLoader, setProductLoader] = useState(false);
  const [statusSearch, setStatusSearch] = useState("");
  const [productId, setProductId] = useState();

  const [top3, setTop3] = useState([]);
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [dates, setDates] = useState({
    from: moment().format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD"),
  });
  const currentYear = new Date().getFullYear();
  const numberOfYears = 5; // Adjust this value as needed

  const years = Array.from(
    { length: numberOfYears },
    (_, index) => currentYear - index
  );

  // State to manage the selected value
  const [selectedFromYear, setSelectedFromYear] = useState(
    currentYear.toString()
  );
  const [selectedToYear, setSelectedToYear] = useState(currentYear.toString());

  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: [
      {
        label: "Monthly Sales",
        data: [],
        borderColor: "rgba(75,192,192,1)",
        fill: false,
        borderWidth: 2,
        lineTension: 0.4,
        pointRadius: 0,
      },
    ],
  });
  const [inboundGraph, setInboundGraph] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
      },
    ],
  });
  const [outboundGraph, setOutboundGraph] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
      },
    ],
  });

  const [dataAccuracy, setDataAccuracy] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
      },
    ],
  });

  const [filter, setFilter] = useState({
    today: true,
    last_7: false,
    last_30: false,
    range: false,
  });
  let login = 0;
  let rejected = 0;
  let disburse = 0;
  let sanction = 0;
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState({
    datasets: [
      {
        data: [10, 20, 30, 40],
        backgroundColor: ["#3098FF", "#FFCA5F", "#B5EAD7", "#FF9AA2"],
        borderWidth: 5,
      },
    ],
    labels: ["Login", "Sanction", "Disbursement", "Rejected Files"],
  });

  const [boundData, setBoundData] = useState({
    datasets: [
      {
        data: [10, 20, 30],
        backgroundColor: ["#3098FF", "#B5EAD7", "#FF9AA2"],

        borderWidth: 5,
      },
    ],
    labels: ["Answered", "Incoming Personal", "Missed"],
  });

  const options = {
    plugins: {
      datalabels: {
        color: "#3A1C67",
        formatter: function (value, context) {
          return value + "%";
        },
      },

      //   tooltip: {
      //     titleFont: {
      //       size: 20
      //     },
      //     bodyFont: {
      //       size: 20
      //     },
      //  },
      legend: {
        display: true,
        responsive: true,
        maintainAspectRatio: true,
        position: "right",

        labels: {
          boxWidth: 15,
          //padding: 20,
          font: {
            size: 14,
          },
        },
        align: "center",
      },
    },
  };

  const boundOptions = {
    plugins: {
      datalabels: {
        // formatter: (value, context) => {
        //   const dataset = context.chart.data.datasets[context.datasetIndex];
        //   const total = dataset.data.reduce(
        //     (acc, currentValue) => acc + currentValue,
        //     0
        //   );
        //   const percentage =
        //     total !== 0 ? ((value / total) * 100).toFixed(2) + "%" : "0%";
        //   return value + "%";
        // },
        display:false,
        color: "white",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.label || "";
            const percentage = "%";
            return ` ${label} : ${context.formattedValue} ${percentage}`;
          },
        },
      },
      legend: {
        // display: true,
        // responsive: true,
        // maintainAspectRatio: true,
        position: "right",

        labels: {
          position: "right",
          // boxWidth: 15,
          // padding: 20,
          // font: {
          //   size: 14,
          // },
        },
        // align: "center",
      },
    },
  };


  useEffect(() => {
    getProductData();
    
  }, [userInfo, dates]);

  const setDateFilter = async (flag) => {
    if (flag == "1") {
      setFilter({
        ...filter,
        today: true,
        last_7: false,
        last_30: false,
        range: false,
      });
      setDates({
        ...dates,
        from: moment().format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
      });
    } else if (flag == "7") {
      setFilter({
        ...filter,
        last_7: true,
        today: false,
        last_30: false,
        range: false,
      });
      setDates({
        ...dates,
        from: moment().subtract(7, "days").format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
      });
    } else if (flag == "30") {
      setFilter({
        ...filter,
        last_30: true,
        today: false,
        last_7: false,
        range: false,
      });
      setDates({
        ...dates,
        from: moment().subtract(30, "days").format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
      });
    } else {
      setFilter({
        ...filter,
        last_30: false,
        today: false,
        last_7: false,
        range: true,
      });
      setDates({ ...dates, from: startDate, to: endDate });
    }
  };

  const formatValue = (value) => {
    // Customize this function to format the value as needed
    if (value >= 1e7) {
      return (value / 1e7).toFixed(2) + " CR";
    } else if (value >= 1e5) {
      return (value / 1e5).toFixed(2) + " LPA";
    } else {
      return value.toFixed(2) + " N";
    }
  };


  const getProductData = async () => {
    try {
      setProductLoader(true);
      const res = await CustomerHistoryApi.getProductMaster();
      if (res.status) {
        const statusArray = Object.entries(res.data).map(([id, status]) => ({
          id,
          status,
        }));
        setStatusArr(statusArray);
      }
      setProductLoader(false);
    } catch (err) {
      setProductLoader(false);
    } finally {
      setProductLoader(false);
    }
  };



 
  

  
  return (
    <div className={classes.mainContainer}>
      <div
        className="filter_boxes"
      >
        {/* <div
          className="company_name"
          style={{ fontWeight: 600, fontSize: "20px" }}
        >
          {companyName}
          {classes.marginRight}
        </div> */}
      <div className="parentDiv">
      <div
        className={classes.statusBox}
        onClick={() => setActiveDashboard("sales")}
        style={{
          background: activeDashboard === "sales" ? "#fff" : "#f3f5f7",
        }}
      >
        <div style={{ fontSize: 14, paddingTop: 5 }}>Sales</div>
      </div>
        {ind_type !== industryType &&  
            <div
            className={classes.statusBox}
            onClick={() => setActiveDashboard("finance")}
            style={{
              background: activeDashboard === "finance" ? "#fff" : "#f3f5f7",
            }}
          >
            <div style={{ fontSize: 14, paddingTop: 5 }}>Finance</div>
          </div>
        
          }
     
    </div>

        <div className={classes.container}>
        {activeDashboard === "finance" && loginIndSubType !== indSubType &&
          <div style={{ marginRight: "20px" }}>
            <UncontrolledButtonDropdown>
              <DropdownToggle
                style={{
                  display: "flex",
                  gap: "10px",
                  background: "white",
                  color: "#3A1C67",
                  border: "none",
                  borderRadius: "5px",
                  width: 200,
                  justifyContent: "space-between",
                }}
              >
                {statusDetails.name.length == 0
                  ? "Select Product"
                  : statusDetails.name}
                <ArrowDropDownIcon></ArrowDropDownIcon>
              </DropdownToggle>
              <DropdownMenu>
                {statusArr.length > 0 && !productLoader && (
                  <>
                    <DropdownItem header>
                      <TextField
                        placeholder="Search Product"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        size="small"
                        variant="outlined"
                        onChange={(e) => setStatusSearch(e.target.value)}
                      />
                    </DropdownItem>
                    <div style={{ maxHeight: 150, overflowY: "scroll" }}>
                      <DropdownItem
                        onClick={() => {
                          setStatusDetails({ id: "", name: "All Product" });
                        }}
                      >
                        All Product
                      </DropdownItem>
                      {statusArr.length > 0 &&
                        statusArr
                          .filter((e) =>
                            e.status
                              .toString()
                              .toLowerCase()
                              .includes(statusSearch.toLowerCase())
                          )
                          .map((el, i) => (
                            <DropdownItem
                              key={el.id}
                              onClick={() => {
                                setStatusDetails({
                                  id: el.id,
                                  name: el.status,
                                });
                              }}
                            >
                              {el.status}
                            </DropdownItem>
                          ))}
                    </div>
                  </>
                )}
                {productLoader && (
                  <div style={{ textAlign: "center" }}>
                    <CircularProgress size={20} />
                  </div>
                )}
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>   }
         

          <div style={{ marginRight: "20px" }}>
            <UncontrolledButtonDropdown>
              <DropdownToggle
                style={{
                  display: "flex",
                  fontSize: "12px",
                  gap: "10px",
                  background: "white",
                  color: "#3A1C67",
                  border: "none",
                  borderRadius: "5px",
                  width: 200,
                  justifyContent: "space-between",
                }}
              >
                Select Days
                <ArrowDropDownIcon></ArrowDropDownIcon>
              </DropdownToggle>
              <DropdownMenu>
                <>
                  <DropdownItem header>
                    {/* <TextField
                      placeholder="Search Product"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      size="small"
                      variant="outlined"
                      onChange={(e) => setStatusSearch(e.target.value)}
                    /> */}
                  </DropdownItem>
                  <div style={{ maxHeight: 150, overflowY: "scroll" }}>
                    <DropdownItem>
                      <div>
                        <Button
                          style={{ backgroundColor: filter.today && "#fff" }}
                          className={classes.button}
                          onClick={() => setDateFilter("1")}
                        >
                          Today's
                        </Button>
                      </div>
                    </DropdownItem>

                    <DropdownItem>
                      <div>
                        <Button
                          style={{ backgroundColor: filter.last_7 && "#fff" }}
                          className={classes.button}
                          onClick={() => setDateFilter("7")}
                        >
                          Last 7 days
                        </Button>
                      </div>
                    </DropdownItem>

                    <DropdownItem>
                      <div>
                        <Button
                          style={{ backgroundColor: filter.last_30 && "#fff" }}
                          className={classes.button}
                          onClick={() => setDateFilter("30")}
                        >
                          Last 30 days
                        </Button>
                      </div>
                    </DropdownItem>
                  </div>
                </>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>

          {/* <div>
            <Button
              style={{ backgroundColor: filter.today && "#fff" }}
              className={classes.button}
              onClick={() => setDateFilter("1")}
            >
              Today's
            </Button>
          </div>
          <div>
            <Button
              style={{ backgroundColor: filter.last_7 && "#fff" }}
              className={classes.button}
              onClick={() => setDateFilter("7")}
            >
              Last 7 days
            </Button>
          </div>
          <div>
            <Button
              style={{ backgroundColor: filter.last_30 && "#fff" }}
              className={classes.button}
              onClick={() => setDateFilter("30")}
            >
              Last 30 days
            </Button>
          </div> */}

          <div>
            <Dropdown
              toggle={() => {
                setOpen(!open);
              }}
              onClick={() => {
                setOpen(true);
              }}
              isOpen={open}
            >
              <DropdownToggle
                style={{
                  //background: filter.range ? "#fff" : "#F1F1F1",
                  background: "#fff",
                  color: "#3A1C67",
                  fontSize: "12px",
                  padding: "6px 10px",
                  border: "none",
                }}
              >
                Select Range
                <KeyboardArrowDown></KeyboardArrowDown>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <div className={classes.dateInputComp}>
                    <div>
                      <DateRange
                        className="dateIcon"
                        color="#3A1C67"
                      ></DateRange>
                    </div>
                    <div style={{ display: "flex", gap: "5px" }}>
                      {" "}
                      <DatePicker
                        style={{
                          display: "none",
                          color: "#3A1C67",
                          cursor: "pointer",
                        }}
                        value={startDate}
                        onChange={(val) => {
                          setOpen(true);
                          const d = moment(val).format("YYYY-MM-DD");
                          setStartDate(d);
                        }}
                        calendarIcon={null}
                        clearIcon={null}
                        className="datePickerInput"
                      ></DatePicker>
                      -
                      <DatePicker
                        calendarIcon={null}
                        clearIcon={null}
                        style={{ display: "none", color: "#3A1C67" }}
                        value={endDate}
                        onChange={(val) => {
                          const d = moment(val).format("YYYY-MM-DD");
                          setEndDate(d);
                          setOpen(true);
                        }}
                        className="datePickerInput"
                        minDate={new Date(startDate)}
                      ></DatePicker>
                    </div>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <Button
                      style={{
                        backgroundColor: "#848891",
                        color: "#fff",
                        textTransform: "none",
                      }}
                      onClick={() => {
                        setDateFilter("range");
                        setOpen(false);
                      }}
                    >
                      Apply
                    </Button>
                  </div>
                </DropdownItem>
                <DropdownItem></DropdownItem>
              </DropdownMenu>
            </Dropdown>
            {/* <Button className={classes.button} endIcon={<ArrowDropDownIcon />}>
              Select Range
            </Button> */}
          </div>
        </div>
      </div>


      { activeDashboard === "sales" ? <SalesDashboard filterData={filter} filterDates={dates}/> : <FinanceDashboard filterData={filter} filterDates={dates} FinanceStatusDetails={statusDetails}/> }



     

    </div>
  );
};
export default SuperAdmin;
