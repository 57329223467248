import {
  Button,
  CircularProgress,
  Fade,
  FormControlLabel,
  IconButton,
  Paper,
  Popper,
  makeStyles,
} from "@material-ui/core";
import { useState, useRef, useEffect } from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
// -----------------------------------------------------

import computerImg from "../../Images/computer 1.png";
import { setIsSubmitted } from "../../Reducers/loginSlice";
import nextImg from "../../Images/next1.svg";
import cancelImg from "../../Images/close.svg";
import polygon from "../../Images/Polygon 7.svg";
import downloadImg from "../../Images/download 1.svg";
import submitImg from "../../Images/submit 1.svg";
import greenPoly from "../../Images/green-poly.svg";
import tickImg from "../../Images/tick.svg";
import history2Icon from "../../Images/history 2.svg";

import { OrangeCheckbox } from "../../Styles";

import FileUpload from "../../Components/Dashboard/Other-components/FileUpload";
import BulkDataSelectUser from "../../Components/Dashboard/Other-components/BulkDataSelectUser";
import { getCookie } from "../../Utils/commonUtils";
import { CommonApi } from "../../Services/CommonApi";
import { ReassignApi } from "../../Services/ReassignApi";

//------------------------------------------------------

// import computerImg from "../../../Images/computer 1.png";
// import nextImg from "../../../Images/next1.svg";
// import cancelImg from "../../../Images/close.svg";
// import polygon from "../../../Images/Polygon 7.svg";
// import downloadImg from "../../../Images/download 1.svg";
// import submitImg from "../../../Images/submit 1.svg";
// import greenPoly from "../../../Images/green-poly.svg";
// import tickImg from "../../../Images/tick.svg";
// import { OrangeCheckbox } from "../../../Styles";
// import FileUpload from "../Other-components/FileUpload";
// import BulkDataSelectUser from "../Other-components/BulkDataSelectUser";
// import { setIsSubmitted } from "../../../Reducers/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import BulkUploadCallAllocation from "./BulkUploadCallAllocation";
import DeleteCallAllocation from "./DeleteCallAllocation";
import moment from "moment";
import Select from "react-select";
import ConfirmationPopper from "../Dashboard/Other-components/ConfirmationPopper";
import { BulkUploadApi } from "../../Services/BulkUploadApi";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 70,
    display: "flex",
    textAlign: "left",
    height: "100%",
  },
  section1: {
    width: "70%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: "30px",
    },
  },
  section2: {
    width: "30%",
    backgroundColor: "#3A1C6733",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    height: "100%",
    //alignItems: 'center',
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  buttonSection: {
    display: "flex",
    justifyContent: "center",
  },

  root: {
    "& label.Mui-focused": {
      color: "#3A1C67",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#3A1C67",
      },
      "&:hover fieldset": {
        borderColor: "#3A1C67",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3A1C67",
      },
    },
    margin: "10px 0px",
    width: "370px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  multilineColor: {
    color: "#3A1C67",
  },
  computerImg: {
    width: "200px",
    height: "auto",
  },
  button1: {
    backgroundColor: "#3A1C67",
    color: "#fff",
    textTransform: "none",
    marginTop: "20px",
    height: "38px",
    borderRadius: "10px",
    fontSize: "14px",
    margin: 20,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    transition: ".4s all ease-in-out",
    "&:hover": {
      backgroundColor: "#3A1C67",
      boxShadow: "-2px 7px 8px #575f6b80",
    },
  },
  button2: {
    backgroundColor: "#fff",
    color: "#3A1C67",
    textTransform: "none",
    marginTop: "20px",
    height: "38px",
    borderRadius: "10px",
    border: "1px solid #3A1C67",
    fontSize: "14px",
    margin: 20,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    transition: ".4s all ease-in-out",
    "&:hover": {
      backgroundColor: "#fff",
      boxShadow: "-2px 7px 8px #575f6b80",
    },
  },
  iconButton: {
    //position: 'absolute',
    //right: '90%',
    marginLeft: -25,
    height: 50,
    width: 50,
    backgroundColor: "#3A1C67",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#3A1C67",
    },
  },
  iconButton2: {
    //position: 'absolute',
    //right: '90%',
    marginLeft: -25,
    height: 50,
    width: 50,
    backgroundColor: "#969696",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#3A1C67",
    },
  },
  allocated: {
    fontSize: 16,
    color: "#3A1C67",
    fontWeight: 700,
    marginRight: 20,
  },
  steps: {
    color: "#848891",
    fontSize: 14,
    padding: "7px 0px",
  },
  cardHeading: {
    color: "#3A1C67",
    fontSize: "14px",
    fontWeight: 700,
    paddingBottom: 5,
  },
  cardContent: {
    color: "#3A1C67",
    fontSize: "12px",
    padding: "5px 0px",
  },
  tooltip: {
    marginLeft: "29px",
    width: "215px",
    padding: 20,
    backgroundColor: "#F9F4F0",
    boxShadow: "5px 10px 18px #888888",
  },
  tooltipInactive: {
    marginLeft: "29px",
    width: "215px",
    padding: 20,
    backgroundColor: "transparent",
    boxShadow: "5px 10px 18px #888888",
  },
  statusBox: {
    width: 140,
    backgroundColor: "#fff",
    borderRadius: 10,
    color: "#3A1C67",
    fontWeight: 600,
    padding: 4,
    marginRight: 30,
    cursor: "pointer",
    border: "1px solid #cbcdce",
  },
}));

const CallAllocation = () => {
  const classes = useStyles();
  
  const dispatch = useDispatch();
  const [isNext, setIsNext] = useState(true);
  const { userInfo } = useSelector((state) => state.login);
  const [loading, setLoading] = useState(false);
  const [deletedUser, setDeletedUser] = useState("");
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [confirm, setConfirm] = useState(false);
  // -----------------------------
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [selectedOption, setSelectedOption] = useState([]);
  const selectRef = useRef();
  const [userList, setUsersList] = useState([]);
  const loginInfo = getCookie("callpal-user");

  useEffect(() => {
    getReportingData();
  }, []);

  const handleDateChange = (date) => {
    setStartDate(moment(date).format("YYYY-MM-DD"));
    // Automatically update end date if it's less than the start date
    if (endDate && date > endDate) {
      setEndDate(date);
    }
  };
  const handleDateChange2 = (date) => {
    setEndDate(moment(date).format("YYYY-MM-DD"));
  };

  // handle onChange event of the dropdown
  const handleStatusChange = (e) => {
    setSelectedOption(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

    const handleSubmit = async () => {
      if (selectedOption && selectedOption == 0 ) {
        dispatch(
          setIsSubmitted({
            open: true,
            severity: "error",
            msg: "Please select atleast one user.",
          })
        );
        setLoading(false);
        return false;
      } else if (startDate > endDate){
        dispatch(
          setIsSubmitted({
            open: true,
            severity: "error",
            msg: "End date must be greater than start date",
          })
        );
        setLoading(false);
       
      }else{
        setConfirm(true);
      }
    };


  const deleteUser = async () => {
    try {
      // setUpdated(false);
      setDeleteLoader(true);
      const data = {
        user_ids: selectedOption,
        fromdate: startDate,
        todate: endDate,
      };
      const res = await BulkUploadApi.DeleteLeads(data);
      setDeleteLoader(false);
      setConfirm(false);
      if (res.status) {
        setSelectedOption(null);
        dispatch(
          setIsSubmitted({ open: true, severity: "success", msg: res.message })
        );

        if (selectRef.current) {
          selectRef.current.select.clearValue();
        }

        // const interval = setInterval(() => {
        //   window.location.reload(); // Reload the page
        // }, 2000); // Reload every 60 seconds (adjust as needed)
    
        // setUpdated(true);
      } else {
        dispatch(
          setIsSubmitted({ open: true, severity: "error", msg: res.message })
        );
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const getReportingData = async () => {
    // setReportLoader(true);
    const data = await CommonApi.getCompanyTeamLead(loginInfo, true);
    // setReportLoader(false);
    if (!data) {
      // setReportArr([]);
    } else {
      const reportingArray = Object.entries(data.data).map(
        ([value, label]) => ({ value, label })
      );
      setUsersList(reportingArray);
    }
  };

  

  return (
    <>
      <div style={{ textAlign: "left", marginTop: 30, marginLeft: 40 }}>
        <div style={{ color: "#3A1C67", fontWeight: 700, fontSize: 17 }}>
          Select Users
        </div>

        <ConfirmationPopper
        loading={deleteLoader}
        open={confirm}
        setOpen={(f) => setConfirm(f)}
        deleteUser={() => {
          deleteUser();
        }}
      />

        <div class="col-sm-11">
          <Select
            isMulti
            ref={selectRef}
            defaultValue={selectedOption}
            onChange={handleStatusChange}
            options={userList}
          />
        </div>

        <div style={{ display: "flex", marginTop: "20px" }}>
          <div
            class="col-sm-6"
            style={{
              padding: "0px",
              color: "#3A1C67",
              fontWeight: 700,
              fontSize: 17,
            }}
          >
            Start Date
          </div>
          <div
            class="col-sm-6"
            style={{
              padding: "0px",
              color: "#3A1C67",
              fontWeight: 700,
              fontSize: 17,
            }}
          >
            End Date
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                //   label="Start Date"
                autoOk // Automatically close the date picker after selection
                value={startDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                InputProps={{
                  style: {
                    fontSize: 14,
                    height: 34,
                  },
                }}
                style={{ marginRight: 20, background: "white" }}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div class="col-sm-6" style={{ padding: "0px" }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                //   label="End Date"
                autoOk // Automatically close the date picker after selection
                value={endDate}
                onChange={handleDateChange2}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                InputProps={{
                  style: {
                    fontSize: 14,
                    height: 34,
                  },
                }}
                minDate={new Date(startDate)}
                style={{ marginRight: 20, background: "white" }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>
      </div>

      {
        <div className={classes.buttonSection}>
          {/* <Button
              variant='contained'
              color='secondary'
              className={classes.button2}
              endIcon={!isNext ? <img src={cancelImg} alt='' /> : null}
              startIcon={isNext ? <img src={prevImg} alt='' /> : null}
              onClick={() => {
                !isNext ? setIsNext(false) : setIsNext(false)
              }}
            >
              {!isNext ? 'Cancel' : 'Previous'}
            </Button> */}
          <Button
            variant="contained"
            color="secondary"
            className={classes.button1}
            endIcon={
              isNext ? (
                <img src={submitImg} alt="" />
              ) : (
                <img src={nextImg} alt="" />
              )
            }
            // onClick={() => {
            //   !isNext ? setIsNext(true) : handleSubmit();
            // }}
            onClick={() => {
              !isNext ? setIsNext(true) : handleSubmit();
            }}
          >
            {!isNext ? (
              "Next"
            ) : loading ? (
              <CircularProgress size="20px" />
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      }

      <ConfirmationPopper
        loading={deleteLoader}
        open={confirm}
        setOpen={(f) => setConfirm(f)}
        deleteUser={() => {
          deleteUser();
        }}
      />
    </>
  );
};
export default CallAllocation;
