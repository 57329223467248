export const routes = {
  home: "/",
  postCrm: "/pos-crm",
  callManagement: "/call-management",
  enquiries: "/enquiries",
  fieldCrm: "/field-crm",
  terms: "/terms&conditions",
  privacyPolicy: "/privacy-policy",
  partners: "/partners",
  blogs: "/blogs",
  blogs1: "/blogs1",
  blogs2: "/blogs2",
  pricing: "/pricing",
  contactUs: "/contact-us",
  liveDemo: "/live-demo",
  freeTrial: "/free-trial",
  usagePolicy: "/usage-policy",
  signUp: "/sign-up",
  signIn: "/sign-in",
  dashboard: "/dashboard/*",
  dashboard1: "/dashboard",
  team: "/team",
  teamdetail: "/team/team-detail/:id",
  teamView: "/team-view",
  teamViewDetail: "/team-view/team-view-detail",
  bulkDataUpload: "/bulk-data-upload",
  newBulkDataUpload: "/new-bulk-data-upload",
  dataAllocation: "/data-allocation/:id?",
  dataAllocationTeamLead: "/data-allocation-teamlead-list",
  smsTemplate: "/sms-template",
  whatsappTemplate: "/whatsapp-template",
  callLogs: "/call-logs/:id?",
  callLogsTeamLead: "/call-logs-teamlead-list",
  callAllocation: "/call-allocation",
 
  calendar: "/calendar",
  followUps: "/follow-ups/:id?",
  followUpsTeamLead: "/follow-ups-teamlead-list",
  reports: "/reports",
  teamLiveStatus: "/team-live-status/:id?",
  teamLiveStatusTeamLead: "/team-live-status-teamlead-list",
  reassignData: "/reassign-data",
  leadTransfer: "/lead-transfer",
  autoDial: "/auto-dial-configuration",
  masterData: "/master-data",
  subscription: "/subscription-details",
  companyDetails: "/company-details",
  customerHistory: "/customer-history",
  customer: "/customer/:id?",
  customerTeamLead: "/customer-teamlead-list",
  help: "/help",
  settings: "/settings",
  carousel: "/carousel",
  bank: "/bank",
  test: "/test",
  reassignHistory: "reassign-history",
  addSubscriptionMember: "add-subscription-member",
  accessModule: "/access-module",
  manageModulePermission: "/manage-module-permission",
  ddrList: "/ddr-list",
  drrCalendar: "/drr-calendar",
  drr: "/drr",
  drrTeleCaller: "/drr/tele_caller/:temlead_id",
  dataAccuracyTeamLead: "/data-accuracy-team-lead",
  dataAccuracyTeleCaller: "/data-accuracy-team-lead/data-accuracy-tele-caller/:lead_id"



};
