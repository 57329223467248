import {
  Button,
  CircularProgress,
  Fade,
  FormControlLabel,
  IconButton,
  Paper,
  Popper,
  makeStyles,
} from "@material-ui/core";
import { useState, useRef, useEffect } from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
// -----------------------------------------------------

import computerImg from "../../Images/computer 1.png";
import { setIsSubmitted } from "../../Reducers/loginSlice";
import nextImg from "../../Images/next1.svg";
import cancelImg from "../../Images/close.svg";
import polygon from "../../Images/Polygon 7.svg";
import downloadImg from "../../Images/download 1.svg";
import submitImg from "../../Images/submit 1.svg";
import greenPoly from "../../Images/green-poly.svg";
import tickImg from "../../Images/tick.svg";
import history2Icon from "../../Images/history 2.svg";

import { OrangeCheckbox } from "../../Styles";

import BulkDataSelectUser from "../../Components/Dashboard/Other-components/BulkDataSelectUser";
import { getCookie } from "../../Utils/commonUtils";

import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import BulkUploadCallAllocation from "./BulkUploadCallAllocation";
import DeleteCallAllocation from "./DeleteCallAllocation";
import moment from "moment";
import Select from "react-select";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 70,
    display: "flex",
    textAlign: "left",
    height: "100%",
  },
  section1: {
    width: "70%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: "30px",
    },
  },
  section2: {
    width: "30%",
    backgroundColor: "#3A1C6733",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    height: "100%",
    //alignItems: 'center',
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  buttonSection: {
    display: "flex",
    justifyContent: "center",
  },

  root: {
    "& label.Mui-focused": {
      color: "#3A1C67",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#3A1C67",
      },
      "&:hover fieldset": {
        borderColor: "#3A1C67",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3A1C67",
      },
    },
    margin: "10px 0px",
    width: "370px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  multilineColor: {
    color: "#3A1C67",
  },
  computerImg: {
    width: "200px",
    height: "auto",
  },
  button1: {
    backgroundColor: "#3A1C67",
    color: "#fff",
    textTransform: "none",
    marginTop: "20px",
    height: "38px",
    borderRadius: "10px",
    fontSize: "14px",
    margin: 20,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    transition: ".4s all ease-in-out",
    "&:hover": {
      backgroundColor: "#3A1C67",
      boxShadow: "-2px 7px 8px #575f6b80",
    },
  },
  button2: {
    backgroundColor: "#fff",
    color: "#3A1C67",
    textTransform: "none",
    marginTop: "20px",
    height: "38px",
    borderRadius: "10px",
    border: "1px solid #3A1C67",
    fontSize: "14px",
    margin: 20,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    transition: ".4s all ease-in-out",
    "&:hover": {
      backgroundColor: "#fff",
      boxShadow: "-2px 7px 8px #575f6b80",
    },
  },
  iconButton: {
    //position: 'absolute',
    //right: '90%',
    marginLeft: -25,
    height: 50,
    width: 50,
    backgroundColor: "#3A1C67",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#3A1C67",
    },
  },
  iconButton2: {
    //position: 'absolute',
    //right: '90%',
    marginLeft: -25,
    height: 50,
    width: 50,
    backgroundColor: "#969696",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#3A1C67",
    },
  },
  allocated: {
    fontSize: 16,
    color: "#3A1C67",
    fontWeight: 700,
    marginRight: 20,
  },
  steps: {
    color: "#848891",
    fontSize: 14,
    padding: "7px 0px",
  },
  cardHeading: {
    color: "#3A1C67",
    fontSize: "14px",
    fontWeight: 700,
    paddingBottom: 5,
  },
  cardContent: {
    color: "#3A1C67",
    fontSize: "12px",
    padding: "5px 0px",
  },
  tooltip: {
    marginLeft: "29px",
    width: "215px",
    padding: 20,
    backgroundColor: "#F9F4F0",
    boxShadow: "5px 10px 18px #888888",
  },
  tooltipInactive: {
    marginLeft: "29px",
    width: "215px",
    padding: 20,
    backgroundColor: "transparent",
    boxShadow: "5px 10px 18px #888888",
  },
  statusBox: {
    width: 140,
    backgroundColor: "#fff",
    borderRadius: 10,
    color: "#3A1C67",
    fontWeight: 600,
    padding: 4,
    marginRight: 30,
    cursor: "pointer",
    border: "1px solid #cbcdce",
  },
}));

const CallAllocation = () => {
  const classes = useStyles();
  const note1Ref = useRef();
  const note2Ref = useRef();
  const note3Ref = useRef();
  const note4Ref = useRef();
  const [state, setState] = useState({
    uploadType1: false,
    uploadType2: false,
    uploadType3: false,
    uploadType4: false,
  });
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const [anchorE3, setAnchorE3] = useState(null);
  const [anchorE4, setAnchorE4] = useState(null);
  const [isNext, setIsNext] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedFile, setSelectedFile] = useState("");
  const { userInfo } = useSelector((state) => state.login);
  const [loading, setLoading] = useState(false);
  const [deletedUser, setDeletedUser] = useState("");
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [confirm, setConfirm] = useState(false);
  // -----------------------------
  const [activeTab, setActiveTab] = useState("upload");
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [selectedOption, setSelectedOption] = useState([]);
  const selectRef = useRef();
  const [matchCustomerCount, setMatchCustomerCount] = useState(0);
  const [statusList, setStatusList] = useState([]);
  const loginInfo = getCookie("callpal-user");
  const [reportArr, setReportArr] = useState([]);

  useEffect(() => {
    setAnchorEl(note1Ref.current);
    setAnchorE2(note2Ref.current);
    setAnchorE3(note3Ref.current);
    setAnchorE4(note4Ref.current);
  }, [isNext]);

  const handleDateChange = (date) => {
    setStartDate(moment(date).format("YYYY-MM-DD"));
  };
  const handleDateChange2 = (date) => {
    setEndDate(moment(date).format("YYYY-MM-DD"));
  };

  // handle onChange event of the dropdown
  const handleStatusChange = (e) => {
    setSelectedOption(Array.isArray(e) ? e.map((x) => x.value) : []);
  };




  
 
  const activeCallAllocationTab =(action)=>{
    setActiveTab(action)
  }

  return (
    <div className={classes.container}>
      <div className={classes.section1}>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item" onClick={()=> activeCallAllocationTab("upload")}>
          <a className={`nav-link ${activeTab === "upload" ? "active" : ""}`} id="home-tab" data-toggle="tab"  role="tab" aria-controls="home" aria-selected="true">UPLOAD</a>
        </li>
        <li className="nav-item" onClick={()=> activeCallAllocationTab("delete")}>
          <a className={`nav-link ${activeTab === "delete"  ? "active" : ""}`} id="profile-tab" data-toggle="tab"  role="tab" aria-controls="profile" aria-selected="false">DELETE</a>
        </li>
      </ul>
     
        <div className={classes.buttonSection}></div>
        <div style={{ textAlign: "center" }}>
          <img src={computerImg} alt="" className={classes.computerImg} />
          <div
            style={{
              color: "#3A1C67",
              fontWeight: 700,
              textAlign: "center",
              fontSize: 17,
            }}
          >

            {activeTab === "upload" ? "Configure Upload" : "Delete Allocation" }
            
          </div>
        </div>
       
        {activeTab === "upload" ? <BulkUploadCallAllocation/> : <DeleteCallAllocation/>}
      </div>

     

      <div className={classes.section2}>
        {!isNext && (
          <div>
            <div>
              <Popper
                open={true}
                style={{ position: "relative" }}
                anchorEl={anchorEl}
                placement={"right"}
                transition
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <>
                      <img
                        src={polygon}
                        width={"20px"}
                        style={{
                          position: "absolute",
                          top: "40%",
                          marginLeft: "10px",
                          textAlign: "center",
                        }}
                      />

                      <Paper className={classes.tooltip}>
                        <div className={classes.cardHeading}>
                          Configure Upload
                        </div>
                        <div>
                          <div className={classes.cardContent}>
                            1. Select the process in which you want to upload
                            data
                          </div>
                          <div className={classes.cardContent}>
                            2. Select the type upload
                          </div>
                        </div>
                      </Paper>
                    </>
                  </Fade>
                )}
              </Popper>
            </div>
            <IconButton
              ref={note1Ref}
              aria-label="1"
              className={classes.iconButton}
            >
              1
            </IconButton>
          </div>
        )}
        {!isNext && (
          <div>
            <Popper
              open={true}
              anchorEl={anchorE2}
              placement={"right"}
              transition
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <>
                    {/* <img
                      src={polygon}
                      width={'20px'}
                      style={{
                        position: 'absolute',
                        top: '40%',
                        marginLeft: '10px',
                        textAlign: 'center',
                      }}
                    /> */}
                    <Paper className={classes.tooltipInactive}>
                      <div
                        style={{ color: "#969696" }}
                        className={classes.cardHeading}
                      >
                        Upload Allocation Data
                      </div>
                      {/* <div> */}
                      {/* <div className={classes.cardContent}>
                          1. Select the process in which you want to upload data
                        </div>
                        <div className={classes.cardContent}>
                          2. Select the type upload
                        </div> */}
                      {/* </div> */}
                    </Paper>
                  </>
                </Fade>
              )}
            </Popper>
            <IconButton
              ref={note2Ref}
              aria-label="2"
              className={classes.iconButton2}
            >
              2
            </IconButton>
          </div>
        )}
        {isNext && (
          <div>
            <div>
              <Popper
                open={true}
                style={{ position: "relative" }}
                anchorEl={anchorE3}
                placement={"right"}
                transition
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <>
                      <img
                        src={greenPoly}
                        width={"20px"}
                        style={{
                          position: "absolute",
                          top: "32%",
                          marginLeft: "10px",
                          textAlign: "center",
                        }}
                      />

                      <Paper className={classes.tooltip}>
                        <div style={{ color: "#138A00", fontWeight: 700 }}>
                          Configured
                        </div>
                      </Paper>
                    </>
                  </Fade>
                )}
              </Popper>
            </div>
            <IconButton
              ref={note3Ref}
              aria-label="3"
              style={{ backgroundColor: "#138A00" }}
              className={classes.iconButton}
            >
              <img src={tickImg} alt="" />
            </IconButton>
          </div>
        )}
        {isNext && (
          <div>
            <Popper
              open={true}
              anchorEl={anchorE4}
              placement={"right"}
              transition
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <>
                    <img
                      src={polygon}
                      width={"20px"}
                      style={{
                        position: "absolute",
                        top: "43%",
                        marginLeft: "10px",
                        textAlign: "center",
                      }}
                    />

              {activeTab === "upload" ? 
                    <Paper className={classes.tooltip}>
                      <div className={classes.cardHeading}>
                        Upload Allocations{" "}
                      </div>
                      <div>
                        <div className={classes.cardContent}>
                          1. Download The Template
                        </div>
                        <div className={classes.cardContent}>
                          2. Only “mobile Numbers”
                        </div>
                        <div style={{ fontSize: 10, color: "#848891" }}>
                          “Assign to (mobile No.)” Columns are mandatory.
                        </div>
                        <div className={classes.cardContent}>
                          3. The maximum upload limit is 5000 records.
                        </div>
                        <div className={classes.cardContent}>
                          4. Save and Upload the excel template.
                        </div>
                      </div>
                    </Paper>
                :  <Paper className={classes.tooltip}>
                <div className={classes.cardHeading}>
                Delete Allocations
                </div>
                <div>
                  <div className={classes.cardContent}>
                    1. Select delete allocations by. 
                  </div>
                  <div className={classes.cardContent}>
                    2. If deleting by uploading customer, upload the excel.
                  </div>
                  {/* <div style={{ fontSize: 10, color: "#848891" }}>
                    “Assign to (mobile No.)” Columns are mandatory.
                  </div> */}
                  <div className={classes.cardContent}>
                    3. If deleting from team members, select the team.
                  </div>
                  <div className={classes.cardContent}>
                    4. Acknowledge the count and proceed to delete.
                  </div>
                </div>
              </Paper>
            }
                  </>
                </Fade>
              )}
            </Popper>
            <IconButton
              ref={note4Ref}
              aria-label="4"
              className={classes.iconButton}
            ></IconButton>
          </div>
        )}
      </div>
      {/* <BulkDataSelectUser
        anchorEl={state.uploadType4}
        setAnchorE1={(f) => setState({ ...state, uploadType4: f })}
        setSelectedUsers={(u) => setSelectedUsers([...u])}
        anchorE2={state.uploadType3}
      /> */}
    </div>
  );
};
export default CallAllocation;
