import {
  Box,
  Button,
  LinearProgress,
  MenuItem,
  TextField,
  makeStyles,
} from "@material-ui/core";
import editIcon from "../../../Images/Edit 1.svg";
import updateIcon from "../../../Images/history 2.svg";
import { useEffect, useState } from "react";
import idle_icon from "../../../Images/Idle 1.svg";
import on_Call from "../../../Images/on call 1.svg";
import no_login from "../../../Images/check in 1.svg";
import check_in from "../../../Images/check in 1 (1).svg";
import check_out from "../../../Images/check out 1.svg";
import ReportsFilter from "../Other-components/ReportsFilter";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { KeyboardArrowDown } from "@material-ui/icons";
import speaker from "../../../Images/speaker.svg";
import mute from "../../../Images/mute 1.svg";
import refresh from "../../../Images/refresh 1.svg";
import { TeamLiveStatusApi } from "../../../Services/TeamLiveStatusApi";
import moment from "moment";
import NoRecords from "../Other-components/NoRecords";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { getCookie } from "../../../Utils/commonUtils";
import { useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
const firebaseConfig = require('../../../constants/firebaseConfig');


if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app(); // if already initialized, use that one
}

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "80px 30px 20px 30px",
  },
  heading: {
    color: "#3A1C67",
    fontSize: "18px",
    fontWeight: 700,
    textAlign: "left",
    marginLeft: 20,
  },
  sectionContainer: {
    display: "flex",
  },
  section1: {
    height: 470,
    backgroundColor: "#fff",
    margin: 10,
    width: "50%",
    borderRadius: 10,
  },
  sectionHeading: {
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 16,
    backgroundColor: "#3A1C67",
    height: 50,
    padding: "0px 30px",
    textAlign: "left",
    fontWeight: 700,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  historyContainer: {
    padding: 15,
    height: 450,
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4em",
      display: "block",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "darkgrey",
      outline: "1px solid slategrey",
      borderRadius: "5px",
    },
  },
  card: {
    width: "100%",
    paddingBottom: 10,
    backgroundColor: "#F1F1F1",
    textAlign: "left",
    fontSize: 12,
    color: "#FA9510",
    boxShadow:
      "rgba(0, 0, 0, 0.22) 0px 1px 5px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
  },
  dot: {
    height: 10,
    width: 10,
    border: "1px solid #FA9510",
    borderRadius: "50%",
  },
  indicator: {
    borderLeft: "2px solid #848891",
    height: 60,
    marginLeft: 4,
  },
  saveButton: {
    backgroundColor: "#FA9510",
    border: "1px solid #FA9510",
    color: "#fff",
    textTransform: "none",
    width: 100,
    height: "30px",
    borderRadius: "10px",
    fontSize: "14px",
    marginLeft: 10,
    "&:hover": {
      backgroundColor: "#FA7A21",
    },
  },
  formContainer: {
    padding: 25,
    width: "100%",
    height: 380,
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4em",
      display: "block",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "darkgrey",
      outline: "1px solid slategrey",
      borderRadius: "5px",
    },
  },
  statusButton: {
    backgroundColor: "#fff",
    color: "#3A1C67",
    textTransform: "none",
    height: "35px",
    borderRadius: "5px",
    border: "1px solid #3A1C67",
    fontSize: "14px",
    marginBottom: 5,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  root: {
    "& label.Mui-focused": {
      color: "#F86E20",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#F86E20",
      },
      "&:hover fieldset": {
        borderColor: "#F86E20",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#F86E20",
      },
    },
    margin: "5px 5px",
    width: "48%",
    // [theme.breakpoints.down('xs')]: {
    //     width:'100%'
    //   },
  },
  multilineColor: {
    color: "#F86E20",
    fontSize: 14,
  },
  progressContainer: {
    width: "230px",
    margin: "0px 40px",
    height: 43,
  },
  progress: {
    color: "#fff",
    textAlign: "left",
    padding: 10,
    height: 43,
    borderRadius: 12,
  },
  tableContainer: {
    height: 700,
    overflowY: "auto",
  },
  dropdownBG: {
    backgroundColor: "#F1F1F1",
    border: "none",
    color: "#3A1C67",
    borderRadius: "15px",
    paddingLeft: "20px",
    paddingRight: "20px",
    textAlign: "left",
    width: 200,
    height: "40px",
    fontSize: "14px",
    marginRight: 15,
    "&:hover": {
      backgroundColor: "#F1F1F1",
      borderRadius: "15px",
      border: "none",
      color: "#3A1C67",
      boxShadow: "box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
    },
  },
}));
const TeamLiveStatus = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [idle, setIdle] = useState(100);
  const [onCall, setOnCall] = useState(20);
  const [noLogin, setNoLogin] = useState(10);
  const [checkIn, setCheckIn] = useState(60);
  const [checkOut, setCheckOut] = useState(50);
  const [liveStatusData, setLiveStatusData] = useState([]);
  const [liveStatus, setLiveStatus] = useState();
  const [loading, setLoading] = useState(false);

  const [liveStatusDetails, setLiveStatusDetails] = useState([]);
  const [activeStatus, setActiveStatus] = useState("on_break");
  const [teamLiveStatusDetails, setTeamLiveStatusDetails] = useState([]);

  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [onCallLiveCount, setOnCallLiveCount] = useState(0);
  const loginInfo = getCookie("callpal-user");

  const [hasMore, setHasMore] = useState(true); // Initially set to false
  const [currentPage, setCurrentPage] = useState(1);

  const params = useParams();
  const id =
    params.id != "" && params.id != undefined
      ? atob(params.id)
      : loginInfo.userInfo.id;

  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");
  const [dates, setDates] = useState({
    from: moment().format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD"),
  });

  const [userData, setUserData] = useState(null);
  const userId = id.toString(); // Specify the user ID you want to fetch
  useEffect(() => {

    const fetchData = async () => {
      try {
        // Your initial fetches and setup
        fetchTeamLiveStatusDetails(activeStatus);
        const res = await TeamLiveStatusApi.getTeamLiveStatus();
        if (res.status) {
          setLiveStatusData(res.data);
          setActiveStatus(res.default_status);
          setLiveStatus(res.live_status);
          const db = firebase.firestore();
          const telecallersArray = Object.keys(res.telecallers).map((key) =>
            String(key)
          );
          const onCallUserList = db
        //  alert(process.env.REACT_APP_FIREBASE_CALL_TABLE)
            .collection(process.env.REACT_APP_FIREBASE_CALL_TABLE)
            .where(
              firebase.firestore.FieldPath.documentId(),
              "in",
              telecallersArray
            );
          // Set up real-time listener for the onCallUserList query

          const unsubscribe = onCallUserList.onSnapshot((snapshot) => {
            const numberOfUsers = snapshot.size;
            setOnCallLiveCount(numberOfUsers);
            const documentDataArray = [];
            // setLiveStatusDetails([]);
            snapshot.forEach((doc) => {
              const timestamp = parseInt(doc.data().call_started_on); // Unix timestamp
               // Convert timestamp to moment object in UTC and adjust for timezone offset to IST
              const startTime = moment.utc(timestamp).utcOffset('+05:30');
              // Calculate duration using current time
              const duration = moment.duration(moment().diff(startTime));
              
              // Get hours, minutes, and seconds from the duration
              const hours = Math.floor(duration.asHours());
              const minutes = duration.minutes();
              const seconds = duration.seconds();
              
              // Create formatted string
              let formattedTime = '';
              if (hours > 0) {
                  formattedTime += hours.toString().padStart(2, '0') + ':';
              }
              if (minutes > 0 || hours > 0) {
                  formattedTime += minutes.toString().padStart(2, '0') + ':';
              }
              formattedTime += seconds.toString().padStart(2, '0');

              // formattedTime += isNaN(formattedTime) ? 0 : formattedTime


              // Add units to the formatted string
              if (hours > 0) {
                  formattedTime += ' H';
              } else if (minutes > 0) {
                  formattedTime += ' M';
              } else {
                  formattedTime += ' S';
              }

              const dataObject = {
                name: res.telecallers[doc.id],
                doc_id: res.telecallers[doc.id],
                time_or_count: formattedTime,
              };
              // Push the object into the documentDataArray
              documentDataArray.push(dataObject);
            });
            setLiveStatusDetails(documentDataArray);
          });
          // Return cleanup function to unsubscribe from the listener
          return () => unsubscribe();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData(); // Call the async function immediately
  }, []);

  // const fetchTeamLiveStatusDetails = async ( activeStatus,startDate, endDate,page) => {
  const fetchTeamLiveStatusDetails = async (activeStatus, page) => {
    try {
      setLoading(true);
      const data = JSON.stringify({
        per_page_row: 30,
        page: page > 0 ? page : currentPage,
        status: activeStatus,
        fromdate: startDate,
        todate: endDate,
        team_lead_id: parseInt(id),
      });
      const res = await TeamLiveStatusApi.getTeamLiveStatusDetails(data);
      if (res.status) {
        // setTeamLiveStatusDetails(res.data); without infinte scroll working code
        // setLoading(false);
        //   dispatch(
        //     setIsSubmitted({
        //       open: true,
        //       severity: "success",
        //       msg: data.message,
        //     })
        //   );

        setTeamLiveStatusDetails((prevItems) => [...prevItems, ...res.data]);
        setCurrentPage((prevPage) => prevPage + 1);
        if (res.data.length == 0) {
          setHasMore(false); // If fetched less than 5 rows, stop loading more data
        }
        setLoading(false)
      } else {
        setLoading(false)
      }
    } catch (e) {
      setLoading(false)
      console.error(e);
    }
  };

  const handleFetchStatusDetails = (status) => {
    setActiveStatus(status);
    setCurrentPage(1); // Reset current page to 1
    setHasMore(true); // Reset hasMore to true
    // fetchTeamLiveStatusDetails(status, startDate, endDate, 1); // Pass necessary parameters
    fetchTeamLiveStatusDetails(status, 1); // Pass necessary parameters
    setTeamLiveStatusDetails([]);
  };

  // const sendFilterDateRange =(startDate, endDate)=>{
  //   fetchTeamLiveStatusDetails(startDate, endDate);
  // }

  const refreshList = () => {
    if (activeStatus != liveStatus) {
      fetchTeamLiveStatusDetails(activeStatus, 1);
    }
    setCurrentPage(1); // Reset current page to 1
    setTeamLiveStatusDetails([]);
    // fetchTeamLiveStatusDetails(activeStatus, 1); // Pass necessary parameters
  };

  return (
    <div className={classes.mainContainer}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ fontWeight: 600, fontSize: "20px", color: "#F86E20" }}>
          Team Live Status
        </div>
        {/* {activeStatus == liveStatus ? (
          <ReportsFilter sendFilterDateRange={sendFilterDateRange}  />
        ) : (
          <></>
        )} */}
      </div>

      <div className={classes.sectionContainer}>
        <div className={classes.section1}>
          <div className={classes.sectionHeading}>Live status</div>
          <div className={classes.historyContainer}>
            {liveStatusData.map((item, index) => (
              <div style={{ display: "flex", marginBottom: 10 }} key={index}>
                <Button
                  style={{
                    height: 45,
                    width: "150px",
                    backgroundColor:
                      item.status === activeStatus ? "#FA9510" : "#FFF",
                    color: item.status === activeStatus ? "" : "#FA9510",
                  }}
                  className={classes.saveButton}
                  startIcon={
                    <img
                      src={require(`../../../Images/${
                        item.status === activeStatus
                          ? "w_" + item.iconname
                          : item.iconname
                      }`)}
                      width="19px"
                      alt=""
                    />
                  }
                  onClick={() => {
                    handleFetchStatusDetails(item.status);
                  }}
                >
                  {item.label}
                </Button>
                <div className={classes.progressContainer}>
                  <div
                    className={classes.progress}
                    style={{
                      width: "100%",
                      backgroundColor:
                        item.status === activeStatus ? "#FA9510" : "#BFC6D1",
                    }}
                  >
                    {item.status == liveStatus ? onCallLiveCount : item.count}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={classes.section1}>
          <div className={classes.sectionHeading}>
            <div>Team Live Details </div>
            <div onClick={refreshList}>
              <img src={refresh} alt="" />
            </div>
          </div>
          <div className={classes.formContainer}>
            <div className={classes.tableContainer} id="scrollableDiv">
              {/* <NoRecords rows={liveStatus === activeStatus ? liveStatusDetails : teamLiveStatusDetails} loading={loading} /> */}
              {liveStatus === activeStatus ? (
                liveStatusDetails.map((item, index) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#3A1C67",
                      fontWeight: 600,
                      justifyContent: "space-between",
                    }}
                    key={index}
                  >
                    <div> {item.name}</div>
                    <div style={{ color: "#848891" }}>
                      {" "}
                      {item.time_or_count ? item.time_or_count : "-"}
                    </div>
                  </div>
                ))
              ) : (
                <InfiniteScroll
                  dataLength={teamLiveStatusDetails.length}
                  next={() =>
                    fetchTeamLiveStatusDetails(activeStatus, startDate, endDate)
                  } // Pass necessary parameters
                  hasMore={hasMore}
                  loader={<p>Loading...</p>}
                  scrollableTarget="scrollableDiv"
                >
                  {teamLiveStatusDetails.map((item, index) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#3A1C67",
                        fontWeight: 600,
                        justifyContent: "space-between",
                      }}
                      key={index}
                    >
                      <div> {item.name}</div>
                      <div style={{ color: "#848891" }}>
                        {" "}
                        {item.time_or_count ? item.time_or_count : "-"}
                      </div>
                    </div>
                  ))}
                 
                </InfiniteScroll>
              )}
               <NoRecords rows={liveStatus === activeStatus ? liveStatusDetails : teamLiveStatusDetails} loading={loading} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TeamLiveStatus;
