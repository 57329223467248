import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./CustomCarousel.css";

import { EffectCoverflow, Pagination, Navigation, Autoplay } from "swiper";

// import slide_image_1 from './assets/images/img_1.jpg'
// import slide_image_2 from './assets/images/img_2.jpg'
// import slide_image_3 from './assets/images/img_3.jpg'
// import slide_image_4 from './assets/images/img_4.jpg'
// import slide_image_5 from './assets/images/img_5.jpg'
// import slide_image_6 from './assets/images/img_6.jpg'
// import slide_image_7 from './assets/images/img_7.jpg'
// import profile from './assets/images/new.jpeg'

function CustomCarousel() {
  const profile =
    "https://writestylesonline.com/wp-content/uploads/2019/01/What-To-Wear-For-Your-Professional-Profile-Picture-or-Headshot.jpg";
  return (
    <div className="container">
      <Swiper
        // effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={"auto"}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        coverflowEffect={
          {
            // rotate: 0,
            // stretch: 0,
            // depth: 100,
            // modifier: 2.5,
          }
        }
        pagination={{ el: ".swiper-pagination", clickable: true }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
        className="swiper_container"
      >
        <SwiperSlide>
          <div className="sliderContain">
            <div
              className=""
              style={{
                background: "yellow",
                color: "",
                height: "13px",
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
              }}
            ></div>
            <div
              style={{
                paddingTop: "10px",
                paddingBottom: "40px",
                paddingLeft: "20px",
                paddingRight: "20px",
                display: "flex",
                flexDirection: "column",
                height: "100%",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  fontFamily: "open-sans",
                  fontWeight: "300",
                  fontSize: "12px",
                  color: "gray",
                }}
              >
                CallPal CRM software excels in streamlining customer relationship management with its user-friendly interface and robust features. It offers seamless integration with various communication channels, efficient lead management tools, and insightful analytics. With its intuitive design and comprehensive functionality, CallPal stands out as a top choice for businesses seeking effective CRM solutions.
              </div>
              <div
                style={{ display: "flex", gap: "5px", marginBottom: "40px" }}
              >
                {/* <img
                  src={profile}
                  style={{ width: "35px", height: "35px", borderRadius: "50%" }}
                ></img> */}
                <div
                  style={{
                    marginLeft: "10px",
                    marginTop: "5px",
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#3A1C67",
                  }}
                >
                  Atmaram Bansode,
                </div>
                <div
                  style={{
                    marginTop: "5px",
                    fontWeight: "300",
                    color: "gray",
                    fontSize: "14px",
                  }}
                >
                  CEO
                </div>
              </div>
            </div>
            {/* <img src={slide_image_1} alt='slide_image' /> */}
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="sliderContain">
            <div
              className=""
              style={{
                background: "blue",
                color: "",
                height: "13px",
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
              }}
            ></div>
            <div
              style={{
                paddingTop: "10px",
                paddingBottom: "40px",
                paddingLeft: "20px",
                paddingRight: "20px",
                display: "flex",
                flexDirection: "column",
                height: "100%",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  fontFamily: "open-sans",
                  fontWeight: "300",
                  fontSize: "12px",
                  color: "gray",
                }}
              >
               Callpal is a handy app for Fintech businesses. It helps manage calls and leads easily. It's simple to use, making it a great tool for busy professionals.
Callpal provides a seamless solution for business call management and brings the convenience of a SIM-based portable office. The app's user-friendly interface enhances productivity by efficiently managing calls, making it a valuable tool for businesses. Its focus on simplicity and practicality sets it apart, offering a reliable platform for professionals on the go.
I'm personally using this software. As a user, Callpal has streamlined my call management. The SIM-based portable office feature is a game-changer, making it a go-to for efficient and convenient communication on the go. My business is increasing from past few months. Resulting in 60-70% calling growth.

              </div>
              <div
                style={{ display: "flex", gap: "5px", marginBottom: "40px" }}
              >
                {/* <img
                  src={profile}
                  style={{ width: "35px", height: "35px", borderRadius: "50%" }}
                ></img> */}
                <div
                  style={{
                    marginLeft: "10px",
                    marginTop: "5px",
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#3A1C67",
                  }}
                >
                  Anurag Jawarkar,
                </div>
                <div
                  style={{
                    marginTop: "5px",
                    fontWeight: "300",
                    color: "gray",
                    fontSize: "14px",
                  }}
                >
                  CEO
                </div>
              </div>
            </div>
            {/* <img src={slide_image_1} alt='slide_image' /> */}
          </div>
        </SwiperSlide>

        {/* <SwiperSlide>
          <div className="sliderContain">
            <div
              className=""
              style={{
                background: "pink",
                color: "",
                height: "13px",
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
              }}
            ></div>
            <div
              style={{
                paddingTop: "10px",
                paddingBottom: "40px",
                paddingLeft: "20px",
                paddingRight: "20px",
                display: "flex",
                flexDirection: "column",
                height: "100%",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  fontFamily: "open-sans",
                  fontWeight: "300",
                  fontSize: "12px",
                  color: "gray",
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                commodo tincidunt felis, a ullamcorper justo bibendum a. Fusce
                euismod semper augue.
              </div>
              <div
                style={{ display: "flex", gap: "5px", marginBottom: "40px" }}
              >
                <img
                  src={profile}
                  style={{ width: "35px", height: "35px", borderRadius: "50%" }}
                ></img>
                <div
                  style={{
                    marginLeft: "10px",
                    marginTop: "5px",
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#3A1C67",
                  }}
                >
                  Cameron Williamson,
                </div>
                <div
                  style={{
                    marginTop: "5px",
                    fontWeight: "300",
                    color: "gray",
                    fontSize: "14px",
                  }}
                >
                  CEO
                </div>
              </div>
            </div>
            {/* <img src={slide_image_1} alt='slide_image' /> 
          </div>
        </SwiperSlide> */}

        

        

        

        {/* <SwiperSlide>
          <img src={slide_image_2} alt='slide_image' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_3} alt='slide_image' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_4} alt='slide_image' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_5} alt='slide_image' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_6} alt='slide_image' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_7} alt='slide_image' />
        </SwiperSlide> */}

        <div className="slider-controler">
          {/* <div className='swiper-button-prev slider-arrow'>
            <ion-icon name='arrow-back-outline'></ion-icon>
          </div>
          <div className='swiper-button-next slider-arrow'>
            <ion-icon name='arrow-forward-outline'></ion-icon>
          </div> */}
          <div className="swiper-pagination"></div>
        </div>
      </Swiper>
    </div>
  );
}

export default CustomCarousel;
