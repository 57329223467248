import { combineReducers } from "@reduxjs/toolkit";
import loginReducer from '../Reducers/loginSlice'
import usersReducer from "./features/users/userSlice";
import dashboardReducer from "./features/dashboard/dashboardSlice";
import homeReducer from "./features/home/homeSlice";
export const rootReducer = combineReducers({
//   register: RegisterReducer,
  login: loginReducer,
  users: usersReducer,
  dashboard:dashboardReducer,
  home:homeReducer,
});
