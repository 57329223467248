import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormControlLabel, makeStyles } from '@material-ui/core';
import { OrangeCheckbox } from '../../Styles';
import { useState } from 'react';
import { CommonApi } from '../../Services/CommonApi';
const useStyles = makeStyles({
 button:{
   
        backgroundColor: "#3A1C67",
        color: "#fff",
        textTransform: "none",
        "&:hover": {
          backgroundColor: "#F76A1A",
        },
        border:'1px solid #fff'
 },
 clearButton:
 {
   
    backgroundColor: "#FA9510",
    color: "#fff",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#F76A1A",
    },
},
empolyeeName:{
    fontSize:'16px',
    color:'#fff'
},
content:{
  minWidth:'400px',
  '&::-webkit-scrollbar': {
    width: '0.5em',
    display: 'block',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'darkgrey',
    outline: '1px solid slategrey',
    borderRadius: '5px',
  },
}
})
export default function BulkUploadTeleCallerSelectUserPopup({openTeleCallerList, teamLeadId, updateTeleCallerData , }) {
 const classes=useStyles()

 const team_lead_id = teamLeadId;

const [arr,setArr]=useState([])
const [open,setOpen]=useState(openTeleCallerList)
const [selectedTeleCaller, setSelectedTeleCaller] = useState({});

useEffect(()=>{
    getTeamLeadTeleCallerList(team_lead_id);
},[team_lead_id])


const getTeamLeadTeleCallerList = async (team_lead_id) => {
    try {
    //   setReportLoader(true);
      
      const res = await CommonApi.getTeamLeadTeleCaller(team_lead_id);
      if (res.status) {
      const callerArray = Object.entries(res.data).map(
          ([id, name]) => ({ id, name }),
        );

        setArr(callerArray)
    //     const reportingArray = Object.entries(res.data).map(
    //       ([id, reporting_to]) => ({ id, reporting_to }),
    //     );
    //     setReportArr(reportingArray);
      }
    //   setReportLoader(false);
    } catch (err) {
    //   setReportLoader(false);
    } finally {
    //   setReportLoader(false);
    }
  };

  const handleLeadImport=()=>{
    let userArr=[]
    arr.forEach((el=>{
      if(el.checked){
        userArr.push(el.id)
      }
    }))
    setOpen(false)
    updateTeleCallerData(selectedTeleCaller)

   
  }


  // Function to handle checkbox change
  const handleCheckboxChange = (id) => {
    setArr(arr.map(item => {
      if (item.id === id) {
        const updatedItem = { ...item, checked: !item.checked };
        setSelectedTeleCaller(prevState => {
          const newState = { ...prevState };
          if (updatedItem.checked) {
            newState[id] = updatedItem;
          } else {
            delete newState[id];
          }
          return newState;
        });
        return updatedItem;
      }
      return item;
    }));
  };
// Function to clear all checkboxes
const clearAll = () => {
  setArr(arr.map(item => ({ ...item, checked: false })));
  setSelectedTeleCaller({});
};

// // Function to select all checkboxes
// const selectAll = () => {
//   const updatedArr = arr.map(item => ({
//     ...item,
//     checked: true
//   }));
//   setArr(updatedArr);
//   setSelectedTeleCaller(prevState => ({
//     ...prevState,
//     [teamLeadId]: updatedArr
//   }));

//   console.log("updatedArr",updatedArr)
// };


// Function to select all checkboxes
const selectAll = () => {
  // Update the arr state with all items checked
  const updatedArr = arr.map(item => ({
    ...item,
    checked: true
  }));
  setArr(updatedArr);

  // Update the selectedTeleCaller state with all items
  const updatedSelectedTeleCaller = updatedArr.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});

  setSelectedTeleCaller(prevState => ({
    ...prevState,
    ...updatedSelectedTeleCaller
  }));
 
};


  return (
    <div>
      
      <Dialog
        // open={true}
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
            style: {
              backgroundColor: '#3A1C67',
              borderRadius:14,
              marginLeft:"300px"
            },
          }}  
      >
        <DialogTitle id="alert-dialog-title">
            <div style={{textAlign:'center',color:'#FA9510',fontWeight:600,fontSize:16}}>
                Select Tele Caller 
            </div>
            <div style={{marginTop:20}}>
            <Button className={classes.button} onClick={selectAll}>
                Select all
            </Button>
            {/* <Button className={classes.button}>
                Select my team
            </Button> */}
            <Button className={classes.clearButton} style={{marginLeft:20}}  onClick={clearAll}>
                Clear all
            </Button>
          </div>
        </DialogTitle>
        <DialogContent className={classes.content}>
          
          
          { arr.length>0 &&arr.map((el,i)=>
          (
            <div >
            <FormControlLabel
            key={el.id}
                  control={
                    <OrangeCheckbox
                    //   checked={el.checked}
                    checked={el.checked || false}
                    onChange={() => handleCheckboxChange(el.id)}

                    //   onChange={(e)=>handleChange(e,el)}
                      name={el.id}
                    />
                  }
                  label={
                      <div className={classes.empolyeeName}>
                        {el.name}
                      </div>
                  }
                />
                 </div>
          ))}
         
        </DialogContent>
        <DialogActions style={{display:'flex',justifyContent:'center',padding:'20px 0px'}}>
          {/* <Button onClick={()=>{clearAll(); setOpen(false);setAnchorE1(false);}} className={classes.button}> */}
          <Button onClick={()=>{clearAll(); setOpen(false)}} className={classes.button}>

           Discard
          </Button>
          <Button onClick={()=>{handleLeadImport(); setOpen(false);}} className={classes.clearButton}>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
