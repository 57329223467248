import logo from './logo.svg';
import './App.css';
import AppRoutes from './Routes/routes';
import MessagePopper from './Components/MessagePopper';

function App() {
  return (
    <div className="App">
      <MessagePopper/>
     <AppRoutes/>
    </div>
  );
}

export default App;
