import laptop from "../../Images/laptop.png"
// import laptop from "../../Images/bg_removed_pc.png"

const Home2=()=>{
    return(
    <div 
    style={{backgroundColor:'#fff',display:'flex',justifyContent:'left'}}
    >
     
 <img src={laptop} alt="" loading="eager" width={'95%'} height={'auto'} style={{paddingTop:'0px'}}/>

    </div>
    )
}
export default Home2
