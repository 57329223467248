import { Box, Container, makeStyles } from "@material-ui/core";
import right from "../../Images/Right.png"
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        padding: '100px',
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            flexDirection:'column',
            padding: '100px 40px',
           
             },
    },
    heading: {
        fontSize: '25px',
        fontWeight: 700,
        color: '#3A1C67'
    },
    description: {
        fontWeight: 400,
        fontSize: '18px',
        color: '#848891'
    },
    infoContainer: {
        paddingLeft: '90px',
        width: '60%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            paddingTop:'40px',
            paddingLeft: '0px',
             },
    },
    imgContainer: {
        width: '100%',
        height: 'auto',
    
        [theme.breakpoints.down('sm')]: {
            width: '80%',
              
             },
    }
}))

const Program = () => {
    const classes = useStyles()
    return (
        <div>
            <Container className={classes.container}>
                <div style={{    textAlign:'center',marginTop: "35px"}}>
                    <img loading="eager" src={right} alt="" className={classes.imgContainer} />
                </div>
                <div className={classes.infoContainer} style={{marginTop: "85px"}}>
                    <Box className={classes.heading}>Partnership Program</Box>
                    <p className={classes.description}>We would love to see you partner with us in the growth story of
                        future of outbound cloud telephony.</p>
                    <div>
                        <ul>
                            <li style={{ fontWeight: 700, fontSize: '15px', color: '#3A1C67', paddingTop: '30px' }}>300+ trusted client base in the first year </li>
                            <li style={{ fontWeight: 700, fontSize: '15px', color: '#B32B88', paddingTop: '30px' }}>New age technology and future ready telephony </li>
                            <li style={{ fontWeight: 700, fontSize: '15px', color: '#FF5458', paddingTop: '30px' }}>Negligible competition in the SIM based cloud telephony space </li>
                            <li style={{ fontWeight: 700, fontSize: '15px', color: '#FA9510', paddingTop: '30px' }}>High recurring revenue sharing through out customer lifecycle </li>
                            <li style={{ fontWeight: 700, fontSize: '15px', color: '#3B4C89', paddingTop: '30px' }}>Best in class support system</li>
                        </ul>
                    </div>
                </div>

            </Container>
        </div>
    )
}
export default Program;