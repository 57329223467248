import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import {
  CircularProgress,
  InputAdornment,
  TextField,
  colors,
} from "@material-ui/core";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { ArrowDropDown } from "@material-ui/icons";
import { useState } from "react";
import {
  addUserApi,
  isValidEmail,
  reportingMasterApi,
  roleMasterApi,
} from "../../../Utils/commonUtils";
import { useDispatch, useSelector } from "react-redux";
import { setIsSubmitted } from "../../../Reducers/loginSlice";
import { getCookie } from "../../../Utils/commonUtils";
import { useFormik } from "formik";
import * as Yup from "yup";
import { KeyboardArrowDown } from '@material-ui/icons'
import NoRecords from '../Other-components/NoRecords'

const useStyles = makeStyles((theme) => ({
    header: {
        height: "70px",
        color: "#fff",
        fontSize: "17px",
        backgroundColor: "#3A1C67",
        fontWeight: 600,
        padding: 20,
        textAlign: "left",
      },
    heading: {
      color: '#3A1C67',
      fontSize: '18px',
      fontWeight: 700,
      textAlign: 'left',
      marginLeft: 20,
    },
    sectionContainer: {
      display: 'flex',
    },
    section1: {
      height: 500,
      backgroundColor: '#fff',
      margin: 10,
      borderRadius: 10,
    },
    sectionHeading: {
      color: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: 16,
      backgroundColor: '#3A1C67',
      height: 50,
      padding: '0px 30px',
      textAlign: 'left',
      fontWeight: 700,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    historyContainer: {
      padding: 15,
      height: 450,
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        width: '0.4em',
        display: 'block',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'darkgrey',
        outline: '1px solid slategrey',
        borderRadius: '5px',
      },
    },
    card: {
      width: '100%',
      paddingBottom: 10,
      backgroundColor: '#F1F1F1',
      textAlign: 'left',
      fontSize: 12,
      color: '#FA9510',
      boxShadow:
        'rgba(0, 0, 0, 0.22) 0px 1px 5px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
    },
    dot: {
      height: 10,
      width: 10,
      border: '1px solid #FA9510',
      borderRadius: '50%',
    },
    indicator: {
      borderLeft: '2px solid #848891',
      height: 60,
      marginLeft: 4,
    },
    saveButton: {
      backgroundColor: '#FA9510',
      color: '#fff',
      textTransform: 'none',
      width: 100,
      height: '30px',
      borderRadius: '10px',
      fontSize: '14px',
      marginLeft: 10,
      '&:hover': {
        backgroundColor: '#FA7A21',
      },
    },
    formContainer: {
      padding: 15,
      width: '100%',
      height: 450,
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        width: '0.4em',
        display: 'block',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'darkgrey',
        outline: '1px solid slategrey',
        borderRadius: '5px',
      },
    },
    statusButton: {
      backgroundColor: '#fff',
      color: '#3A1C67',
      textTransform: 'none',
      height: '35px',
      borderRadius: '5px',
      border: '1px solid #3A1C67',
      fontSize: '14px',
      marginBottom: 5,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
      '&:hover': {
        backgroundColor: '#fff',
      },
    },
    root: {
      '& label.Mui-focused': {
        color: '#F86E20',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#F86E20',
        },
        '&:hover fieldset': {
          borderColor: '#F86E20',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#F86E20',
        },
      },
      margin: '5px 5px',
      width: '48%',
      // [theme.breakpoints.down('xs')]: {
      //     width:'100%'
      //   },
    },
    multilineColor: {
      color: '#F86E20',
      fontSize: 14,
    },
  }))

export default function ViewCallLogHistory({
  handleChildData,
  open,
  setOpen,
  param,
  action,
  historyData,
  loading
}) {

  const classes = useStyles();
  const loginInfo=getCookie('callpal-user'); 

  const toggleDrawer = (anchor, op) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(op);
  };
  

  return (
    <div>
      <SwipeableDrawer
        anchor={"right"} 
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <div>
          <div className={classes.header}>Call History</div>
            
            
          <div className={classes.section1}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <NoRecords rows={historyData} loading={loading} />
          </div>
          <div className={classes.historyContainer}>
          {historyData.length > 0 && !loading && historyData.map((data) => (
                <div style={{ display: 'flex', marginBottom: 10 }}>
                <div style={{ color: '#FA9510', paddingRight: 30, width: 190 }}>
                    <div>{data.date}</div>
                    <div>{data.time}</div>
                </div>
                <div style={{ marginRight: 15 }}>
                    <div className={classes.dot} />
                    <div className={classes.indicator} />
                </div>
                <div className={classes.card}>
                    <ul>
                    <li style={{ color: '#3A1C67' }}>
                        <div style={{ display: 'flex', color: '#FA9510' }}>
                        <div>Status : {data.status}</div>
                        {/* <div style={{ paddingLeft: 20, paddingRight: 25 }}>
                            location - abc, 10:00 AM
                        </div> */}
                        </div>
                    </li>

                    <li style={{ color: '#3A1C67' }}>
                        <div style={{ display: 'flex', color: '#FA9510' }}>
                        <div>Duration : {data.log_calltime_min_sec}</div>
                        </div>
                    </li>
                    </ul>
                    <div style={{ display: 'flex', padding: '0px 32px' }}>
                    <div style={{ color: '#3A1C67', fontWeight: 600 }}>
                      By {data.telecaller}
                    </div>
                    {/* <div style={{ paddingLeft: 20 }}>Source - NA</div> */}
                    </div>
                </div>
                </div>
                  
               ))}

        </div>
        </div>
        </div>
       
      </SwipeableDrawer>
    </div>
  );
}
