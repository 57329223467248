import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DRRCalendar from "./DRRCalendar";
import { useEffect } from "react";
import { useState } from "react";
import { DRRApi } from "../../Services/DRRApi";
import DRRTeamLead from "./DRRTeamLead";
import { getCookie } from "../../Utils/commonUtils";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
 
});

export default function DRR(props) {
  const classes = useStyles();
  const loginInfo = getCookie("callpal-user");
  const  navigate = useNavigate();


  const [calenderDates, setCalenderDates] = useState([])
  const [target, setTarget] = useState(false)
  useEffect(()=>{
    getCalendarList()
  },[])
  const getCalendarList = async ()=> {

    try{
      const res = await DRRApi.getCalendarList()
      if(res.status){
        setCalenderDates(res.data)
        // setTarget(res.target);
        setTarget(res.target);
        if(res.target && loginInfo.userInfo.fk_role_id != 2){
          navigate(`/dashboard/drr/tele_caller/${ btoa(loginInfo.userInfo.id)}`)
        }
      }
    }catch{
    // console.error("")
    }
  }

  const openDRRTeamLead = (permission)=> { 
   setTarget(permission)
  }

  return (
    <>  
    {calenderDates.length > 0 && (
      target  &&   loginInfo.userInfo.fk_role_id == 2 ?       
      <DRRTeamLead/> : <DRRCalendar calenderDates={calenderDates}  openDRRTeamLead = {openDRRTeamLead} />
    )}
    
      
    {/* 
      {calenderDates.length > 0 && !target  && (
      <DRRCalendar calenderDates={calenderDates} />
      )} */}

    </>
  );
}
 