import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Button, TablePagination } from "@material-ui/core";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { useEffect } from "react";
import { useState } from "react";
import { StyledTableCell, StyledTableRow } from "../../../Styles";
import { useDispatch, useSelector } from "react-redux";
import NoRecords from "./../Other-components/NoRecords";
import { getCookie } from "../../../Utils/commonUtils";
import { HashLink as Link } from "react-router-hash-link";
import { CallLogApi } from "../../../Services/CallLogApi";

const useStyles = makeStyles({
  mainContainer: {
    padding: "80px 30px 30px 30px",
  },
  table: {
    minWidth: 700,
  },

  button: {
    backgroundColor: "#3A1C67",
    color: "#fff",
    marginLeft: "10px",
    borderRadius: "5px",
    fontSize: "12px",
    padding: "5px 20px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#7916BD",
    },
  },
  tableContainer: {
    margin: "20px 0px",
    maxHeight: 450,
    width: "100%",
    "&::-webkit-scrollbar": {
      width: "0.5em",
      display: "block",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "darkgrey",
      outline: "1px solid slategrey",
      borderRadius: "5px",
    },
  },
});

export default function CallLogsTeamLead(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [action, setAction] = useState("Add");
  const { userInfo } = useSelector((state) => state.login);
  // const { filteredUsers ,loading,totalCount} = useSelector((state) => state.users)
  const [offset, setOffset] = useState(1);
  const [updated, setUpdated] = useState(true);
  const [confirm, setConfirm] = useState(false);
  const [deletedUser, setDeletedUser] = useState("");
  const [deleteLoader, setDeleteLoader] = useState(false);
  const loginInfo = getCookie("callpal-user");
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [exportExcelLoading, setExportExcelLoading] = useState(false);

  const handleChangePage = (event, newPage) => {
    setUpdated(true);
    setPage(newPage);
    let num = 0;
    if (newPage > page) {
      num = offset + 1;
    } else {
      num = offset - 1;
    }
    setOffset(num);
  };

  const handleChangeRowsPerPage = (event) => {
    setUpdated(true);
    setRowsPerPage(event.target.value);
    setPage(0);
    setOffset(0);
  };

  const headers = [
    "Employee Id",
    "User Name",
    "Role",
    "Count"
  ];

  useEffect(() => {
    if (loginInfo?.userInfo?.mobile_number && updated) {
        getCallLogTeamLeadDetails();
        setUpdated(false);
    }
  }, [userInfo, page, rowsPerPage, updated]);

  const getCallLogTeamLeadDetails = async () => {
    try {
      setLoading(true);
      const data = JSON.stringify({
        per_page_row: rowsPerPage,
        page: offset,
      });
      const res = await CallLogApi.getCallLogsTeamLead(data);
      if (res.status) {
        setRows([...res.data]);
        setCount(res.total_count);
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    } finally {
      setLoading(false);
    } 
  };




  const handleExportExcel = async() => {
    try {
      setExportExcelLoading(true);
      const data = JSON.stringify({
        per_page_row: count,
        page: offset,
      });
      const res = await CallLogApi.getCallLogsTeamLead(data);
      if (res.status) {
        const formattedData = res.data.map(row => ({
          "Employee Id": row.employee_id,
          "User Name":row.name,
          "Role":row.role,
          "Count": row.lead_count,
        }));
  
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(formattedData, { header: headers});
  
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
        saveAs(dataBlob, `Team_lead_Call_logs.xlsx`);
      }
    } catch (err) {
      setExportExcelLoading(false);
      console.error(err);
    } finally {
      setExportExcelLoading(false);
    }
  }

  return (
    <div className={classes.mainContainer}>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ fontWeight: 600, fontSize: '20px', color: '#F86E20' }}>
          Call logs
          </div>

          <div className="col-2">
              <Button className={classes.button} onClick={() => handleExportExcel()}  > 
                  {exportExcelLoading ? "Please wait...." : "Export" } 
              </Button>
          </div>
        </div>
      
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table
          stickyHeader
          className={classes.table}
          aria-label="customized table"
          size="small"
        >
          <TableHead>
            <TableRow>
              {headers.map((el, i) => {
                return (
                  <StyledTableCell key={el} align="center">
                    {el}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.length > 0 &&
              !loading &&
              rows.map((row) => (
                <StyledTableRow>
                  <StyledTableCell align="center">
                    <Link to={`/dashboard/call-logs/${btoa(row.id)}`}>{row.employee_id}</Link>
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.name}</StyledTableCell>
                  <StyledTableCell align="center">{row.role}</StyledTableCell>
                  <StyledTableCell align="center">{row.lead_count}</StyledTableCell>
                </StyledTableRow>
              ))}
            <NoRecords rows={rows} loading={loading} />
          </TableBody>
        </Table>
      </TableContainer>
     

      <div>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          backIconButtonProps={{
            "aria-label": "Previous Page",
            style: {
              color: "#fff",
              backgroundColor: "#3A1C67",
              padding: 3,
              borderRadius: "5px",
              marginRight: "5px",
            },
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
            style: {
              color: "#fff",
              backgroundColor: "#3A1C67",
              padding: "3px",
              borderRadius: "5px",
            },
          }}
        />
      </div>
    </div>
  );
}
