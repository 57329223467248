import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import {  Button, TablePagination } from '@material-ui/core'
import { useEffect } from 'react'
import AddUserPopper from './Other-components/AddUserPopper'
import { useState } from 'react'
import { StyledTableCell, StyledTableRow } from '../../Styles'
import AddIcon from '@material-ui/icons/Add'
import NoRecords from './Other-components/NoRecords'
import { useSelector } from 'react-redux'
import { getCookie } from '../../Utils/commonUtils'
import { TeamApi } from "../../Services/TeamApi";
import { useNavigate, useParams } from 'react-router-dom'


const useStyles = makeStyles({
  mainContainer: {
    padding: '80px 30px 10px 30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100vh',
  },
  table: {
    minWidth: 700,
  },
  button: {
    backgroundColor: '#3A1C67',
    color: '#fff',
    marginLeft: '10px',
    borderRadius: '5px',
    fontSize: '12px',
    padding: '5px 20px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#7916BD',
    },
  },
  tableContainer: {
    margin: '20px 0px',
    maxHeight: 450,
    width: '100%',
    '&::-webkit-scrollbar': {
      width: '0.5em',
      display: 'block',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'darkgrey',
      outline: '1px solid slategrey',
      borderRadius: '5px',
    },
  },
})

const rows1 = [
  {
    empId: 'AT123',
    name: 'Anjali ',
    role: 'Team Leader',
    loginFiles: '234567',
    disbursementAmt: '123456',
    sanctionAmt: '67890',
    rejectedFiles: '45678',
  },
  {
    empId: 'AT123',
    name: 'Anjali ',
    role: 'Team Leader',
    loginFiles: '234567',
    disbursementAmt: '123456',
    sanctionAmt: '67890',
    rejectedFiles: '45678',
  },
  {
    empId: 'AT123',
    name: 'Anjali ',
    role: 'Team Leader',
    loginFiles: '234567',
    disbursementAmt: '123456',
    sanctionAmt: '67890',
    rejectedFiles: '45678',
  },
  {
    empId: 'AT123',
    name: 'Anjali ',
    role: 'Team Leader',
    loginFiles: '234567',
    disbursementAmt: '123456',
    sanctionAmt: '67890',
    rejectedFiles: '45678',
  },
  {
    empId: 'AT123',
    name: 'Anjali ',
    role: 'Team Leader',
    loginFiles: '234567',
    disbursementAmt: '123456',
    sanctionAmt: '67890',
    rejectedFiles: '45678',
  },
]

const rows2=[
  {
    empId: 'NT123',
    name: 'Neha ',
    role: 'Team Leader',
    loginFiles: '1125',
    disbursementAmt: '123456',
    sanctionAmt: '124',
    rejectedFiles: '45678',
  },
  {
    empId: 'NT123',
    name: 'Neha ',
    role: 'Team Leader',
    loginFiles: '112525',
    disbursementAmt: '123456',
    sanctionAmt: '8565',
    rejectedFiles: '45678',
  },
]
export default function TeamViewDetalis() {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open,setOpen]=useState(false)
  const { userInfo } = useSelector((state) => state.login)
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(false)
  const [offset,setOffset]=useState(1)
  const [count,setCount]=useState(0)
  const [updated,setUpdated]=useState(true)
  const loginInfo=getCookie('callpal-user');
  const navigate = useNavigate()
  const param = useParams()
  const teamViewId = param.teamViewId;

  const handleChangePage = (event, newPage) => {
    setUpdated(true);
    setPage(newPage);
    let num=0
    if(newPage>page){
        num=offset+1
    }
    else{
        num=offset-1
    }
   
    setOffset(num)
  };

  const handleChangeRowsPerPage = (event) => {
    setUpdated(true);
    setRowsPerPage(event.target.value);
    setPage(0);
    setOffset(0)
  };

const headers=['Employee Id','User Name','Role','Login Files','Disbursement Amount','Sanction Amount','Rejected Files']

useEffect(() => {
  if (userInfo?.userInfo?.mobile_number && updated) {
    getUserData();
    setUpdated(false);
  }
}, [userInfo,offset,rowsPerPage,updated]);


const getUserData = async () => {
    try {
      setLoading(true);
      const data = JSON.stringify({
        team_lead_id: teamViewId,
        per_page_row: rowsPerPage,
        page: offset,
        industry_sub_type:loginInfo.userInfo.industry_sub_type
      });
      const res = await TeamApi.getTeamViewDetailList(data);
      if (res.status) {
        setRows([...res.data]);
        setCount(res.total_count);
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const backToTeamView = () => {
    navigate(`/dashboard/team-view`);

  }


  return (
    <div className={classes.mainContainer}>
      <div>

      <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          className={classes.filterHolderComp}
        >

        <div style={{ fontWeight: 600, fontSize: '20px', color: '#F86E20' }}>
          {count > 0  ? count : "" } Team View
        </div>
         
        {/* startIcon={<AddIcon />} */}
          <Button className={classes.button} onClick={backToTeamView} > 
           Back
          </Button>
        </div>
        {/* <AddUserPopper open={open} setOpen={(f) => setOpen(f)} param={'team-view'} action={'Add'} selectedUser={{}}
        setUpdated={(f)=>setUpdated(f)}/> */}
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table
            stickyHeader
            className={classes.table}
            aria-label='customized table'
            size='small'
          >

            <TableHead>
              <TableRow>
                {headers.map((el, i) => {
                  return (
                    <StyledTableCell key={el} align='center'>
                      {el}
                    </StyledTableCell>
                  )
                })}
              </TableRow>
            </TableHead>

            <TableBody> 
            {
                rows.length>0 && !loading && rows.map((row)=>(
                  <StyledTableRow style={{cursor:'pointer'}}>
                    
                    <StyledTableCell align='center'>
                    {/* <Link to={`team-detail/${row.id}`}>{row.employee_id}</Link> */}
                      {row.employee_id}
                    </StyledTableCell>
                    <StyledTableCell align='center'>{row.name}</StyledTableCell>
                    <StyledTableCell align='center'>{row.role}</StyledTableCell>
                    <StyledTableCell align='center'>
                      {row.login_files}
                    </StyledTableCell>
                    <StyledTableCell align='center'>
                      {row.disbursement_amount}
                    </StyledTableCell>
                    <StyledTableCell align='center'>
                      {row.sanction_amount}
                    </StyledTableCell>
                    <StyledTableCell align='center'>
                      {row.rejected_files}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
                <NoRecords rows={rows} loading={loading}/>
            </TableBody>
          </Table>
        </TableContainer>
      </div>

     

      <div>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              backIconButtonProps={{
                "aria-label": "Previous Page",
                style: {
                  color: "#fff",
                  backgroundColor: "#3A1C67",
                  padding: 3,
                  borderRadius: "5px",
                  marginRight: "5px",
                },
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page",
                style: {
                  color: "#fff",
                  backgroundColor: "#3A1C67",
                  padding: "3px",
                  borderRadius: "5px",
                },
              }}
            />
          </div>
    </div>
  )
}
