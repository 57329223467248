import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addUserData, deleteUserData, getFilteredUsersData, getUserData } from "./usersCrud";
export const getAllUsers = createAsyncThunk(
  "users/getAllUsers",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await getUserData(payload);
      if (res.status) {
        return res;
      } else {
        throw new Error(res.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getFilteredUsers = createAsyncThunk(
  "users/getFilteredUsers",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await getFilteredUsersData(payload);
      if (res.status) {
        return res;
      } else {
        throw new Error(res.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteUserById = createAsyncThunk(
  "users/deleteUserById",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await deleteUserData(payload);
      if (res) {
        return res;
      } else {
        throw new Error(res.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addUser = createAsyncThunk(
    "users/addUser",
    async ( payload ,{ rejectWithValue }) => {
      try {
        const res = await addUserData(payload);
        if (res.status) {
          return res.data;
        } else {
          throw new Error(res.message);
        }
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );


const usersSlice = createSlice({
  name: "users",
  initialState: {
    loading: false,
    deleteLoader:false,
    allUsers: [],
    filteredUsers:[],
    totalCount:0,
  },
  reducers: {
    emptyallUsers: (state, action) => {
      state.allUsers = {};
    },
  },
  extraReducers: {
    /* 
    getting all users from server 
    */
    [getAllUsers.pending]: (state) => {
       state.loading = true;
    },
    [getAllUsers.fulfilled]: (state, action) => {
      state.loading = false;
      state.allUsers = action.payload?.users;
    },
    [getAllUsers.rejected]: (state, error) => {
      state.loading = false;
    },
    [addUser.pending]: (state) => {
        state.loading = true;
      },
      [addUser.fulfilled]: (state, action) => {
        state.loading = false;
        state.allUsers = action.payload?.users;
      },
      [addUser.rejected]: (state, error) => {
        state.loading = false;
      },
      [getFilteredUsers.pending]: (state) => {
        state.loading = true;
      },
      [getFilteredUsers.fulfilled]: (state, action) => {
        state.loading = false;
        state.filteredUsers = action.payload?.users;
        state.totalCount=action.payload?.total_count
      },
      [getFilteredUsers.rejected]: (state, error) => {
        state.loading = false;
      },

      [deleteUserById.pending]: (state) => {
        state.deleteLoader = true;
      },
      [deleteUserById.fulfilled]: (state, action) => {
        state.deleteLoader = false;
      },
      [deleteUserById.rejected]: (state, error) => {
        state.deleteLoader = false;
      },
  },
});

export const { emptyallUsers } = usersSlice.actions;
export default usersSlice.reducer;
