import {
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    makeStyles,
    Button
  } from '@material-ui/core'
  import React, { useState } from 'react'
  import { StyledTableCell, StyledTableRow } from '../../../Styles'
  import FilterSection from '../Other-components/FilterSection'
import NoRecords from '../Other-components/NoRecords'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getCookie } from '../../../Utils/commonUtils'
import CrmForm from './../Team-Lead/CrmForm'
import { DataAllocationApi } from "../../../Services/DataAllocationApi";
import { useParams } from "react-router-dom";
import { CommonApi } from "../../../Services/CommonApi";
import {  useNavigate } from 'react-router-dom';

  const useStyles = makeStyles((theme, props) => ({
    mainContainer: {
      padding: '80px 30px 20px 30px',
    },
    container: {
      display: 'flex',
      backgroundColor: '#848891',
      padding: '10px',
      justifyContent: 'space-between',
      borderRadius: '12px',
    },
    tableContainer: {
      margin: '20px 0px',
      maxHeight: 430,
      width: '100%',
      '&::-webkit-scrollbar': {
        width: '0.5em',
        display: 'block',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'darkgrey',
        outline: '1px solid slategrey',
        borderRadius: '5px',
      },
    },
    button: {
      backgroundColor: '#3A1C67',
      color: '#fff',
      marginLeft: '10px',
      borderRadius: '5px',
      fontSize: '12px',
      padding: '5px 20px',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: '#7916BD',
      },
    },
  }))
  const DataAllocation = () => {
    const classes = useStyles()
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const { userInfo } = useSelector((state) => state.login)
    const [rows, setRows] = useState([])
    const [loading, setLoading] = useState(false)
    const [offset, setOffset] = useState(1)
    const [count, setCount] = useState(0)
    const [isCreateLead, setIsCreateLead] = useState(false);
    const loginInfo=getCookie('callpal-user');
    const params = useParams();
    const id = params.id != '' && params.id != undefined ?  atob(params.id) : loginInfo.userInfo.id;
    const navigate = useNavigate()
    const [dates,setDates]=useState({
      from:'',
      to:''
    })
    const [empDetails,setEmpDetails]=useState({
      id:'',
      name:''
    });
    const handleChangePage = (event, newPage) => {
        
        setPage(newPage);
        let num=0
        if(newPage>page){
            num=offset+1
        }
        else{
            num=offset-1
        }
       
        setOffset(num)
    };
   
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        setOffset(0)
    };

    

    const heading = ['Customer/Leads Name', 'Mobile Number', 'Tele Caller', 'Company Name', 'Salary']
    useEffect(() => {
        if (userInfo.hasOwnProperty('mobile_number')|| userInfo?.userInfo?.mobile_number) {
            getDataAllocationData()
        }
    }, [userInfo, offset, rowsPerPage,dates,empDetails]);


    const getDataAllocationData = async () => {
      try {
        setLoading(true);
        const data = JSON.stringify({
          team_lead_id: parseInt(id),
          per_page_row: rowsPerPage,
          page: offset,
          fromdate:dates.from,
          todate:dates.to,
          user_id:empDetails.id
        });
        const res = await DataAllocationApi.getDataAllocationList(data);
        if (res.status) {
          setRows([...res.data]);
          setCount(res.total_count);
        }
      } catch (err) {
        setLoading(false);
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

  
    return (
      <div>
        {isCreateLead?
        <CrmForm />:
    
      <div className={classes.mainContainer}>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ fontWeight: 600, fontSize: '20px', color: '#F86E20' }}>
          {count > 0  ? count : "" } Allocation
          </div>
          <FilterSection isCreateLeadButton={false} isAllEmp={true} setIsCreateLead={setIsCreateLead} setDates={(d)=>setDates(d)} setEmpDetails={(d)=>setEmpDetails(d)} empDetails={empDetails} teamLeadId={id}/>
          
          {loginInfo.userInfo.fk_role_id == 2 && (
          <Button className={classes.button} onClick={() => navigate(-1)}> 
               Back
          </Button>
          )}
        
        </div>
  
        <div>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table
              stickyHeader
              className={classes.table}
              aria-label='customized table'
              size='small'
            >
              <TableHead>
                <TableRow>
                  {heading.map((el, i) => {
                    return (
                      <StyledTableCell key={i} align='center'>
                        {el}
                      </StyledTableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
  
              <TableBody>
                { rows.length>0 && !loading && rows.map((row) => (
                  
                    <StyledTableRow>
                      <StyledTableCell align='center'>
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align='center'>
                        {row.mobile_number}
                      </StyledTableCell>
                    
                      <StyledTableCell align='center'>
                        {row.telecaller}
                      </StyledTableCell>
                      <StyledTableCell align='center'>
                        {row.company_name ? row.company_name : '-'}
                      </StyledTableCell>
                      <StyledTableCell align='center'>
                        {row.salary ? row.salary  : '-'}
                      </StyledTableCell>
                   
                    </StyledTableRow>
                  ))}
                  <NoRecords rows={rows} loading={loading} />
              </TableBody>
            </Table>
          </TableContainer> 
  
          <div>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              backIconButtonProps={{
                "aria-label": "Previous Page",
                style: {
                  color: "#fff",
                  backgroundColor: "#3A1C67",
                  padding: 3,
                  borderRadius: "5px",
                  marginRight: "5px",
                },
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page",
                style: {
                  color: "#fff",
                  backgroundColor: "#3A1C67",
                  padding: "3px",
                  borderRadius: "5px",
                },
              }}
            />
          </div>
        </div>
      </div>
  }
      </div>
       )
  }
  export default DataAllocation
  