import {
  Box,
  TextField,
  Button,
  CircularProgress,
  InputAdornment,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import updateIcon from "../../../../Images/history 2.svg";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "80px 30px 20px 30px",
  },
  heading: {
    color: "#3A1C67",
    fontSize: "18px",
    fontWeight: 700,
    textAlign: "left",
    marginLeft: 20,
  },
  sectionContainer: {
    display: "flex",
  },
  section1: {
    height: "auto",
    backgroundColor: "#fff",
    margin: 10,
    width: "100%",
    borderRadius: 10,
  },
  sectionHeading: {
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 16,
    backgroundColor: "#3A1C67",
    height: 50,
    padding: "0px 30px",
    textAlign: "left",
    fontWeight: 700,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  formRow: {
    margin: "0px 5px",
    width: "100%",
  },
  saveButton: {
    backgroundColor: "#FA9510",
    color: "#fff",
    textTransform: "none",
    width: "100%",
    height: "30px",
    borderRadius: "10px",
    fontSize: "14px",
    marginLeft: 10,
    "&:hover": {
      backgroundColor: "#FA7A21",
    },
  },
}));

const CreditCardApproveBank = ({
  bankList,
  creditCardApprove,
  creditCardList,
  isDisabled,
  saveCreditCardRecord,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [bankSearch, setBankSearch] = useState("");
  const [statusSearch, setStatusSearch] = useState("");
  const statuses = creditCardApprove?.status
    ? Object.entries(creditCardApprove.status).map(([id, status]) => ({ id, status }))
    : [];

  const filteredStatuses = statuses.filter((e) =>
    e.status.toLowerCase().includes(statusSearch.toLowerCase())
  );


  const [creditCardSearch, setCreditCardSearch] = useState("");

  const creditCardType = creditCardList
    ? Object.entries(creditCardList).map(([id, type]) => ({ id, type }))
    : [];

  const filteredCreditCard = creditCardType.filter((e) =>
    e.type.toLowerCase().includes(creditCardSearch.toLowerCase())
  );


  const validationSchema = Yup.object().shape({
    fk_bank_id: Yup.number().required("Bank name is required"),
    fk_status_id: Yup.number().required("Status is required"),
    fk_credit_card_type_id: Yup.number().required("Credit Card is required"),

  });

  const formik = useFormik({
    initialValues: {
      fk_bank_name: "",
      fk_bank_id: "",
      status_name: "",
      fk_status_id: "",
      credit_card_name: "",
      fk_credit_card_type_id: ""
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      saveCreditCardRecord(values);
    },
  });

  useEffect(() => {
    formik.setValues({
      fk_bank_name: creditCardApprove?.fk_bank_name || "",
      fk_bank_id: creditCardApprove?.fk_bank_id || "",
      status_name: creditCardApprove?.status_name || "",
      fk_status_id: creditCardApprove?.fk_status_id || "",
      credit_card_name: creditCardApprove?.credit_card_name || "",
      fk_credit_card_type_id: creditCardApprove?.fk_credit_card_type_id || "",
    });
  }, [creditCardApprove]);

  return (
    <div className={classes.sectionContainer}>
      <div className={classes.section1}>
        <form onSubmit={formik.handleSubmit}>
          <div className={classes.sectionHeading}>
            <div>{loading ? <CircularProgress size={20} /> : "Approve"}</div>
            <div style={{ display: "flex" }}>
             {isDisabled ? <> </> :           
             creditCardApprove && <Button
                type="submit"
                className={classes.saveButton}
                startIcon={<img src={updateIcon} width="100%" alt="" />}
              >
                Save
              </Button>

             }
            </div>
          </div>
          <div style={{ padding: "1rem 1rem", pointerEvents: loading ? "none" : "auto" }}>
            {creditCardApprove ? (
              <div className="row">
                <div className="col-sm-4">
                  <div className={classes.formRow}>
                    <UncontrolledDropdown style={{ width: "100%", pointerEvents: 'none' }}>
                      <DropdownToggle
                        caret
                        style={{
                          width: "95%",
                          display: "flex",
                          justifyContent: "space-between",
                          paddingTop: "10px",
                          paddingBottom: "6px",
                          background: "white",
                          color: "#F86E20",
                          border: "1px solid #F86E20",
                          alignItems: "center",
                          fontSize: "14px",
                        }}
                      >
                        {formik.values.fk_bank_name || "Select a Bank"}
                      </DropdownToggle>
                      <DropdownMenu style={{ width: "100%" }}>
                        <DropdownItem header>
                          <TextField
                            placeholder="Search Bank"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                            size="small"
                            variant="outlined"
                            onChange={(e) => setBankSearch(e.target.value)}
                          />
                        </DropdownItem>
                        <div style={{ maxHeight: 95, overflowY: "scroll" }}>
                          {bankList
                            .filter((e) =>
                              e.bank
                                .toString()
                                .toLowerCase()
                                .includes(bankSearch.toLowerCase())
                            )
                            .map((el) => (
                              <DropdownItem
                                key={el.id}
                                onClick={() => {
                                  formik.setFieldValue("fk_bank_name", el.bank);
                                  formik.setFieldValue("fk_bank_id", el.id);
                                }}
                              >
                                {el.bank}
                              </DropdownItem>
                            ))}
                        </div>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                  {formik.touched.fk_bank_id && formik.errors.fk_bank_id && (
                    <p
                      className="text-danger formik-addUser-msg text-small text-left formik-msg mt-2"
                      style={{
                        textAlign: "left",
                        marginLeft: "8px",
                        marginTop: "-4px",
                      }}
                    >
                      {formik.errors.fk_bank_id}
                    </p>
                  )}
                </div>
                <div className="col-sm-4">
                  <div className={classes.formRow}>
                    <UncontrolledDropdown style={{ width: "100%",  pointerEvents: isDisabled ? "none" :"auto" }}>
                      <DropdownToggle
                        caret
                        style={{
                          width: "95%",
                          display: "flex",
                          justifyContent: "space-between",
                          paddingTop: "10px",
                          paddingBottom: "6px",
                          background: "white",
                          color: "#F86E20",
                          border: "1px solid #F86E20",
                          alignItems: "center",
                          fontSize: "14px",
                        }}
                      >
                        {formik.values.status_name || "Select Status"}
                      </DropdownToggle>
                      <DropdownMenu style={{ width: "100%" }}>
                        <DropdownItem header>
                          <TextField
                            placeholder="Search"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                            size="small"
                            variant="outlined"
                            onChange={(e) => setStatusSearch(e.target.value)}
                          />
                        </DropdownItem>
                        <div style={{ maxHeight: 95, overflowY: "scroll" }}>
                          {filteredStatuses.map((el) => (
                            <DropdownItem
                              key={el.id}
                              onClick={() => {
                                formik.setFieldValue("status_name", el.status);
                                formik.setFieldValue("fk_status_id", el.id);
                              }}
                            >
                                {el.status}
                              </DropdownItem>
                            ))}
                        </div>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                   {formik.touched.fk_status_id && formik.errors.fk_status_id && (
                    <p
                      className="text-danger formik-addUser-msg text-small text-left formik-msg mt-2"
                      style={{
                        textAlign: "left",
                        marginLeft: "8px",
                        marginTop: "-4px",
                      }}
                    >
                      {formik.errors.fk_status_id}
                    </p>
                  )}
                </div>
                <div className="col-sm-4">
          <div style={{ margin: "0px 5px", width: "100%" }}>
            <UncontrolledDropdown style={{ width: "100%" ,  pointerEvents: isDisabled ? "none" :"auto"}}>
              <DropdownToggle
                caret
                style={{
                  width: "95%",
                  display: "flex",
                  justifyContent: "space-between",
                  paddingTop: "10px",
                  paddingBottom: "6px",
                  background: "white",
                  color: "#F86E20",
                  border: "1px solid #F86E20",
                  alignItems: "center",
                  fontSize: "14px",
                }}
              >
                {formik.values.credit_card_name || "Select Credit Card"}
              </DropdownToggle>
              <DropdownMenu style={{ width: "100%" }}>
                <DropdownItem header>
                  <TextField
                    placeholder="Search Credit Card"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    size="small"
                    variant="outlined"
                    onChange={(e) => setCreditCardSearch(e.target.value)}
                  />
                </DropdownItem>
                <div style={{ maxHeight: 95, overflowY: "scroll" }}>
                  {filteredCreditCard.map((el) => (
                    <DropdownItem
                      key={el.id}
                      onClick={() => {
                        formik.setFieldValue("credit_card_name", el.type);
                        formik.setFieldValue("fk_credit_card_type_id", el.id);
                      }}
                    >
                      {el.type}
                    </DropdownItem>
                  ))}
                </div>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
          {formik.touched.fk_credit_card_type_id && formik.errors.fk_credit_card_type_id && (
            <p
              className="text-danger formik-addUser-msg text-small text-left formik-msg mt-2"
              style={{
                textAlign: "left",
                marginLeft: "8px",
                marginTop: "-4px",
              }}
            >
              {formik.errors.fk_credit_card_type_id}
            </p>
          )}
        </div>
               
              </div>
            ) : (
              <div className="noRecordFound">No records found</div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreditCardApproveBank;
