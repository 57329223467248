import { Box, Container, makeStyles } from "@material-ui/core"
import Header from "../Header"
import Footer from "../Footer"
const useStyles = makeStyles((theme) => ({
    container:{
        padding: '80px 80px', textAlign: 'left' ,
        [theme.breakpoints.down('sm')]: {
       
            padding: '80px 40px',
           },
    },
    header: {
        fontSize: '25px',
        color: '#3A1C67',
        fontWeight: 700,
        padding: '20px 0px'
    },
    pinkSection: {
        color: '#B32B88',
        fontSize: '15px',
        fontWeight: 600
    },
    greySection:{
        color:'#848891'
    }
}))
const UsagePolicy = () => {
    const classes = useStyles()
    return (
        <div>
            <Header/>
        <Container maxWidth="lg" className={classes.container}>
            <Box className={classes.header}>Cancellation, <span style={{ color: '#F86E20' }}>and Refund Policy</span></Box>
            <Box className={classes.pinkSection}>
                Our watch-word is total customer satisfaction. In case you are not satisfied with the services provided, your money will be refunded, after due investigation and provided the reasons attributed by you are genuine. The fine print provides all relevant details about the Product or Services which you purchase, and we request you to go through the fine print carefully before your procurement.
                <div>In case you are displeased with our Product / Services, you have a right to cancel
                    your Order and request for a refund from us. Details of our Policy for cancellation
                    and refund will be as under:
                </div>
            </Box>

            <Box className={classes.header}>
                Cancellation Policy
            </Box>
            <Box className={classes.greySection}>
                <ul>
                    <li style={{paddingBottom:'20px'}}>For cancellation of your Order, please check in our App for “contact us” link, quoting a valid reason AND also by providing your unique reference number.</li>

                    <li style={{paddingBottom:'20px'}}>Please note that renewal after the validity period is NOT automatic, unless payment is made in advance, and the same is realized/received by our Company.</li>

                    <li style={{paddingBottom:'20px'}}>Request for renewal received later than 3 business days prior to the end of the current contract/service period will result in a cancellation of services for the next renewal/service period.</li>

                    <li>However, the Contract will be renewed within 3 working days on receipt of your Request and on receipt of specified Service Charges.</li>
                </ul>
            </Box>

            <Box className={classes.header}>
                Refund Policy
            </Box>
            <Box className={classes.greySection}>
            <p>We will try our best to create suitable design concepts for our Clients, within the frame of our Company Policy on the subject.</p>

<p>In any case, if you are not satisfied with our product/services, we will try our best to measure up to your level of expectation, and still, if dissatisfaction persists, we will provide a refund, within two weeks of agreement for such Refund.</p>

<p>The refund will be made in the same mode as we received the payment i.e. in case of payment :</p>
<div>1. through Credit Card, a refund will be made to the same credit card used at the time of purchase;</div>
<div>2. through Payment Gateway, a refund will be made to the same Bank account;</div>
<div>3. through Cheque / DD, a refund will be made by way of a crossed Cheque in your favour.</div>
            </Box>

            <Box style={{padding:'60px 0px'}}>
                <Box className={classes.pinkSection}>
                Note: For any further clarifications on the subject, we request you to seek clarification/s, in writing, before making your final decision to procure the product/services.
                </Box>
            </Box>
            <Box className={classes.greySection} style={{fontWeight:700}}>
            2022 © Callpal. All Rights Reserved-2024
            </Box>

        </Container>
        <Footer/>
        </div>
    )
}
export default UsagePolicy