import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, TablePagination } from "@material-ui/core";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsSubmitted } from "../../Reducers/loginSlice";

import { HashLink as Link } from "react-router-hash-link";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import { DashboardApi } from "../../Services/DashboardApi";
import moment from "moment";
import ConfirmationDrr from "./ConfirmationDrr";
import { DRRApi } from "../../Services/DRRApi";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../Utils/commonUtils";
const useStyles = makeStyles({
  mainContainer: {
    padding: "80px 30px 30px 30px",
    background: "white",

    "&: a": {
      textDecoration: "none !important",
    },
  },
  topCOntainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "30px",
  },
  heading: {
    color: "#3A1C67",
    fontSize: "18px",
    fontWeight: 700,
    textAlign: "left",
    marginLeft: 20,
  },
  button: {
    backgroundColor: "#3A1C67",
    color: "#fff",
    marginLeft: "10px",
    borderRadius: "5px",
    fontSize: "12px",
    padding: "5px 20px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#7916BD",
    },
  },
  displayCount: {
    padding: "0px",
    display: "flex",
  },
});

export default function DRRCalendar({calenderDates , openDRRTeamLead, showTargetdList}) {
  const loginInfo = getCookie("callpal-user");
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedDates, setSelectedDates] = useState([]);
  const [dateStatus, setdateStatus] = useState([]);
  // const events = [{ title: "Meeting", start: new Date() }];
  const [confirm, setConfirm] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentMonthDays, getCurrentMonthDays ] = useState();
  const [dateArr, setDateArr] = useState([]);
  // const [targetedList, setTargetedList] = useState(false);
  const navigate = useNavigate();



  const [totalOffDays, setTotalOffDays] = useState(0);
  const [totalWorkingDays, setTotalWorkingDays] = useState(
    moment().daysInMonth()
  );

  const handleDateClick = (info) => {
    const clickedDate = info.dateStr;
    const index = selectedDates.findIndex(
      (dateObj) => dateObj.date === clickedDate
    );
    const updatedIndex = dateArr.findIndex(
      (dateObj) => dateObj.date === clickedDate
    );
    // Copy dateArr to avoid state mutation
    const updatedDateArr = [...dateArr];

    if (index === -1) {
      setSelectedDates([...selectedDates, { date: clickedDate, title: "off" }]);
      if (updatedIndex === -1) {
        // If not found, add it to updatedDateArr
        updatedDateArr.push({ date: clickedDate, title: 'off' });
      } else {
        // If found, update its title to 'off'
        updatedDateArr[updatedIndex].title = 'off';
      }

    } else {
      const newSelectedDates = [...selectedDates];
      newSelectedDates.splice(index, 1);

      if (updatedIndex !== -1) {
        // If found, update its title to 'on'
        updatedDateArr[updatedIndex].title = 'on';
      }

      setSelectedDates(newSelectedDates);
    }

    // Count the total number of "off" and "on" titles
    const totalOffDays = updatedDateArr.filter(
      (dateObj) => dateObj.title === 'off'
    ).length;

    const totalWorkingDays = updatedDateArr.filter(
      (dateObj) => dateObj.title === 'on'
    ).length;
    // Set state for total off days and working days
    setTotalOffDays(totalOffDays);
    setTotalWorkingDays(totalWorkingDays);

    setDateArr(updatedDateArr);

  };


  

  const getAllDates = async ()=> {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const dates = [];
    const offDates = [];

    const res = await DRRApi.getCalendarList();
    const calendarData = res.data;
    calendarData.forEach((calendarItem) => {
      const date = calendarItem.date;
      // const title = selectedDates.some((selectedDate) => selectedDate.date === date)
      //   ? "off"
      //   : "on";
      const title = calendarItem.title;
      // Push the date and title to the dates array
      dates.push({ date, title });

      // if(calendarItem.title == 'off') {
      //   setSelectedDates([...selectedDates, { date: calendarItem.date, title: "off" }]);
      // }

      if (title === 'off') {
        offDates.push({ date, title });
      }

    });


    setSelectedDates(offDates);

    // Count the total number of "off" and "on" titles
    const totalOffDays = dates.filter(
      (dateObj) => dateObj.title === 'off'
    ).length;

    const totalWorkingDays = dates.filter(
      (dateObj) => dateObj.title === 'on'
    ).length;
    // Set state for total off days and working days
    setTotalOffDays(totalOffDays);
    setTotalWorkingDays(totalWorkingDays);
    setDateArr(dates);
  };

  useEffect(() => {
    //getCalendarList();
    getAllDates();
  }, []);



// const getCalendarList = async ()=> {
//   const res = await DRRApi.getCalendarList()
//   if(res.status){
//     getAllDates(res.data)
//   }
// }

  const submitRecord = async () => {
    setConfirm(false);
    try {
      const res = await DRRApi.submitCalendarStatus({
        days_status: dateArr,
      });
      if (res.status) {
        loginInfo.userInfo.fk_role_id == 2 ?     openDRRTeamLead(true) : navigate(`/dashboard/drr/tele_caller/${ btoa(loginInfo.userInfo.id)}`)
        dispatch(
          setIsSubmitted({
            open: true,
            severity: "success",
            msg: res.message,
          })
          
        );

        

      

        
    
       
          
        // setLoading(false);
        return false;
      }else{
        dispatch(
          setIsSubmitted({
            open: true,
            severity: "error",
            msg:res.message,
          })
        );
        // setLoading(false);
        return false;
      }
    } catch {
      dispatch(
        setIsSubmitted({
          open: true,
          severity: "error",
          msg:"Something went wrong !",
        })
      );
      
     
    }
  };

  const submitCalendarStatus = async () => {
    setConfirm(true);
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.topCOntainer}>
        <div style={{ fontWeight: 600, fontSize: "20px", color: "#F86E20" }}>
          Calendar
        </div>

        <div style={{ fontWeight: 600, fontSize: "20px" }}>
          Working days : {totalWorkingDays}
        </div>
        <div style={{ fontWeight: 600, fontSize: "20px" }}>
          Off days : {totalOffDays}
        </div>

        <div>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            // startIcon={<img src={historyIcon} alt='' width={20} />}
            onClick={() => submitCalendarStatus()}
          >
            Submit
          </Button>
        </div>
      </div>

      <FullCalendar
        showNonCurrentDates={false}
        headerToolbar={{
          // left: 'prev,next today myCustomButton',
          left: "",
          center: "title",
          // right: 'dayGridMonth,timeGridWeek,timeGridDay'
          right: "",
        }}
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        weekends={true}
        editable={true}
        selectable={true}
        dateClick={handleDateClick}
        // eventClick={handleEventClick}
        selectMirror={true}
        events={[
          // All dates with status "on"
          ...dateArr.map((date) => ({ title: "off", date: date })),
          // Selected dates with status "off"
          ...selectedDates.map((data) => ({
            title: "off",
            date: data.date,
            backgroundColor: "#e06767", // Background color for selected dates

            //label: 'Selected' // Label for selected dates
          })),
        ]}
        eventContent={(info) => {
          const status = info.event.title;
          const textColor = "white"; // Set text color to black
          return {
            html: `<div style="color: ${textColor}; width: 100%; height: 100%; text-align: center; ">${status}</div>`,
          };
        }}
        dayCellContent={(arg) => {
          // Customizing the cell content (date numbers)
          return {
            html: `<span style="color: black; text-decoration: none; ">${arg.dayNumberText}</span>`,
          };
        }}
        dayHeaderContent={(arg) => {
          // Customizing the day names
          return {
            html: `<span style="color: black; text-decoration: none;">${arg.text}</span>`,
          };
        }}
      />
      <ConfirmationDrr
        //  loading={deleteLoader}
        open={confirm}
        setOpen={(f) => setConfirm(f)}
        submitRecord={() => {
          submitRecord();
        }}
        totalOffDays={totalOffDays}
        totalWorkingDays={totalWorkingDays}
      />
    </div>
  );
}
