import React, { useState, useEffect } from "react";
import {
  SwipeableDrawer,
  TextField,
  Button,
  Typography,
  InputAdornment,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import UpdateIcon from "@material-ui/icons/Update";
import { makeStyles } from "@material-ui/core/styles";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import SearchIcon from "@material-ui/icons/Search";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { MasterDataApi } from "../../../Services/MasterDataApi";
import { useDispatch, useSelector } from "react-redux";
import { setIsSubmitted } from "../../../Reducers/loginSlice";
import { Box,   CircularProgress,  Container,  } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";


const AddAnnouncementDrawer = ({ open, onClose ,announcementAction, userEditId}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);


  useEffect(() => {
    if (announcementAction === "Edit") {
     editAnnouncementPopper(userEditId)
    }else{
      formik.setFieldValue("id",  "");
      formik.setFieldValue("announcement_name",  "");
      formik.setFieldValue("announcement_description",  "");
      // formik.setFieldValue("announcement_start_date",  moment().format("YYYY-MM-DD"));
      formik.setFieldValue("announcement_start_date", null );

    }

  }, [announcementAction, ]);



const editAnnouncementPopper  =async(userEditId)=> {
  setLoading(true)
  try {
    const res = await MasterDataApi.EditMasterDataAnnouncementApi(userEditId);
    if (res.status) {
      formik.setFieldValue("id", userEditId ? userEditId : "");
      formik.setFieldValue("announcement_name", res.data.announcement_name ? res.data.announcement_name : "");
      formik.setFieldValue("announcement_description", res.data.announcement_description ? res.data.announcement_description : "");
      formik.setFieldValue("announcement_start_date", res.data.announcement_start_date ? res.data.announcement_start_date : "");
      // setStartDate(res.data.announcement_start_date)
    } else {
      formik.resetForm({
        values: {
          id:"",
          announcement_name: "",
          announcement_description:"",
          announcement_start_date:""
        },
      });
     
      }
      setLoading(false);
  } catch (e) {
    setLoading(false);
    console.error(e);
  }
}


  const useStyles = makeStyles((theme) => ({
    // Existing styles remain the same
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: "70px",
      color: "#fff",
      fontSize: "17px",
      backgroundColor: "#3A1C67",
      fontWeight: 600,
      padding: "0 20px",
      width: "40rem",
    },
    iconButton: {
      color: "#fff",
      border: "1px solid orange",
      padding: "10px 30px",
    },
    textField: {
      "& label.Mui-focused": {
        color: "#F86E20", // Orange color for the label when the input is focused
      },
      "& .MuiInputBase-input": {
        color: "#F86E20", // Orange color for the input text
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#F86E20", // Orange border
          borderWidth: "2px", // Thicker border
        },
        "&:hover fieldset": {
          borderColor: "#F86E20", // Orange border on hover
        },
        "&.Mui-focused fieldset": {
          borderColor: "#F86E20", // Orange border when focused
          borderWidth: "2px", // Keeping the border thick when focused
        },
      },
      margin: theme.spacing(1),
      marginTop: 20,
    },

    buttonContainer: {
      display: "flex",
      justifyContent: "space-between",
      gap: 20,
    },
    buttonContainerNew: {
      display: "flex",
      margin: "20px 90px",
      justifyContent: "space-between",
    },
    saveButton: {
      backgroundColor: "#FA9510",
      color: "#fff",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#F76A1A",
      },
      width: "180px",
    },
    cancelButton: {
      backgroundColor: "#fff",
      border: "1px solid #848891",
      color: "#848891",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#E5E3E1",
      },
      width: "180px",
    },
  }));

  const classes = useStyles();

  const validationSchema = Yup.object().shape({
    announcement_name: Yup.string()
      .required("Announcement name is required"),
    announcement_description: Yup.string()
      .required("Announcement description is required"),
    announcement_start_date: Yup.string()
      .required("Announcement start date is required")
  });

  const formik = useFormik({
    initialValues: {
      id: "",
      announcement_name: "",
      announcement_description: "",
      announcement_start_date:"",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      // setLoading(true);
      try {
        const data = await MasterDataApi.UpdateMasterDataAnnouncement(values);
        if (data.status) {
          dispatch(
            setIsSubmitted({
              open: true,
              severity: "success",
              msg: data.message,
            })
          );
          onClose(false);
          resetForm();
          setLoading(false);
        } else { 
          if (data.hasOwnProperty("errors")) {
            formik.setFieldError("announcement_name", data.errors.announcement_name[0]);
            formik.setFieldError("announcement_description", data.errors.announcement_description[0]);
            formik.setFieldError("announcement_start_date", data.errors.announcement_start_date[0]);
          } else {
            dispatch(
              setIsSubmitted({
                open: true,
                severity: "error",
                msg: data.message,
              })
            );
          }
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        console.error(e);
      }
    },
  });

  const handleDateChange = (selectedDate) => {
    const date = moment(selectedDate).format("YYYY-MM-DD")
    setStartDate(date);
    formik.setFieldValue("announcement_start_date", date);
  };

  return (

    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={onClose}
      onOpen={() => {}}
    >
        
      <div className={classes.header}>
        <Typography variant="h6">  {announcementAction === "Edit" ? "Edit" : "Add" } Announcement</Typography>
        <div className={classes.buttonContainer}>
        {/* <Button type="submit" className={classes.iconButton} onClick={formik.handleSubmit}>
            <UpdateIcon style={{ marginRight: "1.2rem" }} />   { announcementAction === "Edit" ?  "Update" : "Save" }
          </Button> */}
        </div>
      </div>

      <form onSubmit={formik.handleSubmit}>
      <div className="row">
         <div className="col-sm-12">
      <TextField
        label="Enter Announcement Name"
        variant="outlined"
        className={classes.textField}
        multiline
        minRows={1}
        name="announcement_name"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.announcement_name}
        style={{width:"97%"}}
      />
      {formik.touched.announcement_name &&
        formik.errors.announcement_name && (
          <p className="text-danger formik-masterdata-msg text-small text-left formik-msg ">
            {formik.errors.announcement_name}
          </p>
     )}
     </div>
     <div className="col-sm-12">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                className={classes.textField}
                margin="normal"
                id="date-picker-inline"
                //   label="Start Date"
                autoOk // Automatically close the date picker after selection
                // value={startDate}
                value={formik.values.announcement_start_date}
                // onChange={(date)=> handleDateChange(moment(date).format("YYYY-MM-DD"))}
                onChange={handleDateChange}
                name="announcement_start_date"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                InputProps={{
                  style: {
                    fontSize: 14,
                    height: 34,
                  },
                }}
                style={{ marginLeft:7, width:"97%", background: "white" }}
              />
            </MuiPickersUtilsProvider>

      {formik.touched.announcement_start_date &&
          formik.errors.announcement_start_date && (
            <p className="text-danger formik-masterdata-msg text-small text-left formik-msg ">
              {formik.errors.announcement_start_date}
            </p>
      )}

    </div>
    </div>


<TextField
        label="Enter Announcement Description"
        variant="outlined"
        className={classes.textField}
        multiline
        minRows={1}
        name="announcement_description"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.announcement_description}
        style={{width:"97%"}}
        rows={4}
      />
      {formik.touched.announcement_description &&
        formik.errors.announcement_description && (
          <p className="text-danger formik-masterdata-msg text-small text-left formik-msg ">
            {formik.errors.announcement_description}
          </p>
      )}


     
     
      {loading && (
         <div style={{ textAlign: "center" }}>
         <CircularProgress size={50} />
       </div>
      )}

    <div className="btns_add_user">
              <div className={classes.buttonContainerNew}>
                <Button type="submit" className={classes.saveButton}>
                  {loading ? (
                    <CircularProgress size={20} />
                  ) : announcementAction == "Edit" ? (
                    "Update"
                  ) : (
                    "Save"
                  )}
                </Button>
                <Button
                  className={classes.cancelButton}
                  onClick={() => onClose(false)}
                >
                  Cancel
                </Button>
              </div>
            </div>
    </form>
    </SwipeableDrawer>
  );
};

export default AddAnnouncementDrawer;
