import axios from "axios";
import { dispatchAuthActions } from "../Utils/dispatchActions";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    config["headers"]["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (res) {
    let data;
    if (res.data.status) {
        data = res.data;
    }
    return data;
  },
  function (error) {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      dispatchAuthActions(); // Dispatch actions using the utility function
      window.location.href = "/";
    }
      
  }
);

export default api;
