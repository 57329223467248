import { Box,  makeStyles } from "@material-ui/core";
import logo from "../Images/Logo.png";
import linkedin from "../Images/linkedin.png";
import youtube from "../Images/youtube.png";
import Facebook from "../Images/Facebook.png";
import Instagram from "../Images/Instagram.png";
import { routes } from "../constants/routes";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    position: "relative",
    right: 0,
    bottom: 0,
    zIndex: 1000,
    transition: "opacity 0.8s, transform 0.8s, visibility 0s 1s",
    left: 0,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    height: "320px",
    flex: 1,
    color: "#3A1C67",
    justifyContent: "space-around",
    background: "#f0f3f7",
  },
  container: {
    display: "flex",
    justifyContent: "space-around",
  },
  footerColumn: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
  },
  heading: {
    fontSize: "16px",
    fontWeight: "700",
    marginTop: "50px",
    marginBottom: "10px",
  },
  subText: {
    fontSize: "11px",
    fontWeight: "400",
    color: "#848891",
    marginBottom: "10px",
  },
  footerContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "25px",
  },
  footerFlex: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: "600",
  },
  logo: {
    width: "40px",
    marginTop: "10px",
    marginBottom: "10px",
  },
  socialMedia: {
    width: "20px",
    margin: "10px",
  },
  in: {
    width: "15px",
    margin: "10px",
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.mainContainer}>
      <Box className={classes.container}>
        <div className={classes.footerColumn}>
          <span className={classes.heading}>Product</span>
          <span className={classes.subText}>
            <Link
              style={{ cursor: "pointer", color: "#848891", fontSize: "11px" }}
              to={routes.postCrm}
            >
              POS Field
            </Link>
          </span>
          <span className={classes.subText}>
            <Link
              style={{ cursor: "pointer", color: "#848891", fontSize: "11px" }}
              to={routes.fieldCrm}
            >
              CRM Field
            </Link>
          </span>
          <span className={classes.subText}>
            <Link
              style={{ cursor: "pointer", color: "#848891", fontSize: "11px" }}
              to={routes.callManagement}
            >
              Call Management
            </Link>
          </span>
        </div>
        <div className={classes.footerColumn}>
          <span className={classes.heading}>Explore</span>
          <span className={classes.subText}>
            <Link
              style={{ cursor: "pointer", color: "#848891", fontSize: "11px" }}
              to={routes.pricing}
      
            >
              Price
            </Link>
          </span>
          <span className={classes.subText}>
            <Link
              style={{ cursor: "pointer", color: "#848891", fontSize: "11px" }}
              to={routes.blogs}
            
            >
              Blogs
            </Link>
          </span>
        </div>
        <div className={classes.footerColumn}>
          <span className={classes.heading}>Privacy</span>
          <span className={classes.subText}>
            <Link
              style={{ cursor: "pointer", color: "#848891", fontSize: "11px" }}
              to={routes.usagePolicy}
            
            >
              Usage Policy
            </Link>
          </span>
          <span className={classes.subText}>
            <Link
              style={{ cursor: "pointer", color: "#848891", fontSize: "11px" }}
              to={routes.privacyPolicy}
            
            >
              Privacy Policy
            </Link>
          </span>
          <span className={classes.subText}><Link
              style={{ cursor: "pointer", color: "#848891", fontSize: "11px" }}
              to={routes.terms}
            >
              Terms & Conditions
            </Link></span>
        </div>
        <div className={classes.footerColumn}>
          <span className={classes.heading}>Company</span>
          <span className={classes.subText}>
            <Link
              style={{ cursor: "pointer", color: "#848891", fontSize: "11px" }}
              to={routes.partners}
            
            >
              About Us
            </Link>
          </span>
          <span className={classes.subText}>
            <Link
              style={{ cursor: "pointer", color: "#848891", fontSize: "11px" }}
              to={routes.partners}
            >
              Partners
            </Link>
          </span>
          <span className={classes.subText}>
            <Link
              style={{ cursor: "pointer", color: "#848891", fontSize: "11px" }}
              to={routes.contactUs}
            >
              Contact Us
            </Link>
          </span>
        </div>

        {/* <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '40%',
            justifyContent: 'center',
          }}
        >
          <Link style={{ padding: '0px 12px', cursor: 'pointer' }} href='#'>
            Product
          </Link>
          <Link
            style={{ padding: '0px 12px', cursor: 'pointer' }}
            to={routes.pricing}
            href='#/pricing'
          >
            Price
          </Link>
          <Link style={{ padding: '0px 12px', cursor: 'pointer' }}>
            Privacy
          </Link>
          <Link
            style={{ padding: '0px 12px', cursor: 'pointer' }}
            href='#/contact-us'
          >
            Contact Us
          </Link>
        </Box>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '30%',
            justifyContent: 'center',
            fontSize: '12px',
          }}
        >
          @ 2023 Callpal, All rights reserved
        </Box> */}
      </Box>
      <Box className={classes.footerContainer}>
        <div className={classes.footerFlex}>
          <img src={logo} loading="eager" alt="" className={classes.logo} />
          <span>2024 © Callpal Tech Private Limited. All Rights Reserved</span>
          <div>
            <img
              src={Facebook}
              loading="eager"
              alt=""
              className={classes.socialMedia}
            />
            <img src={linkedin} loading="eager" alt="" className={classes.in} />

            <img
              src={Instagram}
              loading="eager"
              alt=""
              className={classes.socialMedia}
            />
            <img
              src={youtube}
              loading="eager"
              alt=""
              className={classes.socialMedia}
            />
          </div>
        </div>
      </Box>
    </div>
  );
};
export default Footer;
