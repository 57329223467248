import { Box, makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: "30px",
    color: "#3A1C67",
    fontWeight: 700,
  },
  subHeading: {
    fontSize: "18px",
    color: "#848891",
    margin:"150px 15px 0px 25px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 20px",
    },
  },
  img: {
    width: "650px",
    height:"677px"
  },
}));
const FlexCardLayout = ({img,title}) => {
  const classes = useStyles();
  return (
    <div style={{display:"flex", margin:"20px 10px 30px 20px"}}>
        <img loading="eager" src={img} alt="" className={classes.img}/>
        <Box className={classes.subHeading}>
                {title}
              </Box>
   
    </div>
  );
};
export default FlexCardLayout;
