import {
  Box,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputBase,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import { OrangeCheckbox, OrangeSmallCheckbox } from "../../../Styles";
import updateIcon from "../../../Images/history 2.svg";
import { useEffect, useState } from "react";
import { getCookie } from "../../../Utils/commonUtils";

import { useDispatch, useSelector } from "react-redux";
import { setIsSubmitted } from "../../../Reducers/loginSlice";
import { useParams } from "react-router-dom";
import { json, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import constantConfig from "../../../constants/constantConfig";
import LoginBank from "../Customers/LoanComponent/LoginBank";
import SanctionBank from "../Customers/LoanComponent/SanctionBank";
import DisbursementBank from "../Customers/LoanComponent/DisbursementBank";
import RejectedBank from "../Customers/LoanComponent/RejectedBank";
import { BankApi } from "../../../../src/Services/BankApi";
import NoRecords from "../Other-components/NoRecords";
import ConfirmationPopper from "../Other-components/ConfirmationPopper";
import CustomerQD from "../Customers/CreditCardComponent/CustomerQD";
import CustomerPunchOrLoginBank from "../Customers/CreditCardComponent/CustomerPunchOrLoginBank";
import CreditCardApproveBank from "../Customers/CreditCardComponent/CreditCardApproveBank";
import CustomerRejectedBank from "../Customers/CreditCardComponent/CustomerRejectedBank";
import CustomerKYC from "./CreditCardComponent/CustomerKYC";
import CreditCardDispatch from "./CreditCardComponent/CreditCardDispatch";
import { CustomerCreditCardApi } from "../../../Services/CustomerCreditCardApi";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "80px 30px 20px 30px",
  },
  heading: {
    color: "#3A1C67",
    fontSize: "18px",
    fontWeight: 700,
    textAlign: "left",
    marginLeft: 20,
  },
  sectionContainer: {
    display: "flex",
  },
  section1: {
    // container height
    // height: 500,
    height: 275,
    backgroundColor: "#fff",
    margin: 10,
    width: "100%",
    borderRadius: 10,
  },
  sectionHeading: {
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 16,
    backgroundColor: "#3A1C67",
    height: 50,
    padding: "0px 30px",
    textAlign: "left",
    fontWeight: 700,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  historyContainer: {
    padding: 15,
    height: 450,
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "100%",
      display: "block",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "darkgrey",
      outline: "1px solid slategrey",
      borderRadius: "5px",
    },
  },
  card: {
    width: "100%",
    paddingBottom: 10,
    backgroundColor: "#F1F1F1",
    textAlign: "left",
    fontSize: 12,
    color: "#FA9510",
    boxShadow:
      "rgba(0, 0, 0, 0.22) 0px 1px 5px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
  },
  dot: {
    height: 10,
    width: " 100%",
    border: "1px solid #FA9510",
    borderRadius: "50%",
  },
  indicator: {
    borderLeft: "2px solid #848891",
    height: 60,
    marginLeft: 4,
  },
  saveButton: {
    backgroundColor: "#FA9510",
    color: "#fff",
    textTransform: "none",
    width: "100%",
    height: "30px",
    // borderRadius: "10px",
    fontSize: "14px",
    marginLeft: 10,
    "&:hover": {
      backgroundColor: "#FA7A21",
    },
  },
  formContainer: {
    padding: 15,
    width: "100%",
    // height: 450,
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "100%",
      display: "block",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "darkgrey",
      outline: "1px solid slategrey",
      borderRadius: "5px",
    },
  },
  button: {
    backgroundColor: "#3A1C67",
    color: "#fff",
    marginLeft: "10px",
    borderRadius: "5px",
    fontSize: "12px",
    padding: "5px 20px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#7916BD",
    },
  },
  statusButton: {
    backgroundColor: "#fff",
    color: "#3A1C67",
    textTransform: "none",
    height: "35px",
    borderRadius: "5px",
    border: "1px solid #3A1C67",
    fontSize: "14px",
    marginBottom: 5,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  root: {
    "& label.Mui-focused": {
      color: "#F86E20",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#F86E20",
      },
      "&:hover fieldset": {
        borderColor: "#F86E20",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#F86E20",
      },
    },
    margin: "5px 5px",
    width: "100%",
    // [theme.breakpoints.down('xs')]: {
    //     width:'110'
    //   },
  },
  multilineColor: {
    color: "#F86E20",
    fontSize: 14,
  },
}));
const CustomerBank = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loginInfo = getCookie("callpal-user");
  const [loading, setLoading] = useState(false);
  const [bankList, setBankList] = useState([]);
  const [bankSearch, setBankSearch] = useState("");
  const [statusSearch, setStatusSearch] = useState("");
  const [statusLoginArr, setLoginStatusArr] = useState([]);
  const [statusSectionArr, setSanctionStatusArr] = useState([]);
  const [statusDisbursementArr, setDisbursementStatusArr] = useState([]);
  const [statusRejectedArr, setStatusRejectedArr] = useState([]);
  const [statusDetails, setStatusDetails] = useState({
    id: "",
    name: "",
  });
  // const [rows, setRows] = useState([{ bank_name: "", status: "", amount: "" }]);
  const params = useParams();
  const leadId = atob(params.leadId);
  const [firstSection, setFirstSection] = useState([
    {
      fk_lead_id: leadId,
      fk_bank_id: "",
      fk_bank_name: "",
      fk_status_id: "",
      fk_status_name: "",
      login_amount: "",
      sanction_amount: "",
      is_sanction: false,
      status: [],
    },
  ]);
  const [secondSection, setSecondSection] = useState([]);
  const [thirdSection, setThirdSection] = useState([]);
  const [fourthSection, setForthSection] = useState([]);
  const [updated, setUpdated] = useState(true);
  const [isComponentRender, setIsComponentRender] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [currentProductDetail, setCurrentProductDetal] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [isProductEnable, setIsProductEnable] = useState(true);
  const navigate = useNavigate();
  const loginIndSubType = loginInfo.userInfo.ind_sub_type
  const indSubType = constantConfig.INDUSTRY_SUB_TYPE;
  // --------------------------------------------------------------------------------
  const [creditCardList, setCreditCardList] = useState({});

  const [hasFetchedCreditCardData, setHasFetchedCreditCardData] = useState(false);
  const [status, setStatus] = useState({});
  const [creditCardQD, setCreditCardQD] = useState({});
  const [creditCardPunchLogin, setCreditCardPunchLogin] = useState({});
  const [creditCardApprove, setCreditCardApprove] = useState({});
  const [creditCardKYC, setCreditCardKYC] = useState({});
  const [creditCardDispatched, setCreditCardDispatched] = useState({});
  const [creditCardRejected, setCreditCardRejected] = useState({});

  // --------------------------------------------------------------------------------

  // Call getBankList on mount
   useEffect(() => {
    getBankList();
  }, []);

  // Handle updated and indSubType changes
  useEffect(() => {
    if (updated && loginIndSubType !== indSubType.CREDIT_CARD) {
      getLeadBankRecord();
      setUpdated(false);
    }
  }, [updated]);

  // Handle fetching credit card data
  useEffect(() => {
    if (!hasFetchedCreditCardData && loginIndSubType === indSubType.CREDIT_CARD) {
      creditCardTypeList();
      creditCardRecord();
      setHasFetchedCreditCardData(true);
    }
  }, [hasFetchedCreditCardData]);

  const getLeadBankRecord = async () => {
    try {
      setLoading(true);
      const res = await BankApi.getLeandBankDetails(leadId);
      if (res.status) {
        setCustomerName(res.data.name);
        setCurrentProductDetal(res.data.current_product);
        setIsProductEnable(res.data.is_product_enable);
        if (res.data.get_lead_login_bank.length > 0) {
          setFirstSection(res.data.get_lead_login_bank);
        }
        setSecondSection(res.data.get_lead_sanction_bank);
        setThirdSection(res.data.get_lead_disbursement_bank);
        setForthSection(res.data.get_lead_rejected_bank);
        const loginStatusArray = Object.entries(res.data.status).map(
          ([id, status]) => ({ id, status })
        );
        setLoginStatusArr(loginStatusArray);
        setIsComponentRender(true);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const getBankList = async () => {
    try {
      setLoading(true);
      const data = await BankApi.getBankList();
      if (data.status) {
        const bankListArrArray = Object.entries(data.data).map(
          ([id, bank]) => ({ id, bank })
        );
        setBankList(bankListArrArray);
      }
    } catch (e) {
      console.log(e.message);
      setLoading(false);
    }
  };

  const submitCloseFile = async () => {
    try {
      setUpdated(false);
      setDeleteLoader(true);
      const res = await BankApi.leadFileClose(leadId);
      setDeleteLoader(false);
      setConfirm(false);
      if (res.status) {
        dispatch(
          setIsSubmitted({ open: true, severity: "success", msg: res.message })
        );
        navigate(-1);
        //navigate(`/dashboard/customer`);
      } else {
        dispatch(
          setIsSubmitted({ open: true, severity: "error", msg: res.message })
        );
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Loan -------------------------------------------------------------------

  const creditCardTypeList = async () => {
    try {
      const res = await CustomerCreditCardApi.getCreditCardList();

      if (res.status) {
        setCreditCardList(res.data)
      }
    } catch (error) {
      console.error("Error in creditCardTypeList:", error);
      return false;
      //throw error; // Re-throw the error to handle it at the calling site if needed
    }
  };



  const creditCardRecord = async () => {
    try {
      setLoading(true);
      const res = await CustomerCreditCardApi.getCreditCardRecord(leadId);
      if (res.status) {
        if(res.data.get_lead_qd){

          setStatus(res.data.get_lead_qd.status)

        }else{
          setStatus(res.data.status)
        }
        setCustomerName(res.data.name);
        setCreditCardQD(res.data.get_lead_qd);
        setCreditCardPunchLogin(res.data.get_lead_punch_login);
        setCreditCardApprove(res.data.get_lead_approve);
        setCreditCardKYC(res.data.get_lead_kyc);
        setCreditCardDispatched(res.data.get_lead_dispatched);
        setCreditCardRejected(res.data.get_lead_rejected);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };


  const saveCreditCardRecord = async (values) => { 
    
   
    try {
       const data = JSON.stringify({
        fk_lead_id: leadId,
        fk_status_id:values ? values.fk_status_id : "",
        fk_bank_id:values ? values.fk_bank_id : "",
        fk_credit_card_type_id:values ? values.fk_credit_card_type_id : "",
       })
      setDeleteLoader(true);
      const res = await CustomerCreditCardApi.UpdateCreditCardRecord(data);
      setDeleteLoader(false);
      if (res.status) {
        
        creditCardRecord();
        dispatch(setIsSubmitted({ open: true, severity: 'success', msg: (res.message) }))

      } else {
        alert(leadId);
        dispatch(setIsSubmitted({ open: true, severity: 'error', msg: res.message }));
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    } finally {
      setLoading(false);
    }
  }



  // Loan End ---------------------------------------------------------------

  return (
    <div>
      <div className={classes.mainContainer}>
        <div className={classes.heading}>
          <div className="col-12 row">
            <div className="col-9">Bank (Customer : {customerName})</div>
            <div className="col-2">
              <Button
                type="submit"
                className={classes.saveButton}
                startIcon={<img src={updateIcon} width="100%" alt="" />}
                onClick={() => {
                  setConfirm(true);
                }}
              >
                Close File
              </Button>
            </div>
            <div className="col-1">
              <Button
                className={classes.button}
                style={{ float: "right" }}
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
            </div>
          </div>
        </div>
        {loginIndSubType === indSubType.CREDIT_CARD ? (
             <>
             <CustomerQD
               bankList={bankList}
               status={status}
               creditCardQD={creditCardQD}
               isDisabled={!creditCardPunchLogin ? true : false}
               saveCreditCardRecord={saveCreditCardRecord}
             />
           <CustomerPunchOrLoginBank
             bankList={bankList}
             creditCardPunchLogin={creditCardPunchLogin}
             creditCardList={creditCardList}
             isDisabled={(creditCardApprove || creditCardRejected) ? true : false}
             saveCreditCardRecord={saveCreditCardRecord}
           />
           <CreditCardApproveBank
            bankList={bankList}
            creditCardApprove={creditCardApprove}
            creditCardList={creditCardList}
            isDisabled={(creditCardKYC || creditCardRejected) ? true : false}
            saveCreditCardRecord={saveCreditCardRecord}
           />
           <CustomerKYC
            bankList={bankList}
            creditCardKYC={creditCardKYC}
            creditCardList={creditCardList}
            isDisabled={(creditCardDispatched || creditCardRejected) ? true : false}
            saveCreditCardRecord={saveCreditCardRecord}
           />
             <CreditCardDispatch
              bankList={bankList}
              creditCardDispatched={creditCardDispatched}
              creditCardList={creditCardList}
              saveCreditCardRecord={saveCreditCardRecord}
           />
           <CustomerRejectedBank
             bankList={bankList}
             creditCardRejected={creditCardRejected}
             creditCardList={creditCardList}
             saveCreditCardRecord={saveCreditCardRecord}
           />    
         </>
        
        ) : (
          
          <>
          {isComponentRender && !loading && (
            <LoginBank
              bankList={bankList}
              statusLoginArr={statusLoginArr}
              firstBankSection={firstSection}
              setUpdated={(f) => setUpdated(f)}
              currentProductDetail={currentProductDetail}
              isProductEnable={isProductEnable}
            />
          )}

          {isComponentRender && !loading && (
            <SanctionBank
              bankList={bankList}
              statusSectionArr={statusSectionArr}
              secondBankSection={secondSection}
            />
          )}

          {isComponentRender > 0 && !loading && (
            <DisbursementBank
              bankList={bankList}
              statusDisbursementArr={statusDisbursementArr}
              thirdBankSection={thirdSection}
            />
          )}

          {isComponentRender > 0 && !loading && (
            <RejectedBank
              bankList={bankList}
              statusRejectedArr={statusRejectedArr}
              fourthBankSection={fourthSection}
            />
          )}
        </>
       
        )}
        `
        <div
          className="col "
          style={{ marginLeft: " 400px", marginTop: " 150px" }}
        >

          <ConfirmationPopper
            loading={deleteLoader}
            open={confirm}
            setOpen={(f) => setConfirm(f)}
            deleteUser={() => {
              submitCloseFile();
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default CustomerBank;
