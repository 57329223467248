import {
    Button,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    makeStyles,
    
    
  } from "@material-ui/core";
  import filterIcon from "../../Images/Filter 1.svg";
  import { useState } from "react";
  import { StyledTableCell, StyledTableRow } from "../../Styles";
  import React from "react";
  import { Pending } from "@mui/icons-material";
  const useStyles = makeStyles((theme) => ({
   
    heading: {
      color: "#3A1C67",
      fontSize: "18px",
      fontWeight: 700,
      textAlign: "left",
      marginLeft: 20,
    },
    button: {
      backgroundColor: "#fff",
      color: "#3A1C67",
      textTransform: "none",
      height: "35px",
      borderRadius: "10px",
      border: "1px solid #3A1C67",
      fontSize: "14px",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
      "&:hover": {
        backgroundColor: "#fff",
      },
    },
    statusBox: {
      width: 200,
      backgroundColor: "#fff",
      borderRadius: 10,
      color: "#3A1C67",
      fontWeight: 600,
      padding: 10,
      marginRight: 30,
      cursor: "pointer",
    },
    boxContainer: {
      display: "flex",
    },
    tableContainer: {
      margin: "20px 0px",
      maxHeight: 350,
      width: "100%",
      "&::-webkit-scrollbar": {
        width: "0.5em",
        display: "block",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "darkgrey",
        outline: "1px solid slategrey",
        borderRadius: "5px",
      },
    },
  }));
  const FollowUpCount = ({searchFilter, todayCount, pendingCount, upcomingCount, pendingTitle, upcomingTitle, todayTitle}) => {
    const classes = useStyles({});
    const [status, setStatus] = useState("");
    const [btnColor, setBtnColor] = useState("");
    

    const filterStatus = (statusTitle) => {
      if(statusTitle == status) {
        setStatus("");
        searchFilter("");
      } else {
        setStatus(statusTitle);
        searchFilter(statusTitle);
      }
    }    
  
    
    
    return (
      <div className={classes.mainContainer}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 20,
          }}
        >
          <div className={classes.heading}>Follow-Up</div>
          {/* <div>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              startIcon={<img src={filterIcon} alt="" />}
              //onClick={()=>setOpen(true)}
            >
              Filter
            </Button>
          </div> */}
        </div>
        <div className={classes.boxContainer}>
          <div
            className={classes.statusBox}
            
             onClick={() => filterStatus(pendingTitle)}
            style={{
              border: status === pendingTitle && "",
              boxShadow:
                status === pendingTitle && "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
            }}
          >
            <div>Pending</div>
            <div style={{ fontSize: 18, paddingTop: 5 }}> {pendingCount} </div>
          </div>
          <div
            className={classes.statusBox}
            style={{
              border: status === todayTitle && "",
              boxShadow:
                status === todayTitle && "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
              backgroundColor: {btnColor}
            }}
            onClick={() => filterStatus(todayTitle)}
          >
            <div>Today's</div>
            <div style={{ fontSize: 18, paddingTop: 5 }}>  {todayCount}</div>
          </div>
          <div
            className={classes.statusBox}
            style={{
              border: status === upcomingTitle && "",
              boxShadow:
                status === upcomingTitle && "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
            }}
            onClick={() => filterStatus(upcomingTitle)}
          >
            <div>Upcoming</div>
            <div style={{ fontSize: 18, paddingTop: 5 }}>{upcomingCount }</div>
          </div>
        </div>
  

      </div>
    );
  };
  export default FollowUpCount;
  