import { Box, Button,  TextField, makeStyles, CircularProgress,InputAdornment } from "@material-ui/core"
import {  useEffect, useState } from "react";
import logImg from "../../Images/login1.png"
import logo from '../../Images/Logo.png'
import {  useNavigate } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import { routes } from "../../constants/routes";
import { useDispatch} from "react-redux";
import {  setIsSubmitted } from "../../Reducers/loginSlice";
import { setIsAuthenticated, setUserInfo } from "../../Reducers/loginSlice";
import { setCookie } from "../../Utils/commonUtils";
import { useFormik } from "formik";
import viewIcon from "../../Images/eye.svg";
import eyeCross from "../../Images/eye-cross.svg";
import * as Yup from "yup";
import IndustrySubTypePopper from "./IndustrySubTypePopper";
const useStyles = makeStyles((theme) => ({
    container: {

        display: 'flex',
        height: 'auto',
        textAlign: 'left',
        height: '100vh',
        [theme.breakpoints.down('sm')]: {
            padding: '20px'
        },
    },
    section1: {
        paddingTop: '20px',
        width: '75%',
        padding: '0px 120px',
        justifyContent: 'center',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            width: '100%',

        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            padding: '30px'
        },
    },
    section2: {
        width: '25%',
        backgroundColor: '#3A1C6733',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },

    root: {

        '& label.Mui-focused': {
            color: '#3A1C67',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#3A1C67',

            },
            '&:hover fieldset': {
                borderColor: '#3A1C67',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#3A1C67',
            },
        },
        margin: '10px 0px',
        width: '370px',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
    },
    multilineColor: {
        color: '#3A1C67'
    },
    eyeicon:{
        cursor:"pointer"
      },
    button: {
        backgroundColor: '#FA9510',
        color: '#fff',
        textTransform: 'none', marginTop: '20px',
        width: '370px',
        height: '45px',
        borderRadius: '10px',
        fontSize: '16px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
        transition: '.4s all ease-in-out',
        '&:hover': {
            backgroundColor: '#FA9510',
            boxShadow: '-2px 7px 8px #575f6b80'
        }
    },
    form: {
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',

        padding: '30px 60px 30px 0px',
        textAlign: 'left',
        paddingRight: '60px',
        [theme.breakpoints.down('sm')]: {
            padding: '20px 0px'
        },
    },
    image: {
        position: 'absolute',
        right: '20%',
        width: '500px',
        height: 'auto'
    },
    error: {
        fontSize: 12,
        color: 'red',
        paddingTop: '20px'
    }
}))
const SignIn = () => {
    const classes = useStyles();
    const [login, setLogin] = useState(false);
    const [showPassword, setShowPassword] = useState(true);
    const [industrySubTypeArry, setIndustrySubTypeArry] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [userInfoUpdate, setUserInfoUpdate] = useState();

    const [successLoginMsg, setSuccessLoginMessage] = useState("");


    const navigate = useNavigate();
    const dispatch = useDispatch();
    const validateEmail = (email) => {
        let result=Yup.number().integer().positive().test(
            (email) => {
              return (email && email.toString().length ===10) ? true : false;
            }
          ).isValidSync(email) ||(!email.includes('@') );
          if(!result){
            result=Yup.string().email().isValidSync(email)
          }
          return result
     };
     
     const validatePhone = (phone) => {
        let result=Yup.string().email().isValidSync(phone)
        if(!result){
            result= Yup.number().integer().positive().test(
           (phone) => {
             return (phone && phone.toString().length ===10) ? true : false;
           }
         ).isValidSync(phone);
        }
        return result
     };

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string()
            .required('Email / Phone Number is required')
            .max(255,'Email / Phone Number less than 30 characters')
            .test('email_or_phone', 'Invalid Email Id', (value) => {
                return validateEmail(value);
             
             })
            .test('email_or_phone', 'Invalid Phone Number', (value) => {
               return validatePhone(value);
            
            }),
            password: Yup.string()
                .min(8, 'Password must be at least 8 characters')
                .max(20,'Password should be  less than 20 characters')
                .required('Password is required'),
        }),
        onSubmit: async (values, { setSubmitting, setFieldError }) => {
            let payload = values;
            if(Number(values.email)){
                payload={mobile_number:values.email,password:values.password}
            }
            try {
                // Your API call here
                const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/login`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload)
                });
                const data = await response.json();
                if (data.status) {
                    const obj = {
                        email: data.user.email,
                        token: data.token,
                        userInfo: data.user
                    };
                    if(data.is_both){
                        setIndustrySubTypeArry(data.industry_sub_type_array);
                        setIsOpen(true)
                        setUserInfoUpdate(obj);
                        setSuccessLoginMessage(data.message);
                        return false;
                    }else{
                        navigate(`/dashboard${routes.dashboard1}`);
                    }
                    
                    // dispatch(setIndustry(data.user.industry_type)); // Dispatch the industry type
                    setCookie('callpal-user', JSON.stringify(obj), 1);
                    dispatch(setIsAuthenticated(true));
                    dispatch(setUserInfo(obj));
                    localStorage.setItem("token", data.token);
                    // navigate(`/dashboard${routes.dashboard1}`);
                    dispatch(
                        setIsSubmitted({
                          open: true,
                          severity: "success",
                          msg: data.message,
                        }),
                      );
                } else {
                    localStorage.removeItem("refreshToken");
                    localStorage.clear();
                    if (data.message === 'validation error') {
                        
                        if(data?.errors?.username[0]) {
                            setFieldError('email', data.errors.username[0]);
                        }
                    } else {
                        if(data.user_exist){
                            setFieldError('password', data.message);
                        }else{
                        setFieldError('email', data.message);
                        }
                        dispatch(setIsSubmitted({ open: true, severity: 'error', msg: data.message }));
                        if(!data?.user_exist) {
                            setTimeout(()=>{
                                navigate(routes.signUp);
                            },[6000])
                          
                        } 
                    }
                   
                
                    dispatch(setIsAuthenticated(false));
                    dispatch(
                        setIsSubmitted({
                          open: true,
                          severity: "error",
                          msg: data.message,
                        }),
                      );
                }
            } catch (e) {
              //  formik.setFieldError("mobile_number", data.errors.mobile_number[0]);
                dispatch(setIsSubmitted({ open: true, severity: 'error', msg: 'Something went wrong!' }));
            } finally {
                setSubmitting(false);
            }
        },
    });

    useEffect(()=>{
        formik.resetForm()
        setIsOpen(false)
    },[])

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
      };    


     const updateUserIndustrySubType =(data)=>{

        userInfoUpdate.userInfo.industry_sub_type = data.industry_sub_type;
        userInfoUpdate.userInfo.industry_sub_type_name = data.industry_sub_type_name;
        userInfoUpdate.userInfo.ind_sub_type = data.ind_sub_type;
        setCookie('callpal-user', JSON.stringify(userInfoUpdate), 1);
        dispatch(setIsAuthenticated(true));
        dispatch(setUserInfo(userInfoUpdate));
        localStorage.setItem("token", userInfoUpdate.token);
        navigate(`/dashboard${routes.dashboard1}`);
        dispatch(
            setIsSubmitted({
              open: true,
              severity: "success",
              msg: successLoginMsg,
            }),
          ); 
        //     setIsOpen(false)
        //    // Update userInfoUpdate with new data
        //     setUserInfoUpdate((prevUserInfoUpdate) => ({
        //         ...prevUserInfoUpdate,
        //         userInfo: { ...prevUserInfoUpdate.userInfo, ...data },
        //     }))
        //         ...prevUserInfoUpdate,
        //         userInfo: { ...prevUserInfoUpdate.userInfo, ...data },
        //      }))
         //   setCookie('callpal-user', JSON.stringify(obj), 1);
        // navigate(`/dashboard${routes.dashboard1}`);
     }

    return (
        <div className={classes.container}>

            <IndustrySubTypePopper industrySubTypeArry={industrySubTypeArry}  isOpen={isOpen}  updateUserIndustrySubType={updateUserIndustrySubType} />
            <div className={classes.section1}>
                <img src={logo} width={'50px'} loading="eager" />
                <div style={{ color: '#3A1C67', fontSize: '20px', paddingTop: '20px' }}><span  style={{ marginRight: '20px', paddingBottom: '10px', borderBottom: login ? '2px solid #3A1C67' : '', cursor: 'pointer' }}
                >
            <span
              style={{
                cursor: "pointer",
                paddingBottom: "10px",
                borderBottom: !login ? "2px solid black" : "",
              }}
            >
              {" "}
              Sign In
            </span>

                </span>  <span onClick={() => navigate(routes.signUp)} style={{ cursor: 'pointer', color: '#848891' }}>   SignUp</span></div>
                <form onSubmit={formik.handleSubmit}>
            <Box className={classes.form}>
                <Box style={{ color: '#3A1C67', marginBottom: '20px', fontWeight: 700, fontSize: '30px' }}>
                    {'Welcome Back!'}
                </Box>
                <Box style={{ color: '#848891', marginBottom: '10px', fontWeight: 400, fontSize: '17px' }}>
                    {'Please enter your mobile number and password'}
                </Box>

                <TextField
                    size="small"
                    placeholder="Mobile number or email*"
                    inputProps={{
                        className: classes.multilineColor,
                        autocomplete: 'off',
                    }}
                    className={classes.root} id="email" variant="outlined"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    autocomplete="off"
                />
                {formik.touched.email && formik.errors.email ? (
                <p className="text-danger text-small text-left formik-msg ">
                  {formik.errors.email}
                </p>
              ) : null}

                <TextField
                    
                    size="small"
                    placeholder="Password*"
                    inputProps={{
                        className: classes.multilineColor,
                        autocomplete: 'new-password',
                        form: {
                          autocomplete: 'off',
                        },
                    }}
                    // type="password"
                    type={showPassword ? 'password' : 'text'}

                    className={classes.root} id="password" variant="outlined"
                    name="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                />

                    <div>
                    <img
                        className="passwordEyeIcon"
                        src={showPassword ? eyeCross : viewIcon}
                        alt="Toggle Password Visibility"
                        onClick={toggleShowPassword}
                    />
                    </div>

                




             {formik.touched.password && formik.errors.password ? (
                <p className="text-danger text-small text-left formik-msg ">
                  {formik.errors.password}
                </p>
              ) : null}
                <div>
                    <Box style={{ color: '#3A1C67', fontSize: '12px', fontWeight: 600 }}>Forgot Password?</Box>
                </div>
                <div className="btns_add_user">
                    <Button className={classes.button} type="submit" disabled={formik.isSubmitting}>
                        {formik.isSubmitting ? <CircularProgress size={30} /> : 'Sign In'}
                    </Button>
                </div>
                <div className={classes.error}>{formik.errors.other}</div>
                <Box style={{ fontSize: '12px', color: '#848891', padding: '10px 0px' }}>Don't have an account? <span onClick={() => navigate(routes.signUp)} style={{ color: '#3A1C67', cursor: 'pointer' }}>Sign Up</span></Box>
                <Box style={{ fontSize: '12px', color: '#848891' }}>By clicking on Sign In, I accept The <Link style={{ textDecoration: 'none', color: '#3A1C67' }} to={routes.terms} target='_blank'>Terms & Conditions</Link> &<Link style={{ textDecoration: 'none', color: '#3A1C67' }} to={routes.privacyPolicy} target='_blank'> Privacy Policy</Link> </Box>
            </Box>
        </form>
            </div>

            <div className={classes.section2}>
                <img src={logImg} loading="eager" className={classes.image} />
            </div>
           
        </div>
    )
}
export default SignIn;