import * as React from 'react';
import Paper from '@material-ui/core/Paper';

import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import { useState, useEffect } from "react";
import { StyledTableCell,StyledTableRow  } from '../../../Styles';
import moment from "moment";

// import trashIcon from "../../../Images/trash.svg";
import EditIcon from "@material-ui/icons/Edit";
import { HashLink as Link } from "react-router-hash-link";
import { getCookie } from "../../../Utils/commonUtils";
import NoRecords from '../Other-components/NoRecords';


import { Button, TablePagination } from "@material-ui/core";
import { ViewState } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  DayView,
  Appointments,
} from '@devexpress/dx-react-scheduler-material-ui';
import Calendar from 'react-calendar';
import { makeStyles } from '@material-ui/core';

import { CalendarNewApi } from "../../../Services/CalendarNewApi";


const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: '70px 0px 0px 0px',
    textAlign: 'left'
  },
  subContainer: {
    padding: 10, 
    display: 'flex',
    //backgroundColor: '#fff'
  },

  calendarHeader: {
    // color: "#3A1C67",
    // padding: "10px",
    // fontSize : "14px",
    // fontWeight: 700,
    //marginTop: 20,
    marginBottom: 12,
    padding:"12px",
    fontSize: 18,
    fontWeight: 600,
    color: '#3A1C67',
    background:"#FFF"
},
calendarData: {
  color: "#F86E20",
  // padding: "10px",
  padding: "1px 30px 1px 25px",
  marginBottom:"12px",
  fontSize : 14,
  fontWeight: 600,
  background: "#FFF"
 
},
calendarLeftCol :{
  textAlign: "left"
  

},
calendarCenterCol :{
  textAlign: "center"
},
calendarRightCol :{
  textAlign:"right"
},
calendarNoRecordFound: {
  position: 'absolute',
  top: '25%',
  left: '70%',
  transform: 'translate(-50%, -50%)'
},
calendarDataTitle: {
  color: "#FA9510 !important",
  fontWeight:"normal"
},


  activeMeeting: {
    width: 20,
    height: 20,
    //backgroundColor:'#3A1C67',
    borderRadius: '5px',
    marginRight: 20,
    border: '1px solid #3A1C67'
  },
  meetingHeading: {
    display: 'flex', paddingBottom: 15, fontSize: 12, color: '#3A1C67', fontWeight: 600
  },
  dateHeading: {
    //marginTop: 20,
    marginBottom: 12,
    padding:"12px",
    fontSize: 18,
    fontWeight: 600,
    color: '#3A1C67',
    background:"#FFF"
  },
  eventPaper:{
    maxHeight:550,
    overflowY:'scroll',
    '&::-webkit-scrollbar': {
      width: '0.5em',
      display: 'block'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'darkgrey',
      outline: '1px solid slategrey',
      borderRadius: '5px'
    }
  }
}))

// In here we pass the information of schedule


// const Appointment = ({ children, style, data }) => (
//   <Appointments.Appointment
//     //{...restProps}
//     style={{
//       ...style,
//       backgroundColor: data.backgroundColor,
//       color: '#3A1C67',
//       border: `2px solid ${data.borderColor}`
//     }}
//   >
//     {children}
//   </Appointments.Appointment>
// );
// const AppointmentContent = (props) => {
  


//   return (
//     <Appointments.AppointmentContent
//     // this code is use to change the text color of schedule
//       style={{
//         ...props.style,
//         color: '#3A1C67'
//       }}
//       {...props}
//     />
//   );
// };



const CalendarEvents = () => {
  // const [value, setValue] = React.useState(new Date())
  const [value, setValue] = useState(moment().format('YYYY-MM-DD'));
  const classes = useStyles()
  const [loading, setLoading] = useState(false);
  const loginInfo = getCookie("callpal-user");
  const [rows, setRows] = useState([]);
  const [day, setDate] = useState();
  const [data, setData] = useState([]);


  const handleCalendarDateChange = (e) => {
    const date= moment(e).format('YYYY-MM-DD')
    setValue(date)
  }

  useEffect(() =>{
    getCalendarData();
  },[value])

  const getCalendarData = async () => {
    try {
      setLoading(true);
      const res = await CalendarNewApi.getCalendarList({ follow_up_date: value });
      setLoading(false);
      if (res.status) {
        setDate(res.day)
        setData(res.data);
        
      }
    } catch (error) {
      console.error('Error fetching calendar data:', error);
      setLoading(false);
    }
  };
  return (
    <div className={classes.mainContainer}>
      <div className={classes.subContainer}>
        <div className='col-3' style={{background:"#FFF", padding:"10px"}}>
          <Calendar onChange={handleCalendarDateChange} value={value} />
        </div>
        <div className='col-9'>
          <div style={{ marginLeft: "15px" }}>
            <div className={`col-12 text-center ${classes.dateHeading}`}>{day}</div>
            <div  className={classes.calendarNoRecordFound} >
              <NoRecords rows={data} loading={loading} />
            </div>
            
            {data.map((item, index) => (
              <div key={index} className={`${classes.calendarData}`}>
                <div className={`col-12 text-center ${classes.dateHeading}`}>{item.time}</div>
                <div className={`col-12 text-center mt-10` }>
                  {item.followUpList.map((followUp, i) => (
                    <div key={i} className='row' style={{ marginBottom: "25px"  }}>
                      <div className={`col-4 ${classes.calendarLeftCol}`}>  <span className={classes.calendarDataTitle}>Lead Name :</span>  {followUp.name}</div>
                      <div className={`col-4 ${classes.calendarCenterCol}`}> <span className={classes.calendarDataTitle}>Telecaller :</span> {followUp.telecaller}</div>
                      <div className={`col-4 ${classes.calendarRightCol}`}> <span className={classes.calendarDataTitle}>Status :</span>  {followUp.status}</div>
                    </div>
                    
                  ))}
                </div>
              </div>
            ))}

            

            
             
          </div>
          
        </div>
      </div>
    </div>
  );
  
}
export default CalendarEvents