import { Routes, Route } from "react-router-dom";
import SuperAdmin from "./Components/Dashboard/SuperAdmin";
import Team from "./Components/Dashboard/Team";
import TeamDetail from "./Components/Dashboard/TeamDetail";
import Subscription from "./Components/Dashboard/Subscription";
import CompanyDetails from "./Components/Dashboard/CompanyDetails";
import SmsTemplate from "./Components/Dashboard/Team-Lead/SmsTemplate";
import AutoDialConfig from "./Components/Dashboard/Team-Lead/AutoDialConfig";
import CallLogs from "./Components/Dashboard/Call-Log/CallLogs";
import CallLogsTeamLead from "./Components/Dashboard/Call-Log/CallLogsTeamLead";
// import CallAllocation from "./Components/Dashboard/Team-Lead/CallAllocation";
import CallAllocation from "./Components/BulkUpload/CallAllocation"
import HelpCenter from "./Components/Dashboard/HelpCenter";
import Settings from "./Components/Dashboard/Settings";
import TeamView from "./Components/Dashboard/TeamView";
import WhatsappTemplate from "./Components/Dashboard/Team-Lead/WhatsappTemplate";
import ReassignCustomer from "./Components/Dashboard/Team-Lead/ReassignCustomer";

import { routes } from "./constants/routes";

import CalendarEvents from "./Components/Dashboard/Team-Lead/Calendar";
import FollowUp from "./Components/Follow-Up/FollowUp";
import FollowUpTeamLead from "./Components/Follow-Up/FollowUpTeamLead";

import DataAllocation from "./Components/Dashboard/Data-Allocation/DataAllocation";
import DataAllocationTeamLead from "./Components/Dashboard/Data-Allocation/DataAllocationTeamLead";
import Customer from "./Components/Dashboard/Customers/Customer";
import CustomerTeamLead from "./Components/Dashboard/Customers/CustomerTeamLead";
import ErrorBoundary from "./Components/ErrorBoundary";
import TeamLiveStatus from "./Components/Dashboard/Team-Live-Status/TeamLiveStatus";
import TeamLiveStatusTeamLead from "./Components/Dashboard/Team-Live-Status/TeamLiveStatusTeamLead";
import Reports from "./Components/Dashboard/Team-Lead/Reports";
import Enquiries from "./Components/Dashboard/Team-Lead/Enquiries";
import CustomerHistory from "./Components/Dashboard/Other-components/CustomerHistory";
import CustomerStatusPopper from "./Components/Dashboard/Other-components/CustomerStatusPopper";
import CustomerBank from "./Components/Dashboard/Customers/CustomerBank";
import TeamViewDetalis from "./Components/Dashboard/TeamViewDetail";
import ReassignHistory from "./Components/Dashboard/Team-Lead/ReassignHistory";
import SubscriptionDetail from "./Components/Dashboard/SubscriptionDetail";
import AccessModule from "./Components/Dashboard/AccessModule";
import ManageModulePermission from "./Components/Dashboard/Team-Lead/ManageModulePermission";
import Drr from "./Components/DRR/DRR";
import DRRTeleCaller from "./Components/DRR/DRRTeleCaller";
import TeamLeadDataAccuracy from "./Components/DataAccuracy/TeamLeadDataAccuracy";
import MasterData from "./Components/MasterData/MasterData";
import LeadTransfer from "./Components/LeadTransfer/LeadTransfer";
import TaleCallerDataAccuracy from "./Components/DataAccuracy/TaleCallerDataAccuracy";
const Screens = () => {
  return (
    <Routes>
      <Route
        exact
        path={routes.dashboard1}
        element={<SuperAdmin />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        exact
        path={routes.team}
        element={<Team />}
        errorElement={<ErrorBoundary />}
      />
      <Route path={routes.enquiries} element={<Enquiries />}></Route>

      <Route
        exact
        path={routes.teamdetail}
        element={<TeamDetail />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        exact
        path={routes.subscription}
        element={<Subscription />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        exact
        path={routes.companyDetails}
        element={<CompanyDetails />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        exact
        path={routes.smsTemplate}
        element={<SmsTemplate />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        exact
        path={routes.autoDial}
        element={<AutoDialConfig />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        exact
        path={routes.masterData}
        element={<MasterData />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        exact
        path={routes.callLogs}
        element={<CallLogs />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        exact
        path={routes.callLogsTeamLead}
        element={<CallLogsTeamLead />}
        errorElement={<ErrorBoundary />}
      />
     
      <Route
        exact
        path={routes.bulkDataUpload}
        element={<CallAllocation />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        exact
        path={routes.help}
        element={<HelpCenter />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        exact
        path={routes.settings}
        element={<Settings />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        exact
        path={routes.teamView}
        element={<TeamView />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        exact
        path={`${routes.teamViewDetail}/:teamViewId`}
        element={<TeamViewDetalis />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        exact
        path={routes.whatsappTemplate}
        element={<WhatsappTemplate />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        exact
        path={routes.reassignData}
        element={<ReassignCustomer />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        exact
        path={routes.leadTransfer}
        element={<LeadTransfer />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        exact
        path={routes.calendar}
        element={<CalendarEvents />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        exact
        path={routes.followUps}
        element={<FollowUp />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        exact
        path={routes.followUpsTeamLead}
        element={<FollowUpTeamLead />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        exact
        path={routes.dataAllocation}
        element={<DataAllocation />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        exact
        path={routes.dataAllocationTeamLead}
        element={<DataAllocationTeamLead />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        exact
        path={routes.customer}
        element={<Customer />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        exact
        path={routes.customerTeamLead}
        element={<CustomerTeamLead />}
        errorElement={<ErrorBoundary />}
      />
      <Route path={routes.reports} element={<Reports />}></Route>
      <Route
        exact
        path={routes.teamLiveStatus}
        element={<TeamLiveStatus />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        exact
        path={routes.teamLiveStatusTeamLead}
        element={<TeamLiveStatusTeamLead />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        exact
        path={`${routes.customerHistory}/:customerID`}
        element={<CustomerHistory />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        exact
        path={`${routes.bank}/:leadId`}
        element={<CustomerBank />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        exact
        path={routes.reassignHistory}
        element={<ReassignHistory />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        exact
        path={routes.addSubscriptionMember}
        element={<SubscriptionDetail />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        exact
        path={routes.accessModule}
        element={<AccessModule />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        exact
        path={routes.manageModulePermission}
        element={<ManageModulePermission />}
        errorElement={<ErrorBoundary />}
      />

  <Route
        exact
        path={routes.drr}
        element={<Drr/>}
        errorElement={<ErrorBoundary />}
      />
      <Route
        exact
        path={routes.drrTeleCaller}
        element={<DRRTeleCaller/>}
        errorElement={<ErrorBoundary />}
      />
       <Route
        exact
        path={routes.dataAccuracyTeamLead}
        element={<TeamLeadDataAccuracy/>}
        errorElement={<ErrorBoundary />}
      />
        <Route
        exact
        path={routes.dataAccuracyTeleCaller}
        element={<TaleCallerDataAccuracy/>}
        errorElement={<ErrorBoundary />}
      />


      {/* <Route exact path={routes.test} element={<CustomerStatusPopper/>} errorElement={<ErrorBoundary />} /> */}
    </Routes>
  );
};
export default Screens;
