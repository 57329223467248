import CryptoAES from 'crypto-js/aes'
import CryptoENC from 'crypto-js/enc-utf8'
export const getCookie = (name) => {
  var cookieName = name + '='

  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      let value = c.substring(cookieName.length, c.length)
      if (Number.isInteger(parseInt(value))) {
        return parseInt(value)
      } else {
        let _cipherText = CryptoAES.decrypt(value.toString(), 'secretKey')
        let cookieValue = JSON.parse(_cipherText.toString(CryptoENC))
        return cookieValue
      }
    }
  }
  return null
}

export const setCookie = (name, value, days) => {
  var expires = ''
  if (days) {
    var date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }
  var encryptedValue = CryptoAES.encrypt(value, 'secretKey')
  document.cookie = name + '=' + encryptedValue + expires + '; path=/'
}

export const isValidEmail = (email) => {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const addUserApi=async(userInput,userInfo,roleId)=>{
  debugger
  try{
    const obj={
      id: userInput.id,
      name: userInput.name,
      email: userInput.email,
      phone_numbers: userInput.phone_numbers,
      role_id: roleId.role_id,
      password: userInput.password,
      reporting_to: roleId.report_id,
      designation:roleId.design_id
    }
  const res=await fetch(`${process.env.REACT_APP_API_ENDPOINT}/team/add_update_team_user`,{
    method:'POST',
    headers: {
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userInfo.token}`
    },
    body:JSON.stringify(obj)
  })
  const data=await res.json()
 
  return data
}
catch(e){
 return ''
}
}

export const reportingMasterApi=async(userInfo,is_telecaller)=>{
  try{
    const obj={
      telecaller: is_telecaller
    };
    const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/reporting-master`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userInfo.token}`
    },
    body:JSON.stringify(obj)
    })
    const data = await res.json()
    if (data.status) {
      return data;
    }
  return false
}
catch (e) {
  console.log(e.message)
  return false
}
}


// export const statusMasterApi=async(userInfo)=>{
//   try{

//     const obj={
//       industry_sub_type: getCookie('callpal-user')?.userInfo?.industry_sub_type
//     };

//     const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/status-master`, {
//     method: 'POST',
//     headers: {
//       'Accept': 'application/json',
//       'Access-Control-Allow-Origin': '*',
//       'Content-Type': 'application/json',
//       Authorization: `Bearer ${userInfo.token}`
//     },
//     body:JSON.stringify(obj)
//     })
//     const data = await res.json()
//     if (data.status) {
//       return data;
//     }
//   return false
// }
// catch (e) {
//   console.log(e.message)
//   return false
// }
// }

export const roleMasterApi=async(userInfo)=>{
  try{
    const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/role-master`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    })
    const data = await res.json()
   
    if (data.status) {
      return data;
    }
   return false
  }
  catch (e) {
    console.log(e.message)
    return false
  }
}