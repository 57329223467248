import axios from "axios"
import { getCookie } from '../Utils/commonUtils'


export const BankApi = {
  getLeandBankDetails: async (id) => {
    try {
       
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/get-lead-bank-record/${id}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getCookie('callpal-user').token}`
          }
        });
        return response;
      } catch (error) {
        console.error('Error in deleteTeam:', error);
        return false
        //throw error; // Re-throw the error to handle it at the calling site if needed
      }
  },
  leadFileClose: async (id) => {
    try {
       
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/lead-file-close/${id}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getCookie('callpal-user').token}`
          }
        });
        return response;
      } catch (error) {
        console.error('Error in deleteTeam:', error);
        return false
        //throw error; // Re-throw the error to handle it at the calling site if needed
      }
  },
  addBankLogin: async (data) => {
    try {
       
        const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/add-lead-login-bank`, data, {
          headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getCookie('callpal-user').token}`
          }
        });
        return response; // Assuming your data is in response.data
      } catch (error) {
        console.error('Error in getTeamList:', error);
        return false
        //throw error; // Re-throw the error to handle it at the calling site if needed
      }
  },
  addBankSanction: async (data) => {
    try {
       
        const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/add-lead-sanction-bank`, data, {
          headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getCookie('callpal-user').token}`
          }
        });
        return response; // Assuming your data is in response.data
      } catch (error) {
        console.error('Error in getTeamList:', error);
        return false
        //throw error; // Re-throw the error to handle it at the calling site if needed
      }
  },
  getBankList: async () => {
    try {
       
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/bank-list`, {
          headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getCookie('callpal-user').token}`
          }
        });
        return response; // Assuming your data is in response.data
      } catch (error) {
        console.error('Error in getTeamList:', error);
        return false
        //throw error; // Re-throw the error to handle it at the calling site if needed
      }
  }


}