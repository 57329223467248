import { Box, Button, Container, Divider, makeStyles } from "@material-ui/core"
import Footer from "../Footer"
import Header from "../Header"
import rupee from '../../Images/rupee.png'

const useStyles = makeStyles((theme) => ({
    header: {
        fontSize: '30px',
        color: '#3A1C67',
        fontWeight: 700,
        padding: '10px 0px'
    },
    greySection: {
        color: '#848891',
        fontSize: '18px',
        fontWeight: 400
    },
    container: {
        marginTop: '50px',
        margin:'0px 40px',
        display: 'flex',
        alignItems: 'end',
        paddingBottom: '40px',
        justifyContent:'center',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'center',
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
        },

    },
    box1: {
        height: '256px',
        width: '250px',
        borderRadius: '20px',
        backgroundColor: '#fff',
        position: 'relative',
        padding: '30px',
        border: '2px solid #E41C61',
        margin: '0px 30px',
        cursor:'pointer',
        transition:'.5s all ease-in-out',
        '&:hover': {
            boxShadow: '0 0 22px rgba(33,33,33,.7)',
            padding: '30px',
        },
        [theme.breakpoints.down('xs')]: {

            marginTop: '40px'
        },
        [theme.breakpoints.down('sm')]: {

            marginTop: '40px'
        }

    },
    box2: {
        height: '256px',
        width: '250px',
        borderRadius: '20px',
        backgroundColor: '#fff',
        position: 'relative',
        padding: '30px',
        border: '2px solid #FA9510',
        margin: '0px 30px',
        transition:'.5s all ease-in-out',
        cursor:'pointer',
        '&:hover': { boxShadow: '0 0 22px rgba(33,33,33,.7)' },
        [theme.breakpoints.down('xs')]: {

            marginTop: '40px'
        },
        [theme.breakpoints.down('sm')]: {
           
            marginTop: '40px'
        }
    },
    bigBox: {
        height: '301px',
        width: '300px',
        borderRadius: '20px',
        backgroundColor: '#fff',
        padding: '30px',
        margin: '0px 40px',
        border: '2px solid #41256B',
        position: 'relative',
        transition:'.5s all ease-in-out',
        cursor:'pointer',
        '&:hover': { boxShadow: '0 0 22px rgba(33,33,33,.7)' },
        [theme.breakpoints.down('xs')]: {
          
            marginTop: '40px'
        },
       
        [theme.breakpoints.down('sm')]: {
           
           
            marginTop: '40px'
        }
    },
    button1: {

        position: 'absolute',
        backgroundColor: 'pink',
        height: '45px',
        width: '155px',
        borderRadius: '16px',
        background: "linear-gradient(#E41C61,#FC5FAA,#FF97ED)",
        fontSize: '14px',
        color: '#fff',
        right: '18%',
        bottom: '-25px',
        [theme.breakpoints.down('sm')]: {
            height: '40px',
            fontSize: '12px',
            width: '144px',
            right: '20%',
        },
        [theme.breakpoints.down('xs')]: {
           
            bottom: '-20px'
        },
    },
    button2: {

        position: 'absolute',
        backgroundColor: 'pink',
        height: '50px',
        width: '180px',
        borderRadius: '16px',
        background: "linear-gradient(#3A1C67,#644191,#8F69BD)",
        fontSize: '15px',
        color: '#fff',
        right: '18%',
        bottom: '-25px',
        [theme.breakpoints.down('sm')]: {
            height: '40px',
            fontSize: '12px',
            width: '144px',
            right: '25%',
        },
        [theme.breakpoints.down('xs')]: {
         
            bottom: '-20px'
        },
    },
    button3: {

        position: 'absolute',
        backgroundColor: 'pink',
        height: '45px',
        width: '155px',
        borderRadius: '16px',
        background: "linear-gradient(#A14D00,#CD7000,#FA9510)",
        fontSize: '14px',
        color: '#fff',
        right: '18%',
        bottom: '-25px',
        [theme.breakpoints.down('sm')]: {
            height: '40px',
            fontSize: '12px',
            width: '144px',
            right: '20%',
        },
        [theme.breakpoints.down('xs')]: {
         
            bottom: '-20px'
        },
    }

}))

const PricingPage = () => {
    const classes = useStyles()
    return (
        <div>
            <Header />
            <Container style={{ padding: '100px 20px 70px 20px' }}>
                <Box>
                    <Box className={classes.header}>Pricing</Box>
                </Box>
                <Box className={classes.container}>
                    {/* <Box style={{position:'relative'}}> */}
                    <Box className={classes.box1}>
                        <Box style={{ color: '#E41C61', fontSize: '18px', fontWeight: 700, paddingBottom: '15px' }}>3 Months</Box>
                        <Box style={{ fontSize: '25px', color: '#252525', paddingBottom: '15px' }}><img width={'15px'} loading="eager" src={rupee} alt="" />799/<span style={{ fontSize: '13px' }}>per month</span></Box>
                        <Box style={{ fontSize: "18px", color: '#252525' }}>Per User</Box>
                        <Divider style={{ color: '#848891', margin: '15px 0px', height: '2px' }} />
                        <Box style={{ fontSize: '18px', color: '#E41C61' }}>BILLED QUARTERLY </Box>

                        {/* </Box> */}
                        <Button className={classes.button1}>free trail</Button>
                    </Box>
                    <Box className={classes.bigBox}>
                        <Box style={{ color: '#41256B', fontSize: '22px', fontWeight: 700, paddingBottom: '15px' }}>MOST POPULAR</Box>
                        <Box style={{ color: '#41256B', fontSize: '18px', fontWeight: 700, paddingBottom: '15px' }}>6 Months</Box>
                        <Box style={{ fontSize: '25px', color: '#252525', paddingBottom: '15px' }}><img width={'15px'} loading="eager" src={rupee} alt="" />599/<span style={{ fontSize: '13px' }}>per month</span></Box>
                        <Box style={{ fontSize: "18px", color: '#252525' }}>Per User</Box>
                        <Divider style={{ color: '#848891', margin: '15px 0px', height: '2px' }} />
                        <Box style={{ fontSize: '18px', color: '#41256B' }}>BILLED HALF QUARTERLY </Box>
                        <Button className={classes.button2}>free trail</Button>
                    </Box>

                    <Box className={classes.box2}>
                        <Box style={{ color: '#FA9510', fontSize: '18px', fontWeight: 700, paddingBottom: '15px' }}>12 Months</Box>
                        <Box style={{ fontSize: '25px', color: '#252525', paddingBottom: '15px' }}><img width={'15px'} loading="eager" src={rupee} alt="" />499/<span style={{ fontSize: '13px' }}>per month</span></Box>
                        <Box style={{ fontSize: "18px", color: '#252525' }}>Per User</Box>
                        <Divider style={{ color: '#848891', margin: '15px 0px', height: '2px' }} />
                        <Box style={{ fontSize: '18px', color: '#FA9510' }}>BILLED YEARLY </Box>
                        <Button className={classes.button3}>free trail</Button>
                    </Box>

                </Box>
            </Container>
            <Footer />

        </div>
    )
}
export default PricingPage