import axios from "axios"
import { getCookie } from '../Utils/commonUtils'

export const MasterDataApi = {


  // Master Data Overview -------------------------------------------------------
  UpdateComponyInfoApi: async (data) => {
    try {
         
        const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/update-company-info`, data, {
          headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getCookie('callpal-user').token}`
          }
        });
        return response; // Assuming your data is in response.data
      } catch (error) {
        console.error('Error in UpdateComponyInfoApi:', error);
        return false
        //throw error; // Re-throw the error to handle it at the calling site if needed
      }
  },


  GetCompanyInfoApi: async (id) => {
    try {
       
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/get-company`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getCookie('callpal-user').token}`
          }
        });
        return response;
      } catch (error) {
        console.error('Error in GetCompanyInfoApi:', error);
        return false
        //throw error; // Re-throw the error to handle it at the calling site if needed
      }
  },



  // Master Department ----------------------------------------------------------

    MasterDataDepartmentList: async (data) => {
      try {
           
          const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/list-department`, data, {
            headers: {
              'Accept': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getCookie('callpal-user').token}`
            }
          });
          return response; // Assuming your data is in response.data
        } catch (error) {
          console.error('Error in MasterDataDepartmentList:', error);
          return false
          //throw error; // Re-throw the error to handle it at the calling site if needed
        }
    },
    deleteMasterDataDepartment: async (id) => {
      try {
         
          const response = await axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/delete-department/${id}`, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getCookie('callpal-user').token}`
            }
          });
          return response;
        } catch (error) {
          console.error('Error in deleteMasterDataDepartment:', error);
          return false
          //throw error; // Re-throw the error to handle it at the calling site if needed
        }
  },

  UpdateMasterDataDepartment: async (data) => {
    try {
         
        const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/add-update-department`, data, {
          headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getCookie('callpal-user').token}`
          }
        });
        return response; // Assuming your data is in response.data
      } catch (error) {
        console.error('Error in UpdateMasterDataDepartment:', error);
        return false
        //throw error; // Re-throw the error to handle it at the calling site if needed
      }
  },
  EditMasterDataDepartmentApi: async (id) => {
    try {
       
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/get-department/${id}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getCookie('callpal-user').token}`
          }
        });
        return response;
      } catch (error) {
        console.error('Error in EditMasterDataDepartmentApi:', error);
        return false
        //throw error; // Re-throw the error to handle it at the calling site if needed
      }
  },
    //  Office Timings-----------------------------------------------------------
    MasterDataOfficeTimingsList: async (data) => {
      try {
           
          const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/list-shift`, data, {
            headers: {
              'Accept': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getCookie('callpal-user').token}`
            }
          });
          return response; // Assuming your data is in response.data
        } catch (error) {
          console.error('Error in MasterDataOfficeTimingsList:', error);
          return false
          //throw error; // Re-throw the error to handle it at the calling site if needed
        }
    },
    deleteMasterDataOfficeTimings: async (id) => {
      try {
         
          const response = await axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/delete-shift/${id}`, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getCookie('callpal-user').token}`
            }
          });
          return response;
        } catch (error) {
          console.error('Error in deleteMasterDataOfficeTimings:', error);
          return false
          //throw error; // Re-throw the error to handle it at the calling site if needed
        }
  },
  UpdateMasterDataOfficeTimings: async (data) => {
    try {
         
        const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/add-update-shift`, data, {
          headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getCookie('callpal-user').token}`
          }
        });
        return response; // Assuming your data is in response.data
      } catch (error) {
        console.error('Error in UpdateMasterDataOfficeTimings:', error);
        return false
        //throw error; // Re-throw the error to handle it at the calling site if needed
      }
  },

  EditMasterDataOfficeTimingApi: async (id) => {
    try {
       
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/get-shift/${id}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getCookie('callpal-user').token}`
          }
        });
        return response;
      } catch (error) {
        console.error('Error in EditMasterDataDepartmentApi:', error);
        return false
        //throw error; // Re-throw the error to handle it at the calling site if needed
      }
  },
  // -------------------------------------
  MasterDataProcessorList: async (data) => {
    try {
         
        const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/list-process`, data, {
          headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getCookie('callpal-user').token}`
          }
        });
        return response; // Assuming your data is in response.data
      } catch (error) {
        console.error('Error in MasterDataDepartmentList:', error);
        return false
        //throw error; // Re-throw the error to handle it at the calling site if needed
      }
  },
  deleteMasterProcessor: async (id) => {
    try {
       
        const response = await axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/delete-process/${id}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getCookie('callpal-user').token}`
          }
        });
        return response;
      } catch (error) {
        console.error('Error in deleteMasterDataOfficeTimings:', error);
        return false
        //throw error; // Re-throw the error to handle it at the calling site if needed
      }
  },
  UpdateMasterDataProcessor: async (data) => {
    try {
        
        const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/add-update-process`, data, {
          headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getCookie('callpal-user').token}`
          }
        });
        return response; // Assuming your data is in response.data
      } catch (error) {
        console.error('Error in UpdateMasterDataDepartment:', error);
        return false
        //throw error; // Re-throw the error to handle it at the calling site if needed
      }
  },
  EditMasterDataProcessorApi: async (id) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/get-process/${id}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getCookie('callpal-user').token}`
          }
        });
        return response;
      } catch (error) {
        console.error('Error in EditMasterDataDepartmentApi:', error);
        return false
        //throw error; // Re-throw the error to handle it at the calling site if needed
      }
  },
  MasterDataAnnouncementList: async (data) => {
    try {
         
        const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/list-announcement`, data, {
          headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getCookie('callpal-user').token}`
          }
        });
        return response; // Assuming your data is in response.data
      } catch (error) {
        console.error('Error in MasterDataDepartmentList:', error);
        return false
        //throw error; // Re-throw the error to handle it at the calling site if needed
      }
  },
  deleteMasterAnnouncement: async (id) => {
    try {
       
        const response = await axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/delete-announcement/${id}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getCookie('callpal-user').token}`
          }
        });
        return response;
      } catch (error) {
        console.error('Error in deleteMasterDataOfficeTimings:', error);
        return false
        //throw error; // Re-throw the error to handle it at the calling site if needed
      }
  },
  EditMasterDataAnnouncementApi: async (id) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/get-announcement/${id}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getCookie('callpal-user').token}`
          }
        });
        return response;
      } catch (error) {
        console.error('Error in EditMasterDataDepartmentApi:', error);
        return false
        //throw error; // Re-throw the error to handle it at the calling site if needed
      }
  },
  UpdateMasterDataAnnouncement: async (data) => {
    try {
        
        const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/add-update-announcement`, data, {
          headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getCookie('callpal-user').token}`
          }
        });
        return response; // Assuming your data is in response.data
      } catch (error) {
        console.error('Error in UpdateMasterDataDepartment:', error);
        return false
        //throw error; // Re-throw the error to handle it at the calling site if needed
      }
  },
}